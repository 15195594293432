
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { delay, delayWhen, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
import { TemplateOutput, TemplateOutputDragged } from '../models/api/template/template-output.model';
import { Template } from '../models/api/template/template.model';
import { TemplateEditorRepositoryService } from '../repositories/pages/template-editor-repository.service';
import { TemplateEditorService } from './pages/template-editor.service';

export const MAX_ZOOM_LEVEL = 2.6;
export const MIN_ZOOM_LEVEL = 0.4;
export const ZOOM_INCREASER = 0.2;
export const INITIAL_ZOOM = 1;
export const WAIT_TIME_BETWEEEN_TRIES = 1000;

@Injectable({
    providedIn: 'root'
})
export class PdfControlsService
{
    private zoom: BehaviorSubject<number>;
    public zoom$: Observable<number>;

    private pages: BehaviorSubject<number>;
    public pages$: Observable<number>;

    private currentPage: BehaviorSubject<number>;
    public currentPage$: Observable<number>;

    private template: BehaviorSubject<Template>;
    public template$: Observable<Template>;

    public pdf$: Observable<any>;
    public isPreview$: Observable<boolean>;

    private outputToBeAdded: TemplateOutputDragged;

    private widthScaleFactor: number;
    private heightScaleFactor: number;
    private boundary: BehaviorSubject<HTMLDivElement>;

    // REFS.
    public MAX_ZOOM_LEVEL = MAX_ZOOM_LEVEL;

    constructor(
        public templateEditor: TemplateEditorService,
        private translator: TranslateService,
        private repository: TemplateEditorRepositoryService
    )
    {
        this.zoom = new BehaviorSubject<number>(INITIAL_ZOOM);
        this.zoom$ = this.zoom.asObservable();

        this.pages = new BehaviorSubject<number>(1);
        this.pages$ = this.pages.asObservable();

        this.currentPage = new BehaviorSubject<number>(1);
        this.currentPage$ = this.currentPage.asObservable();

        this.template = new BehaviorSubject(null);
        this.template$ = this.template.asObservable();

        // Referencia al pdf.
        this.pdf$ = this.templateEditor.pdf$;
        this.isPreview$ = this.templateEditor.isPreview$;

        this.boundary = new BehaviorSubject(null);
        this.boundary
            .pipe(
                mergeMap(x => this.templateEditor.template$ as Observable<Template>),
                map(
                    template =>
                    {
                        if (this.boundary.value && template && template.tpl?.id)
                        {
                            const boundary = this.boundary.value;
                            this.calculatePageIndex(template);
                            this.calculateScaleFactors(boundary, template);
                            this.applyScale(template);

                            return template;
                        }
                    })
            )
            .subscribe(
                x =>
                {
                    if (x)
                    {
                        this.template.next(x);
                    }
                },
                err => console.log(err)
            );
    }
    public reset(): void
    {
        this.boundary.next(null);
        this.template.next(null);
        this.templateEditor.reset();
        this.zoom.next(INITIAL_ZOOM);
        this.pages.next(1);
        this.currentPage.next(1);
    }
    public zoomIn(): void
    {
        const actualZoom = this.zoom.value;
        if ((actualZoom + ZOOM_INCREASER) <= MAX_ZOOM_LEVEL)
            this.zoom.next(this.zoom.value + ZOOM_INCREASER);
    }
    public zoomOut(): void
    {
        const actualZoom = this.zoom.value;
        if ((actualZoom - ZOOM_INCREASER) >= MIN_ZOOM_LEVEL)
            this.zoom.next(this.zoom.value - ZOOM_INCREASER);
    }
    public setPages(pages: number): void
    {
        this.pages.next(pages);
    }
    public nextPage(): void
    {
        const currentPage = this.currentPage.value;
        const totalPages = this.pages.value;
        if ((currentPage + 1) <= totalPages)
            this.currentPage.next(currentPage + 1);
    }
    public updateTemplateProperties(template: Template): void
    {
        this.template.next(template);
    }
    public lastPage(): void
    {
        const currentPage = this.currentPage.value;
        if ((currentPage - 1) > 0)
            this.currentPage.next(currentPage - 1);
    }
    public configureOutputToBeAdded(output: TemplateOutputDragged): void
    {
        this.outputToBeAdded = output;
    }
    public addOutput(x: number, y: number): TemplateOutputDragged
    {
        if (this.outputToBeAdded)
        {
            const outputs = this.template.value.outputs[0].dragged;
            const newOutput = Object.assign(new TemplateOutputDragged(), this.outputToBeAdded);

            // Tenemos en cuenta que pude haber un zoom aplicado.
            const xScaled = x / this.zoom.value;
            const yScaled = y / this.zoom.value;

            newOutput.location = { x: xScaled, y: yScaled, position: '' };
            newOutput.meta.pageIndex = this.currentPage.value - 1;

            newOutput.id = new Date().getTime().toString();

            outputs.push(newOutput);

            this.template.next(this.template.value);

            return newOutput;
        }
    }
    public deleteOutput(output: TemplateOutputDragged): boolean
    {
        // Lo buscamos
        const outputs = this.template.value.outputs[0].dragged;
        const found = outputs.findIndex(
            x => x == output
        );

        // Lo quitamos
        if (found != -1)
        {
            outputs.splice(found, 1);
            this.template.next(this.template.value);

            return true;
        }

        return false;
    }
    public setBoundary(boundary: HTMLDivElement): void
    {
        if (!this.boundary.value)
            this.boundary.next(boundary);
    }
    public serializeAndSave(): Observable<Template>
    {
        const finalTemplate = cloneDeep(this.template.value);
        return of(finalTemplate)
            .pipe(
                map(
                    template =>
                    {
                        this.revertScale(template);
                        this.serializeYCoords(template);

                        const holder = {};

                        for (let y = 0; y < template.tpl.contact_count; y++)
                        {
                            holder[y] = [];
                        }

                        const relevantOuts = ['firma', 'aditional_doc'];

                        template.outputs[0].dragged.forEach(output =>
                        {
                            if (relevantOuts.indexOf(output.meta.output_type) !== -1)
                            {
                                holder[output.meta.contac_idx].push(output.meta.output_type);
                            }
                        });

                        for (let y = 0; y < template.tpl.contact_count; y++)
                        {
                            if (holder[y].length === 0)
                            {
                                const msg = this.translator.instant(
                                    'Signer is not going to interact on the document', { value: (y + 1) });
                                throw new Error(msg);
                            }
                        }

                        return template;
                    }
                ),
                mergeMap(template => this.repository.uploadTplInputsConf(template.tpl.id, template.outputs))
            )
    }

    private calculateScaleFactors(boundaryElement: HTMLDivElement, template: Template): void
    {
        const boundary = boundaryElement.getBoundingClientRect();
        const boundaryWidth = boundary.width;
        const boundaryHeight = boundary.height;

        const templateWidth = template.tpl.width;
        const templateHeight = template.tpl.pageHeight;

        this.widthScaleFactor = boundaryWidth / templateWidth;
        this.heightScaleFactor = boundaryHeight / templateHeight;
    }
    private applyScale(template: Template): void
    {
        if (template.outputs.length > 0)
            template.outputs[0].dragged.forEach(
                output =>
                {
                    output.location.x *= this.widthScaleFactor;
                    output.location.y *= this.heightScaleFactor;
                    output.meta.style['width.px'] *= this.widthScaleFactor;
                    output.meta.style['height.px'] *= this.heightScaleFactor;
                }
            );
    }
    private revertScale(template: Template): void
    {
        if (template.outputs.length > 0)
            template.outputs[0].dragged.forEach(
                output =>
                {
                    output.location.x /= this.widthScaleFactor;
                    output.location.y /= this.heightScaleFactor;
                    output.meta.style['width.px'] /= this.widthScaleFactor;
                    output.meta.style['height.px'] /= this.heightScaleFactor;
                }
            );
    }
    private calculatePageIndex(template: Template): void
    {
        if (template.outputs.length > 0)
        {
            /** Primero, buscamos si algún input tiene la prop vacía.
             * Si es así, habrá que hacer el cálculo.
             */
            const pageHeight = template.tpl.pageHeight;
            const outputWithoutPage = template.outputs[0].dragged.map(x => { if (!x.meta.pageIndex) return x }).filter(x => x);
            if (outputWithoutPage.length != 0)
            {
                for (let i = 1; i <= template.tpl.pageCount; i++)
                {
                    const min = (i * pageHeight) - pageHeight;
                    const max = i * pageHeight;

                    outputWithoutPage.forEach(
                        output =>
                        {
                            const y = output.location.y;

                            if (y > min && y <= max)
                                output.meta.pageIndex = i - 1;
                        });
                }
            }

            /** Ahora, después de calcular los índices de las páginas,
             * pasamos a normalizar las coordenadas de cada una de las páginas.
             * Hay que recordar que el backend dado un:
             * template.tpl.pageHeight == 792
             * querrá decir que todo elemento que esté entre 0 y 792
             * pertenece a la página 1.
             * Por tanto, todas las páginas excepto a la 1, tenemos que restar
             * a la coord. Y el valor correcto para que se vea adecuadamente
             * en el editor.
             */
            template.outputs[0].dragged.forEach(
                output =>
                {
                    // La primera página, la skipeamos.
                    const pageIndex = output.meta.pageIndex + 1;
                    if (pageIndex > 1)
                    {
                        const factor = (pageIndex * pageHeight) - pageHeight;
                        const newY = output.location.y - factor;

                        output.location.y = newY;
                    }
                }
            );
        }
        else
        {
            template.outputs = new Array<TemplateOutput>();
            template.outputs[0] = new TemplateOutput();
            template.outputs[0].dragged = new Array<TemplateOutputDragged>();
            template.outputs[0].id = 'pdfOver';
        }
    }
    private serializeYCoords(template: Template): void
    {
        const pageHeight = template.tpl.pageHeight;
        template.outputs[0].dragged.forEach(
            output =>
            {
                // La primera página, la skipeamos.
                const pageIndex = output.meta.pageIndex + 1;
                if (pageIndex > 1)
                {
                    const factor = (pageIndex * pageHeight) - pageHeight;
                    const newY = output.location.y + factor;

                    output.location.y = newY;
                }
            }
        );
    }
}
