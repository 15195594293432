import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubtenantEditComponent } from './components/subtenant-edit/subtenant-edit.component';
import { SubtenantListComponent } from './components/subtenant-list/subtenant-list.component';
import { SubtenantManagementComponent } from './subtenant-management.component';

const routes: Routes = [
    {
        path: '',
        component: SubtenantManagementComponent,
        children: [
            {
                path: 'list',
                component: SubtenantListComponent,
            },
            {
                path: 'edit/:uuid',
                component: SubtenantEditComponent,
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SubtenantManagementRoutingModule { }
