<div class="bar top">
    <div class="ball"></div>
    <div>MyRubric</div>
    <mat-icon>search</mat-icon>
    <mat-icon>more_horiz</mat-icon>
</div>
<div>
    <div class="message-left info">
        {{
            "This is a company account chat, Click here to see more info"
                | translate
        }}
    </div>
    <div class="message-left mat-elevation-z2">
        <p>
            {{ "SIGN_REQ_TPL" | translate: translationParams }}
        </p>
    </div>
</div>
<div class="bar footer">
    <mat-icon>mood</mat-icon>
    <mat-icon>attach_file</mat-icon>
    <div class="enter-text">
        {{ "Write the text of your message" | translate }}
    </div>
    <mat-icon>mic</mat-icon>
</div>
