import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { delay, delayWhen, map, retryWhen, tap } from 'rxjs/operators';
import { Input as InputElement } from 'src/app/core/models/template-editor/input.model';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
    selector: 'app-editable-editor',
    templateUrl: './editable-editor.component.html',
    styleUrls: ['./editable-editor.component.scss']
})
export class EditableEditorComponent implements OnInit
{
    @Output() lastPageHasBeenVisited = new EventEmitter<boolean>();
    @Input() templateId: number;
    @Input() src: string;
    private dpiRatio = 96 / 72;
    public myForm: FormGroup;
    public inputList: InputElement[] = [];
    private zoom = new BehaviorSubject(1);
    public zoom$: Observable<number>;
    public currentPage: number = 1;
    public totalPages: number = 1;
    public proxy: PDFDocumentProxy;
    public anotationsLoadEnd$: Observable<boolean>;

    constructor(
        private _fb: FormBuilder,
        public responsive: ResponsiveService,
        public editor: TemplateEditorService
    )
    {
        this.myForm = this._fb.group({});
        this.zoom$ = this.zoom.asObservable();
    }

    ngOnInit(): void
    {
        if (!this.src)
            this.editor.loadForm(this.templateId);

        this.anotationsLoadEnd$ = interval(1500)
        .pipe(
            map(
                () =>
                {
                    if (this.myForm)
                    {
                        const totalControls = Object.keys(this.myForm.controls).length;
                        if (totalControls > 0)
                            return true;
                    }

                    throw 'No match';
                }
            ),
            retryWhen(
                errors => errors.pipe(
                    tap(
                        () => console.log('No está listo aún')
                    ),
                    delay(750)
                )
            )
        )
    }

    public loadComplete(pdf: PDFDocumentProxy): void
    {
        this.totalPages = pdf.numPages;
        for (let i = 1; i <= pdf.numPages; i++)
        {

            // track the current page
            let currentPage = null;
            pdf.getPage(i).then(p =>
            {
                currentPage = p;

                // get the annotations of the current page
                return p.getAnnotations();
            }).then(ann =>
            {

                // ugly cast due to missing typescript definitions
                // please contribute to complete @types/pdfjs-dist
                const annotations = (<any>ann) as PDFAnnotationData[];

                annotations
                    .filter(a => a.subtype === 'Widget') // get the form field annotation only
                    .forEach(a =>
                    {

                        // get the rectangle that represent the single field
                        // and resize it according to the current DPI
                        const fieldRect = currentPage.getViewport({scale: this.dpiRatio})
                            .convertToViewportRectangle(a.rect);

                        // add the corresponding input
                        this.addInput(a, fieldRect, i);
                    });
            });
        }


        this.lastPageHasBeenVisited.emit(this.totalPages === 1);

    }
    public getInputPosition(input: InputElement): any
    {
        const styles = {
            top: `${ input.top }px`,
            left: `${ input.left }px`,
            height: `${ input.height }px`,
            width: `${ input.width }px`,
            display: this.currentPage == input.page ? 'block' : 'none'
        } as any;

        if (input.type == 'text' || input.type == 'select')
        {
            let fsize = (input.height - 8) < 8 ? 8 : (input.height - 8);
            styles.fontSize = (fsize) + 'px';
        }

        return styles;
    }
    public zoomIn(): void
    {
        let currentZoom = this.zoom.value;
        this.inputList = this.inputList.map(i =>
        {
            i.left *= (.25 / currentZoom) + 1;
            i.top *= (.25 / currentZoom) + 1;
            i.width *= (.25 / currentZoom) + 1;
            i.height *= (.25 / currentZoom) + 1;
            return i;
        });
        this.zoom.next(currentZoom += .25);
    }
    public zoomOut(): void
    {
        let currentZoom = this.zoom.value;
        if (currentZoom - .25 <= 0)
        {
            alert("No puedes quitar más zoom");
        }
        else
        {
            this.inputList = this.inputList.map(i =>
            {
                i.left *= 1 - (.25 / currentZoom);
                i.top *= 1 - (.25 / currentZoom);
                i.width *= 1 - (.25 / currentZoom);
                i.height *= 1 - (.25 / currentZoom);
                return i;
            });
            this.zoom.next(currentZoom -= .25);
        }
    }
    public nextPage(): void
    {
        if (this.currentPage + 1 <= this.totalPages)
            this.currentPage++;


        this.lastPageHasBeenVisited.emit(this.totalPages === this.currentPage);
    }
    public previousPage(): void
    {
        if (this.currentPage - 1 > 0)
            this.currentPage--;

        this.lastPageHasBeenVisited.emit(this.totalPages === this.currentPage);

    }
    public checkboxChange(elem: InputElement): void
    {
        const val = this.myForm.controls[elem.name].value;
        if (val == true)
            this.myForm.controls[elem.name].setValue(elem.value);

        console.log(this.myForm.controls[elem.name].value);
    }

    private createInput(annotation: PDFAnnotationData, rect: number[] = null, page: number)
    {
        let formControl = new FormControl(annotation.buttonValue || '');

        const input = new InputElement();
        input.page = page;
        input.name = annotation.fieldName;

        if (annotation.fieldType === 'Tx')
        {
            input.type = 'text';
            if (annotation.multiLine)
                input.type = 'text_area';
            input.value = annotation.fieldValue || '';
        }

        if (annotation.fieldType === 'Btn' && !annotation.checkBox)
        {
            input.type = 'radio';
            input.value = annotation.buttonValue;
        }

        if (annotation.checkBox)
        {
            input.type = 'checkbox';
            input.value = annotation.exportValue;
            formControl = new FormControl(annotation.exportValue || false);
            console.log(annotation);
        }

        if (annotation.fieldType == 'Ch')
        {
            input.type = "select";
            input.value = annotation.fieldValue || '';
            input.options = annotation.options;
            input.multiSelect = annotation.multiSelect
            if (!annotation.combo)
                input.type = "list";
            if (input.value instanceof Array && !annotation.multiSelect)
            {
                input.value = input.value[0];
            }

            formControl = new FormControl(input.value || '');
        }

        // Calculate all the positions and sizes
        if (rect)
        {
            input.top = rect[1] - (rect[1] - rect[3]);
            input.left = rect[0];
            input.height = (rect[1] - rect[3]) * .9;
            input.width = (rect[2] - rect[0]);
        }

        this.inputList.push(input);
        return formControl;
    }
    private addInput(annotation: PDFAnnotationData, rect: number[] = null, page: number): void
    {
        // add input to page
        this.myForm.addControl(annotation.fieldName, this.createInput(annotation, rect, page));
    }
}
