import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LegalVerificationRepositoryService } from 'src/app/core/repositories/content/legal-verification-repository/legal-verification-repository.service';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';


@Injectable({
  providedIn: 'root'
})
export class LegalVerificationService {

  private verified: BehaviorSubject<Boolean>;
  public verified$: Observable<Boolean>;

  private verifiedLegalIdentification: BehaviorSubject<string>;
  public verifiedLegalIdentification$: Observable<string>;

  constructor(
    private repository: LegalVerificationRepositoryService,
  ){

    this.verified = new BehaviorSubject(false);
    this.verified$ = this.verified.asObservable();

    this.verifiedLegalIdentification = new BehaviorSubject("");
    this.verifiedLegalIdentification$ = this.verifiedLegalIdentification.asObservable();

  }

  public verifyLegalIdentifier(legal_identification: string, config:DialogData<{token: string, type: string}>): void{

    this.repository.verifyLegalIdentifier(legal_identification, config)
    .pipe(
      take(1)
    )
    .subscribe(
      result =>
      {
        if (result.verified)
        {
            this.verified.next(result.verified);
            this.verifiedLegalIdentification.next(legal_identification)
        }
      }
    )
  }

  public getTenantLegalVerification(): Observable<{id: number, created_at: string, update_at: string, key: string, type: string, value: string}>{
    return this.repository.getTenantLegalVerification()
  }

}
