import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SubtenantInvoicesAction from './actions';
import { SubtenantService } from 'src/app/core/services/pages/subtenant.service';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class SubtenantEffects
{
    constructor(
        private actions$: Actions,
        private service: SubtenantService
    ) { }

    fetch$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantInvoicesAction.Fetch>(SubtenantInvoicesAction.SubtenantInvoicesActionTypes.FETCH),
                mergeMap(action => this.service.fetchSubtenantInvoices(action.payload)
                    .pipe(
                        map(data => new SubtenantInvoicesAction.FetchSuccess(data)),
                        catchError(() => of(new SubtenantInvoicesAction.FetchFail()))
                    )
                )
            )
    );
}