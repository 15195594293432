import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GROUP_TIPS_CREATE } from 'src/app/configs/dicctionaries/groups/group-management.dictionary';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
    selector: 'app-create-group',
    templateUrl: './create-group.component.html',
    styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit
{
    @HostBinding('class') class = 'mat-typography';
    public newGroup: FormGroup;
    public createTip = GROUP_TIPS_CREATE;

    constructor(
        public dialogRef: MatDialogRef<CreateGroupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: number,
        public responsive: ResponsiveService,
        public dialog: MatDialog
    )
    {
        this.newGroup = new FormGroup({
            name: new FormControl('', Validators.required),
            type: new FormControl('contacts', Validators.required)
        });

        if (data)
            this.newGroup.addControl('parent_id', new FormControl(data));
    }

    ngOnInit(): void
    {
    }

    public cancel(): void
    {
        this.dialogRef.close(null);
    }
    public save(): void
    {
        if (this.newGroup.valid)
        {
            this.dialogRef.close(this.newGroup.value)
        }
    }
    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: [this.createTip]
        });
    }

}
