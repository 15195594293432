import { Tip } from 'src/app/core/models/tip';

export const TEMPLATE_TIPS: Tip[] = [
    {
        title: 'Choose elements',
        body: `tip_8_body`
    },
    {
        title: 'Field props',
        body: 'tip_9_body'
    },
    {
        title: 'Clone fields',
        body: 'tip_10_body'
    },
    // {
    //     title: 'Zoom',
    //     body: 'tip_11_body'
    // },
    // {
    //     title: 'Change page',
    //     body: 'tip_12_body'
    // },
    {
        title: 'Aditional content',
        body: 'tip_13_body'
    }
];