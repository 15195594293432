import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GeonamesAction from './actions';
import { Store } from '@ngrx/store';
import { RootStoreState } from '..';
import { GeoNamesService } from 'src/app/shared/geo-names.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class GeonamesEffects
{
    constructor(
        private actions$: Actions,
        private store$: Store<RootStoreState.State>,
        private service: GeoNamesService
    ) { }

    fetch$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<GeonamesAction.Fetch>(GeonamesAction.GeonamesActionTypes.FETCH),
                mergeMap(
                    action => this.service.loadCountries()
                        .pipe(
                            map(data => new GeonamesAction.FetchSuccess(data)),
                            catchError(() => of(new GeonamesAction.FetchFail()))
                        )
                )
            )
    );
}
