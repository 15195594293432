<div *ngIf="tileData$ | async as tileData; else loading"
     fxLayout="column" fxFlex style="background-color: #29abe233;border-radius: 15px; overflow: hidden; padding: 15px;">
    <div fxLayout="row" fxFlex="40px" fxLayoutAlign="start center">
        <div class="fab" fxLayoutAlign="center center" [style.color]="'white'" [style.backgroundColor]="'#FF82B1'">
            <mat-icon>format_list_bulleted</mat-icon>
        </div>
        <span class="kpi-box-title">{{ 'Recent activity' | translate }}</span>
    </div>
    <div fxLayout="row" fxFlex style="overflow-y: auto; overflow-x: hidden; padding-top: 15px;">
        <div fxLayout="column" fxFlex>
            <div fxLayout="row" *ngFor="let log of tileData" style="margin-bottom: 5px; padding-bottom: 5px; border-bottom: 1px solid #ccc">
                <div fxLayout="column">
                    <div fxLayout="row" fxFlex fxLayoutAlign="center center">
                        <mat-icon style="margin-right: 15px;">{{ iconByState(log.action) }}</mat-icon>
                    </div>
                </div>
                <div fxLayout="column" fxFlex>
                    <div fxLayout="row">
                        <span style="margin-right: 15px;"> {{ log.date| prepareDate | date:'short' }}</span>
                    </div>
                    <div fxLayout="row" style="color: #707070; font-size: 12px;line-height: 1;" fxLayoutAlign="start center">
                        {{ log.email }}
                        <mat-icon style="color: black;">arrow_right_alt</mat-icon>
                        {{ log.template }}
                        <mat-icon style="color: black;">arrow_right_alt</mat-icon>
                        <mat-chip-list>
                            <mat-chip color="primary" selected style="font-size: 12px; line-height: 1!important; text-align: center!important;">
                                {{ log.action | translate}}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div fxLayout="column" fxFlex style="background-color: #f7f7f7;border-radius: 15px; overflow: hidden; padding: 15px;">
        <div fxLayout="row" fxFlex="40px" fxLayoutAlign="start center">
            <div class="fab" fxLayoutAlign="center center" [style.color]="'white'" [style.backgroundColor]="'#FF82B1'">
                <mat-icon>format_list_bulleted</mat-icon>
            </div>
            <span class="kpi-box-title">{{ 'Recent activity' | translate }}</span>
        </div>
        <div fxLayout="row" fxFlex fxLayoutAlign="space-between end">
        </div>
    </div>
</ng-template>
