

<h1 mat-dialog-title>{{ 'import report' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
    <div class="flex-row">
        <span class="bold mar-r5"> {{ "rows to import" | translate }}: </span>
        <span> </span>
        {{ data.totalRows }}
    </div>

    <div class="flex-row">
        <span class="bold mar-r5"> {{ "imported registers" | translate }}: </span>
        <span> </span>
        {{ data.inserts }}
    </div>

    <div class="flex-row">
        <span class="bold mar-r5"> {{ "updated registers" | translate }}: </span>
        <span> </span>
        {{ data.updates }}
    </div>

    <br>
    <div class="flex-row">
        <span class="bold"> {{ "errors" | translate }} </span>
    </div>
    <table mat-table #table [dataSource]="tableAdapter.data" class="full-width">
        <ng-container *ngFor="let colDef of tableAdapter.getStdCols()">
            <ng-container [matColumnDef]="colDef.column">
                <th mat-header-cell *matHeaderCellDef>{{colDef.columnTitle}}</th>
                <td mat-cell *matCellDef="let row">{{row[colDef.column]}}</td>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableAdapter.getAllColumnsName()"></tr>
        <tr mat-row *matRowDef="let row; columns: tableAdapter.getAllColumnsName();"></tr>
    </table>
</div>
<div mat-dialog-actions class="flex-reverse-row full-width">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</div>
