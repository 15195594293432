import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class PaginatorService
{
    public resetPagination$: Subject<boolean> = new Subject<boolean>();
}