import { EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { Component, OnInit } from '@angular/core';
import { iDialogBase } from 'src/app/cross-platform-dialog/shared/iDialogBase';


@Component({
    selector: 'app-use-chrm-plz',
    templateUrl: './use-chrm-plz.component.html',
    styleUrls: ['./use-chrm-plz.component.css']
})
export class UseChrmPlzComponent implements OnInit, iDialogBase {

    @ViewChild("daInput", {read: ElementRef}) daInput: ElementRef;

    // #region Interface Opts.
    binding: EventEmitter<boolean>;
    public data: DialogData<any>;
    public setData(data: any) {
        this.data = data;
    }

    action(args?: any) {
        this.daInput.nativeElement.select();
        document.execCommand("copy");

        // No ejecutamos la binding para que no se cierre el dialog.
        //this.binding.emit(true);
    }

    public link: string;

    constructor() {
        this.binding = new EventEmitter();
        this.link = window.location.href;
    }

    ngOnInit() {}
}
