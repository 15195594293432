<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <app-mobile-stepper [currentStep]="3"></app-mobile-stepper>
    <div class="title flex-row-between">
        3. {{ "Set up the contacts" | translate }}
        <button (click)="openHelp()" color="primary" mat-icon-button>
            <mat-icon>help</mat-icon>
        </button>
    </div>
    <div>
        <mat-radio-group
            [(ngModel)]="sendType"
            style="display: flex;justify-content: space-between;"
            class="radio-container choose-opt"
        >
            <mat-radio-button
                (click)="deployAmount = 1; selectedContacts.length = 1"
                value="simple"
                class="mar-r10 subtitle"
            >
                {{ "Simple" | translate }}
            </mat-radio-button>
            <mat-radio-button
                [disabled]="template.contact_count >= 2"
                [matTooltip]="
                    template.contact_count >= 2
                        ? ('You can not send via groups on templates/documents that has more than 1 signer'
                          | translate)
                        : ''
                "
                (click)="deployAmount = 1; selectedContacts.length = 1"
                value="groups"
                class="mar-r10 subtitle"
            >
                {{ "Group shipment" | translate }}</mat-radio-button
            >

            <mat-radio-button
                [disabled]="template.contact_count >= 2"
                [matTooltip]="
                    template.contact_count >= 2
                        ? ('You can not send via personal on templates/documents that has more than 1 signer'
                          | translate)
                        : ''
                "
                (click)="deployAmount = 1; selectedContacts.length = 1"
                value="personal"
                class="mar-r10 subtitle"
            >
                {{ "Personal" | translate }}</mat-radio-button
            >
        </mat-radio-group>
    </div>
    <ng-container *ngTemplateOutlet="mobileTitle"></ng-container>
    <ng-container *ngTemplateOutlet="mobileList"></ng-container>
    <ng-container *ngIf="cvsAllow != 'allowed'; else cvsConfigMbl">
        <div></div>
    </ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<!-- Desktop -->
<ng-template #desktop>
    <div class="help-col">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ "Help" | translate }}</span>
        </div>
        <app-tip
            *ngFor="let tip of TIPS; let i = index"
            [idx]="i"
            [tip]="tip"
        ></app-tip>
    </div>
    <div class="content">
        <div class="subtitle" style="grid-column: 1/-1;">
            1. {{ "Choose the shippment type" | translate }}
        </div>
        <mat-radio-group
            [(ngModel)]="sendType"
            class="radio-container choose-opt"
        >
            <!-- First option -->
            <div>
                <mat-radio-button
                    (click)="deployAmount = 1; selectedContacts.length = 1"
                    value="simple"
                    class="mar-r10 subtitle"
                >
                    {{ "Simple" | translate }}</mat-radio-button
                >
            </div>

            <!-- Second option -->
            <div>
                <mat-radio-button
                    [disabled]="template.contact_count >= 2"
                    [matTooltip]="
                        template.contact_count >= 2
                            ? ('You can not send via groups on templates/documents that has more than 1 signer'
                              | translate)
                            : ''
                    "
                    (click)="deployAmount = 1; selectedContacts.length = 1"
                    value="groups"
                    class="mar-r10 subtitle"
                >
                    {{ "Group shipment" | translate }}</mat-radio-button
                >
            </div>

            <!-- Third option -->
            <div>
                <mat-radio-button
                    (click)="deployAmount = deployAmount > 1 ? deployAmount : 2"
                    value="batch"
                    class="mar-r10 subtitle"
                    >{{ "Batch shipment" | translate }}</mat-radio-button
                >
            </div>

            <!-- Forth option -->
            <div>
                <mat-radio-button
                    [disabled]="template.contact_count >= 2"
                    [matTooltip]="
                        template.contact_count >= 2
                            ? ('You can not send via personal on templates/documents that has more than 1 signer'
                              | translate)
                            : ''
                    "
                    (click)="
                        deployAmount = 1;
                        selectedContacts.length = 1;
                        setMyselfAsSigner()
                    "
                    value="personal"
                    class="mar-r10 subtitle"
                    >{{ "Personal shipment" | translate }}</mat-radio-button
                >
            </div>

            <div class="info-details">
                <div>1 {{ "Doc" | translate }}</div>
                <div style="padding-left: 22px">
                    {{ "Total signers" | translate }}
                </div>
                <div>
                    <mat-icon>description</mat-icon>
                </div>
                <div style="padding-left: 22px">
                    <mat-icon>person</mat-icon> /
                    <mat-icon>supervisor_account</mat-icon>
                </div>
                <div class="small">*{{ "By contacts" | translate }}</div>
            </div>
            <div class="info-details">
                <div>
                    {{ "Docs" | translate }}
                </div>
                <div>
                    {{ "Signer" | translate }}
                </div>
                <div style="justify-content: center;">
                    <mat-icon>description</mat-icon>
                    <mat-icon>description</mat-icon>
                    <mat-icon>more_horiz</mat-icon>
                    <mat-icon>description</mat-icon>
                </div>
                <div>
                    <mat-icon>person</mat-icon>
                </div>
                <div class="small">*{{ "By groups" | translate }}</div>
            </div>
            <div class="info-details">
                <div>
                    {{ "Docs" | translate }}
                </div>
                <div style="padding-left: 22px">
                    {{ "Total signers" | translate }}
                </div>
                <div style="justify-content: center;">
                    <input
                        min="2"
                        style="width: 40px"
                        [disabled]="
                            sendType === 'simple' || sendType === 'groups'
                        "
                        [(ngModel)]="tempDeployAmount"
                        type="number"
                        (click)="sendType = 'batch'"
                        (change)="adaptBatchesWithRepeatedContacts()"
                        matInput
                    />
                    <mat-icon>
                        description
                    </mat-icon>
                </div>
                <div style="padding-left: 22px">
                    <mat-icon>person</mat-icon> /
                    <mat-icon>supervisor_account</mat-icon>
                </div>
                <div class="small">*{{ "By contacts" | translate }}</div>
                <!-- <mat-form-field class="mar-l10" appearance="standard" style="height: 50px;">
                    <mat-label>{{ 'Number of shipments' | translate }}</mat-label>
                    <input (click)="sendType='batch'" matInput placeholder="{{'Number of shippings' | translate}}"
                        min="2" [disabled]="sendType === 'simple'" [(ngModel)]="tempDeployAmount" type="number"
                        (change)="adaptBatchesWithRepeatedContacts()">
                </mat-form-field>
                <button mat-mini-fab color="primary" class="update-list">
                    <mat-icon>refresh</mat-icon>
                </button> -->
            </div>

            <div class="info-details" *ngIf="user.user$ | async as currentUser">
                <div>
                    {{ "Doc" | translate }}
                </div>
                <div>
                    {{ "Signer" | translate }}
                </div>
                <div style="justify-content: center;">
                    <mat-icon>description</mat-icon>
                </div>
                <div>
                    <mat-icon>person</mat-icon>
                    <div class="user-name-wrapper">{{ currentUser.name }}</div>
                </div>
            </div>
        </mat-radio-group>
        <ng-container *ngIf="sendType != 'groups' && sendType != 'personal'">
            <div class="subtitle">
                2.
                {{ "Select the contacts will sign the document" | translate }}
            </div>
            <div
                class="subtitle"
                style="justify-content: space-between; display: flex;padding-left: 15px;"
            >
                <div class="subtitle">{{ "Send channel" | translate }}:</div>
                <ng-container *ngIf="sendType == 'batch'">
                    <mat-button-toggle-group
                        multiple
                        #generalChannel
                        [value]="selectedContacts | selectedSendChannelGeneral"
                        [disabled]="selectedContacts | disabledChannelGeneral"
                        color="primary"
                        name="channel"
                        class="toggle-channel"
                    >
                        <mat-button-toggle
                            (click)="flagAllDeploys('MAIL')"
                            value="MAIL"
                            [matTooltip]="'MAIL' | translate"
                            ><mat-icon>mail</mat-icon></mat-button-toggle
                        >
                        <mat-button-toggle
                            (click)="flagAllDeploys('WHATSAPP')"
                            value="WHATSAPP"
                            [matTooltip]="'WHATSAPP' | translate"
                            ><img
                                style="width: 20px; height: 20px;"
                                src="assets/svg/whatsapp-icon.svg"
                        /></mat-button-toggle>
                        <mat-button-toggle
                            (click)="flagAllDeploys('SMS')"
                            value="SMS"
                            [matTooltip]="'SMS' | translate"
                            ><mat-icon>sms</mat-icon></mat-button-toggle
                        >
                    </mat-button-toggle-group>
                </ng-container>
                <ng-container *ngIf="sendType == 'simple'">
                    <mat-button-toggle-group
                        #toggleD
                        (change)="flagDeploy($event, selectedContacts[0])"
                        [value]="
                            (selectedContacts[0] &&
                                selectedContacts[0][0] &&
                                selectedContacts[0][0].flags[0]) ||
                            'MAIL'
                        "
                        [disabled]="selectedContacts[0] | disabledChannel"
                        name="channel"
                        class="toggle-channel"
                    >
                        <mat-button-toggle
                            value="MAIL"
                            [matTooltip]="'MAIL' | translate"
                            ><mat-icon>mail</mat-icon></mat-button-toggle
                        >
                        <mat-button-toggle
                            value="WHATSAPP"
                            [matTooltip]="'WHATSAPP' | translate"
                            ><img
                                style="width: 20px; height: 20px;"
                                src="assets/svg/whatsapp-icon.svg"
                        /></mat-button-toggle>
                        <mat-button-toggle
                            value="SMS"
                            [matTooltip]="'SMS' | translate"
                            ><mat-icon>sms</mat-icon></mat-button-toggle
                        >
                        <mat-button-toggle
                            value="FIRMA_LINK"
                            [matTooltip]="'FIRMA_LINK' | translate"
                            *ngIf="template.contact_count == 1"
                            ><mat-icon>link</mat-icon></mat-button-toggle
                        >
                    </mat-button-toggle-group>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="sendType != 'groups' && sendType == 'personal'">
            <div class="subtitle">
                2. {{ "Sign now or use your signature" | translate }}
            </div>
            <div
                class="subtitle"
                style="justify-content: space-between; display: flex;"
            >
                <div class="subtitle">{{ "Send channel" | translate }}:</div>
                <mat-button-toggle-group
                    #toggleD
                    (change)="flagDeploy($event, selectedContacts[0])"
                    [value]="
                        (selectedContacts[0] &&
                            selectedContacts[0][0] &&
                            selectedContacts[0][0].flags[0]) ||
                        'MAIL'
                    "
                    [disabled]="selectedContacts[0] | disabledChannel"
                    name="channel"
                    class="toggle-channel"
                >
                    <mat-button-toggle
                        value="MAIL"
                        [matTooltip]="'MAIL' | translate"
                        ><mat-icon>mail</mat-icon></mat-button-toggle
                    >
                    <mat-button-toggle
                        value="WHATSAPP"
                        [matTooltip]="'WHATSAPP' | translate"
                        ><img
                            style="width: 20px; height: 20px;"
                            src="assets/svg/whatsapp-icon.svg"
                    /></mat-button-toggle>
                    <mat-button-toggle
                        value="SMS"
                        [matTooltip]="'SMS' | translate"
                        ><mat-icon>sms</mat-icon></mat-button-toggle
                    >
                </mat-button-toggle-group>
            </div>
        </ng-container>
        <div
            class="subtitle"
            style="grid-column: 1/-1;"
            *ngIf="sendType == 'groups'"
        >
            2. {{ "Select the group will sign the document" | translate }}
        </div>

        <app-contact-search
            *ngIf="sendType != 'groups' && sendType != 'personal'"
            (newContact)="addContact()"
            (editContact)="editContact($event)"
            (selected)="selectContact($event)"
        >
        </app-contact-search>
        <div
            *ngIf="sendType == 'groups'"
            style="grid-column: 1 / -1; height: 100%;display: flex;flex-direction: column;"
        >
            <app-group-hierarchy-list
                [showButton]="false"
            ></app-group-hierarchy-list>
        </div>
        <mat-radio-group
            [(ngModel)]="flagForPersonalSend"
            class="personal-send-holder"
            *ngIf="sendType == 'personal'"
        >
            <div>
                <mat-radio-button
                    value="FIRMA_EN_TIENDA"
                    class="mar-r10 subtitle"
                    >{{ "Online signature" | translate }}
                </mat-radio-button>
                <div class="mar-t10 phone-img">
                    <img src="assets/svg/touch-phone.svg" />
                </div>
                <div class="hint">
                    {{ "signature_advise" | translate }}
                </div>
            </div>
            <div>
                <mat-radio-button value="AUTOFIRMA" class="mar-r10 subtitle"
                    >{{ "Personal signature" | translate }}
                </mat-radio-button>
                <app-user-signatures></app-user-signatures>
            </div>
            <div style="grid-column: 1 / -1;">
                <ng-container *ngTemplateOutlet="inputCopies"></ng-container>
            </div>
        </mat-radio-group>
        <ng-container *ngTemplateOutlet="mobileList"></ng-container>
        <ng-container *ngIf="cvsAllow != 'allowed'; else cvsconfig">
            <div></div>
        </ng-container>
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
</ng-template>

<!-- MOBILE TITLE -->
<ng-template #mobileTitle>
    <div class="subtitle" *ngIf="sendType == 'simple'">
        {{ "Select the contacts will sign the document" | translate }}
    </div>
    <div
        class="subtitle"
        style="display: flex; justify-content: space-between; align-items: center;"
        *ngIf="sendType == 'simple'"
    >
        <div>{{ "Send channel" | translate }}</div>
        <mat-button-toggle-group
            #toggleD
            (change)="flagDeploy($event, selectedContacts[0])"
            [value]="
                (selectedContacts[0] &&
                    selectedContacts[0][0] &&
                    selectedContacts[0][0].flags[0]) ||
                'MAIL'
            "
            [disabled]="selectedContacts[0] | disabledChannel"
            name="channel"
            class="toggle-channel"
        >
            <mat-button-toggle value="MAIL" [matTooltip]="'MAIL' | translate"
                ><mat-icon>mail</mat-icon></mat-button-toggle
            >
            <mat-button-toggle
                value="WHATSAPP"
                [matTooltip]="'WHATSAPP' | translate"
                ><img
                    style="width: 20px; height: 20px;"
                    src="assets/svg/whatsapp-icon.svg"
            /></mat-button-toggle>
            <mat-button-toggle value="SMS" [matTooltip]="'SMS' | translate"
                ><mat-icon>sms</mat-icon></mat-button-toggle
            >
        </mat-button-toggle-group>
    </div>
    <div class="subtitle" *ngIf="sendType == 'groups'">
        {{ "Select the group will sign the document" | translate }}
    </div>
    <div class="subtitle" *ngIf="sendType == 'personal'">
        {{ "Sign now or use your signature" | translate }}
    </div>
</ng-template>

<!-- Input para enviar copias -->
<ng-template #inputCopies>
    <mat-form-field style="width:100%">
        <mat-label>{{ "Emails for copy" | translate }}</mat-label>
        <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip
                *ngFor="let email of emailCallbacksForPersonalSend"
                (removed)="removeEmail(email)"
            >
                {{ email }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
                email
                [formControl]="formControlCopies"
                placeholder="{{ 'Email' | translate }}"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addEmail($event)"
            />
        </mat-chip-list>
    </mat-form-field>
</ng-template>

<!-- LIST -->
<ng-template #mobileList>
    <div
        *ngIf="sendType == 'simple' || sendType == 'batch'"
        class="selected-contact-holder"
    >
        <div class="contact-wrapper">
            <div class="contact-wrapper-scroller">
                <ng-container *ngIf="sendType == 'batch'">
                    <mat-vertical-stepper
                        #stepper
                        [linear]="false"
                        class="w100"
                        style="background-color: transparent;"
                    >
                        <mat-step
                            *ngFor="let i of range(deployAmount)"
                            [completed]="
                                selectedContacts[i] !== undefined &&
                                selectedContacts[i].length ===
                                    template.contact_count
                            "
                            [label]="('Shipment' | translate) + ' ' + (i + 1)"
                        >
                            <div
                                style="display: flex; justify-content: space-between;"
                            >
                                <div class="subtitle">
                                    {{ "Send channel" | translate }}:
                                </div>
                                <mat-button-toggle-group
                                    #toggleD
                                    (change)="
                                        flagDeploy($event, selectedContacts[i])
                                    "
                                    [value]="
                                        (selectedContacts[i] &&
                                            selectedContacts[i][0] &&
                                            selectedContacts[i][0].flags[0]) ||
                                        'MAIL'
                                    "
                                    [disabled]="
                                        selectedContacts[i] | disabledChannel
                                    "
                                    name="channel"
                                    class="toggle-channel"
                                >
                                    <mat-button-toggle
                                        value="MAIL"
                                        [matTooltip]="'MAIL' | translate"
                                        ><mat-icon
                                            >mail</mat-icon
                                        ></mat-button-toggle
                                    >
                                    <mat-button-toggle
                                        value="WHATSAPP"
                                        [matTooltip]="'WHATSAPP' | translate"
                                        ><img
                                            style="width: 20px; height: 20px;"
                                            src="assets/svg/whatsapp-icon.svg"
                                    /></mat-button-toggle>
                                    <mat-button-toggle
                                        value="SMS"
                                        [matTooltip]="'SMS' | translate"
                                        ><mat-icon
                                            >sms</mat-icon
                                        ></mat-button-toggle
                                    >
                                </mat-button-toggle-group>
                            </div>
                            <ng-container
                                *ngFor="
                                    let x of range(template.contact_count);
                                    let c_idx = index
                                "
                            >
                                <div class="signer-content-handler">
                                    <div
                                        class="ball-24px mar-r10"
                                        [style.backgroundColor]="
                                            mtlg.StringToColour(
                                                x + 'Firmante ' + (x + 20)
                                            )
                                        "
                                    ></div>
                                    {{ "Signer" | translate }} {{ x + 1 }}:

                                    <!-- Pin buttons-->
                                    <ng-container
                                        *ngIf="
                                            selectedContacts.length > 0 &&
                                            selectedContacts[i] &&
                                            selectedContacts[i].length > 0 &&
                                            selectedContacts[i][x]
                                        "
                                    >
                                        <div class="fill-remaining-space"></div>
                                        <div *ngIf="i == 0">
                                            <button
                                                mat-icon-button
                                                (click)="
                                                    selectedContacts[i][
                                                        x
                                                    ].repeated = !selectedContacts[
                                                        i
                                                    ][x].repeated;
                                                    repeatContactOverBatches(
                                                        selectedContacts[i][x],
                                                        x
                                                    )
                                                "
                                            >
                                                <svg
                                                    style="width:24px;height:24px"
                                                    viewBox="0 0 24 24"
                                                    *ngIf="
                                                        !selectedContacts[i][x]
                                                            .repeated
                                                    "
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z"
                                                    />
                                                </svg>
                                                <svg
                                                    style="width:24px;height:24px"
                                                    viewBox="0 0 24 24"
                                                    *ngIf="
                                                        selectedContacts[i][x]
                                                            .repeated
                                                    "
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div
                                            *ngIf="
                                                i > 0 &&
                                                selectedContacts[i][x].repeated
                                            "
                                        >
                                            <mat-icon>lock</mat-icon>
                                        </div>
                                    </ng-container>
                                </div>
                                <app-contact-simple
                                    [showDeleteButton]="true"
                                    *ngIf="
                                        selectedContacts.length > 0 &&
                                            selectedContacts[i] &&
                                            selectedContacts[i].length > 0 &&
                                            selectedContacts[i][x];
                                        else nocontact
                                    "
                                    matRipple
                                    [contact]="selectedContacts[i][x]"
                                    (click)="unselectContact(i, c_idx)"
                                    style="padding-left: 20px;min-height: 75px;"
                                ></app-contact-simple>
                            </ng-container>
                        </mat-step>
                    </mat-vertical-stepper>
                </ng-container>
                <ng-container *ngIf="sendType == 'simple'">
                    <ng-container
                        *ngFor="let i of range(template.contact_count)"
                    >
                        <div class="signer-content-handler">
                            <div
                                class="ball-24px mar-r10"
                                [style.backgroundColor]="
                                    mtlg.StringToColour(
                                        i + 'Firmante ' + (i + 20)
                                    )
                                "
                            ></div>
                            {{ "Signer" | translate }} {{ i + 1 }}:
                        </div>
                        <app-contact-simple
                            [showDeleteButton]="true"
                            *ngIf="
                                selectedContacts.length > 0 &&
                                    selectedContacts[0] &&
                                    selectedContacts[0][i];
                                else nocontact
                            "
                            matRipple
                            [contact]="selectedContacts[0][i]"
                            (click)="unselectContact(0, i)"
                            style="padding-left: 20px;"
                        ></app-contact-simple>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <!-- CONTACT PLACEHOLDER TEMPLATE-->
        <ng-template #nocontact>
            <div
                matRipple
                *ngIf="responsive.isMobile$ | async"
                class="contact-placeholder"
                (click)="openSelectContactDialog()"
            >
                {{ "Click here to choose a contact" | translate }}
            </div>
            <div
                matRipple
                *ngIf="!(responsive.isMobile$ | async)"
                class="contact-placeholder"
            >
                {{ "Select a contact from the list" | translate }}
            </div>
        </ng-template>
    </div>
    <div
        *ngIf="sendType == 'groups'"
        style="height: 100%;display: flex;flex-direction: column;overflow: hidden;"
    >
        <app-group-hierarchy-list
            [showButton]="false"
        ></app-group-hierarchy-list>
    </div>
    <ng-container *ngIf="responsive.isMobile$ | async">
        <mat-radio-group
            [(ngModel)]="flagForPersonalSend"
            *ngIf="sendType == 'personal'"
            style="height: 100%;display: flex;flex-direction: column;overflow-y: auto;overflow-x: hidden;"
        >
            <div class="choose-opt">
                <mat-radio-button
                    value="FIRMA_EN_TIENDA"
                    class="mar-r10 subtitle"
                    >{{ "Online signature" | translate }}
                </mat-radio-button>
                <div class="mar-t10 phone-img">
                    <img
                        style="height: 70px;"
                        src="assets/svg/touch-phone.svg"
                    />
                </div>
                <div class="hint">
                    {{ "signature_advise" | translate }}
                </div>
            </div>

            <div class="choose-opt mar-t10 mbl">
                <mat-radio-button value="AUTOFIRMA" class="mar-r10 subtitle"
                    >{{ "Personal signature" | translate }}
                </mat-radio-button>
                <app-user-signatures></app-user-signatures>
            </div>

            <div class="mar-t10">
                <ng-container *ngTemplateOutlet="inputCopies"></ng-container>
            </div>
        </mat-radio-group>
    </ng-container>
</ng-template>

<!-- Footer -->
<ng-template #footer>
    <div class="footer" *ngIf="onlyEdit === false">
        <button
            mat-stroked-button
            color="primary"
            (click)="previous()"
            class="mar-l8-important"
        >
            {{ "Back" | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="next()">
            {{ "Next" | translate }}
        </button>
    </div>
    <div class="footer" *ngIf="onlyEdit === true">
        <button
            mat-stroked-button
            color="primary"
            (click)="isEditPrevious()"
            class="mar-l8-important"
        >
            {{ "Back" | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="isEditNext()">
            {{ "Next" | translate }}
        </button>
    </div>
</ng-template>

<!-- CVS -->
<ng-template #cvsConfigMbl>
    <div style="grid-column: 1 / -1; margin-bottom: 10px;">
        <mat-checkbox class="subtitle" [formControl]="cvs">{{
            "Add cvs to document" | translate
        }}</mat-checkbox>
    </div>
</ng-template>

<ng-template #cvsconfig>
    <div
        style="padding-top: 12px; display: flex; flex-direction: row; grid-column: 1 / -1;"
    >
        <span class="subtitle"
            >3.
            {{ "Do you want to add a CVS code to document?" | translate }}</span
        >
        <mat-checkbox [formControl]="cvs" style="margin-left: 12px;">{{
            "Yes, add it" | translate
        }}</mat-checkbox>
    </div>
</ng-template>
