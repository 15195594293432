import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { timingSafeEqual } from 'crypto';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PDF_STATE } from 'src/app/configs/enums/pdf-elements-state.enum';
import { Template } from 'src/app/core/models/api/template/template.model';
import { DocumentTemplateManagementService } from 'src/app/core/services/content/document-template-management.service';
import { FormTemplateManagementService } from 'src/app/core/services/content/form-template-management.service';
import { PdfElementsService } from 'src/app/core/services/content/pdf-elements.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { FileSelectorDialogComponent } from './components/file-selector-dialog/file-selector-dialog.component';

@Component({
    selector: 'app-signature-only-ribbon',
    templateUrl: './signature-only-ribbon.component.html',
    styleUrls: ['./signature-only-ribbon.component.scss']
})
export class SignatureOnlyRibbonComponent implements OnInit, OnDestroy
{
    @ViewChild("file", { static: false }) fileInput;
    public tpl: Template = new Template();
    public isPublic = new FormControl(1);

    //REFS.
    public PDF_STATE = PDF_STATE;
    private unsubscriber = new Subject<void>();

    constructor(
        public responsive: ResponsiveService,
        public pdfControls: PdfControlsService,
        public template: FormTemplateManagementService,
        public pdfElements: PdfElementsService,
        public editor: TemplateEditorService,
        public dialog: MatDialog,
        public toast: I18nToastService
    )
    {
        this.isPublic.setValue(1);
    }

    ngOnInit(): void
    {
        const done = new Subject();
        (this.editor.template$ as Observable<Template>)
            .pipe(
                takeUntil(this.unsubscriber)
            )
            .subscribe(
                template =>
                {
                    if (template && template.tpl && template.tpl.id)
                    {
                        this.tpl = template;
                        this.isPublic.setValue(this.tpl.tpl.is_public);
                    }
                }
            );
    }
    ngOnDestroy(): void
    {
        this.tpl = new Template();
        this.fileInput.nativeElement.value = null;
        this.editor.reset();
        this.editor.resetTemplate();

        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public fileChange($event: Event)
    {
        const files = ($event.target as HTMLInputElement).files;

        if (files && files.length > 0)
        {
            const name = files[0].name.split('.')[0];
            this.dialog.open(FileSelectorDialogComponent,
                {
                    data: name
                })
                .afterClosed()
                .subscribe(
                    x =>
                    {
                        if (x)
                        {
                            this.tpl.tpl.name = x;
                            this.tpl.tpl.contact_count = 1;
                            this.tpl.tpl.is_public = this.isPublic.value;
                            this.template.createFormTemplate(this.tpl.tpl, files[0])
                                .subscribe(
                                    res =>
                                    {
                                        this.editor.setPdfEditableId(res.id);
                                        this.editor.load(res.template.id);
                                    },
                                    err =>
                                    {
                                        this.toast.open(
                                            'There was an error uploading the file',
                                            'Accept',
                                            5000,
                                            true
                                        );
                                        ($event.target as HTMLInputElement).value = null;
                                        this.tpl = new Template();
                                    }
                                )
                        }
                        else
                        {
                            ($event.target as HTMLInputElement).value = null;
                            this.tpl = new Template();
                        }
                    }
                );
        }
    }
    public resetTemplate(): void
    {
        this.tpl = new Template();
    }
    public addSignature(): void
    {
        const done = new Subject<void>();
        this.pdfControls.template$
            .pipe(
                takeUntil(done)
            )
            .subscribe(
                tpl =>
                {
                    if (tpl.repo)
                    {
                        const signature = tpl.repo.find(x => x.meta.output_type == 'firma');
                        if (signature)
                        {
                            const newSignature = _.cloneDeep(signature);
                            (newSignature.meta as any).contac_idx = 0;
                            this.pdfControls.configureOutputToBeAdded(newSignature as any);
                            this.pdfElements.changeState(PDF_STATE.ADDING);

                            done.next();
                            done.complete();
                        }
                    }
                }
            )
    }
    public addAttached(): void
    {
        const done = new Subject<void>();
        this.pdfControls.template$
            .pipe(
                takeUntil(done)
            )
            .subscribe(
                tpl =>
                {
                    const attach = tpl.repo.find(x => x.meta.output_type == 'aditional_doc');
                    if (attach)
                    {
                        const newAttach = _.cloneDeep(attach);
                        (newAttach.meta as any).contac_idx = 0;
                        this.pdfControls.configureOutputToBeAdded(newAttach as any);
                        this.pdfElements.changeState(PDF_STATE.ADDING);

                        done.next();
                        done.complete();
                    }
                }
            )
    }
    public changeType(): void
    {
        this.tpl.tpl.is_public = this.isPublic.value;
        this.pdfControls.updateTemplateProperties(this.tpl);
    }
}
