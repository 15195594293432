import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {WebServiceHelperService} from '../../../shared/web-service-helper.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DocumentTemplateFormManagementService {
    constructor(private wsh: WebServiceHelperService, private translator: TranslateService) {

    }

    public listAllDocForms(): Observable<Array<any>> {
        let url = environment.backEndDomain;
        url += 'api/document_template/all';
        return this.wsh.get(url);
    }


    public getById(id){
        return this.listAllDocForms()
            .pipe(
                map((d: Array<any>) => d.filter(f => f.form_id == id).pop())
            );
    }

    public listDocForms(): Observable<Array<any>> {
        let url = environment.backEndDomain;
        url += 'api/document_template/allDocsForms';
        return this.wsh.get(url);
    }

    public listFlaggedDocForms(filter?: { template: any, dateIni: any, dateEnd: any }): Observable<Array<any>> {

        let qs = '';

        if (filter) {
            filter.dateIni = (filter.dateIni as Date).toISOString();
            filter.dateEnd = (filter.dateEnd as Date).toISOString();
            qs = '?' + Object.keys(filter).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
            }).join('&');
        }

        
        let url = environment.backEndDomain;
        url += 'api/document_template/allDocsForms' + qs;
        console.log(url)
        return this.wsh.get(url);
    }

    public cloneForm(template: { formId: number, newName: string }): Observable<any> {
        const url = environment.backEndDomain + 'api/document_template/cloneForm';
        return this.wsh.post(url, template);
    }


    public getTplPreviewUrl(id): string {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id + '/preview';
        return url;
    }

    public getTplPreview(id): Observable<string> {
        const url = this.getTplPreviewUrl(id);
        return this.wsh.get(url, {responseType: 'blob'});
    }

    public getTplPreviewAsB64(id, page): Observable<string> {
        return new Observable<string>(o => {
            let url = environment.backEndDomain + 'api/document_template/';
            url += id + '/';
            url += page + '/previewB64';
            this.wsh.get<any>(url, {responseType: 'blob'}).subscribe(
                r => {
                    const reader = new FileReader();
                    reader.readAsDataURL(r);
                    reader.onloadend = function () {
                        o.next(reader.result.toString());
                        o.complete();
                    };
                },
                e => {
                    o.error(e);
                }
            );
        });
    }

    public getTplInput(id): Observable<any> {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id + '/outputs';
        return this.wsh.get(url).pipe(
            map((d: any) => {
                d.tpl.text_name = JSON.parse(d.tpl.text_name);
                return d;
            })
        );
    }

    public uploadTplInputsConf(id, serialized): Observable<any> {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id + '/outputs';
        return this.wsh.post(url, serialized);
    }

    public createForm(template: any, file?): Observable<any> {
        const url = environment.backEndDomain + 'api/document_template/postForm';

        if (undefined !== file) {
            const payload = new FormData();
            payload.set('data', JSON.stringify(template));
            payload.set('file', file, file.name);
            payload.set('is_form', '1');
            console.log("create form")
            return this.wsh.post(url, payload);
        } else {
            template.is_form = 1;
            return this.wsh.post(url, template);
        }
    }

    public uploadFile(tpl: any, file): Observable<any> {
        const formData = new FormData();
        formData.set('file', file, file.name);
        formData.set('is_form', '1');

        let url = environment.backEndDomain + 'api/document_template/';
        url += tpl.id + '/change_file';
        return this.wsh.post(url, formData);

    }


    validateSerialized(remoteConf: any, serialized: { id: string; dragged: { class: any; db_id: any; id: any; innerHTML: any; location: { position: string; y: string; x: string }; meta: any }[] }[]) {

        return new Observable(o => {

            const holder = {};

            for (let c = 0; c < remoteConf.tpl.contact_count; c++) {
                holder[c] = [];
            }

            const relevantOuts = ['firma', 'aditional_doc'];

            serialized.forEach(s => {
                s.dragged.forEach(d => {

                    if (relevantOuts.indexOf(d.meta.output_type) !== -1) {
                        holder[d.meta.contac_idx].push(d.meta.output_type);
                    }
                });
            });

            for (let c = 0; c < remoteConf.tpl.contact_count; c++) {
                if (holder[c].length === 0) {
                    const msg = this.translator.instant('Signer is not going to interact on the document', {value: (c + 1)});
                    throw new Error(msg);
                    // El "Firmante ' + (c + 1) + '" no va a interactuar con el documento. Añade su firma o reduce el numero de contactos.'
                }
            }

            o.next(true);
            o.complete();

        });

    }

    deleteTpl(id: number): Observable<any> {
        const url = environment.backEndDomain + 'api/document_template/' + id;
        return this.wsh.delete(url, {});
    }
}
