<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title flex-row-between">
        {{ 'Text for mail' | translate }}
    </div>
    <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    <ng-container *ngTemplateOutlet="textarea"></ng-container>
    <ng-container *ngTemplateOutlet="previewTitle"></ng-container>
    <ng-container *ngTemplateOutlet="preview"></ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    <ng-container *ngTemplateOutlet="textarea"></ng-container>
    <ng-container *ngTemplateOutlet="previewTitle"></ng-container>
    <ng-container *ngTemplateOutlet="preview"></ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #subtitle>
    <div class="subtitle" style="align-items: center;display: flex;">
        <span>{{ 'Write the text that will appear on the email' | translate }}</span>
    </div>
</ng-template>
<ng-template #textarea>
    <div>
        <mat-form-field appearance="fill" class="mar-t10" style="width: 100%;" [hintLabel]="'hint_label' | translate">
            <mat-label translate>Text for email</mat-label>
            <textarea [placeholder]="'email_template_text' | translate" style="height: 40px" required matInput
                [(ngModel)]="tpl.mail_text"></textarea>
        </mat-form-field>
    </div>
</ng-template>
<ng-template #previewTitle>
    <div class="subtitle">
        <span>{{ 'Email preview' | translate }}</span>
    </div>
</ng-template>
<ng-template #preview>
    <div class="preview-holder" *ngIf="contacts && contacts[0] && contacts[0][0]">
        <app-message-preview [template]="tpl" [contacts]="contacts"></app-message-preview>
    </div>
</ng-template>
<ng-template #footer>
    <div class="button-row">
        <button mat-stroked-button color="primary" (click)="previous()">{{'Back' | translate}}</button>
        <button mat-raised-button color="accent" (click)="next()">{{ 'Send document' | translate }}</button>
    </div>
</ng-template>