import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contact } from 'src/app/core/models/contacts/contact';
import { TextDefinitionService } from 'src/app/core/services/pages/text-definition.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
    selector: 'app-bylink',
    templateUrl: './bylink.component.html',
    styleUrls: ['./bylink.component.scss']
})
export class BylinkComponent implements OnInit, OnDestroy
{
    public signer: Contact

    constructor(
        public responsive: ResponsiveService,
        public service: TextDefinitionService,
        private router: Router
    ) { }

    ngOnInit(): void
    {
        const done = new Subject<void>();
        this.service.signers$
            .pipe(takeUntil(done))
            .subscribe(x =>
            {
                if (x)
                {
                    this.signer = x[0][0];
                    done.next();
                    done.complete();
                }
            });
    }
    ngOnDestroy(): void
    {
        this.service.resetSignLink();
    }

    public next(): void
    {
        this.service.resetSignLink();
        this.service.resetSigners();
        this.router.navigate(['document']);
    }
}
