import { Component, OnInit, Input } from '@angular/core';
import { Tip } from 'src/app/core/models/tip';

@Component({
    selector: 'app-tip',
    templateUrl: './tip.component.html',
    styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit
{
    @Input()
    public tip: Tip;

    @Input()
    public idx: number;

    constructor() { }

    ngOnInit()
    {
    }

}
