<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <app-mobile-stepper *ngIf="!tpl.is_tpl" [currentStep]="1"></app-mobile-stepper>
    <ng-template #titleTemplate>
        <!-- <div class="title flex-row-between"> -->
            {{ 'Create template' | translate }}
            <button (click)="openHelp()" color="primary" mat-icon-button>
                <mat-icon>help</mat-icon>
            </button>
        <!-- </div> -->
    </ng-template>
    <div class="title flex-row-between">
        <ng-container *ngIf="!tpl.is_tpl; else titleTemplate">
            1. {{ 'Choose a document' | translate }}
             <button (click)="openHelp()" color="primary" mat-icon-button>
                <mat-icon>help</mat-icon>
            </button>
        </ng-container>
    </div>
    <div style="overflow-y: auto;">
        <ng-container *ngTemplateOutlet="left"></ng-container>
        <ng-container *ngTemplateOutlet="right"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<!-- Desktop -->
<ng-template #desktop>
    <div class="help-col">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <div class="list">
            <app-tip *ngFor="let tip of TIPS; let i = index" [idx]="i" [tip]="tip"></app-tip>
        </div>
    </div>
    <div class="content">
        <!-- left -->
        <ng-container *ngTemplateOutlet="left"></ng-container>

        <!-- right -->
        <ng-container *ngTemplateOutlet="right"></ng-container>

        <!-- Footer -->
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
    <!-- The empty one-->
    <div></div>
</ng-template>

<ng-template #left>
    <div class="left-col-container">
        <div class="subtitle">
            1. {{ 'Select' | translate }}
        </div>
        <div class="choose-opt left-col-content">
            <!-- New Doc -->
            <div class="radio-opt">
                <mat-radio-button [checked]="choice == 'new'" #radioNew (change)="setChoice($event)" value="new"
                    class="mar-r10 subtitle">{{ 'Upload new document' | translate }}</mat-radio-button>
            </div>
            <ng-container *ngIf="choice == 'new'">
                <div class="flex-row-center">
                    <b class="tooltip" *ngIf="file.files[0] !== undefined">
                        {{file.files[0].name}} - {{ (file.files[0].size / 1000)  | number:'0.0-2':'es-ES'}} KB
                    </b>
                    <b *ngIf="!file.files[0]"
                        class="tooltip">{{ 'Allowed formats: .pdf, .xlsx, .docx, .pptx' | translate}}</b>
                </div>
                <mat-error *ngIf="inputFileErr.length > 0">
                    <b>{{inputFileErr}}</b>
                </mat-error>
                <input type="file" #file (change)="fileChange($event)" style="display: none">
                <button [disabled]="tpl.doc_count > 0" mat-raised-button class="file-btn btn w100" color="primary" (click)="choice='new'; file.click();">
                    {{ 'Find document' | translate }}
                </button>
            </ng-container>

            <!-- Existing Doc -->
            <div class="radio-opt" *ngIf="!this.tpl.is_tpl">
                <mat-radio-button #radioExisting [checked]="choice == 'existing'" (change)="setChoice($event)"
                    value="existing" class="mar-r10 subtitle">{{ 'Use an already sent document' | translate }}
                </mat-radio-button>
            </div>
            <div [hidden]="choice == 'new' || choice == undefined">
                <app-template-search [showPreview]="true" (selected)="setSelectedTemplate($event)" (preview)="preview($event)">
                </app-template-search>
            </div>

            <!-- Preview BTTNS-->
            <!-- <button style="margin-top: 20px" mat-stroked-button
                (click)="preview()">{{ 'Preview of the document' | translate }}</button> -->
        </div>
        <ng-container *ngIf="tpl.is_tpl">
            <div class="subtitle mar-t10">
                2. {{ 'Give it a description' | translate }}
            </div>
            <div>
                <mat-form-field appearance="fill" class="mar-t10 low-density" style="width: 100%">
                    <mat-label>{{ 'Description' | translate }}</mat-label>
                    <input matInput required placeholder="{{'Description' | translate }}" [(ngModel)]="tpl.description">
                </mat-form-field>
            </div>

            <div *ngIf="!(responsive.isMobile$ | async)" style="font-size: 12px;">
                <i>{{ 'tip_15_body' | translate }}</i>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #right>
    <div class="col">
        <div class="subtitle">
            {{ tpl.is_tpl ? 3 : 2 }}. {{ 'Give it a name' | translate }}
        </div>
        <div class="mar-t10">
            <mat-form-field appearance="fill" class="low-density" style="margin-right: 15px; width: 100%">
                <mat-label>{{ 'Name for the document' | translate }}</mat-label>
                <input matInput required placeholder="{{'Name for the document' | translate }}" [(ngModel)]="tpl.name">
            </mat-form-field>
        </div>
        <div style="margin-top: -15px">
            <mat-error>
                {{docNameErr | translate}}
            </mat-error>
        </div>
        <div class="subtitle">
            {{ tpl.is_tpl ? 4 : 3 }}. {{ 'Indicate the number of persons going to sign it' | translate }}
        </div>
        <div class="mar-t10">
            <mat-form-field appearance="fill" class="low-density" style="margin-right: 15px; width: 100%">
                <mat-label>{{ 'Number of signers' | translate }}</mat-label>
                <input [disabled]="choice == 'existing'" matInput required
                    placeholder="{{'Number of signers' | translate }}" [disabled]="tpl.doc_count > 0" [(ngModel)]="tpl.contact_count">
            </mat-form-field>
        </div>
        <ng-container *ngIf="tpl.is_tpl">
            <div class="subtitle">
                {{ 5 }}. {{ 'Tipo' | translate }}
            </div>
            <div class="mar-t10">
                <!-- <div style="margin-right: 15px; width: 100%">
                    <mat-checkbox required [(ngModel)]="tpl.is_public">
                        {{ 'is public' | translate }}
                    </mat-checkbox>
                </div> -->
                <mat-form-field appearance="fill" class="low-density" style="margin-right: 15px; width: 100%">
                    <mat-label>{{ 'Tipo' | translate }}</mat-label>
                    <mat-select [(ngModel)]="tpl.is_public" [value]="tpl.is_public">
                        <mat-option [value]="1">{{ 'publica' | translate }}</mat-option>
                        <mat-option [value]="0">{{ 'privada' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #footer>
    <div class="footer">
        <button mat-stroked-button (click)="cancel()">
            {{ 'Back' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="next()">
            {{ 'Next' | translate }}
        </button>
    </div>
</ng-template>
