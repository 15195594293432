import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BaseComponent } from 'src/app/base/base.component';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Contact } from 'src/app/core/models/contacts/contact';
import { ContactFilters } from 'src/app/core/models/contacts/contactFilters';
import { ContactsDataSource } from 'src/app/core/models/contacts/ContactsDataSource';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';

@Component({
    selector: 'app-contact-virtual-scroll',
    templateUrl: './contact-virtual-scroll.component.html',
    styleUrls: ['./contact-virtual-scroll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactVirtualScrollComponent extends BaseComponent implements OnInit
{
    @Input() totalItems: number;
    @Input() pageSize: number;
    @Input() cachedData: Contact[];
    @Input() trackedPage: number;
    @Input() forcedItemsPerRow = 0;
    @Input() groupView = false;
    @Input() listType = 'default'; // Puede tener el valor default o simple.
    @Input() selectedContacts = new Array<Contact>();
    @Input() maxSelectedContacts = 1;
    @Output() editedContact = new EventEmitter<Contact>();
    @Output() contactSelected = new EventEmitter<Array<Contact>>();
    @Output() contactDeleted = new EventEmitter<Contact>();
    @ViewChild('scroll') scroller: CdkVirtualScrollViewport;

    public Contacts: ContactsDataSource;
    public itemsPerRow: number;
    public countries: Array<any>;
    public locales: Array<any>;

    constructor(
        private contactService: ConstactsService,
        private sideNav: SidenavService,
        private changes: ChangeDetectorRef
    )
    {
        super(sideNav);
    }

    ngOnInit()
    {
         // En función del tamaño de pantalla definimos cuantos items van por row.
        // Esto se usa para calcular el itemSize del scroll.
        if (window.screen.availWidth <= 768)
        {
            this.itemsPerRow = 1;
        }
        else if(window.screen.availWidth <= 1024 && this.groupView)
        {
            this.itemsPerRow = 2;
        }
        else if (window.screen.availWidth <= 1024 || this.groupView)
        {
            this.itemsPerRow = 3;
        }
        else
        {
            this.itemsPerRow = 4;
        }

        this.Contacts = new ContactsDataSource(
            this.contactService,
            this.totalItems,
            this.pageSize,
            this.cachedData,
            this.trackedPage
        );

        if (this.forcedItemsPerRow != 0)
        {
            this.itemsPerRow = this.forcedItemsPerRow;
        }
    }

    public openModal(user: Contact, isEdit: boolean): void
    {
        this.editedContact.emit(user);
    }

    public ReloadDataSource(keepPosition: boolean, filters?: ContactFilters): void
    {
        this.Contacts.disconnect();
        if (keepPosition)
        {
            // const scrollToOffset = this.scroller.measureScrollOffset("top");
            const range = this.scroller.getRenderedRange();
            this.contactService.post(1, filters).subscribe(r =>
            {
                this.Contacts = new ContactsDataSource(this.contactService, r.total, r.per_page, r.data, 0);
                if (filters)
                {
                    this.Contacts.setFilters(filters);
                }
                this.Contacts.fetchPages(range, filters);
                this.changes.detectChanges();
            });
        }
        else
        {
            this.contactService.post(1, filters).subscribe(r =>
            {
                this.Contacts = new ContactsDataSource(this.contactService, r.total, r.per_page, r.data, 0);
                if (filters)
                {
                    this.Contacts.setFilters(filters);
                }
                this.scroller.scrollTo({top: 0});
                const range = this.scroller.getRenderedRange();
                this.Contacts.fetchPages(range, filters);
                this.changes.detectChanges();
            });
        }
    }

    public AdaptScrollerToViewportChanges(): void
    {
        this.scroller.checkViewportSize();
    }

    public ContactSelected(contact: Contact): void
    {
        const found = _.findIndex(this.selectedContacts, x => { return x.id == contact.id });
        if (found != -1)
        {
            this.selectedContacts.splice(found, 1);
        }
        else
        {
            if (this.selectedContacts.length < this.maxSelectedContacts)
                this.selectedContacts.push(contact);
        }

        // Llamamos al evento para envíar la array de contactos seleccionados.
        this.contactSelected.emit(this.selectedContacts);
    }

    public isSelected(contact: Contact): boolean
    {
        try
        {
            return _.findIndex(this.selectedContacts, x => { return x.id == contact.id }) != -1;
        }
        catch (e)
        {
            return _.findIndex(this.selectedContacts, contact) != -1;
        }
    }

    public DetectChanges(): void
    {
        this.changes.detectChanges();
    }

    deleteContact($event: Contact){
        console.log('fisrt');
        this.contactDeleted.emit($event);
    }

}
