import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const OAuthConf: AuthConfig = {

    issuer: environment.backEndDomain + 'oauth/authorize',
    requireHttps: false,
    strictDiscoveryDocumentValidation: false,
    loginUrl: environment.backEndDomain + 'oauth/authorize',
    tokenEndpoint: environment.backEndDomain + 'oauth/token',
    redirectUri: window.location.origin + '/spa/logincallback',
    clientId: '1',
    scope: '*',
    oidc: false,
    responseType: 'token'

};
