import { MsgBoxConfig } from './message-box/MsgBoxConfig';
import { MessageBoxService } from './message-box.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PdfBlobOpenerService {
    constructor(
        private messageBoxService: MessageBoxService,
        private translateService: TranslateService,
        private http: HttpClient
    ) {}

    public openArbitrary(r, mime): void {
        const blob = new Blob([r], { type: mime});
        const data = window.URL.createObjectURL(blob);
        const h = window.open(data);
        if (h === null) {
            this.messageBoxService.instant(<MsgBoxConfig>{
                data: {
                    title: this.translateService.instant(
                        'allow_emergent_dialogs_title'
                    ),
                    body: this.translateService.instant(
                        'allow_emergent_dialogs_body'
                    ),
                    btn_ok: this.translateService.instant('accept')
                },
                width: '700px',
                height: '200px'
            });
        }
    }

    public open(r): void {
        const blob = new Blob([r], { type: 'application/pdf' });
        const data = window.URL.createObjectURL(blob);
        const h = window.open(data);
        if (h === null) {
            this.messageBoxService.instant(<MsgBoxConfig>{
                data: {
                    title: this.translateService.instant(
                        'allow_emergent_dialogs_title'
                    ),
                    body: this.translateService.instant(
                        'allow_emergent_dialogs_body'
                    ),
                    btn_ok: this.translateService.instant('accept')
                },
                width: '700px',
                height: '200px'
            });
        }
    }

    public openUrl(url){
        console.log('aaaaaaaaaaaaaaaaaaaah');
        this.http.get(url, { responseType: 'blob' }).subscribe(
                r => {
                    console.log(r);
                    this.open(r);
                },
                e => {
                    console.error(e)
                },
            () => {
                }
            );
    }
}
