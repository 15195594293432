import { Injectable } from '@angular/core';
import { TemplateData } from '../../models/api/template/template-data.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { Contact } from '../../models/contacts/contact';
import { ContactNode } from '../../models/contacts/contact-node.model';

@Injectable({
    providedIn: 'root'
})
export class ContactDefinitionService
{
    public editablePdfId$: Observable<number>;
    private editablePdfId: BehaviorSubject<number>;
    private template: BehaviorSubject<TemplateData>;
    public template$: Observable<TemplateData>;
    private signers: BehaviorSubject<Array<Array<Contact>>>;
    public signers$: Observable<Array<Array<Contact>>>;
    public arbitraryText: { [key: string]: string; } = {};
    public cvs: boolean = false;
    public wasGroup: boolean = false;
    public group: ContactNode;

    constructor()
    {
        this.template = new BehaviorSubject(null);
        this.template$ = this.template.asObservable();

        this.signers = new BehaviorSubject(null);
        this.signers$ = this.signers.asObservable();

        this.editablePdfId = new BehaviorSubject(null);
        this.editablePdfId$ = this.editablePdfId.asObservable();
    }

    public setTemplate(template: TemplateData): void
    {
        this.template.next(template);
    }
    public setSigners(signers: Array<Array<Contact>>): void
    {
        this.signers.next(signers);
    }
    public getCurrentTemplate(): TemplateData
    {
        return this.template.value;
    }
    public getCurrentSigners(): Array<Array<Contact>>
    {
        return this.signers.value;
    }
    public getPdfEditableId(): number
    {
        return this.editablePdfId.value;
    }
    public setPdfEditableId(val: number): void
    {
        this.editablePdfId.next(val);
    }
}
