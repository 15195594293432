import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TemplateData } from '../../models/api/template/template-data.model';
import { TemplateEditorRepositoryService } from '../../repositories/pages/template-editor-repository.service';
import { delay, map, mergeMap, retryWhen, takeUntil } from 'rxjs/operators';
import { MessageBoxService } from 'src/app/shared/message-box.service';
import { MsgBoxConfig, MsgBoxData } from 'src/app/shared/message-box/MsgBoxConfig';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BudgetShortageAlertComponent } from 'src/app/shared/budget-shortage-alert/budget-shortage-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { Contact } from '../../models/contacts/contact';
import { TemplateDeployService } from './contacts/template-deploy.service';
import { ContactNode } from '../../models/contacts/contact-node.model';

@Injectable({
    providedIn: 'root'
})
export class TextDefinitionService
{
    private signers: BehaviorSubject<Array<Array<Contact>>>;
    private template: BehaviorSubject<TemplateData>;
    private arbitraryText: BehaviorSubject<{ [key: string]: string; }>;
    private formDeploy: BehaviorSubject<any>;
    private signatureLink: BehaviorSubject<string>;
    public wasGroup: boolean = false;
    public group: ContactNode;
    public cvs: boolean;

    public signers$: Observable<Array<Array<Contact>>>;
    public template$: Observable<TemplateData>;
    public arbitraryText$: Observable<{ [key: string]: string; }>;
    public formDeploy$: Observable<any>;
    public signatureLink$: Observable<string>;

    constructor(
        private repository: TemplateEditorRepositoryService,
        private service: TemplateDeployService,
        private msgbox: MessageBoxService,
        private translator: TranslateService,
        private router: Router,
        private dialog: MatDialog
    )
    {
        this.signers = new BehaviorSubject([[]]);
        this.signers$ = this.signers.asObservable();

        this.template = new BehaviorSubject(null);
        this.template$ = this.template.asObservable();

        this.arbitraryText = new BehaviorSubject(null);
        this.arbitraryText$ = this.arbitraryText.asObservable();

        this.formDeploy = new BehaviorSubject(null);
        this.formDeploy$ = this.formDeploy.asObservable();

        this.signatureLink = new BehaviorSubject(null);
        this.signatureLink$ = this.signatureLink.asObservable();
    }

    public setTemplate(template: TemplateData): void
    {
        this.template.next(template);
    }
    public setSigners(signers: Array<Array<Contact>>): void
    {
        this.signers.next(signers);
    }
    public setArbitraryText(text: { [key: string]: string }): void
    {
        this.arbitraryText.next(text);
    }
    public setFormDeploy(deploy: any): void
    {
        this.formDeploy.next(deploy);
    }
    public getFormDeploy(): any
    {
        return this.formDeploy.value;
    }
    public getCurrentTemplate(): TemplateData
    {
        return this.template.value;
    }
    public getCurrentSigners(): Array<Array<Contact>>
    {
        return this.signers.value;
    }
    public getCurrentArbitraryText(): { [key: string]: string }
    {
        return this.arbitraryText.value;
    }
    public resetSignLink(): void
    {
        this.signatureLink.next(null);
    }
    public resetSigners(): void
    {
        this.signers.next(null);
    }
    public send(template: TemplateData): Observable<any>
    {
        return this.repository.createTemplate(template);
    }
    public deploy(template: TemplateData, signers: Array<Array<Contact>>, arbitraryText: Array<any>): void
    {
        const d = {
            deploys: [],
            tpl_id: template.id
        };
        d.deploys = signers.map((x, k) =>
        {
            return {
                contact_id: x.map((c, k) => c.id),
                flags: x.reduce(
                    (flags, contact) =>
                    {
                        if (contact.flags)
                            contact.flags.map(x =>
                            {
                                flags.push(x);
                                if (x == 'FIRMA_LINK')
                                    flags.push('FIRMA_EN_TIENDA');
                            });
                        return flags
                    }, []),
                text: arbitraryText[k] || {},
                email_callbacks: x.reduce(
                    (emails, contact) =>
                    {
                        if (contact.email_callbacks) contact.email_callbacks.map(x => emails.push(x))
                        return emails
                    }, [])
            };
        });

        if (this.cvs)
            d.deploys.forEach(c => c.flags.push("DO_CVS"));

        this.service.deploy(d)
            .pipe(
                mergeMap(
                    r =>
                    {
                        const signatureOnShop = d.deploys[0].flags.findIndex(x => x == 'FIRMA_EN_TIENDA');
                        const autoSignature = d.deploys[0].flags.findIndex(x => x == 'AUTOFIRMA');
                        if (d.deploys[0].flags.length > 0 && signatureOnShop != -1)
                        {
                            return of(r);
                        }
                        else
                        {
                            let title = 'Document sent succesfully!';
                            let body = 'You can follow its status from the document tray.';

                            if (autoSignature != -1)
                            {
                                body = 'We sent you a copy of the signed document';
                                title = 'Document signed';

                            }

                            if (d.deploys[0].email_callbacks.length > 0)
                                body = 'We sent you and the destinataries a copy of the signed document';

                            const msgad = template.contact_count > 1 ? this.translator.instant('sign_process') : '';
                            const c = {
                                data: {
                                    title: this.translator.instant(title),
                                    body: this.translator.instant(body).concat(msgad),
                                    btn_ok: this.translator.instant('Continue')
                                } as MsgBoxData,
                                height: '285px',
                                width: '500px',
                                disableClose: true
                            } as MsgBoxConfig;

                            return this.msgbox.open(c);
                        }
                    }
                )
            )
            .subscribe(
                r =>
                {
                    const signatureOnShop = d.deploys[0].flags.findIndex(x => x == 'FIRMA_EN_TIENDA');
                    const signaturebyLink = d.deploys[0].flags.findIndex(x => x == 'FIRMA_LINK');
                    if (d.deploys[0].flags.length > 0 && signatureOnShop != -1 && signaturebyLink != -1)
                    {

                        this.retreiveLinkForSingOnShop(r[0].id, true);
                    }
                    else if (d.deploys[0].flags.length > 0 && signatureOnShop != -1 && signaturebyLink == -1)
                    {
                        this.retreiveLinkForSingOnShop(r[0].id);
                    }
                    else
                        this.router.navigate(['document']);
                },
                (e: HttpErrorResponse) =>
                {
                    if (e && e.status !== 402)
                    {
                        const c = {
                            data: {
                                title: this.translator.instant('Error!'),
                                body: this.translator.instant('Please, contact with support.'),
                                btn_ok: this.translator.instant('Continue')
                            }
                        } as MsgBoxConfig;
                        this.msgbox.open(c).subscribe(() =>
                        {
                        });

                    }
                    else
                    {
                        this.dialog.open(BudgetShortageAlertComponent)
                            .afterClosed()
                            .subscribe(r =>
                            {
                            });
                    }
                }
            );
    }
    public deployForm(struc: any): Observable<any>
    {
        const done = new Subject<any>();
        this.repository.formDeploy(struc)
            .subscribe
            (
                x =>
                {
                    console.log(x);
                    done.next(x);
                    done.complete();
                }
            );

        return done;
    }

    public retreiveLinkForSingOnShop(docId: string, byLink: boolean = false): void
    {
        const done = new Subject<void>();
        this.repository.checkStatus(docId)
            .pipe(
                map(res =>
                {
                    if (!res)
                        throw 'nolink';
                    return res;
                }),
                retryWhen(error => error.pipe(
                    delay(5000)
                )),
                takeUntil(done)
            )
            .subscribe(x =>
            {
                if (x)
                {
                    if (!byLink)
                    {
                        const link = x.replace('token', 'redirectToHome=1&token');
                        window.location.href = link;
                    }
                    else
                    {
                        this.signatureLink.next(x);
                        this.router.navigate(['send_assistant', 'bylink']);
                    }
                    done.next();
                    done.complete();
                }
            });
    }
}
