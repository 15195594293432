<app-responsive-topbar></app-responsive-topbar>
<div class="bg-content mat-elevation-z6 pad10 document-tray-content">
    <div class="flex-space-between pad-l10 pad-r10">
        <div class="title flex-center-content">
            {{ "menu_document" | translate }}
        </div>

        <!-- Boton de los filtros con estado -->
        <div class="flex-center-content">
            <!-- DESKTOP -->
            <button
                *ngIf="!(responsive.isMobile$ | async)"
                mat-button
                (click)="filters.toggle()"
            >
                {{
                    filters.expanded
                        ? ("Hide options" | translate)
                        : ("Show options" | translate)
                }}
            </button>

            <!-- MOBILE -->
            <button
                *ngIf="responsive.isMobile$ | async"
                mat-icon-button
                (click)="filters.toggle()"
            >
                <mat-icon>filter_alt</mat-icon>
            </button>

            <mat-icon
                style="cursor: pointer;"
                (click)="filters.toggle()"
                [@iconExapansionAnimation]="
                    filters.expanded ? 'EXPANDED' : 'COLLAPSED'
                "
                >keyboard_arrow_up
            </mat-icon>
        </div>
    </div>
    <div class="filters">
        <mat-expansion-panel #filters>
            <ng-container [formGroup]="documentTAS.formGroup">
                <div class="not-too-big">
                    <mat-form-field>
                        <mat-icon
                            matSuffix
                            (click)="
                                documentTAS.formGroup.controls.tplFilter.setValue(
                                    ''
                                )
                            "
                            >clear</mat-icon
                        >
                        <mat-label>{{ "Document" | translate }}</mat-label>
                        <input
                            (keydown.enter)="documentTAS.getData(false, true)"
                            formControlName="tplFilter"
                            matInput
                        />

                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls[
                                    'tplFilter'
                                ] as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-icon
                            matSuffix
                            (click)="
                                documentTAS.formGroup.controls.templateIdFilter.setValue(
                                    -1
                                )
                            "
                            >clear</mat-icon
                        >
                        <mat-label>{{ "Template" | translate }}</mat-label>
                        <mat-select
                            disableRipple
                            formControlName="templateIdFilter"
                        >
                            <mat-option
                                *ngFor="let k of documentTAS.tplsKeys"
                                [value]="k.id"
                            >
                                {{ k.name }}
                            </mat-option>
                        </mat-select>

                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls
                                    .templateIdFilter as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-icon
                            matSuffix
                            (click)="
                                documentTAS.formGroup.controls.signerFilter.setValue(
                                    ''
                                )
                            "
                            >clear</mat-icon
                        >
                        <mat-label>{{ "Signer" | translate }}</mat-label>
                        <input
                            (keydown.enter)="documentTAS.getData(false, true)"
                            formControlName="signerFilter"
                            matInput
                        />

                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls
                                    .signerFilter as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-icon
                            matSuffix
                            (click)="documentTAS.setDefaulDeliverOptions()"
                            >clear</mat-icon
                        >
                        <mat-label>{{ "Tipo de envio" | translate }}</mat-label>
                        <mat-select
                            disableRipple
                            formControlName="deliverOption"
                            multiple
                        >
                            <mat-option
                                *ngFor="let k of documentTAS.deliverOptions"
                                [value]="k.id"
                            >
                                {{ k.name }}
                            </mat-option>
                        </mat-select>

                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls[
                                    'deliverOption'
                                ] as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>

                    <!-- <mat-form-field  >
                        <mat-icon matSuffix (click)="documentTAS.batchFilter = ''">clear</mat-icon>
                        <mat-label>{{ 'Batch' | translate }}</mat-label>
                        <input [(ngModel)]="documentTAS.batchFilter" (keydown.enter)="documentTAS.getData(false, true)" matInput>
                    </mat-form-field> -->

                    <mat-form-field class="status">
                        <mat-label>{{ "Status" | translate }}</mat-label>
                        <mat-select
                            disableRipple
                            formControlName="statusFilter"
                            multiple
                        >
                            <mat-option
                                *ngFor="let k of documentTAS.statusKeys"
                                [value]="k"
                            >
                                {{
                                    documentTAS.translateStatusKey(k)
                                        | async
                                        | translate
                                }}
                            </mat-option>
                        </mat-select>

                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls[
                                    'statusFilter'
                                ] as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ "From" | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="pickerFrom"
                            placeholder="Choose a date"
                            formControlName="dateFrom"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="pickerFrom"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #pickerFrom></mat-datepicker>

                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls[
                                    'dateFrom'
                                ] as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ "To" | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="pickerTo"
                            placeholder="Choose a date"
                            formControlName="dateTo"
                        />
                        <ng-container
                            *ngIf="
                                documentTAS.formGroup.controls[
                                    'dateTo'
                                ] as frmCtrl
                            "
                        >
                            <ng-container
                                *ngIf="frmCtrl.dirty || frmCtrl.touched"
                            >
                                <mat-error
                                    *ngFor="
                                        let err of frmCtrl.errors | keyvalue
                                    "
                                >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="pickerTo"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                    <button
                        mat-stroked-button
                        class="btn white zip-btn"
                        (click)="documentTAS.getZip()"
                    >
                        {{ "Download zip report" | translate }}
                    </button>
                    <button
                        mat-stroked-button
                        class="btn white excel-btn"
                        (click)="documentTAS.getExcel()"
                    >
                        {{ "Download excel report" | translate }}
                    </button>
                    <button
                        mat-raised-button
                        color="primary"
                        class="btn white filter-btn"
                        (click)="documentTAS.getData(false, true)"
                    >
                        {{ "filter" | translate }}
                    </button>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </div>
    <app-document-table
        [tableAdapter]="documentTAS"
        class="full-width"
    ></app-document-table>
</div>
