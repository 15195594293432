<app-control-ribbon *ngIf="!signatureOnly"></app-control-ribbon>
<app-signature-only-ribbon *ngIf="signatureOnly"></app-signature-only-ribbon>
<ng-container *ngIf="signatureOnly && !(pdfControls.pdf$ | async)">
    <div style="position: relative;top: calc(100% / 2);width: 100%;text-align: center;height: 30px;">
        {{ 'Selecciona un pdf para comenzar' }}
    </div>
</ng-container>
<div class="pdf-container" cdk-scrollable>
    <pdf-viewer
    [show-all]="false"
    [original-size]="!(responsive.isMobile$ | async)"
    [render-text]="false"
    [zoom]="pdfControls.zoom$ | async"
    [page]="pdfControls.currentPage$ | async"
    (after-load-complete)="loadedCompleted($event)"
    (page-rendered)="pageRendered()"
    (pageChange)="pageChanged()"
    [src]="pdfControls.pdf$ | async"
    (error)="onError($event)"></pdf-viewer>
    <div
        #boundary
        *ngIf="initialSizes"
        class="boundary"
        [ngClass]="{'boundary-adding': (pdfElements.state$ | async) == PDF_STATE.ADDING}"
        [style.width.px]="initialSizes.width"
        [style.height.px]="initialSizes.height"
        [style.zoom]="pdfControls.zoom$ | async"
        (click)="clickOverBoundary($event)">
        <ng-container *ngIf="pdfControls.currentPage$ | async as currentPage">
            <ng-container *ngIf="pdfControls.template$ | async as template">
                <ng-container *ngIf="template.outputs.length > 0">
                    <div class="resizable"
                        #resizableElement
                        [id]="idx"
                        *ngFor="let pdfItem of template.outputs[0].dragged | pageIndexFilter: (currentPage - 1); let idx = index; trackBy: trackById"
                        cdkDragBoundary=".boundary"
                        cdkDrag
                        (cdkDragEnded)="updateLocation($event, pdfItem)"
                        [cdkDragFreeDragPosition]="{x: pdfItem.location.x, y: pdfItem.location.y}"
                        [style.width.px]="pdfItem.meta.style['width.px']"
                        [style.height.px]="pdfItem.meta.style['height.px']"
                        [enableGhostResize]="true"
                        mwlResizable
                        (resizeEnd)="onResizeEnd($event, resizableElement, pdfItem)"
                        [validateResize]="validateResize.bind(this)">
                        <div
                            (click)="openPdfItemConfiguration($event, pdfItem)"
                            class='resizers'
                            [style.borderColor]="color.StringToColour((pdfItem.meta.contac_idx) + 'Firmante ' + (pdfItem.meta.contac_idx + 20))">
                            <div mat-ripple style="display:flex;">
                                <div cdkDragHandle class='move-handler'>
                                <svg class="not-clickable" width="20px" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                                </div>
                                <ng-container [ngSwitch]="pdfItem.meta.output_type">
                                    <svg *ngSwitchCase="'aditional_doc'" class="label" viewBox="0 0 200 30">
                                        <text x="0" y="25" style="font-size: 20px">
                                            {{ pdfItem.meta.text }}
                                            {{ pdfItem.meta.filename ? (': ' + pdfItem.meta.filename) : ''}}
                                        </text>
                                    </svg>
                                    <svg *ngSwitchCase="'arbitrary_text_on_send'" class="label" viewBox="0 0 200 30">
                                        <text x="0" y="25" style="font-size: 20px">
                                            {{ pdfItem.meta.textname ? (pdfItem.meta.textname) : pdfItem.meta.text}}
                                        </text>
                                    </svg>
                                    <svg *ngSwitchDefault class="label" viewBox="0 0 200 30">
                                        <text x="0" y="25" style="font-size: 20px">
                                            {{ pdfItem.meta.text }}
                                        </text>
                                    </svg>
                                </ng-container>
                            </div>
                            <div mwlResizeHandle [resizeEdges]="{ bottom: true, right: true }" class='resizer bottom-right'></div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
