import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EntryPointStatus } from 'src/app/configs/enums/entry-point-status.enum';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-responsive-topbar',
    templateUrl: './responsive-topbar.component.html',
    styleUrls: ['./responsive-topbar.component.scss']
})
export class ResponsiveTopbarComponent implements OnInit
{
    // Solo se usa cuando se punta en la ruta del send assist.
    @Input() status: EntryPointStatus;
    @Input() isPreview: Observable<boolean>;

    //Refs.
    public EntryComponentStatus = EntryPointStatus;
    public isEdit: boolean;

    constructor(
        public SideNav: SidenavService,
        public responsive: ResponsiveService,
        public route: Router,
        public stepper: MobileStepperService,
        public aRoute: ActivatedRoute
    )
    {
    }

    ngOnInit(): void
    {

        if (this.aRoute.firstChild)
        {
            this.aRoute.firstChild.params
                .pipe(take(1))
                .subscribe(r =>
                {
                    this.isEdit = (r.FLAG_ONLY_EDIT === 'true' ?? false);
                });
        }
    }
}
