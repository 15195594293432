export class Input {
    name: string;
    type: string; // bind this in HTML when this issue will be fixed https://github.com/angular/angular/issues/13243
    top: number;
    left: number;
    width: number;
    height: number;
    value; any;
    options?: { exportValue: any, displayValue: any }[];
    multiSelect?: boolean;
    page: number;
}