import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArbitraryTextDefinitionService } from '../../../../core/services/pages/arbitrary-text-definition.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import { DocumentTemplateManagementService } from '../../../../core/services/content/document-template-management.service';
import { AribtraryStructure } from 'src/app/core/models/send-assistant/arbitrary-structure.model';
import { Subject } from 'rxjs';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { group } from 'console';

@Component({
    selector: 'app-arbitrary-text-definition',
    templateUrl: './arbitrary-text-definition.component.html',
    styleUrls: ['./arbitrary-text-definition.component.scss']
})
export class ArbitraryTextDefinitionComponent implements OnInit, OnDestroy
{
    public formGroup: FormGroup;
    private unsubscriber: Subject<void>;
    private onlyEdit: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public service: ArbitraryTextDefinitionService,
        public responsive: ResponsiveService
    )
    {
        this.unsubscriber = new Subject<void>();
    }

    ngOnInit()
    {
        this.service.data$
        .pipe(
            takeUntil(this.unsubscriber)
        )
        .subscribe(
            data =>
            {
                if (data)
                {
                    this.buildForm(data);
                }
            }
        )

        this.onlyEdit = (this.route.snapshot.params.FLAG_ONLY_EDIT === 'true' ?? false);
    }
    ngOnDestroy()
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public previous(): void
    {
        const data = this.service.getData();
        const commands: Array<any> = [
            'send_assistant',
            'contacts',
            data.tpl.id
        ];

        if(this.onlyEdit){
            commands.push({ FLAG_ONLY_EDIT: true });
        }

        this.router.navigate(commands, {
            state: {
                template: data.tpl,
                signers: data.contacts,
                arbitraryText: this.formGroup.getRawValue(),
                wasGroup: data.wasGroup,
                group: data.group
            }
        });
    }
    public next(): void
    {
        const data = this.service.getData();
        const commands: Array<any> = ['send_assistant', 'text'];
        if(this.onlyEdit){
            commands.push({ FLAG_ONLY_EDIT: true });
        }
        this.router.navigate(
            commands,
            {
                state: {
                    template: data.tpl,
                    signers: data.contacts,
                    arbitraryText: this.formGroup.getRawValue(),
                    wasGroup: data.wasGroup,
                    group: data.group
                }
            }
        );
    }

    private buildForm(data: AribtraryStructure): void
    {
        const group = {};
        data.contacts.forEach((contactGroup, kContactGroup) =>
        {
            const tmpGrup = {};
            data.tpl.text_name.forEach(inputName =>
            {
                tmpGrup[inputName] = new FormControl('', Validators.required);
            });
            group[kContactGroup] = new FormGroup(tmpGrup);
        });

        this.formGroup = new FormGroup(group);
        const canSetValue = Object.keys(data.arbitraryText).length > 0;
        if (canSetValue)
            this.formGroup.setValue(data.arbitraryText);
    }
}
