<ng-container [formGroup]="configForm">
    <div class="subtitle full-row">{{ "Documents recharge" | translate }}</div>
    <mat-form-field>
        <mat-label>{{ "limit" | translate }}</mat-label>
        <input
            matInput
            type="number"
            placeholder="{{ 'limit' | translate }}"
            formControlName="budget_trigger_docs"
        />
        <mat-error *ngIf="configForm.controls.budget_trigger_docs.errors">
            <ng-container
                *ngIf="
                    configForm.controls.budget_trigger_docs.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ configForm.controls.budget_trigger_docs.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "quantity" | translate }}</mat-label>
        <input
            matInput
            type="number"
            placeholder="{{ 'quantity' | translate }}"
            formControlName="budget_trigger_docs_transfer_amount"
        />
        <mat-error
            *ngIf="
                configForm.controls.budget_trigger_docs_transfer_amount.errors
            "
        >
            <ng-container
                *ngIf="
                    configForm.controls.budget_trigger_docs_transfer_amount
                        .errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{
                    configForm.controls.budget_trigger_docs_transfer_amount
                        .errors
                }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <div></div>
    <div></div>
    <div class="subtitle full-row">{{ "Whatsapp recharge" | translate }}</div>
    <mat-form-field>
        <mat-label>{{ "limit" | translate }}</mat-label>
        <input
            matInput
            type="number"
            placeholder="{{ 'limit' | translate }}"
            formControlName="budget_trigger_whats"
        />
        <mat-error *ngIf="configForm.controls.budget_trigger_whats.errors">
            <ng-container
                *ngIf="
                    configForm.controls.budget_trigger_whats.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ configForm.controls.budget_trigger_whats.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "quantity" | translate }}</mat-label>
        <input
            matInput
            type="number"
            placeholder="{{ 'quantity' | translate }}"
            formControlName="budget_trigger_whats_transfer_amount"
        />
        <mat-error
            *ngIf="
                configForm.controls.budget_trigger_whats_transfer_amount.errors
            "
        >
            <ng-container
                *ngIf="
                    configForm.controls.budget_trigger_whats_transfer_amount
                        .errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{
                    configForm.controls.budget_trigger_whats_transfer_amount
                        .errors
                }}
            </ng-template>
        </mat-error>
    </mat-form-field>
</ng-container>
