import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContactFilters } from 'src/app/core/models/contacts/contactFilters';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { CONTACT_HIERARCHY } from 'src/app/core/mocks/pages/contacts/contact-hierarcy.mock';
import { ContactsGroupsRepositoryService } from 'src/app/core/repositories/pages/contacts-groups-repository.service';
import { ContactGroupPayload } from 'src/app/core/models/api/contacts-groups/contact-group-payload.model';
import { GroupArrayDataSource } from 'src/app/core/classes/group-array-datasource.class';
import { I18nToastService } from '../../i18n-toast.service';
import { Contact } from 'src/app/core/models/contacts/contact';
import { catchError, map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomTextConf } from 'src/app/core/models/contacts/custom-text-conf.model';
import {CustomFieldsConfiguration} from '../../../models/contacts/custom-fields-configuration';

@Injectable({
    providedIn: 'root'
})
export class ConstactsService
{


    private groupsHierarchy: BehaviorSubject<GroupArrayDataSource>;
    public groupsHierarchy$: Observable<GroupArrayDataSource>;
    private contactsOfGroup: BehaviorSubject<Array<Contact>>;
    public contactsOfGroup$: Observable<Array<Contact>>;

    private customFieldConf: BehaviorSubject<CustomFieldsConfiguration>;
    public customFieldConf$: Observable<CustomFieldsConfiguration>;

    private contacts: BehaviorSubject<Array<Contact>>;
    public contacts$: Observable<Array<Contact>>;


    constructor(
        private wsh: WebServiceHelperService,
        private repository: ContactsGroupsRepositoryService,
        private toast: I18nToastService,
        private translator: TranslateService
    )
    {
        // TODO: Cambiar esto para recoger la hierarcy por consulta.
        this.groupsHierarchy = new BehaviorSubject(new GroupArrayDataSource([]));
        this.groupsHierarchy$ = this.groupsHierarchy.asObservable();
        this.contactsOfGroup = new BehaviorSubject([]);
        this.contactsOfGroup$ = this.contactsOfGroup.asObservable();

        this.customFieldConf = new BehaviorSubject(null);
        this.customFieldConf$ = this.customFieldConf.asObservable();

        this.contacts = new BehaviorSubject(null);
        this.contacts$ = this.contacts.asObservable();

        // Pillamos la configuración de los campos y la guardamos aquí.
        this.refreshCustomFieldConf();

        this.getHierarchy();
    }

    public getAllContacts(filters?: ContactFilters): void
    {
        this.post(0, filters)
        .subscribe(
            r => this.contacts.next(r.data)
        );
    }

    getExcel(filters: ContactFilters) {
        return this.wsh.post(environment.backEndDomain + 'api/contact_managment/excel', filters, {responseType: 'arraybuffer' });
    }

    post(page = 0, filters?: ContactFilters): Observable<any>
    {
        filters = filters ? filters : new ContactFilters();

        return new Observable<any>(o =>
        {
            this.wsh.post(environment.backEndDomain + 'api/contact_managment/list?page=' + page, filters).subscribe(r =>
            {
                o.next(r);
            },
                e =>
                {
                    o.error(e);
                },
                () =>
                {
                    o.complete();
                }
            );
        });
    }
    uploadExcel(file): Observable<any>
    {
        return new Observable<any>(o =>
        {
            const formData = new FormData();
            formData.set('file', file, file.name);

            const url =
                environment.backEndDomain +
                'api/contact_managment/upload_excel';
            this.wsh.post(url, formData).subscribe(
                r =>
                {
                    o.next(r);
                },
                e =>
                {
                    o.error(e);
                },
                () =>
                {
                    o.complete();
                }
            );
        });
    }
    postContact(c): Observable<any>
    {
        const url =
            environment.backEndDomain + 'api/contact_managment';
        return this.wsh.post(url, c);
    }
    public refreshCustomFieldConf(): void
    {
        this.getCustomTextStatus().subscribe(x => this.customFieldConf.next(x));
    }
    public getActiveCustomField(): Observable<string>
    {
        return this.customFieldConf$
        .pipe(
            map(
                conf =>
                {
                    let key = null;
                    if (conf && conf.useAsName.flag_value)
                        key = 'custom_value_' + conf.useAsName.data_value;

                    return key;
                }
            )
        );
    }
    public createGroup(group: ContactGroupPayload): Observable<any>
    {
        return this.repository.addGroup(group)
            .pipe(
                tap(
                    x =>
                    {
                        if (x)
                            this.getHierarchy();
                    }
                ),
                catchError(
                    err =>
                    {
                        this.handleErrors(err, 'There was a problem trying to add the group, try it again later');
                        throw new Error();
                    }
                )
            );
    }
    public deleteGroup(groupId: number): void
    {
        this.repository.deleteGroup(groupId)
            .subscribe(
                x =>
                {
                    this.getHierarchy();
                },
                error =>
                {
                    this.toast.open('You can not delete a group with childs', 'Accept', 7000, true);
                }
            );
    }
    public editGroup(group: ContactNode): void
    {
        const payload = new ContactGroupPayload();
        payload.name = group.name;
        payload.parent_id = group.parent_id;
        payload.type = group.type;

        this.repository.editGroup(payload, group.id)
            .subscribe(
                x =>
                {
                    this.getHierarchy();
                },
                err =>
                {
                    this.handleErrors(err, 'There was a problem trying to edit the group, try it again later');
                }
            )
    }
    public removeContactFromGroup(groupId: number, contactId: number): Observable<any>
    {
        return this.repository.removeContactFromGroup(groupId, contactId);
    }
    public getCurrentHierarchy(): GroupArrayDataSource
    {
        return this.groupsHierarchy.value;
    }
    public getContactsOfGroup(id: number): void
    {
        this.repository.getContactsOfGroup(id)
            .subscribe(
                x =>
                {
                    this.contactsOfGroup.next(x.contacts);
                }
            )
    }
    public getContactsOfSpecificGroup(id: number): Observable<any>
    {
        return this.repository.getContactsOfGroup(id);
    }
    public resetContacts(): void
    {
        this.contactsOfGroup.next([]);
    }
    public addContactToGroup(contactId: number, groupId: number): Observable<any>
    {
        return this.repository.addContactToGroup(groupId, contactId)
            .pipe(
                catchError(
                    err =>
                    {
                        this.handleErrors(err, 'There was a problem while trying to add the contact, please try it again later');
                        return of(null);
                    }
                )
            );
    }
    public validateGroupName(name: string, origin: number): boolean
    {
        const structure = this.groupsHierarchy.value;
        const validity = { isValid: true };
        structure.rawData().forEach(node => this.checkName(node, name, origin, validity));

        return validity.isValid;
    }
    public generateStructureForSendByGroups(node: ContactNode): Observable<Contact[][]>
    {
        // 1. Recogemos los contactos del grupo.
        return this.getContactsOfSpecificGroup(node.id)
        .pipe(
            map(
                res =>
                {
                    const contacts = [];
                    const structure: Contact[][] = [];

                    this.getContacts(contacts, res);
                    contacts.forEach(
                        (contact, i) =>
                        {
                            structure.push([contact]);
                        }
                    );

                    return structure;
                }
            )
        );
    }
    public getCustomTextStatus(): Observable<CustomFieldsConfiguration>
    {
        return this.wsh.get<Array<any>>(environment.backEndDomain + 'api/contact_managment/customTextConf')
            .pipe(
                map(arr => arr.map(arrb => arrb.map(c =>
                            {
                                return Object.assign(new CustomTextConf(), c);
                            }
                        ))),
                map(arr =>
                    {
                        return {
                            customText: arr[0],
                            useAsName: arr[1][0]
                        };
                    }
                )
            );
    }
    public setCustomConfTexts(conf: { customText: Array<CustomTextConf>, useAsName: CustomTextConf})
    {
        return this.wsh.post<any>(environment.backEndDomain + 'api/contact_managment/customTextConf', conf);
    }

    private getContacts(contacts: Array<Contact>, node: ContactNode): void
    {
        if (node.contacts.length > 0)
        {
            node.contacts.forEach(
                contact =>
                {
                    const found = contacts.findIndex(x => x.id == contact.id);
                    if (found == -1)
                        contacts.push(contact);
                }
            )
        }

        if (node.children.length > 0)
        {
            node.children.forEach(x => this.getContacts(contacts, x));
        }
    }
    private checkName(node: ContactNode, name: string, origin: number, valid: { isValid: boolean }): void
    {
        if (node.name == name && node.id != origin)
        {
            valid.isValid = false;
            return;
        }
        else
        {
            if (node.children && node.children.length > 0)
            {
                node.children.forEach(child => this.checkName(child, name, origin, valid));
            }
        }
    }
    private getHierarchy(): void
    {
        this.repository.getGroupHierarchy().subscribe(
            x => this.groupsHierarchy.next(new GroupArrayDataSource(x))
        );
    }
    private handleErrors(e: any, defaultMsg: string): void
    {
        const errors = e.error.errors;
        const keys = Object.keys(errors);
        if (keys.length > 0)
        {
            this.toast.open(
                errors[keys[0]],
                this.translator.instant('Accept'),
                7000,
                false);
        }
        else
        {
            this.toast.open(
                defaultMsg,
                'Accept',
                7000);
        }
    }

    disableContact(contact: Contact)
    {
        return this.wsh.post<any>(environment.backEndDomain + 'api/contact_managment/disable', contact);
    }
}
