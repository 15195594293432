import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { geonamesReducer } from './reducer';
import { GeonamesEffects } from './effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('geonames', geonamesReducer),
        EffectsModule.forFeature([GeonamesEffects])
    ]
})
export class GeonamesStoreModule { }
