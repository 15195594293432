import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TenantService} from '../../../../core/services/pages/tenant-management/tenant.service';
import {Observable} from 'rxjs';
import {Tenant} from '../../../../core/models/tenant-management/tenant';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-buy-alert',
    templateUrl: './buy-alert.component.html',
    styleUrls: ['./buy-alert.component.scss']
})
export class BuyAlertComponent implements OnInit
{
    @HostBinding('class') classes = 'mat-typography';
    tenant$: Observable<Tenant>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public tenant: TenantService) {
        this.tenant$ = this.tenant.getTenantData().pipe(take((1)))
    }

    ngOnInit()
    {



    }

    parseFloat(includedVat: any)
    {
        return parseFloat(includedVat);

    }
}
