import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfEditableRoutingModule } from './pdf-editable-routing.module';
import { PdfEditableComponent } from './pdf-editable.component';
import { EditablePdfEditorComponent } from './components/editable-pdf-editor/editable-pdf-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TipModule } from 'src/app/content/tip/tip.module';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { TemplateEditorModule } from 'src/app/content/template-editor/template-editor.module';
import { SignatureEditorComponent } from './components/signature-editor/signature-editor.component';
import { ContactSelectionComponent } from './components/contact-selection/contact-selection.component';
import { MessageDefinitionComponent } from './components/message-definition/message-definition.component';
import { ContactsModule } from 'src/app/content/contacts/contacts.module';
import { MessagePreviewModule } from 'src/app/content/message-preview/message-preview.module';
import { PdfEditableListComponent } from './components/pdf-editable-list/pdf-editable-list.component';
import { PdfEditableCardComponent } from './components/pdf-editable-list/components/pdf-editable-card/pdf-editable-card.component';
import { SendAssistantModule } from '../send-assistant/send-assistant.module';
import { PdfEditableUnattendedListComponent } from './components/pdf-editable-list/unattended/pdf-editable-unattended-list/pdf-editable-unattended-list.component';
import { PdfEditableUnattendedCardComponent } from './components/pdf-editable-list/unattended/pdf-editable-unattended-list/components/pdf-editable-unattended-card/pdf-editable-unattended-card.component';
import { SignatureEditorUnattendedComponent } from './components/signature-editor/unattended/signature-editor-unattended/signature-editor-unattended.component';
import { ShareComponent } from './components/pdf-editable-list/unattended/pdf-editable-unattended-list/components/share/share.component';
import { CopyAddonFieldModule } from 'src/app/content/copy-addon-field/copy-addon-field.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
    declarations: [
        PdfEditableComponent,
        EditablePdfEditorComponent,
        SignatureEditorComponent,
        ContactSelectionComponent,
        MessageDefinitionComponent,
        PdfEditableListComponent,
        PdfEditableCardComponent,
        PdfEditableUnattendedListComponent,
        PdfEditableUnattendedCardComponent,
        SignatureEditorUnattendedComponent,
        ShareComponent
    ],
    imports: [
        CommonModule,
        PdfEditableRoutingModule,
        TranslateModule,
        MaterialModule,
        UserMenuModule,
        TipModule,
        FormsModule,
        ReactiveFormsModule,
        ResponsiveTopbarModule,
        TemplateEditorModule,
        ContactsModule,
        MessagePreviewModule,
        SendAssistantModule,
        CopyAddonFieldModule,
        NgxQRCodeModule
    ]
})
export class PdfEditableModule { }
