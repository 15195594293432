import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TextDefinitionService } from '../services/pages/text-definition.service';

@Injectable({
    providedIn: 'root'
})
export class BylinkGuard implements CanActivate
{
    constructor(
        private router: Router,
        private service: TextDefinitionService
    )
    { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.service.signatureLink$
            .pipe(
                map(link =>
                {
                    if (link)
                        return true;

                    this.router.navigate(['send_assistant', 'file']);
                    return false;
                })
            );
    }
}
