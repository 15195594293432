import { Tip } from 'src/app/core/models/tip';

export const EDITABLE_EDITOR_TIPS: Tip[] = [
    {
        title: 'Fill the form',
        body: `form_filler_1`
    },
    {
        title: 'Send the document',
        body: 'form_filler_2'
    }
];