import { Component, Input, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';
import { Package } from 'src/app/core/models/tenant-management/package.model';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
    selector: 'app-buy-packages-carrousel',
    templateUrl: './buy-packages-carrousel.component.html',
    styleUrls: ['./buy-packages-carrousel.component.scss']
})
export class BuyPackagesCarrouselComponent implements OnInit
{
    @HostBinding('class') class = 'round-container bg';

    @Input() title: string;
    @Input() docType: string;
    @Input() packs: Array<Package>;
    @Input() countryId: number;

    @Output() buyAction = new EventEmitter<Package>();

    constructor(public responsive: ResponsiveService) { }

    ngOnInit(): void
    {
    }

    public startBuyProcess(pack: Package): void
    {
        this.buyAction.emit(pack);
    }

}
