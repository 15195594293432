import {Component, Inject} from '@angular/core';
import {matBaseAnimations} from '../../../base/base.component';
import {DocumentService} from '../../../core/services/content/document.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-document-extras-modal',
    templateUrl: './document-extras-modal.component.html',
    styleUrls: ['./document-extras-modal.component.css'],
    animations: [matBaseAnimations.iconExapansionAnimation]
})
export class DocumentExtrasModalComponent {
    JSON: JSON = JSON;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public docSer: DocumentService) {
    }

    isContactKey(column: string) {
        return column.match(/contact_.*/) !== null;
    }

    getContacStatus(column: string, data) {
        const matches = column.match(/contact_(.*)/);
        return parseInt(data['contact_status_' + matches[1]], 10);
    }

    getContacStatusText(column: string, data) {
        const matches = column.match(/contact_(.*)/);
        switch (parseInt(data['contact_status_' + matches[1]], 10)) {
            case 0:
                return 'Ha firmado';
                break;
            case 4:
                return 'link firma expirado)';
                break;
            default:
                return 'Pendiente firma)';
                break;
        }
    }

    getContacStatusDate(column: string, data) {
        const matches = column.match(/contact_(.*)/);
        return data['contact_status_date_' + matches[1]];
    }
}
