import { Component, OnInit} from '@angular/core';

import { TileNumeric } from '../shared/tile-numeric';
import { KpiService } from '../shared/kpi.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-breakdown-numeric-kpi',
    templateUrl: './breakdown-numeric-kpi.component.html',
    styleUrls: ['./breakdown-numeric-kpi.component.css']
})
export class BreakdownNumericKpiComponent implements OnInit
{
    public tileData$: Observable<TileNumeric>;

    constructor(
        private kpi: KpiService
    )
    { }

    ngOnInit()
    {
        this.tileData$ = this.kpi.getBreakdownByStatesKpiData();
    }

}
