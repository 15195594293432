import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstTwoLetters'
})
export class FirstTwoLettersPipe implements PipeTransform
{

    transform(value: string, ...args: unknown[]): unknown
    {
        if (value.length < 2)
            return value;

        const res = value[0] + value[1] + value[2];
        return res;
    }

}
