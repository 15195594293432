import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'src/app/core/models/contacts/contact';

@Pipe({
    name: 'disabledChannel',
    pure: false
})
export class DisabledChannelPipe implements PipeTransform
{
    transform(deploy: Contact[]): boolean
    {
        if (deploy && deploy.length > 0)
        {
            const emptyContactFound = deploy.findIndex(contact => !contact)
            if (emptyContactFound == -1)
                return false;
        }

        return true;
    }
}
