import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactSelectionGuard } from 'src/app/core/guards/contact-selection.guard';
import { MessageDefinitionGuard } from 'src/app/core/guards/message-definition.guard';
import { PreFormTextGuard } from 'src/app/core/guards/pre-form.guard';
import { SignatureEditorGuard } from 'src/app/core/guards/signature-editor.guard';
import { ContactSelectionComponent } from './components/contact-selection/contact-selection.component';
import { EditablePdfEditorComponent } from './components/editable-pdf-editor/editable-pdf-editor.component';
import { MessageDefinitionComponent } from './components/message-definition/message-definition.component';
import { PdfEditableListComponent } from './components/pdf-editable-list/pdf-editable-list.component';
import { SignatureEditorComponent } from './components/signature-editor/signature-editor.component';
import { PdfEditableComponent } from './pdf-editable.component';
import { PdfEditableUnattendedListComponent } from './components/pdf-editable-list/unattended/pdf-editable-unattended-list/pdf-editable-unattended-list.component';
import { SignatureEditorUnattendedComponent } from './components/signature-editor/unattended/signature-editor-unattended/signature-editor-unattended.component';
import { ShareComponent } from './components/pdf-editable-list/unattended/pdf-editable-unattended-list/components/share/share.component';

const routes: Routes = [
    {
        path: '',
        component: PdfEditableComponent,
        children: [
            {
                path: 'file',
                component: SignatureEditorComponent,
                canActivate: [SignatureEditorGuard]
            },
            {
                path: 'contacts/:id/:editablePdfId',
                component: ContactSelectionComponent,
                canActivate: [ContactSelectionGuard]
            },
            {
                path: 'form',
                component: EditablePdfEditorComponent,
                canActivate: [PreFormTextGuard]
            },
            {
                path: 'message',
                component: MessageDefinitionComponent,
                canActivate: [MessageDefinitionGuard]
            },
            {
                path: 'list',
                component: PdfEditableListComponent
            },
            {
                path: 'file_unattended',
                component: SignatureEditorUnattendedComponent,
                canActivate: [SignatureEditorGuard]
            },
            {
                path: 'list_unattended',
                component: PdfEditableUnattendedListComponent
            },
            {
                path: 'share/:id',
                component: ShareComponent
            }
            
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PdfEditableRoutingModule { }
