export enum EntryPointStatus {
    DefineDoc,
    SendTypeSwitch,
    SetDocName,
    SetBaseFile,
    EditingTemplate,
    SetContacts,
    ArbitraryText,
    Send,
    DefineDocText
}