import { Observable, timer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { map, delayWhen, takeUntil, tap, retryWhen } from 'rxjs/operators';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';

export const WAIT_TIME_BETWEEEN_REQUEST = 3000;

@Injectable({
    providedIn: 'root'
})
export class TemplateDeployService
{

    constructor(private wsh: WebServiceHelperService, private httpClient: HttpClient) { }

    deploy(data: DeployStructure): Observable<any>
    {
        const url = environment.backEndDomain + 'api/document/deploy_tpl_on_contact';
        return this.wsh.post(url, data)
    }

    deployFormData(data: DeployStructure, file: File): Observable<any>
    {
        const params = new FormData();
        params.set('file', file, file.name);
        params.set('tpl_id', data.tpl_id.toString());
        _.forEach(data.deploys, (deploy, deployIdx) =>
        {
            _.forEach(deploy.contact_id, (contact, contactIndex) =>
            {
                _.forEach(deploy.flags, (flag, flagIndex) =>
                {
                    params.set('deploys[' + deployIdx + '][flags][' + flagIndex + ']', flag);
                })
                params.set('deploys[' + deployIdx + '][contact_id][' + contactIndex + ']', contact.toString());
                // For debugging purpouses XDDD -> console.log("contact[" + i + "][" + f + "]", z);
            })
        });

        const url = environment.backEndDomain + 'api/document/deploy_tpl_on_contact';
        return this.wsh.post(url, params);
    }

    public waitForGoToPresenceSign(docId: number, cancelSubject: Subject<void>): Observable<any>
    {
        return this.wsh.get(environment.backEndDomain + 'api/document/' + docId + '/checkStatus')
            .pipe(
                map(val =>
                {
                    if (val)
                        return val
                    else
                        throw 'without data';
                }),
                retryWhen(errors =>
                    errors.pipe(
                        tap(val => console.log(`Error ${val} was detected!`)),
                        delayWhen(val => timer(WAIT_TIME_BETWEEEN_REQUEST))
                    )
                ),
                takeUntil(cancelSubject)
            )
    }
}

export interface DeployStructure
{
    deploys: Array<{
        contact_id: Array<number>,
        flags: Array<string>
    }>
    tpl_id: number;
}
