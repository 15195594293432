import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableTrayRoutingModule } from './editable-tray-routing.module';
import { EditableTrayComponent } from './editable-tray.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { MaterialModule } from 'src/app/material/material.module';
import { DateAdapter } from '@angular/material/core';
import { DatePickerAdapter } from 'src/app/shared/date-picker.adapter';
import { EditablePdfTableComponent } from './components/editable-pdf-table/editable-pdf-table.component';


@NgModule({
    declarations: [
        EditableTrayComponent,
        EditablePdfTableComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        UserMenuModule,
        TranslateModule,
        FormsModule,
        ResponsiveTopbarModule,
        EditableTrayRoutingModule
    ],
    providers: [
        {provide: DateAdapter, useClass: DatePickerAdapter}
    ],
})
export class EditableTrayModule { }
