import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'app-document-template-form-management',
    templateUrl: './document-template-form-management.component.html',
    styleUrls: ['./document-template-form-management.component.scss']
})
export class DocumentTemplateFormManagementComponent implements OnInit
{
    @HostBinding('class') classes = 'bg mat-typography';
    constructor() { }

    ngOnInit(): void
    {
    }

}
