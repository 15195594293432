import { PdfBlobOpenerService } from '../../../shared/pdf-blob-opener.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { WebServiceHelperService } from '../../../shared/web-service-helper.service';
import { TableFilters, TablePayload, TableProvider } from 'src/app/material-table/shared/iTableProvider';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MaterialTableColumn } from 'src/app/material-table/shared/tableModel';
import { map, tap } from 'rxjs/operators';
import { LaravelPaginatedList } from '../../../shared/auto-table/definitions/laravel-paginated-list';

@Injectable({
    providedIn: 'root'
})
export class DocumentService implements TableProvider
{

    public statusMap: any;


    public statusCache: ReplaySubject<Object> = new ReplaySubject(1);
    public statusCacheKey: ReplaySubject<Object> = new ReplaySubject(1);
    public atActionDictCache: ReplaySubject<Object> = new ReplaySubject(1);


    /** Sección table provider */
    templateId: string;
    availableFilters: Array<TableFilters>;
    filters = <TablePayload>{ params: {} };
    endPointUrl: string;

    setEndpointUrl(url: string, templateId: string): void
    {
        this.endPointUrl = url;
        this.templateId = templateId;
    }

    getData(payload?: TablePayload): Observable<any>
    {
        // prevent nulls
        _.forEach(Object.keys(payload.params), x =>
        {
            payload.params[x] =
                payload.params[x] == null ? '' : payload.params[x];
        });

        if (this.endPointUrl)
        {
            const url = environment.backEndDomain + this.endPointUrl;
            return new Observable(o =>
            {
                this.wsh.get<any>(url, payload)
                    .pipe(
                        map(r =>
                        {
                            const action = <MaterialTableColumn>{
                                column: 'action',
                                columnTitle: 'action',
                                isSticky: false
                            }
                            const audit_trail = <MaterialTableColumn>{
                                column: 'audit_trail',
                                columnTitle: 'audit_trail',
                                isSticky: false
                            }

                            r.conf.columns.push(action, audit_trail);

                            return r;
                        })
                    )
                    .subscribe(
                        r =>
                        {
                            // Comprobamos que los filtros no esten configuados.
                            if (!this.availableFilters)
                            {
                                this.availableFilters = r.conf.filter;
                            }

                            // Recorremos el objeto rows.data para mapear los estados.
                            _.forEach(r.rows.data, row =>
                            {
                                row.action = row.status_id;
                                row.status_id = this.translator.instant(
                                    this.statusMap[row.status_id]
                                );
                            });
                            o.next(r);
                        },
                        error =>
                        {
                            o.error(error);
                        },
                        () => o.complete()
                    );
            });
        } else
            throw new Error(
                'No se ha configurado el endPointUrl en el DocumentService/TableProvider'
            );
    }

    constructor(
        private wsh: WebServiceHelperService,
        private bloblOpener: PdfBlobOpenerService,
        private translator: TranslateService
    )
    {

        this
            .getStatusObs()
            .pipe(
                tap(r =>
                {
                    this.statusCacheKey.next(Object.keys(r));
                })
            )
            .subscribe(
                r => this.statusCache.next(r),
                e => this.statusCache.error(e),
                () => this.statusCache.complete()
            );
        this.getAuditTrailActionKeys()
            .subscribe(
                r => this.atActionDictCache.next(r),
                e => this.atActionDictCache.error(e),
                () => this.atActionDictCache.complete()
            );
    }

    public getContacts(filter): Observable<any>
    {
        const url =
            environment.backEndDomain + 'api/document?page=' + filter.page;
        return this.wsh.get(url);
    }

    public getStatus(): void
    {
        const url = environment.backEndDomain + 'api/document/status';
        this.wsh.get(url).subscribe(r =>
        {
            this.statusMap = r;
        });
    }

    public getPdf(id): void
    {
        const url = environment.backEndDomain + 'api/document/' + id + '/pdf';
        this.wsh.get(url, { responseType: 'blob' }).subscribe(r =>
        {
            this.bloblOpener.open(r);
        });
    }
    public getForm(id): void
    {
        const url = environment.backEndDomain + 'api/form/' + id + '/preview';
        this.wsh.get(url, { responseType: 'blob' }).subscribe(r =>
        {
            this.bloblOpener.open(r);
        });
    }

    public retrySend(id): void
    {
        const url =
            environment.backEndDomain + 'api/document/' + id + '/retrySend';
        this.wsh.post(url, {}, {}).subscribe(r =>
        {
        }, e =>
        {
        }, () =>
        {
        });
    }
    public retrySendForm(id): void
    {
        const url =
            environment.backEndDomain + 'api/form/' + id + '/transition';
        this.wsh.post(url, {}, {}).subscribe(r =>
        {
        }, e =>
        {
        }, () =>
        {
        });
    }

    public getAuditTrail(id): Observable<Array<any>>
    {
        const url =
            environment.backEndDomain + 'api/document/' + id + '/audit_trail';
        return this.wsh.get<any[]>(url);
    }

    public delete(id: Number): Observable<any>
    {
        const url =
            environment.backEndDomain + 'api/document/' + id;
        return this.wsh.delete(url, {});
    }
    public deleteForm(id: Number): Observable<any>
    {
        const url =
            environment.backEndDomain + 'api/form/' + id;
        return this.wsh.delete(url, {});
    }
    public getPdfAuditTrail(id): void
    {
        const url =
            environment.backEndDomain + 'api/document/' + id + '/audit_trail/pdf';
        this.wsh.get(url, { responseType: 'blob' }).subscribe(r =>
        {
            this.bloblOpener.open(r);
        });
    }

    public downloadAttachment(docId, input_id, mime)
    {
        mime = JSON.parse(mime).mime;
        const url =
            environment.backEndDomain + 'api/document/' + docId + '/attachment/' + input_id;
        this.wsh.get(url, { responseType: 'blob' }).subscribe(r =>
        {
            this.bloblOpener.openArbitrary(r, mime);
        });
    }

    public listByTpl(tpl_id: string, filter: Object)
    {

        const url = environment.backEndDomain + 'api/document/type/' + tpl_id;
        return this.wsh.post(url, filter);

    }

    public listAll(page: any, tplNameFilter: string, templateIdFilter: number, batchFilter: string, signerFilter: string, statusFilter: string[], fromDate: Date, toDate: Date, deliverOption: string[], loader?: boolean): Observable<LaravelPaginatedList<any>>
    {

        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 59);

        const paramsObj = {
            page: page,
            batch: batchFilter,
            template: tplNameFilter,
            template_id: templateIdFilter,
            signer: signerFilter,
            status: statusFilter,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
            deliverOption: deliverOption
        };

        const params = Object.keys(paramsObj)
            .filter(k => paramsObj[k] !== undefined && paramsObj[k] !== '')
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(paramsObj[k]))
            .join('&');

        const url =
            environment.backEndDomain + 'api/document' + '?' + params;
        return this.wsh.get<LaravelPaginatedList<any>>(url, { nospiner: loader })
    }

    public getExcel(tplNameFilter: string, templateIdFilter: number, batchFilter: string, signerFilter: string, statusFilter: string[], fromDate: Date, toDate: Date, deliverOption: string[], loader?: boolean): Observable<any>
    {

        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 59);

        console.log(templateIdFilter);

        const paramsObj = {
            batch: batchFilter,
            template: tplNameFilter,
            template_id: templateIdFilter,
            signer: signerFilter,
            status: statusFilter,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
            deliverOption: deliverOption
        };

        const params = Object.keys(paramsObj)
            .filter(k => paramsObj[k] !== undefined && paramsObj[k] !== '')
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(paramsObj[k]))
            .join('&');

        const url =
            environment.backEndDomain + 'api/document/excel' + '?' + params;
        return this.wsh.get<any>(url, { nospiner: loader, responseType: 'arraybuffer' })
    }

    public getZip(tplNameFilter: string, templateIdFilter: number, batchFilter: string, signerFilter: string, statusFilter: string[], fromDate: Date, toDate: Date, deliverOption: string[], loader?: boolean): Observable<any>
    {

        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 59);

        console.log(templateIdFilter);

        const paramsObj = {
            batch: batchFilter,
            template: tplNameFilter,
            template_id: templateIdFilter,
            signer: signerFilter,
            status: statusFilter,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
            deliverOption: deliverOption
        };

        const params = Object.keys(paramsObj)
            .filter(k => paramsObj[k] !== undefined && paramsObj[k] !== '')
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(paramsObj[k]))
            .join('&');

        const url =
            environment.backEndDomain + 'api/document/zip' + '?' + params;
        return this.wsh.get<any>(url, { nospiner: loader, responseType: 'arraybuffer' })
    }

    public listAllForms(page: any, tplNameFilter: string, templateIdFilter: number, batchFilter: string, signerFilter: string, statusFilter: string[], fromDate: Date, toDate: Date, loader?: boolean): Observable<LaravelPaginatedList<any>>
    {

        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 59);

        console.log(templateIdFilter);

        const paramsObj = {
            page: page,
            batch: batchFilter,
            title: tplNameFilter,
            signer: signerFilter,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString()
        };

        if (templateIdFilter != -1)
        {
            paramsObj['form_template_id'] = templateIdFilter;
        }

        statusFilter
            .forEach(
                (x, i) =>
                {
                    paramsObj[`status[${ i }]`] = x;
                }
            );

        const params = Object.keys(paramsObj)
            .filter(k => paramsObj[k] !== undefined && paramsObj[k] !== '')
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(paramsObj[k]))
            .join('&');

        const url =
            environment.backEndDomain + 'api/form' + '?' + params;
        return this.wsh.get<LaravelPaginatedList<any>>(url, { nospiner: loader })
    }

    getStatusObs(): Observable<any>
    {
        const url = environment.backEndDomain + 'api/document/status';
        return this.wsh.get(url);
    }

    translateStatusKey(doc_status: any):
        Observable<string>
    {
        return this.statusCache
            .pipe(map(r => r[doc_status]));
    }

    getAuditTrailActionKeys(): Observable<any>
    {
        const url = environment.backEndDomain + 'api/document/audit_trail_action_keys';
        return this.wsh.get(url);
    }

    translateAtActionKey(doc_status: any): Observable<string>
    {
        doc_status = Number(doc_status);
        return this.atActionDictCache
            .pipe(map(r => r[doc_status]));
    }

}
