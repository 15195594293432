import { MaterialModule } from './../../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseTableComponent } from './components/base-table/base-table.component';

@NgModule({
  declarations: [BaseTableComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    BaseTableComponent
  ]

})
export class AutoTableModule { }
