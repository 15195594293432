import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService
{
    private isMobile: BehaviorSubject<boolean>;
    public isMobile$: Observable<boolean>;
    private isMd: BehaviorSubject<boolean>;
    public isMd$: Observable<boolean>;

    constructor(
        private breakpointObserver: BreakpointObserver
    )
    {
        this.isMobile = new BehaviorSubject<boolean>(false);
        this.isMobile$ = this.isMobile.asObservable();
        this.isMd = new BehaviorSubject<boolean>(false);
        this.isMd$ = this.isMd.asObservable();

        breakpointObserver.observe(['(max-width: 768px)'])
            .subscribe(results =>
            {
                if (results.matches)
                    this.isMobile.next(true);
                else
                    this.isMobile.next(false);
            });

        breakpointObserver.observe(['(max-width: 1366px)'])
            .subscribe(
                results =>
                {
                    if (results.matches)
                        this.isMd.next(true);
                    else
                        this.isMd.next(false);
                }
            );
    }

    public IsMobile(): boolean
    {
        return this.isMobile.value;
    }
}
