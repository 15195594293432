import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PDF_EDITABLE_STATUS } from 'src/app/configs/dicctionaries/pdf-editable/pdf-editable-status.dictionary';
import { FileSelectorDialogComponent } from 'src/app/content/template-editor/components/signature-only-ribbon/components/file-selector-dialog/file-selector-dialog.component';
import { PdfEditable } from 'src/app/core/models/pdf-editable/pdf-editable.model';
import { DocumentService } from 'src/app/core/services/content/document.service';
import { FormTemplateManagementService } from 'src/app/core/services/content/form-template-management.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { TemplateNewNameDialogComponent } from 'src/app/pages/template-management/components/template-new-name-dialog/template-new-name-dialog.component';
import { MessageBoxService } from 'src/app/shared/message-box.service';

@Component({
    selector: 'app-pdf-editable-card',
    templateUrl: './pdf-editable-card.component.html',
    styleUrls: ['./pdf-editable-card.component.scss']
})
export class PdfEditableCardComponent implements OnInit
{
    @Input() template: PdfEditable;
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
    public status = PDF_EDITABLE_STATUS.map(x => x.value).join(',');

    constructor(
        protected router: Router,
        public service: FormTemplateManagementService,
        public trans: TranslateService,
        public toast: I18nToastService,
        public msgBox: MessageBoxService,
        public docService: DocumentService,
        public dialog: CrossPlatformDialogService
    ) { }

    ngOnInit(): void
    {
    }

    public edit(): void
    {
        if (this.template)
            this.router.navigate(['editable_pdf', 'file'], {
                queryParams: { id: this.template.document_template_id, editablePdfId: this.template.id }
            });
    }
    public send(): void
    {
        if (this.template)
            this.router.navigate(['editable_pdf', 'contacts', this.template.document_template_id, this.template.id],{
                queryParams: { referer: '/editable_pdf/list' }
            });
    }
    delete()
    {
        this.service.deleteTpl(this.template.id)
        .subscribe(
            x =>
            {
                let msg = this.trans.instant('template x has been disabled', {contact_name: this.template.name});
                if (this.template.status == 'OFF')
                    msg = this.trans.instant('template x has been enabled', {contact_name: this.template.name});

                this.toast.open(msg, this.trans.instant('Accept'), 5000, false);
                this.deleted.emit();
            },
            e =>
            {
                console.log(e);
                this.msgBox.instant({
                    data: {
                        title: this.trans.instant('error'),
                        body: this.trans.instant('Can not delete'),
                        btn_ok: this.trans.instant('ok')
                    }
                })
            }
        );
    }
    public clone(): void
    {
        this.dialog.open({
            data: {
                data: this.template,
                dynamycComponent: TemplateNewNameDialogComponent,
                countries: [],
                isEdit: false,
                hideCloseButton: false,
                locales: [],
                positiveButton: 'save',
                message: this.trans.instant('Type the new name you want for the new form'),
                title: this.trans.instant('Duplicate form')
            },
            disableClose: false,
            width: '500px'
        })
        .subscribe(
            newName =>
            {
                if (newName)
                {
                    const newTpl = {
                        id: this.template.id,
                        name: newName
                    };
                    this.service.cloneForm(newTpl)
                    .subscribe(r =>
                    {
                        // LOL SORRY: FORCING LIST RELOAD
                        this.deleted.emit();
                    });
                }
            }
        );
    }
}
