import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, take } from 'rxjs/operators';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { Contact } from 'src/app/core/models/contacts/contact';
import { CustomTextConf } from 'src/app/core/models/contacts/custom-text-conf.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { MessageBoxService } from 'src/app/shared/message-box.service';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ContactSearchComponent } from '../contact-search/contact-search.component';

@Component({
    selector: 'app-contact-picker-base',
    template: '',
})
export class ContactPickerBaseComponent implements OnInit
{
    @ViewChild('stepper') stepper: MatHorizontalStepper;
    @ViewChild(ContactSearchComponent) contactsList: ContactSearchComponent;
    public selectedContacts: Array<Array<Contact>> = [];
    public template: TemplateData;
    public sendType: string = 'simple';

    constructor(
        protected contactService: ConstactsService,
        protected dialog: MatDialog,
        protected toast: I18nToastService,
        protected bottomSheet: MatBottomSheet,
        protected msgBox: MessageBoxService,
        protected translator: TranslateService,
        protected changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit(): void
    {
    }

    public range(n: number): Array<number>
    {
        // @ts-ignore
        return Array(n).fill().map((x, i) => i);
    }
    public addContact()
    {
        this.contactService.customFieldConf$
            .pipe(
                map(x => x.customText),
                map((arr: Array<CustomTextConf>) =>
                {
                    return arr.filter(c =>
                    {
                        return c.flag_value > 0;
                    }
                    )
                }),
                mergeMap(c =>
                {
                    const structure = this.buildModalConf(null, false, c);

                    return this.dialog.open(ContactFormComponent, structure).afterClosed()
                })
            )
            .subscribe(
                r =>
                {
                    if (r)
                    {
                        this.toast.open(
                            'The contact has been created sucessful',
                            'accept',
                            5000
                        );
                        this.selectContact(r);
                        this.contactsList.loadContacts();
                    }
                }
            );
    }
    public selectContact(originContact: Contact)
    {
        const contact = Object.assign(new Contact(), originContact);

        let index = this.sendType == 'simple' ? 0 : this.stepper.selectedIndex;
        this.prepareContactsArray(index);

        let firstEmptySpace = this.selectedContacts[index].findIndex(x => !x);

        if (firstEmptySpace == -1 && this.sendType != 'simple')
        {
            if (this.stepper)
            {
                this.stepper.next();
                index = this.stepper.selectedIndex;
            }
            this.prepareContactsArray(index);
            firstEmptySpace = this.selectedContacts[index].findIndex(x => !x);
        }

        if (firstEmptySpace != -1)
        {

            // Si el primer espacio vacío es diferente que 0, tenemos que controlar
            // que el contacto que vayamos a añadir tenga mobile_phone, si no tien,
            // hay que cambiar la flag del contacto en posición 0 y avisar de que
            // se ha cambiado el envío a mail por ese motivo.
            if (firstEmptySpace != 0)
            {
                if (!contact.mobile_phone && this.selectedContacts[index][0].flags.indexOf('WHATSAPP') != -1)
                {
                    this.selectedContacts[index][0].flags = ['MAIL'];
                    this.msgBox.open({
                        data: {
                            title: this.translator.instant('There is a contact without mobile phone'),
                            body: this.translator.instant('The send channel was changed to mail because you cannot send documents via Whatsapp to contacts that do not have mobile phone'),
                            btn_ok: this.translator.instant('Accept')
                        },
                        width: '400px'
                    }).subscribe().unsubscribe();
                }
            }
            else
                // By default, seteamos la flag como email.
                contact.flags = ['MAIL'];

            this.selectedContacts[index][firstEmptySpace] = contact;
        }
        else
            this.toast.open(
                'Unselect a contact in order to add a new one',
                'Accept',
                5000
            );
    }
    public editContact(contact: Contact): void
    {
        this.contactService.customFieldConf$
            .pipe(
                map(x => x.customText),
                map((arr: Array<CustomTextConf>) =>
                {
                    return arr.filter(c =>
                    {
                        return c.flag_value > 0;
                    }
                    )
                }),
                mergeMap(c =>
                {
                    const structure = this.buildModalConf(contact, true, c);

                    return this.dialog.open(ContactFormComponent, structure).afterClosed()
                })
            )
            .subscribe(
                r =>
                {
                    if (r)
                    {
                        contact = r;
                        this.toast.open(
                            'The contact has been edited sucessful',
                            'accept',
                            5000
                        );
                        this.selectContact(r);
                        this.contactsList.loadContacts();
                    }

                }
            );
    }
    public unselectContact(i: number, contactIndex: number): void
    {
        const found = this.selectedContacts[i][contactIndex];
        if (!found.repeated)
            this.selectedContacts[i][contactIndex] = null;
    }
    public openSelectContactDialog(): void
    {
        const ref = this.bottomSheet.open(ContactSearchComponent, {
            panelClass: 'contact-search-from-dialog'
        });

        // Nos guardamos las referencias.
        const selected = ref.instance.selected.subscribe(
            x =>
            {
                this.selectContact(x);
                ref.dismiss();
            });
        const newContact = ref.instance.newContact.subscribe(
            x =>
            {
                this.addContact();
                ref.dismiss();
            });
        const editContact = ref.instance.editContact.subscribe(x => this.editContact(x));

        // Matamos las subs al cerrar el dialogo.
        ref.afterDismissed()
            .pipe(
                take(1)
            )
            .subscribe(
                x =>
                {
                    selected.unsubscribe();
                    newContact.unsubscribe();
                    editContact.unsubscribe();
                }
            );
    }
    public flagAllDeploys(flag: string): void
    {
        this.selectedContacts.forEach(deploy =>
        {
            this.treatDeployFlags(flag, deploy);
        })
    }

    flagDeploy(event: MatButtonToggleChange, deploy: Contact[]): void
    {
        const flag = event.value;
        const toggle = event.source.buttonToggleGroup;
        this.treatDeployFlags(flag, deploy, toggle);
    }

    public treatDeployFlags(flag: string, deploy: Contact[], toggle?: MatButtonToggleGroup): void
    {
        const noMobileFound = deploy.findIndex(x => !x || (x && !x.mobile_phone));
        if (noMobileFound != -1 && flag != 'MAIL')
        {
            //alert("Alguno no tiene mobial!!!!, configurando a mail");
            this.openBadFlagMessage();
            flag = 'MAIL';
        }

        // Salvamos las personal flags.
        let personalFlags = []
        if (deploy[0].flags && deploy[0].flags.length > 1)
        {
            personalFlags = deploy[0].flags.filter(x => x == 'FIRMAR_EN_TIENDA' || x == 'AUTOFIRMA');
        }

        deploy[0].flags = [];
        deploy[0].flags.push(flag);
        deploy[0].flags = [...deploy[0].flags, ...personalFlags];

        if (toggle)
            toggle.value = flag;
    }

    private buildModalConf(user: Contact, isEdit: boolean, conf: Array<CustomTextConf>)
    {
        user = user || (new Contact());
        const structure = {
            data: {
                title: isEdit ? 'edit contact' : 'new contact',
                positiveButton: isEdit ? 'save' : 'create',
                countries: [],
                locales: [],
                data: {
                    contact: isEdit ? user : new Contact(),
                    config: conf
                },
                isEdit,
                dynamycComponent: ContactFormComponent,
                hideCloseButton: false
            },
            width: '730px',
            height: '550px',
            disableClose: false
        } as DialogStructure<{ contact: Contact, config: Array<CustomTextConf> }>;

        return structure;
    }
    private prepareContactsArray(index: number): void
    {
        let contact_count = 1;
        if (this.template && this.template.contact_count)
            contact_count = this.template.contact_count;

        if (this.selectedContacts[index] === undefined)
            this.selectedContacts[index] = Array(contact_count).fill(null);
    }
    private openBadFlagMessage(): void
    {
        this.msgBox.open({
            data: {
                title: this.translator.instant('There is a contact without mobile phone'),
                body: this.translator.instant('The send channel was changed to mail because you cannot send documents via Whatsapp to contacts that do not have mobile phone'),
                btn_ok: this.translator.instant('Accept')
            },
            width: '400px'
        }).subscribe().unsubscribe();
    }
}
