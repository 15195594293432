import { M } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, HostBinding, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EDITABLE_EDITOR_TIPS } from 'src/app/configs/dicctionaries/pdf-editable/editable-editor-tips.dictionary';
import { EditableEditorComponent } from 'src/app/content/template-editor/components/editable-editor/editable-editor.component';
import { ArbitraryTextDefinitionService } from 'src/app/core/services/pages/arbitrary-text-definition.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';

@Component({
    selector: 'app-editable-pdf-editor',
    templateUrl: './editable-pdf-editor.component.html',
    styleUrls: ['./editable-pdf-editor.component.scss']
})
export class EditablePdfEditorComponent implements OnInit, AfterViewInit
{
    @HostBinding('class') classes = '';
    @ViewChild('editor') editor: EditableEditorComponent;
    public TIPS = EDITABLE_EDITOR_TIPS;
    @Output() next: Observable<any>;

    constructor(
        public responsive: ResponsiveService,
        public service: ArbitraryTextDefinitionService,
        public confirmation: ConfirmationBoxService,
        private translator: TranslateService,
        private router: Router
    ) { }

    ngOnInit(): void
    {}
    ngAfterViewInit(): void
    {
        // Cuando termine de cargar las anotaciones, miramos si podemos cargar
        // datos en el form. Puede ser que haya vuelto atrás desde la pantalla
        // de personalización del mensaje.
        const done = new Subject();
        this.editor.anotationsLoadEnd$
        .pipe(
            takeUntil(done)
        )
        .subscribe(
            x =>
            {
                if (x)
                {
                    const data = this.service.getFormData();
                    if (data)
                        setTimeout(() => {
                            console.log('Configurando formdata en', this.editor.myForm)
                            this.editor.myForm.setValue(data)
                        });
                }

                done.next();
                done.complete();
            }
        )
    }
    public continue(): void
    {
        const struct = this.service.getData();
        const form = this.editor.editor.getForm();
        const deploy = {
            form_template_id: struct.editablePdfId,
            title: form.name,
            status: form.status,
            contacts_payload: [struct.contacts[0][0].id],
            fixed_texts: this.editor.editor.buildFormInputMapping(this.editor.myForm.value)
        }

        //console.log(deploy);
        this.service.setFormData(this.editor.myForm.value);

        this.router.navigate(
            [
                'editable_pdf',
                'message'
            ],
            {
                state: {
                    deploy
                }
            }
        )
    }
    public previous(): void
    {
        this.confirmation.open(
            {
                title: this.translator.instant('You will lose the changes'),
                body: this.translator.instant('If you go back, you will lose the data you introduced in the form, do you agree?'),
                btn_cancel: this.translator.instant('cancel'),
                btn_ok: this.translator.instant('Accept')
            }
        )
        .subscribe(
            res =>
            {
                if (res)
                {
                    this.service.setFormData(null);
                    const struct = this.service.getData();
                    this.router.navigate(
                        [
                            'editable_pdf',
                            'contacts',
                            struct.tpl_id,
                            struct.editablePdfId
                        ],
                        {
                            state: {
                                signers: struct.contacts,
                                template: struct.tpl
                            }
                        }
                    );
                }
            }
        )
    }

}
