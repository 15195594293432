<table
    mat-table
    #table
    [dataSource]="tableAdapter.data | async"
    style="width: 100%"
>
    <ng-container [matColumnDef]="'doc_name'">
        <th mat-header-cell *matHeaderCellDef>
            {{ "Document name" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            <ng-container
                *ngIf="
                    tableAdapter.responsive.isMobile$ | async;
                    else docnameNombl
                "
            >
                {{ row["doc_name"] }}
                <div
                    class="mbl-date flex-row-start-center"
                    style="margin-bottom: 2px"
                >
                    <mat-icon style="font-size: 15px;height: 17px;"
                        >calendar_today</mat-icon
                    >{{
                        row.min_date != null
                            ? (row.min_date | prepareDate | date: "short")
                            : "-"
                    }}
                </div>
            </ng-container>
            <ng-template #docnameNombl>
                <div class="table-label" [matTooltip]="row['doc_name']">
                    {{ row["doc_name"] }}
                </div>
            </ng-template>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'full_name'">
        <th mat-header-cell *matHeaderCellDef>
            {{ "Signers name" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            <ng-container
                *ngIf="
                    tableAdapter.responsive.isMobile$ | async;
                    else statusNombl
                "
            >
                <ng-container *ngTemplateOutlet="statusNombl"></ng-container>
                <div style="display: flex; align-items: center;">
                    <mat-icon
                        style="margin-right: 5px;"
                        [matTooltip]="row.flags | flagToIcon | translate"
                        [ngSwitch]="row.flags | flagToIcon"
                    >
                        <ng-container *ngSwitchCase="'WHATSAPP'">
                            <img
                                style="width: 20px; height: 20px;"
                                src="assets/svg/whatsapp-icon.svg"
                            />
                        </ng-container>
                        <ng-container *ngSwitchCase="'FIRMA_LINK'">
                            link
                        </ng-container>
                        <ng-container *ngSwitchCase="'AUTOFIRMA'">
                            gesture
                        </ng-container>
                        <ng-container *ngSwitchCase="'MAIL'">
                            mail
                        </ng-container>
                        <ng-container *ngSwitchCase="'DO_CVS'">
                            mail
                        </ng-container>
                        <ng-container *ngSwitchCase="'FIRMA_EN_TIENDA'">
                            link
                        </ng-container>
                        <ng-container *ngSwitchCase="'SMS'">
                            sms
                        </ng-container>
                    </mat-icon>
                    <div class="table-status">
                        {{
                            tableAdapter.translateStatusKey(row.doc_status)
                                | async
                                | translate
                        }}
                    </div>
                </div>
            </ng-container>
            <ng-template #statusNombl>
                <div
                    class="signer-balls-table"
                    [ngClass]="{
                        'mbl-date': tableAdapter.responsive.isMobile$ | async
                    }"
                >
                    <div
                        class="ball-24px mar-r10 ball"
                        [matTooltip]="signer.name"
                        [ngClass]="{
                            signed: signer.status == 0,
                            'link-expired': signer.status == 4,
                            unsigned: signer.status != 0
                        }"
                        *ngFor="let signer of row['parsedSigners']"
                    >
                        {{ signer.initials }}
                    </div>
                </div>
            </ng-template>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'min_date'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "created_date" | translate }}
        </th>
        <td
            mat-cell
            *matCellDef="let row"
            style="text-align: right;"
            [matTooltip]="
                row.min_date != null
                    ? (row.min_date | prepareDate | date: 'short')
                    : '-'
            "
        >
            {{
                row.min_date != null
                    ? (row.min_date | prepareDate | date: "short")
                    : "-"
            }}
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'doc_status'">
        <th mat-header-cell *matHeaderCellDef>
            {{ "Status" | translate }}
        </th>
        <td
            mat-cell
            *matCellDef="let row"
            [matTooltip]="
                tableAdapter.translateStatusKey(row.doc_status)
                    | async
                    | translate
            "
        >
            <span>
                {{
                    tableAdapter.translateStatusKey(row.doc_status)
                        | async
                        | translate
                }}
            </span>
        </td>
    </ng-container>

    <!-- TYPE (FLAG) -->
    <ng-container [matColumnDef]="'flags'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "Shipment" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <mat-icon
                [matTooltip]="row.flags | flagToIcon | translate"
                [ngSwitch]="row.flags | flagToIcon"
            >
                <ng-container *ngSwitchCase="'WHATSAPP'">
                    <img
                        style="width: 20px; height: 20px;"
                        src="assets/svg/whatsapp-icon.svg"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'FIRMA_LINK'">
                    link
                </ng-container>
                <ng-container *ngSwitchCase="'AUTOFIRMA'">
                    gesture
                </ng-container>
                <ng-container *ngSwitchCase="'MAIL'">
                    mail
                </ng-container>
                <ng-container *ngSwitchCase="'DO_CVS'">
                    mail
                </ng-container>
                <ng-container *ngSwitchCase="'FIRMA_EN_TIENDA'">
                    link
                </ng-container>
                <ng-container *ngSwitchCase="'SMS'">
                    sms
                </ng-container>
            </mat-icon>
        </td>
    </ng-container>

    <!-- REINTENTAR -->
    <ng-container [matColumnDef]="'retry'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "Retry" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button
                [matTooltip]="'Retry' | translate"
                mat-icon-button
                [disabled]="row.doc_status == 8"
                (click)="tableAdapter.retrySend(row.doc_id, row)"
            >
                <mat-icon>
                    redo
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- AUDIT TRAIL -->
    <ng-container [matColumnDef]="'audit_trail'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            Audit trail
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button
                [matTooltip]="'Open audit trail' | translate"
                mat-icon-button
                [disabled]="row.doc_status != 8"
                (click)="tableAdapter.getPdfAuditTrail(row.doc_id)"
            >
                <mat-icon>
                    fingerprint
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Más INFO -->
    <ng-container [matColumnDef]="'more_info'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "More info" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button
                [matTooltip]="'More info' | translate"
                mat-icon-button
                (click)="tableAdapter.more(row)"
            >
                <mat-icon>
                    info
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Download -->
    <ng-container [matColumnDef]="'download'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "Download" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button
                [matTooltip]="'Download' | translate"
                mat-icon-button
                (click)="tableAdapter.getPdf(row.doc_id)"
            >
                <mat-icon>
                    cloud_download
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Delete -->
    <ng-container [matColumnDef]="'delete'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "Delete" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button
                matTooltip="{{ 'Delete' | translate }}"
                mat-icon-button
                (click)="tableAdapter.delete(row.doc_id)"
            >
                <mat-icon style="color:red;">
                    delete
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- MBL ACTIONS -->
    <ng-container [matColumnDef]="'actions'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ "Actions" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu class="mat-typography">
                <div class="menu-doc-name">
                    {{ row.doc_name }}
                </div>
                <mat-divider></mat-divider>
                <button
                    matTooltip="{{ 'Actions' | translate }}}"
                    mat-menu-item
                    (click)="tableAdapter.more(row)"
                >
                    <mat-icon>
                        description
                    </mat-icon>
                    <span> {{ "Más info" | translate }}</span>
                </button>
                <button
                    matTooltip="{{ 'Ver audit trail' | translate }}"
                    mat-menu-item
                    (click)="tableAdapter.getPdfAuditTrail(row.doc_id)"
                >
                    <mat-icon>
                        fingerprint
                    </mat-icon>
                    <span> {{ "Audit trail" | translate }}</span>
                </button>
                <button
                    matTooltip="{{ 'Descargar' | translate }}"
                    mat-menu-item
                    (click)="tableAdapter.getPdf(row.doc_id)"
                >
                    <mat-icon>
                        cloud_download
                    </mat-icon>
                    <span> {{ "Descargar" | translate }}</span>
                </button>
                <button
                    matTooltip="{{ 'Reintentar' | translate }}"
                    *ngIf="row.doc_status != 4"
                    mat-menu-item
                    (click)="tableAdapter.retrySend(row.doc_id, row)"
                >
                    <mat-icon>
                        redo
                    </mat-icon>
                    <span> {{ "Reintentar" | translate }}</span>
                </button>
                <button
                    matTooltip="{{ 'Eliminar' | translate }}"
                    mat-menu-item
                    (click)="tableAdapter.delete(row.doc_id)"
                >
                    <mat-icon>
                        delete
                    </mat-icon>
                    <span> {{ "Eliminar" | translate }}</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="tableAdapter.getAllColumnsName(); sticky: true"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: tableAdapter.getAllColumnsName()"
    ></tr>
</table>

<mat-paginator
    class="mat-paginator-sticky"
    #paginator
    [pageIndex]="tableAdapter.currentPage"
    [length]="tableAdapter.elementCount"
    [pageSize]="tableAdapter.pageSize"
    (page)="tableAdapter.paginatorEventHandler($event)"
>
</mat-paginator>
