import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactSearchComponent } from './components/contact-search/contact-search.component';
import { ContactSimpleComponent } from './components/contact-simple/contact-simple.component';
import { ContactVirtualScrollComponent } from './components/contact-virtual-scroll/contact-virtual-scroll.component';
import { ContactComponent } from './components/contact/contact.component';
import { TranslateModule } from '@ngx-translate/core';
import { TipModule } from '../tip/tip.module';
import { ExcelUploadReportComponent } from './components/excel-upload-report/excel-upload-report.component';
import { ContactPickerBaseComponent } from './components/contact-picker-base/contact-picker-base.component';
import { LacMatTelInputModule } from 'lac-mat-tel-input';


@NgModule({
    declarations: [
        ContactComponent,
        ContactFormComponent,
        ContactVirtualScrollComponent,
        ContactSimpleComponent,
        ContactSearchComponent,
        ExcelUploadReportComponent,
        ContactPickerBaseComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        TipModule,
        LacMatTelInputModule
    ],
    exports: [
        ContactFormComponent,
        ContactVirtualScrollComponent,
        ContactSimpleComponent,
        ContactSearchComponent,
        ContactComponent,
        ExcelUploadReportComponent,
        ContactPickerBaseComponent
    ]
})
export class ContactsModule { }
