import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'src/app/core/models/contacts/contact';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';

@Pipe({
    name: 'templateMessage'
})
export class TemplateMessagePipe implements PipeTransform
{
    constructor(private service: UserMenuService) { }
    transform(text: string, contact: Contact): any
    {
        if (this.service.userData && this.service.tenantData)
        {
            text = text.replace(/:sender_name/g, this.service.userData.name);
            text = text.replace(/:sender_surname/g, this.service.userData.surname);
            text = text.replace(/:company_name/g, this.service.tenantData.company);
        }

        if (contact)
        {
            text = text.replace(/:contact_name/g, contact.name);
            text = text.replace(/:contact_surname/g, `${ contact.surname_1 } ${ contact.surname_2 }`);
        }
        return text;
    }

}
