<app-responsive-topbar></app-responsive-topbar>
<div class="bg-content user-management-content mat-elevation-z6 pad10">
    <div class="flex-space-between pad-l10 pad-r10">
        <div class="title flex-center-content">{{'menu_users_managament' | translate}}</div>

        <!-- Boton de los filtros con estado -->
        <div class="flex-center-content">
            <!-- DESKTOP -->
            <button *ngIf="!(responsive.isMobile$ | async)" mat-button (click)="filters.toggle()">
                {{ filters.expanded ? ('Hide filters' | translate) : ('Show filters' | translate) }}
            </button>

            <!-- MOBILE -->
            <button *ngIf="(responsive.isMobile$ | async)" mat-icon-button (click)="filters.toggle()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <mat-icon style="cursor: pointer;" (click)="filters.toggle()"
                [@iconExapansionAnimation]="filters.expanded ? 'EXPANDED' : 'COLLAPSED'">keyboard_arrow_up
            </mat-icon>
        </div>
    </div>

    <div class="filters">
        <mat-expansion-panel #filters>
            <div fxLayout="row wrap" class="not-too-big">
                <mat-form-field class="filter_input">
                    <mat-icon matPrefix class="mar-r10">person</mat-icon>
                    <mat-icon matSuffix (click)="filterObj.name = null;">clear</mat-icon>
                    <mat-label>{{ 'name' | translate }}</mat-label>
                    <input [(ngModel)]="filterObj.name" matInput placeholder="{{ 'name' | translate }}">
                </mat-form-field>
                <mat-form-field class="filter_input">
                    <mat-icon matPrefix class="mar-r10">mail</mat-icon>
                    <mat-icon matSuffix (click)="filterObj.email = null;">clear</mat-icon>
                    <mat-label>{{ 'mail' | translate }}</mat-label>
                    <input [(ngModel)]="filterObj.email" matInput placeholder="{{ 'mail' | translate }}">
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Grid con los usuarios -->
    <div class="responsive-grid">
        <app-user style="width: auto!important;" [User]="user" (delete)="delete($event)"
            (passwordReset)="passwordReset($event)" (edit)="openModal($event, true)"
            [BaseColor]="StringToColour(user.name)" [Color]="InvertColor(StringToColour(user.name), true)"
            *ngFor="let user of userService.users | async | contentFilter: filterObj"
            class="round-container blue"></app-user>
    </div>
</div>
<button (click)="openModal(null, false)" mat-fab color="primary" class="floating-button">
    <mat-icon>add</mat-icon>
</button>