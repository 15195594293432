import { Contact } from 'src/app/core/models/contacts/contact'
import { TemplateData } from '../api/template/template-data.model'
import { ContactNode } from '../contacts/contact-node.model';

export class AribtraryStructure
{
    public tpl_id: number;
    public tpl: TemplateData;
    public contacts: Array<Array<Contact>>;
    public arbitraryText: { [key: string]: string; };
    public wasGroup?: boolean;
    public group?: ContactNode;
    public cvs?: boolean;
    public editablePdfId?: number;
}