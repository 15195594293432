import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BaseComponent, matBaseAnimations } from 'src/app/base/base.component';
import { FormTemplateManagementService } from 'src/app/core/services/content/form-template-management.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';

@Component({
    selector: 'app-pdf-editable-list',
    templateUrl: './pdf-editable-list.component.html',
    styleUrls: ['./pdf-editable-list.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation]
})
export class PdfEditableListComponent extends BaseComponent implements OnInit
{
    public templates$: Subject<Array<any>> = new Subject<Array<any>>();
    public filtersGroup: FormGroup;
    protected unsubscriber: Subject<void>;

    constructor(
        public SideNav: SidenavService,
        public responsive: ResponsiveService,
        public service: FormTemplateManagementService,
        protected router: Router
    )
    {
        super(SideNav);
        const now = new Date();
        const today = new Date();
        today.setDate(now.getDate() + 3600);
        const past = new Date();
        past.setDate(now.getDate() - 3600);

        this.filtersGroup = new FormGroup({
            name: new FormControl(''),
            dateIni: new FormControl(past),
            dateEnd: new FormControl(today),
            status:  new FormControl(1)
        });

        this.unsubscriber = new Subject<void>();
    }

    ngOnInit(): void
    {
        this.filter();
    }

    public filter(): void {
        const filters = Object.assign({}, this.filtersGroup.value);

        this.service.listAllForms(filters)
        .subscribe(
            x =>
            {
                this.templates$.next([]);
                this.templates$.next(x);

            }
        )
        // this.tplMng.listFlaggedTemplates(filters).subscribe(r => {
        //     this.templates$.next([])
        //     this.templates$.next(r)
        // });
    }
}
