import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, mergeMap, take } from 'rxjs/operators';
import { GROUP_OF_CONTACTS_EDIT_TIP } from 'src/app/configs/dicctionaries/groups/group-management.dictionary';
import { ContactFormComponent } from 'src/app/content/contacts/components/contact-form/contact-form.component';
import { ContactSearchComponent } from 'src/app/content/contacts/components/contact-search/contact-search.component';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { Contact } from 'src/app/core/models/contacts/contact';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { CustomTextConf } from 'src/app/core/models/contacts/custom-text-conf.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';

@Component({
    selector: 'app-contact-group-editor',
    templateUrl: './contact-group-editor.component.html',
    styleUrls: ['./contact-group-editor.component.scss']
})
export class ContactGroupEditorComponent implements OnInit
{
    @HostBinding('class') class = 'mat-typography';
    @ViewChild(ContactSearchComponent) contactsList: ContactSearchComponent;
    public isEdit = false;
    public name: FormControl;
    public contactsOfGroup: Array<Contact>;
    public tips = GROUP_OF_CONTACTS_EDIT_TIP;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ContactNode,
        public dialog: MatDialog,
        private toast: I18nToastService,
        public service: ConstactsService,
        private dialogRef: MatDialogRef<ContactGroupEditorComponent>,
        public responsive: ResponsiveService,
        private bottomSheet: MatBottomSheet,
        public contactsService: ConstactsService
    )
    {
        this.contactsOfGroup = new Array();
    }

    ngOnInit(): void
    {
        this.name = new FormControl(this.data.name, Validators.required);
        this.getContactsOfGroup()
    }

    public editGroupName(): void
    {
        // Si el estado es edit, hay que verificar y guardar, de lo contrario, es togle.
        if (this.isEdit)
        {
            if (this.name.valid)
            {
                this.isEdit = !this.isEdit;
            }
            else
            {
                this.toast.open('You have to introduce a valid name', 'Accept', 7000);
            }
        }
        else
            this.isEdit = !this.isEdit;
    }
    public save(): void
    {
        if (this.data.name != this.name.value && this.name.valid)
        {
            // Asignamos para que se haga el update.
            this.data.name = this.name.value;
            this.dialogRef.close('save');
        }
        else
            this.dialogRef.close();
    }
    public addContact(contact: Contact): void
    {
        this.service.addContactToGroup(contact.id, this.data.id)
        .subscribe(
            x =>
            {
                if (x)
                    this.contactsOfGroup.push(contact);
            }
        )
    }
    public removeContact(contact: Contact): void
    {
        this.service.removeContactFromGroup(this.data.id, contact.id)
        .subscribe(
            () => this.getContactsOfGroup(),
            error =>
            {
                this.toast.open(
                    'There was a problem while trying to add the contact, please try it again later',
                    'Accept',
                    7000);
            }
        )
    }
    public addNewContact()
    {
        this.contactsService.customFieldConf$
            .pipe(
                map(x => x.customText),
                map((arr: Array<CustomTextConf>) => {
                    return arr.filter(c => {
                            return c.flag_value > 0;
                        }
                    )
                }),
                mergeMap(c => {
                    const structure = this.buildModalConf(null, false, c);

                    return this.dialog.open(ContactFormComponent, structure).afterClosed()
                })
            )
            .subscribe(
                r =>
                {
                    if (r)
                    {
                        this.toast.open(
                            'The contact has been created sucessful',
                            'accept',
                            5000
                        );
                        this.addContact(r);
                        this.contactsList.loadContacts();
                    }
                }
            );
    }
    public editContact(contact: Contact): void
    {
        this.contactsService.customFieldConf$
            .pipe(
                map(x => x.customText),
                map((arr: Array<CustomTextConf>) => {
                    return arr.filter(c => {
                            return c.flag_value > 0;
                        }
                    )
                }),
                mergeMap(c => {
                    const structure = this.buildModalConf(contact, true, c);

                    return this.dialog.open(ContactFormComponent, structure).afterClosed()
                })
            )
            .subscribe(
                r =>
                {
                    if (r)
                    {
                        contact = r;
                        this.toast.open(
                            'The contact has been edited sucessful',
                            'accept',
                            5000
                        );
                        this.contactsList.loadContacts();
                        this.getContactsOfGroup()
                    }

                }
            );
    }
    /**
     * Solo se usa para mbl
     */
    public openSelectContactDialog(): void
    {
        const ref = this.bottomSheet.open(ContactSearchComponent, {
            panelClass: 'contact-search-from-dialog'
        });

        // Nos guardamos las referencias.
        const selected = ref.instance.selected.subscribe(
            x =>
            {
                this.addContact(x);
                ref.dismiss();
            });
        const newContact = ref.instance.newContact.subscribe(
            x =>
            {
                this.addNewContact();
                ref.dismiss();
            });
        const editContact = ref.instance.editContact.subscribe(x => this.editContact(x));

        // Matamos las subs al cerrar el dialogo.
        ref.afterDismissed()
            .pipe(
                take(1)
            )
            .subscribe(
                x =>
                {
                    selected.unsubscribe();
                    newContact.unsubscribe();
                    editContact.unsubscribe();
                }
            );
    }

    private getContactsOfGroup(): void
    {
        this.service.getContactsOfSpecificGroup(this.data.id)
        .subscribe(
            x =>
            {
                this.contactsOfGroup = x.contacts;
            }
        );
    }
    private buildModalConf(user: Contact, isEdit: boolean, conf: Array<CustomTextConf>)
    {
        user = user || (new Contact());
        const structure = {
            data: {
                title: isEdit ? 'edit contact' : 'new contact',
                positiveButton: isEdit ? 'save' : 'create',
                countries: [],
                locales: [],
                data: {
                    contact: isEdit ? user : new Contact(),
                    config: conf
                },
                isEdit,
                dynamycComponent: ContactFormComponent,
                hideCloseButton: false
            },
            width: '730px',
            height: '550px',
            disableClose: false
        } as DialogStructure<{ contact: Contact, config: Array<CustomTextConf> }>;

        return structure;
    }
    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: [this.tips]
        });
    }
}
