import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentFilterPipe } from './content-filter.pipe';

@NgModule({
    declarations: [ContentFilterPipe],
    imports: [
        CommonModule
    ],
    exports: [ContentFilterPipe]
})
export class PipesModule { }
