// Set constant for all moduleRoutes
import {Routes} from '@angular/router';
import {DocumentTrayComponent} from './components/document-tray/document-tray.component';

export const ModuleRoutes: Routes = [
    {
        path: '',
        component: DocumentTrayComponent
    },{
        path: ':filter',
        component: DocumentTrayComponent
    }
];
