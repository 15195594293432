import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
    selector: 'app-base-table',
    template: ''
})
export class BaseTableComponent implements OnInit, OnDestroy
{
    public gridOptions: GridOptions = {
        pagination: true,
        rowHeight: 35,
        defaultColDef: {
            resizable: true,
            sortable: true
        }
    };
    public context = { componentParent: this };
    protected gridApi: GridApi | undefined;
    protected hasToFit: boolean = true;

    constructor(
        public translator: TranslateService
    ) { }

    ngOnInit(): void
    {
    }

    ngOnDestroy(): void
    {
        this.gridApi = null;
    }

    public gridReady(params: GridReadyEvent): void
    {
        this.gridApi = params.api;
        if (this.hasToFit)
            this.gridApi.sizeColumnsToFit();
    }

    protected translateColumnHeaders(colDefs: ColDef[]): void
    {
        colDefs.forEach(
            col =>
            {
                setTimeout(() =>
                {
                    col.headerName = this.translator.instant(col.headerName);
                    return col;
                });
            }
        );

        this.gridOptions.columnDefs = colDefs
    }

}
