import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from 'src/app/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DocumentTemplateFormListComponent} from './components/document-form-list/document-template-form-list.component';
import {DocumentTemplateFormManagementRoutingModule} from './document-template-form-management-routing.module';
import {ResponsiveTopbarModule} from '../../content/responsive-topbar/responsive-topbar.module';
import {TemplateEditorComponent} from './components/template-editor/template-editor.component';
import {DocumentTemplateFormComponent} from './components/document-template-form/document-template-form.component';
import {DocumentFormCardComponent} from './components/document-form-card/document-form-card.component';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { DocumentTemplateFormManagementComponent } from './document-template-form-management.component';
import { TemplateNewNameDialogComponent } from './components/template-new-name-dialog/template-new-name-dialog.component';
import { ShareComponent } from './components/share/share.component';
import { CopyAddonFieldModule } from 'src/app/content/copy-addon-field/copy-addon-field.module';
import { SpaceExtractorPipe } from './pipes/space-extractor.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
    declarations: [
        DocumentTemplateFormListComponent,
        DocumentFormCardComponent,
        DocumentTemplateFormComponent,
        TemplateEditorComponent,
        DocumentTemplateFormManagementComponent,
        ShareComponent,
        SpaceExtractorPipe,
        TemplateNewNameDialogComponent
    ],
    imports: [
        CommonModule,
        DocumentTemplateFormManagementRoutingModule,
        TranslateModule,
        MaterialModule,
        UserMenuModule,
        FormsModule,
        CopyAddonFieldModule,
        ReactiveFormsModule,
        ResponsiveTopbarModule,
        NgxQRCodeModule

    ],
    entryComponents: [
        DocumentTemplateFormComponent
    ],
    exports: [ShareComponent]
})
export class DocumentTemplateFormManagementModule {

    constructor()
    {

    }

}
