import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { DialogComponent } from './dialog/dialog.component';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule
    ],
    declarations: [BottomSheetComponent, DialogComponent],
    exports: [BottomSheetComponent, DialogComponent],
    entryComponents: [BottomSheetComponent, DialogComponent]
})
export class CrossPlatformDialogModule { }
