import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { WebServiceHelperService } from './web-service-helper.service';
import { map, catchError } from 'rxjs/operators';
import { Country } from '../models/country.model';

@Injectable({
    providedIn: 'root'
})
export class GeoNamesService
{
    public countries: Array<any>;
    public region: Array<any>;
    public towns: Array<any>;

    public regionControl: any;

    public selected = {
        country: '',
        region: '',
        sub_region: '',
        town: ''
    };

    constructor(private wsh: WebServiceHelperService) { }

    loadCountries(): Observable<any>
    {
        return this.wsh.get(environment.backEndDomain + 'api/geo/countries')
            .pipe(
                map(
                    countries =>
                    {
                        const parsed = [];
                        Object.keys(countries).forEach(
                            k =>
                            {
                                const country = countries[k];
                                parsed.push(country);
                            }
                        );

                        return parsed;
                    }
                ),
                catchError(err => of([]))
            );
    }

    loadRegions(country): Observable<any>
    {
        return this.wsh.get(environment.backEndDomain + 'api/geo/region/' + country);
    }

    loadTowns(country, region, sub_region): Observable<any>
    {
        let url = environment.backEndDomain + 'api/geo/town/';

        url += country;
        url += '/' + region;
        url += '/' + sub_region;

        return this.wsh.get(url);
    }

    loadLocales()
    {
        const url = environment.backEndDomain + 'api/geo/locales';
        return this.wsh.get(url);
    }
}
