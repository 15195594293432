import { Tip } from 'src/app/core/models/tip';

export const GROUP_TIPS_CREATE = {
    title: 'Kind of group',
    body: 'tip_16_body'
} as Tip;

export const GROUP_OF_GROUPS_EDIT_TIPS = [
    {
        title: 'Add group',
        body: 'tip_17_body'
    },
    {
        title: 'Visualize subgroups',
        body: 'tip_18_body'
    }
] as Array<Tip>

export const GROUP_OF_CONTACTS_EDIT_TIP = {
    title: 'Select contacts',
    body: 'tip_19_body'
} as Tip;