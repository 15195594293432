import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { iDialogBase } from 'src/app/cross-platform-dialog/shared/iDialogBase';

@Component({
    selector: 'app-template-new-name-dialog',
    templateUrl: './template-new-name-dialog.component.html',
    styleUrls: ['./template-new-name-dialog.component.scss']
})
export class TemplateNewNameDialogComponent implements iDialogBase, OnInit
{
    public newName = new FormControl(null, Validators.required);
    data: TemplateData;
    public struc: DialogData<any>;
    binding: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private toast: I18nToastService
    ) { }

    setData(data: any): void
    {
        console.log(data);
        this.struc = data;
        this.data = data.data;
        const placeholderName = this.translate.instant('Copy of') + ' ' + this.data.name;
        this.newName.setValue(placeholderName);
    }
    action(args?: any)
    {
        if (this.newName.valid && this.newName.value != this.data.name)
            this.binding.emit(this.newName.value);
        else
            this.toast.open('You must type a valid name. Cannot be the same as the original template', 'Accept', 5000, true);
    }

    ngOnInit(): void
    {
    }

}
