import { Component, Input, OnInit } from '@angular/core';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { Contact } from 'src/app/core/models/contacts/contact';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';

@Component({
    selector: 'app-sms-preview',
    templateUrl: './sms-preview.component.html',
    styleUrls: ['./sms-preview.component.scss']
})
export class SmsPreviewComponent implements OnInit
{
    @Input() template: TemplateData;
    @Input() contacts: Array<Array<Contact>>;

    constructor(public user: UserMenuService) { }

    ngOnInit(): void
    {
        const contacts = this.contacts.map(contactArr =>
        {

            const contact = contactArr[0];

            let contactName = [contact.name, contact.surname_1, contact.surname_2].join(' ');

            if (contact.company !== null && contact.company.length > 0)
            {
                contactName += ' (' + contact.company + ')';
            }

            return contactName;
        })
    }

}
