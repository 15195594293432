import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { CommonState } from 'src/app/models/default.model';

export interface SubtenantEditState extends CommonState
{
    tenant: Tenant
}

export const subtenantInitialState: SubtenantEditState =
{
    tenant: null,
    isLoading: true,
    errorMsg: null
}
