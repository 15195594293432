<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title flex-row-between">
        {{ "Share the link" | translate }}
    </div>
    <div>
        <div class="subtitle flex-row-between">
            {{ "Copy the link and share it in social media" | translate }}
        </div>
        <app-copy-addon-field
            [link]="signatureLink$ | async"
        ></app-copy-addon-field>
        <label style="margin-bottom: 20px;" class="small flex-row-between">
            {{ "You can share the link" | translate }}
        </label>
        <div class="subtitle flex-row-between">
            {{ "embed code title" | translate }}
        </div>
        <app-copy-addon-field
            [link]="iframeLink$ | async"
        ></app-copy-addon-field>
        <label style="margin-bottom: 20px;" class="small flex-row-between">
            {{ "embed code description" | translate }}
        </label>
        <div class="subtitle flex-row-between" style="margin-bottom: 10px">
            {{ "qr code title" | translate }}
        </div>
        <ngx-qrcode
        [elementType]="elementType"
        [errorCorrectionLevel]="correctionLevel"
        [value]="signatureLink$ | async"
        cssClass="bshadow"
        (click)="downloadQRCode($event)"
        >
        
        </ngx-qrcode>
        <label style="margin-bottom: 20px; margin-top: 10px;" class="small flex-row-between">
            {{ "qr code description" | translate }}
        </label>
        <div style="display:flex; justify-content: flex-end;" class="button-row">
            <button mat-raised-button color="accent" (click)="next()">
                {{ "Complete" | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-template #desktop>
    <div class="title flex-row-between">
        {{ "Share the link" | translate }}
    </div>
    <div>
        <div class="subtitle flex-row-between">
            {{ "Copy the link and share it in social media" | translate }}
        </div>
        <app-copy-addon-field
            [link]="signatureLink$ | async"
        ></app-copy-addon-field>
        <label style="margin-bottom: 20px;" class="small flex-row-between">
            {{ "You can share the link" | translate }}
        </label>
        <div class="subtitle flex-row-between">
            {{ "embed code title" | translate }}
        </div>
        <app-copy-addon-field
            [link]="iframeLink$ | async"
        ></app-copy-addon-field>
        <label style="margin-bottom: 20px;" class="small flex-row-between">
            {{ "embed code description" | translate }}
        </label>
        <div class="subtitle flex-row-between" style="margin-bottom: 10px">
            {{ "qr code title" | translate }}
        </div>
        <ngx-qrcode
        [elementType]="elementType"
        [errorCorrectionLevel]="correctionLevel"
        [value]="signatureLink$ | async"
        cssClass="bshadow"
        (click)="downloadQRCode($event)"
        >
        
        </ngx-qrcode>
        <label style="margin-bottom: 20px; margin-top: 10px;" class="small flex-row-between">
            {{ "qr code description" | translate }}
        </label>
        <div style="display:flex; justify-content: flex-end;" class="button-row">
            <button mat-raised-button color="accent" (click)="next()">
                {{ "Complete" | translate }}
            </button>
        </div>
    </div>
</ng-template>
