import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MakeThingsLookGoodService} from '../../../../shared/MakeThingsLookGood.service';
import {DocumentService} from '../../../../core/services/content/document.service';
import {DocumentTemplateManagementService} from '../../../../core/services/content/document-template-management.service';
import {ConfirmationBoxService} from '../../../../shared/confirmation-box.service';
import { Router } from '@angular/router';
import {MessageBoxService} from '../../../../shared/message-box.service';
import {TranslateService} from '@ngx-translate/core';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { TemplateNewNameDialogComponent } from '../template-new-name-dialog/template-new-name-dialog.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-document-form-card',
    templateUrl: './document-form-card.component.html',
    styleUrls: ['./document-form-card.component.scss']
})
export class DocumentFormCardComponent implements OnInit
{

    @Input() template: any;
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>()
    private signatureLink: BehaviorSubject<string>;
    public signatureLink$: Observable<string>;
    public elementType = NgxQrcodeElementTypes.URL;
    public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

    constructor(
        public mtlg: MakeThingsLookGoodService,
        public docService: DocumentService,
        public docTplService: DocumentTemplateManagementService,
        public alertBox: ConfirmationBoxService,
        public msgBox: MessageBoxService,
        public trans: TranslateService,
        public toast: I18nToastService,
        public dialog: CrossPlatformDialogService,
        private router: Router
    )
    {

    }

    ngOnInit(): void
    {
        this.signatureLink = new BehaviorSubject<string>(null);
        this.signatureLink$ = this.signatureLink.asObservable();

        this.signatureLink.next(`${environment.backEndDomain}document_template_form?token=${this.template.token}`);
        console.log(this.signatureLink$)
    }

    delete()
    {
        this.docTplService.deleteTpl(this.template.id)
        .subscribe(
            x =>
            {
                let msg = this.trans.instant('template x has been disabled', {contact_name: this.template.name});
                if (this.template.status == 2)
                    msg = this.trans.instant('template x has been enabled', {contact_name: this.template.name});

                this.toast.open(msg, this.trans.instant('Accept'), 5000, false);
                this.deleted.emit();
            },
            e =>
            {
                this.msgBox.instant({
                    data: {
                        title: this.trans.instant('error'),
                        body: this.trans.instant('Can not delete'),
                        btn_ok: this.trans.instant('ok')
                    }
                })
            }
        );
    }

    clone()
    {
        this.dialog.open({
            data: {
                data: this.template,
                dynamycComponent: TemplateNewNameDialogComponent,
                countries: [],
                isEdit: false,
                hideCloseButton: false,
                locales: [],
                positiveButton: 'save',
                message: this.trans.instant('Type the new name you want for the new template'),
                title: this.trans.instant('Duplicate template')
            },
            disableClose: false,
            width: '500px'
        })
        .subscribe(
            newName =>
            {
                if (newName)
                {
                    const newTpl = {
                        tplId: this.template.id,
                        newName: newName
                    };
                    this.docTplService.cloneForm(newTpl)
                    .subscribe(r =>
                    {
                        this.deleted.emit();
                    });
                }
            }
        );
    }

    public shareDocument($event, token): void{
        this.router.navigate([`document_template_form_management/share/${token}`]);
    }

    public downloadQRCode($event): void{
        const image = document.querySelector('ngx-qrcode').querySelector('img');
        const link = document.createElement('a');
        link.href = image.src;
        link.download = 'qr_code.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}

