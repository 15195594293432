import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

import { map } from 'rxjs/operators';

import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { AutoTableColumnDefinition } from 'src/app/shared/auto-table/definitions/auto-table-column-definition';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';
import { TableAdapterInterface } from 'src/app/shared/auto-table/definitions/table-adapter.interface';
import { BaseTableAdapterService } from 'src/app/shared/auto-table/services/base-table-adapter.service';
import { PaginatorService } from 'src/app/shared/auto-table/services/paginator.service';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TemplateTableAdapterService extends BaseTableAdapterService implements TableAdapterInterface
{
    constructor(
        private wsh: WebServiceHelperService,
        public responsive: ResponsiveService,
        public paginator: PaginatorService
    )
    {
        super(responsive, paginator);
        this.data = new ReplaySubject(1);
    }

    getStdCols(): Array<AutoTableColumnDefinition>
    {
        return [
            {
                column: 'id',
                columnTitle: 'id',
                order: 0
            },
            {
                column: 'name',
                columnTitle: 'name',
                order: 1
            },
            {
                column: 'created_at',
                columnTitle: 'created_at',
                order: 1
            },
            {
                column: 'preview',
                columnTitle: 'Preview',
                order: 2
            }
        ];
    }

    getNonStdCols(): Array<AutoTableColumnDefinition>
    {

        return [];
    }

    getLaravelPaginatedList(loader?: boolean): Observable<LaravelPaginatedList<any>>
    {
        let url = environment.backEndDomain;
        url += 'api/document_template/paginated_flaged_tpl?page=' + (this.currentPage + 1);
        return this.wsh.get<LaravelPaginatedList<any>>(url);
    }

    warmUp(): Observable<boolean>
    {
        this.getData();
        return this.data
            .pipe(
                map(x => true)
            );
    }

    downloadAttachment(val: any): void
    {
        throw new Error('Method not implemented.');
    }
}
