<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
    <div class="flex-row-between">
        <button color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
        <button (click)="save()" color="primary" mat-raised-button mat-dialog-close>{{ 'Save' | translate }}</button>
    </div>
</ng-container>


<ng-template #helpCol>
    <div class="help-col mat-typography">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <app-tip [idx]="0" [tip]="tips"></app-tip>
    </div>
</ng-template>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="helpCol"></ng-container>
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
    <div></div>
    <div class="flex-row-between">
        <button color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
        <button (click)="save()" color="primary" mat-raised-button mat-dialog-close>{{ 'Save' | translate }}</button>
    </div>
</ng-template>

<ng-template #contentCol>
    <div class="content">
        <div class="subtitle flex-row">
            <div class="editable-name" [ngClass]="{'hidden': !isEdit}">
                <input type="text" [formControl]="name" name="name">
            </div>
            <div class="info-name" [ngClass]="{'hidden': isEdit}">
                <button (click)="openHelp()" mat-icon-button color="primary" *ngIf="responsive.isMobile$ | async">
                    <mat-icon>help</mat-icon>
                </button>
                <div [matTooltip]="data.name != name.value ? name.value : data.name">
                    {{ data.name != name.value ? name.value : data.name }} <ng-container *ngIf="data.name != name.value">*</ng-container>
                </div>
            </div>
            <span *ngIf="!(responsive.isMobile$ | async)" style="opacity: 0.6; margin-left: 5px;">- {{ 'Contact agrupation' | translate }}</span>
        </div>
        <div class="subtitle" *ngIf="responsive.isMobile$ | async">
            <span style="opacity: 0.6;">{{ 'Contact agrupation' | translate }}</span>
        </div>
        <div style="overflow-x: auto;">
            <mat-chip-list class="parent-chips" aria-label="Breadcrumbs">
                <ng-container *ngIf="data | groupHierarchy as breadcrumbs; else noparents">
                    <ng-container *ngIf="breadcrumbs.length > 0; else noparents">
                        <mat-chip *ngFor="let breadcrumb of (data | groupHierarchy)" selected color="primary">{{ breadcrumb }}</mat-chip>
                    </ng-container>
                </ng-container>

                <ng-template #noparents>
                    <mat-chip selected color="primary">{{ data.name }}</mat-chip>
                </ng-template>
            </mat-chip-list>
        </div>
        <div class="subtitle finder-title" [ngClass]="{'mobile': responsive.isMobile$ | async}">
            <div *ngIf="!(responsive.isMobile$ | async)">
                {{ 'Finder' | translate }}
            </div>
            <div>
                {{ 'Groups contacts' | translate }}
            </div>
        </div>
        <div class="finder" *ngIf="!(responsive.isMobile$ | async)">
            <app-contact-search
                style="padding-right: 10px; border-right: 1px solid #0077bb4a;"
                (selected)="addContact($event)"
                (newContact)="addNewContact()"
                (editContact)="editContact($event)">
            </app-contact-search>
            <div *ngIf="contactsOfGroup.length > 0; else nocontacts" style="overflow-y: auto;">
                <app-contact-simple [showDeleteButton]="true" class="mar-b10" (selected)="removeContact(contact)" *ngFor="let contact of contactsOfGroup" [contact]="contact"></app-contact-simple>
            </div>
        </div>
        <div class="finder mobile" *ngIf="(responsive.isMobile$ | async)">
            <div matRipple *ngIf="responsive.isMobile$ | async" class="contact-placeholder" (click)="openSelectContactDialog()">
                {{ 'Click here to choose a contact' | translate }}
            </div>
            <div class="scroller" *ngIf="contactsOfGroup.length > 0; else nocontacts">
                <app-contact-simple class="mar-b10" (selected)="removeContact(contact)" *ngFor="let contact of contactsOfGroup" [contact]="contact"></app-contact-simple>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #nocontacts>
    <div class="flex-row">
        <div style="text-align: center; display: flex; align-items: center; height: 60px;">
            <mat-icon color="primary">info</mat-icon> {{ 'Select contacts from the list to be added on the group' | translate }}
        </div>
    </div>
</ng-template>

<div class="actions">
    <button
        [matTooltip]="'Edit group name' | translate"
        mat-icon-button
        color="primary"
        (click)="editGroupName()">
        <mat-icon>{{ isEdit ? 'done' : 'edit' }}</mat-icon>
    </button>
    <button
        [matTooltip]="'Delete group' | translate"
        [mat-dialog-close]="'delete'"
        mat-icon-button
        color="warn"
        class="mar-l10">
        <mat-icon>delete</mat-icon>
    </button>
</div>