import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SubtenantAction from './actions';
import { Store } from '@ngrx/store';
import { RootStoreState } from '..';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { SubtenantService } from 'src/app/core/services/pages/subtenant.service';
import { of } from 'rxjs';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ERROR_CODES } from '../error-codes';

const FETCH = SubtenantAction.SubtenantListActionTypes.FETCH;
const UNLINK = SubtenantAction.SubtenantListActionTypes.UNLINK;
const UNLINK_SUCCESS = SubtenantAction.SubtenantListActionTypes.UNLINK_SUCCESS;
const UNLINK_FAIL = SubtenantAction.SubtenantListActionTypes.UNLINK_FAIL;

@Injectable()
export class SubtenantEffects
{
    constructor(
        private actions$: Actions,
        private store$: Store<RootStoreState.State>,
        private service: SubtenantService,
        private toast: I18nToastService
    ) { }

    fetch$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantAction.Fetch>(FETCH),
                mergeMap(action => this.service.fetchSubtenantList(action.payload)
                    .pipe(
                        map(data => new SubtenantAction.FetchSuccess(data)),
                        catchError(() => of(new SubtenantAction.FetchFail()))
                    ))
            )
    );

    unlink$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantAction.Unlink>(UNLINK),
                mergeMap(action => this.service.unlink(action.payload)
                    .pipe(
                        map(data => new SubtenantAction.UnlinkSuccess()),
                        catchError(err => of(new SubtenantAction.UnlinkFail()))
                    )
                )
            )
    )

    unlinkSuccess$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantAction.UnlinkSuccess>(UNLINK_SUCCESS),
                map(action => new SubtenantAction.Fetch(1))
            )
    )

    unlinkFail$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantAction.UnlinkFail>(UNLINK_FAIL),
                tap(action => this.toast.open(ERROR_CODES.UNLINKING_SUBTENANT, 'Accept', 5000, true))
            ),
        { dispatch: false }
    )
}
