import { T } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { localesDictionary } from 'src/app/configs/languages.dictionary';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { GeonamesStoreActions, GeonamesStoreSelectors, RootStoreState, SubtenantEditStoreSelectors } from 'src/app/root-store';
import { GeonamesState } from 'src/app/root-store/geonames-store/state';

@Component({
    selector: 'app-subtenant-form',
    templateUrl: './subtenant-form.component.html',
    styleUrls: ['./subtenant-form.component.scss']
})
export class SubtenantFormComponent implements AfterViewInit, OnDestroy
{
    @Input() data: Tenant;
    @Input() isNewTenant: boolean = true;
    @Output() done: EventEmitter<Tenant>;
    public loading$: Observable<boolean>;
    public state$: Observable<GeonamesState>;
    public formErrors$: any;
    public error$: Observable<any>;
    public locales = localesDictionary;

    public tenantForm: FormGroup;

    private unsubscriber: Subject<void>;

    constructor(
        private store$: Store<RootStoreState.State>,
        private toast: I18nToastService,
    )
    {
        this.tenantForm = new FormGroup(
            {
                company: new FormControl(null, Validators.required),
                fiscal_id: new FormControl(null, Validators.required),
                name: new FormControl(null, Validators.required),
                surname: new FormControl(null, Validators.required),
                phone_no: new FormControl(null, Validators.required),
                email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
                address: new FormControl(null, Validators.required),
                city: new FormControl(null, Validators.required),
                postal_code: new FormControl(null, Validators.required),
                region: new FormControl(null, Validators.required),
                country: new FormControl(null, Validators.required),
                country_id: new FormControl(null),
                locale: new FormControl(null, Validators.required)
            }
        );

        this.loading$ = this.store$.select(GeonamesStoreSelectors.selectGeonamesIsLoading);
        this.state$ = this.store$.select(GeonamesStoreSelectors.selectGeonamesState);
        this.error$ = this.store$.select(GeonamesStoreSelectors.selectGeonamesError);
        this.formErrors$ = this.store$.select(SubtenantEditStoreSelectors.selectSubtenantEditError);

        this.done = new EventEmitter<Tenant>();
        this.unsubscriber = new Subject<void>();

        this.store$.dispatch(new GeonamesStoreActions.Fetch());
    }

    ngAfterViewInit(): void
    {
        if (this.data)
            this.tenantForm.patchValue(this.data);

        if (!this.isNewTenant)
        {
            this.tenantForm.removeControl('locale');
            this.tenantForm.removeControl('country');
            this.tenantForm.controls.country_id.setValidators(Validators.required);
            this.tenantForm.updateValueAndValidity();
        }
        else
        {
            this.tenantForm.removeControl('country_id');
        }

        if (this.formErrors$)
            this.formErrors$
                .pipe(
                    takeUntil(this.unsubscriber)
                )
                .subscribe(errors =>
                {
                    if (errors && typeof errors == 'object')
                        Object.keys(errors).forEach(key =>
                        {
                            this.tenantForm.controls[key].setErrors(errors[key][0]);
                        });
                    else if (errors && typeof errors == 'string')
                        this.toast.open(errors, 'Accept', 5000, true);
                });
    }

    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public submit(): void
    {
        if (this.tenantForm.valid)
        {
            const newTenant = {
                ...this.data,
                ...this.tenantForm.value
            };
            this.done.emit(newTenant);
        }
        else
        {
            this.toast.open('you must fill all the fields', 'accept', 5000, true);
            this.tenantForm.markAllAsTouched();
        }
    }
}
