import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TablePayload, TableFilters } from 'src/app/material-table/shared/iTableProvider';
import * as _ from 'lodash';
import { PdfBlobOpenerService } from 'src/app/shared/pdf-blob-opener.service';
import { TenantRepositoryService } from 'src/app/core/repositories/tenant-repository.service';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { TenantCVS } from 'src/app/core/models/tenant-management/tenant-cvs.model';
import { TwoFactorAuthService } from '../two-factor-auth/two-factor-auth.service';

@Injectable({
    providedIn: 'root'
})
export class TenantService
{
    /** Table provider iface */
    templateId: string;
    endPointUrl: string;
    availableFilters: TableFilters[];
    filters: TablePayload;


    constructor(
        private repository: TenantRepositoryService,
        private bloblOpener: PdfBlobOpenerService,
        private tfAuthService: TwoFactorAuthService
    ) { }

    public getTenantData(): Observable<Tenant>
    {
        return this.repository.getTenantData();
    }
    public setTenantData(tenant)
    {
        return this.repository.setTenantData(tenant);
    }
    public getInvoicesList(page: number): Observable<any>
    {
        return this.repository.getInvoicesList(page);
    }
    public downloadPdf(invoice)
    {
        return this.repository.downloadPdf(invoice);
    }
    public downloadAndOpen(invoice): void
    {
        this.downloadPdf(invoice).subscribe(r =>
        {
            this.bloblOpener.open(r);
        });
    }
    public getPacksList()
    {
        return this.repository.getPacksList();
    }
    public getWhatsappPacksList()
    {
        return this.repository.getWhatsappPacksList();
    }
    public setPaymentCard(token: string)
    {
        return this.repository.setPaymentCard(token);
    }
    public buy(pack)
    {
        return this.repository.buy(pack);
    }
    public getTenantSeal(): Observable<Blob>
    {
        return this.repository.getTenantSeal();
    }
    public getTenantLogo(): Observable<Blob>
    {
        return this.repository.getTenantLogo();
    }
    public setTenantSeal(file: File): Observable<void>
    {
        return this.repository.setTenantSeal(file);
    }
    public setTenantLogo(file: File): Observable<void>
    {
        return this.repository.setTenantLogo(file);
    }
    public deleteTenantLogo(): Observable<string>
    {
        return this.repository.deleteTenantLogo();
    }
    public deleteTenantSeal(): Observable<string>
    {
        return this.repository.deleteTenantSeal();
    }
    public getTenantCvs(): Observable<TenantCVS>
    {
        return this.repository.getCvsConfig();
    }
    public setTenantCvs(cvs: {value: string}): Observable<TenantCVS>
    {
        return this.repository.setCvsConfig(cvs)
    }

    public setTenantLegalValidation(legalValidation: boolean): Observable<boolean>{
        return this.repository.setTenantLegalValidation(legalValidation);
    }

    public setOTPAuth(otpAuth: boolean): Observable<boolean>{
        this.tfAuthService.tfAuthStatus$.next(otpAuth);
        return this.repository.setOTPAuth(otpAuth);
    }

    public checkImageBounds(imgSource: string): Observable<boolean>
    {
        const result = new Subject<boolean>();
        const image = new Image();
        image.onload = () =>
        {
            console.log(image.width);
            if (image.width > 300 || image.height > 110)
                result.next(false);
            else
                result.next(true);

            result.complete();
        };

        image.src = imgSource;

        return result.asObservable();
    }
}
