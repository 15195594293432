import { Component, OnInit, Input } from '@angular/core';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { Contact } from 'src/app/core/models/contacts/contact';
import { TenantService } from 'src/app/core/services/pages/tenant-management/tenant.service';

@Component({
    selector: 'app-message-preview',
    templateUrl: './message-preview.component.html',
    styleUrls: ['./message-preview.component.scss']
})
export class MessagePreviewComponent implements OnInit
{
    @Input() template: TemplateData;
    @Input() contacts: Array<Array<Contact>>;
    public logo: string;

    constructor(
        private tenantService: TenantService
    ) { }

    ngOnInit()
    {
        this.tenantService.getTenantLogo()
            .subscribe(
                x =>
                {
                    const reader = new FileReader();
                    reader.onload = (e: any) =>
                    {
                        this.logo = e.target.result;
                    };

                    reader.readAsDataURL(x);
                }
            );
    }
}
