import { Component, OnInit, AfterViewInit, OnDestroy, HostBinding } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { EntryPointStatus } from 'src/app/configs/enums/entry-point-status.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';

@Component({
    selector: 'app-send-assistant',
    templateUrl: './send-assistant.component.html',
    styleUrls: ['./send-assistant.component.scss']
})
export class SendAssistantComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy
{
    @HostBinding('class') classes = 'bg mat-typography';
    public status: EntryPointStatus;

    // Ref.
    public EntryComponentStatus: any = EntryPointStatus;
    public isPreview: boolean = false;

    private unsubscriber: Subject<void>;

    constructor(
        public sideNav: SidenavService,
        public route: Router,
        public active: ActivatedRoute,
        public template: TemplateEditorService
    )
    {
        super(sideNav);

        this.unsubscriber = new Subject<void>();
    }

    ngOnInit()
    {
        // when the app lands here:
        const segment = this.parseUrl(this.route.url);
        this.setStatus(segment);

        // also, subsscribe to navigation.
        this.route.events.subscribe(x =>
        {
            if (x instanceof NavigationEnd)
            {
                const navSegment = this.parseUrl(x.url);
                this.setStatus(navSegment);
            }
        });

        this.active.queryParams
        .pipe(
            takeUntil(this.unsubscriber)
        )
        .subscribe(params =>
        {
            if (params.isPreview)
                this.isPreview = true;
        });
    }
    ngAfterViewInit()
    {}
    ngOnDestroy()
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private parseUrl(url: string): string
    {
        let segment = url.replace('/send_assistant/', '');
        segment = segment.split('/')[0];

        return segment;
    }
    private setStatus(urlSegment: string): void
    {
        switch (urlSegment)
        {
            case 'file':
                this.status = EntryPointStatus.DefineDoc;
                break;
            case 'template':
                this.status = EntryPointStatus.EditingTemplate;
                break;
            case 'contacts':
                this.status = EntryPointStatus.SetContacts;
                break;
            case 'text':
                this.status = EntryPointStatus.DefineDocText;
                break;
            case 'arbitrarytext':
                this.status = EntryPointStatus.ArbitraryText;
                break;
            default:
                this.status = EntryPointStatus.DefineDoc;
        }
    }
}
