import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendAssistantRoutingModule } from './send-assistant-routing.module';
import { SendAssistantComponent } from './send-assistant.component';
import { DocumentDefinitionComponent } from './components/document-definition/document-definition.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateSearchModule } from 'src/app/content/template-search/template-search.module';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { ContactDefinitionComponent } from './components/contact-definition/contact-definition.component';
import { TextDefinitionComponent } from './components/text-definition/text-definition.component';
import { MessagePreviewModule } from 'src/app/content/message-preview/message-preview.module';
import { ArbitraryTextDefinitionComponent } from './components/arbitrary-text-definition/arbitrary-text-definition.component';
import { ContactsNameExtractorPipe } from './pipes/contacts-name-extractor.pipe';
import { TemplateEditorModule } from 'src/app/content/template-editor/template-editor.module';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { MobileStepperModule } from 'src/app/content/mobile-stepper/mobile-stepper.module';
import { TipModule } from 'src/app/content/tip/tip.module';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { ContactsModule } from 'src/app/content/contacts/contacts.module';
import { GroupsModule } from 'src/app/content/groups/groups.module';
import { UserSignaturesModule } from 'src/app/content/user-signatures/user-signatures.module';
import { SelectedSendChannelPipe } from './components/contact-definition/pipes/selected-send-channel.pipe';
import { DisabledChannelGeneralGeneralPipe } from './components/contact-definition/pipes/disabled-channel-general.pipe';
import { DisabledChannelPipe } from './components/contact-definition/pipes/disabled-channel.pipe';
import { SelectedSendChannelGeneralPipe } from './components/contact-definition/pipes/selected-send-channel-general.pipe';
import { BylinkComponent } from './components/bylink/bylink.component';
import { CopyAddonFieldModule } from 'src/app/content/copy-addon-field/copy-addon-field.module';
import { SpaceExtractorPipe } from './pipes/space-extractor.pipe';
import { DocumentTemplateFormDefinitionComponent } from './components/document-definition/components/document-template-form-definition/document-form-definition.component';
import { DocumentFormEditorComponent } from './components/template-editor/components/document-form-editor/document-form-editor.component';

@NgModule({
    declarations: [
        SendAssistantComponent,
        DocumentDefinitionComponent,
        TemplateEditorComponent,
        ContactDefinitionComponent,
        TextDefinitionComponent,
        ArbitraryTextDefinitionComponent,
        ContactsNameExtractorPipe,
        SelectedSendChannelPipe,
        SelectedSendChannelGeneralPipe,
        DisabledChannelGeneralGeneralPipe,
        DisabledChannelPipe,
        BylinkComponent,
        SpaceExtractorPipe,
        DocumentTemplateFormDefinitionComponent,
        DocumentFormEditorComponent
    ],
    imports: [
        CommonModule,
        SendAssistantRoutingModule,
        TranslateModule,
        MaterialModule,
        UserMenuModule,
        TipModule,
        FormsModule,
        TemplateSearchModule,
        ContactsModule,
        MessagePreviewModule,
        ReactiveFormsModule,
        ResponsiveTopbarModule,
        MobileStepperModule,
        TemplateEditorModule,
        GroupsModule,
        UserSignaturesModule,
        CopyAddonFieldModule
    ],
    exports: [DocumentDefinitionComponent, TemplateEditorComponent, DocumentFormEditorComponent, DisabledChannelPipe]
})
export class SendAssistantModule { }
