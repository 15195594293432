import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductTypes } from 'src/app/configs/enums/product-types.enum';
import { CreditTransferConfig } from 'src/app/core/models/subtenants-management/credit-transfer-config.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { RootStoreState, SubtenantEditStoreActions, SubtenantEditStoreSelectors } from 'src/app/root-store';

export const SUBTENANT_CREDIT_DIAG_WIDTH = '500px';
export const SUBTENANT_CREDIT_DIAG_HEIGHT = '300px';

@Component({
    selector: 'app-credit-transfer-dialog',
    templateUrl: './credit-transfer-dialog.component.html',
    styleUrls: ['./credit-transfer-dialog.component.scss']
})
export class CreditTransferDialogComponent implements OnInit, OnDestroy
{
    @HostBinding('class') classes = 'mat-typography';
    public transferForm: FormGroup;

    // ref.
    public PRODUCT_TYPE = ProductTypes;

    private unsubscribe: Subject<void>;
    private errors$: Observable<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private store$: Store<RootStoreState.State>,
        private toast: I18nToastService
    )
    {
        this.transferForm = new FormGroup(
            {
                product: new FormControl(null, Validators.required),
                movementType: new FormControl(null, Validators.required),
                quantity: new FormControl(null, Validators.required),
                uuid: new FormControl(data, Validators.required)
            }
        );

        this.errors$ = this.store$.select(SubtenantEditStoreSelectors.selectSubtenantEditError);

        this.unsubscribe = new Subject<void>();

        this.errors$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(err =>
            {
                if (err)
                    this.toast.open(err, 'Accept', 5000, true);
            });
    }

    ngOnInit(): void
    {
    }
    ngOnDestroy(): void
    {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public store(): void
    {
        if (this.transferForm.valid)
            this.store$.dispatch(new SubtenantEditStoreActions.CreditTransfer(
                this.transferForm.value as CreditTransferConfig)
            );
        else
            this.toast.open('All the fields must be filled', 'Accept', 5000, true);
    }
}
