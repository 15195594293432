<div class="first-col">
    <button mat-icon-button (click)="SideNav.open()">
        <mat-icon>menu</mat-icon>
    </button>
    <img id="top-bar-logo" src="assets/svg/my_rubric_logo_04.svg" />
</div>
<div
    class="second-col"
    *ngIf="(responsive.isMobile$ | async) && route.url.indexOf('send') == -1"
>
    <!-- <button mat-raised-button color="accent" class="send-button" routerLink="/send_assistant/file">
        {{ 'Send doc' | translate }}
    </button> -->
</div>
<div
    class="second-col stepper-wrapper"
    *ngIf="
        !(responsive.isMobile$ | async) &&
        route.url.indexOf('send') != -1 &&
        route.url.indexOf('bylink') == -1
    "
>
    <div class="stepper" *ngIf="!(isPreview | async) && !isEdit">
        <div
            class="height100 assinstat-step"
            [ngClass]="{
                'selected-step': status == EntryComponentStatus.DefineDoc
            }"
        >
            {{ "Choose a document" | translate }}
        </div>
        <div
            class="height100 assinstat-step"
            [ngClass]="{
                'selected-step': status == EntryComponentStatus.EditingTemplate
            }"
        >
            {{ "Prepare the template" | translate }}
        </div>
        <div
            class="height100 assinstat-step"
            [ngClass]="{
                'selected-step': status == EntryComponentStatus.SetContacts
            }"
        >
            {{ "Set up the contacts" | translate }}
        </div>
        <div
            class="height100 assinstat-step"
            *ngIf="(stepper.steps$ | async) > 4"
            [ngClass]="{
                'selected-step': status == EntryComponentStatus.ArbitraryText
            }"
        >
            {{ "Set up custom text" | translate }}
        </div>
        <div
            class="height100 assinstat-step"
            [ngClass]="{
                'selected-step': status == EntryComponentStatus.DefineDocText
            }"
        >
            {{ "Prepare the messages" | translate }}
        </div>
    </div>
</div>
<div
    class="second-col"
    *ngIf="
        !(responsive.isMobile$ | async) &&
        (route.url.indexOf('send') == -1 || route.url.indexOf('bylink') != -1)
    "
></div>
<div
    class="second-col"
    *ngIf="(responsive.isMobile$ | async) && route.url.indexOf('send') != -1"
></div>

<div class="third-col">
    <ng-container
        *ngIf="
            route.url.indexOf('send') == -1 &&
            route.url.indexOf('template_management') == -1
        "
    >
        <button
            mat-raised-button
            color="accent"
            class="send-button"
            [matMenuTriggerFor]="userMenu"
        >
            {{ "Send" | translate }}
        </button>

        <mat-menu #userMenu>
            <button mat-menu-item routerLink="/send_assistant/file">
                <mat-icon class="mar-r10">description</mat-icon
                >{{ "Send document" | translate }}
            </button>
            <button
                mat-menu-item
                routerLink="/template_management/list"
                style="align-items: center; display: flex; flex-direction: row; line-height: 1.4;"
            >
                <mat-icon class="mar-r10">file_copy</mat-icon>
                <p style="margin: 0">
                    {{ "Send document" | translate }}<br />{{
                        "Send template" | translate
                    }}
                </p>
            </button>
            <button mat-menu-item routerLink="/editable_pdf/file">
                <mat-icon class="mar-r10">drive_file_rename_outline</mat-icon
                >{{ "Send form" | translate }}
            </button>
            <button mat-menu-item routerLink="/document_template_form_management/create">
                <mat-icon class="mar-r10">supervised_user_circle</mat-icon
                >{{ "Send unattended template" | translate }}
            </button>
            <button mat-menu-item routerLink="/editable_pdf/file_unattended">
                <mat-icon class="mar-r10">list_alt</mat-icon
                >{{ "Send unattended form" | translate }}
            </button>
        </mat-menu>

        <div
            *ngIf="!(responsive.isMobile$ | async)"
            class="separator-topbar"
            style="margin-right: 0px;"
        ></div>
    </ng-container>
    <app-user-menu
        mat-button
        class="btn"
        style="flex: 1 1 0px;justify-content: space-between;padding-left: 20px;"
    ></app-user-menu>
</div>
