import {Component, Input, OnInit} from '@angular/core';
import {TemplateData} from 'src/app/core/models/api/template/template-data.model';
import {Contact} from 'src/app/core/models/contacts/contact';
import {UserMenuService} from 'src/app/core/services/content/user-menu.service';

@Component({
    selector: 'app-whatsapp-preview',
    templateUrl: './whatsapp-preview.component.html',
    styleUrls: ['./whatsapp-preview.component.scss']
})
export class WhatsappPreviewComponent implements OnInit
{
    @Input() template: TemplateData;
    @Input() contacts: Array<Array<Contact>>;


    public expire: Date;

    public translationParams = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: ''
    };

    constructor(
        public user: UserMenuService
    )
    {
        this.expire = new Date();
        this.expire.setDate(this.expire.getDate() + 2);
    }

    ngOnInit(): void
    {

        const contacts = this.contacts.map(contactArr =>
        {

            const contact = contactArr[0];

            let contactName = [contact.name, contact.surname_1, contact.surname_2].join(' ');

            if (contact.company !== null && contact.company.length > 0)
            {
                contactName += ' (' + contact.company + ')';
            }

            return contactName;
        })

        const da = this.expire.getDay();
        const mo = this.expire.getMonth();
        const ye = this.expire.getFullYear();
        const ho = this.expire.getHours();
        const mi = this.expire.getMinutes();
        const sec = this.expire.getSeconds();


        this.translationParams = {
            1: contacts[0],
            2: this.user.userData.name,
            3: this.template.name,
            4: contacts.join(', '),
            5: `${da}-${mo}-${ye} ${ho}:${mi}.${sec} UTC`,
            6: 'https://bit.ly/XXXX'
        };
    }
}
