import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PDF_STATE } from 'src/app/configs/enums/pdf-elements-state.enum';
import { PdfElementsStateMachine } from '../../interfaces/State-machines/pdf-elements.interface';

@Injectable({
    providedIn: 'root'
})
export class PdfElementsService implements PdfElementsStateMachine
{
    // STATE MACHINE
    private state: BehaviorSubject<PDF_STATE>;
    public state$: Observable<PDF_STATE>;
    public onStateEnter$: Subject<PDF_STATE>;
    public onStateExit$: Subject<PDF_STATE>;

    // DATA

    constructor()
    {
        this.state = new BehaviorSubject<PDF_STATE>(PDF_STATE.PENDING);
        this.state$ = this.state.asObservable();

        this.onStateEnter$ = new Subject<PDF_STATE>();
        this.onStateExit$ = new Subject<PDF_STATE>();

        this.manageStateMachine();
    }

    public changeState(state: PDF_STATE): void
    {
        if (state == PDF_STATE.PENDING)
        {
            this.state.next(PDF_STATE.PENDING);
            this.onStateEnter$.next(PDF_STATE.PENDING);
            this.onStateExit$.next(PDF_STATE.ADDING);
        }
        else
        {
            this.state.next(PDF_STATE.ADDING);
            this.onStateEnter$.next(PDF_STATE.ADDING);
            this.onStateExit$.next(PDF_STATE.PENDING);
        }
    }
    public getCurrentState(): PDF_STATE
    {
        return this.state.value;
    }

    private manageStateMachine(): void
    {
        this.onStateEnter$
            .subscribe(
                state =>
                {
                    switch (state)
                    {
                        case PDF_STATE.PENDING:
                            break;
                        case PDF_STATE.ADDING:
                            break;
                    }
                });
        this.onStateExit$
            .subscribe(
                state =>
                {
                    switch (state)
                    {
                        case PDF_STATE.PENDING:
                            break;
                        case PDF_STATE.ADDING:
                            break;
                    }
                });
    }
}
