import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { KpiService } from '../shared/kpi.service';
import { Log } from '../shared/log';

@Component({
    selector: 'app-log-kpi',
    templateUrl: './log-kpi.component.html',
    styleUrls: ['./log-kpi.component.css']
})
export class LogKpiComponent implements OnInit
{
    public tileData$: Observable<Array<Log>>;
    constructor(
        public kpi: KpiService
    ) { }

    ngOnInit()
    {
        this.tileData$ = this.kpi.getLogKpiData();
    }

    public iconByState(state: string): string
    {
        let icon = null;
        switch(state)
        {
            case "audit_trail_table_action_id_1":
                icon = "gesture";
                break;
            case "audit_trail_table_action_id_3":
                icon = "mail";
                break;
            case "audit_trail_table_action_id_4":
                icon = "get_app";
                break;
            case "audit_trail_table_action_id_5":
                icon = "drafts";
                break;
            case "audit_trail_table_action_id_6":
                icon = "save_alt";
                break;
            case "audit_trail_table_action_id_8":
                icon = "select_all";
                break;
            case "audit_trail_table_action_id_9":
                icon = "cloud_upload";
                break;
            case "audit_trail_table_action_id_10":
                icon = "note_add";
                break;
            case "audit_trail_table_action_id_11":
                icon = "done_all";
                break;
            case "audit_trail_table_action_id_0":
                icon = "send";
                break;
        }

        return icon;
    }

}
