import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class I18nToastService
{
    constructor(
        private translator: TranslateService,
        private toast: MatSnackBar
    )
    { }

    public open(message: string, button: string, duration: number = 7000, autoTranslate = true)
    {
        try{
            this.toast.open(
                autoTranslate ? this.translator.instant(message) : message,
                this.translator.instant(button),
                { duration }
            );
        }catch (e){

            console.error(e);
        }
    }
}
