import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipModalComponent } from './tip-modal.component';
import { MaterialModule } from 'src/app/material/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [TipModalComponent],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule
    ],
    exports: [TipModalComponent]
})
export class TipModalModule { }
