<div *ngIf="tileData$ | async as tileData; else loading" fxLayout="column" fxFlex
     style="background-color: #29abe233;border-radius: 15px; overflow: hidden; padding: 15px;">
    <div fxLayout="row" fxFlex="40px" fxLayoutAlign="start center">
        <div class="fab" fxLayoutAlign="center center" [style.color]="tileData.iconColor"
             [style.backgroundColor]="tileData.iconFabColor">
            <mat-icon>{{ tileData.icon }}</mat-icon>
        </div>
        <span class="kpi-box-title">{{ tileData.title | translate }}</span>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="space-between end">
        <div fxLayout="column" *ngFor="let entry of tileData.entries">
            <div fxLayout="row" fxFlex class="entry-value" fxLayoutAlign="start end" [routerLink]="entry.link">
                {{ entry.value }}
            </div>
            <div fxLayout="row" class="entry-description">{{ entry.description | translate}}</div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div fxLayout="column" fxFlex
         style="background-color: #f7f7f7;border-radius: 15px; overflow: hidden; padding: 15px;">
        <div fxLayout="row" fxFlex="40px" fxLayoutAlign="start center">
            <div class="fab" fxLayoutAlign="center center" [style.color]="'white'" [style.backgroundColor]="'#1a237e'">
                <mat-icon>autorenew</mat-icon>
            </div>
            <span class="kpi-box-title">{{ 'Loading...' | translate }}</span>
        </div>
        <div fxLayout="row" fxFlex fxLayoutAlign="space-between end">

        </div>
    </div>
</ng-template>
