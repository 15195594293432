<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <app-mobile-stepper
        [currentStep]="(mobileStepper.steps$ | async) > 4 ? 5 : 4"
    ></app-mobile-stepper>
    <div class="title flex-row-between">
        {{ (mobileStepper.steps$ | async) > 4 ? 5 : 4 }}.
        {{ "Text for message" | translate }}
    </div>
    <ng-container *ngIf="!personalSend.isPersonal; else personalSendTpl">
        <ng-container
            *ngIf="
                this.availableFlags && this.availableFlags.length > 1;
                else singlesend
            "
        >
            <mat-tab-group>
                <mat-tab [label]="'Mail'">
                    <ng-container *ngTemplateOutlet="mailsend"></ng-container>
                </mat-tab>
                <mat-tab [label]="'Whatsapp'">
                    <ng-container
                        *ngTemplateOutlet="whatsappsend"
                    ></ng-container>
                </mat-tab>
                <mat-tab [label]="'Sms'">
                    <ng-container *ngTemplateOutlet="smssend"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <!-- <ng-container *ngTemplateOutlet="subtitle"></ng-container>
        <ng-container *ngTemplateOutlet="textarea"></ng-container>
        <ng-container *ngTemplateOutlet="previewTitle"></ng-container>
        <ng-container *ngTemplateOutlet="preview"></ng-container> -->
    </ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<ng-template #desktop>
    <ng-container *ngIf="!personalSend.isPersonal; else personalSendTpl">
        <ng-container
            *ngIf="
                this.availableFlags && this.availableFlags.length > 1;
                else singlesend
            "
        >
            <mat-tab-group>
                <mat-tab [label]="'Mail'">
                    <ng-container *ngTemplateOutlet="mailsend"></ng-container>
                </mat-tab>
                <mat-tab [label]="'Whatsapp'">
                    <ng-container
                        *ngTemplateOutlet="whatsappsend"
                    ></ng-container>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #singlesend>
    <ng-container
        *ngIf="
            this.availableFlags &&
            (this.availableFlags[0] == 'MAIL' ||
                this.availableFlags[0] == 'FIRMA_EN_TIENDA' ||
                this.availableFlags[0] == 'AUTOFIRMA')
        "
    >
        <ng-container *ngTemplateOutlet="mailsend"></ng-container>
    </ng-container>
    <ng-container
        *ngIf="this.availableFlags && this.availableFlags[0] == 'SMS'"
    >
        <ng-container *ngTemplateOutlet="smssend"></ng-container>
    </ng-container>
    <ng-container
        *ngIf="this.availableFlags && this.availableFlags[0] == 'WHATSAPP'"
    >
        <ng-container *ngTemplateOutlet="whatsappsend"></ng-container>
    </ng-container>
</ng-template>

<ng-template #mailsend>
    <div class="grid-send">
        <ng-container *ngIf="!personalSend.isPersonal; else personalSendTpl">
            <ng-container *ngTemplateOutlet="subtitle"></ng-container>
            <ng-container *ngTemplateOutlet="textarea"></ng-container>
            <ng-container *ngTemplateOutlet="previewTitle"></ng-container>
            <ng-container *ngTemplateOutlet="preview"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #whatsappsend>
    <div class="grid-send-whatsapp">
        <ng-container *ngIf="!personalSend.isPersonal; else personalSendTpl">
            <ng-container *ngTemplateOutlet="subtitleWhatsapp"></ng-container>
            <ng-container *ngTemplateOutlet="previewWhatsapp"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #smssend>
    <div class="grid-send-whatsapp">
        <ng-container *ngIf="!personalSend.isPersonal; else personalSendTpl">
            <ng-container *ngTemplateOutlet="subtitleSms"></ng-container>
            <ng-container *ngTemplateOutlet="previewSms"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #personalSendTpl>
    <div style="grid-row: span 2;display: flex;flex-direction: column;">
        <ng-container *ngTemplateOutlet="subtitlePersonalSend"></ng-container>
        <div class="personal-explanation" style="flex: 1;">
            <mat-progress-spinner
                [mode]="'indeterminate'"
            ></mat-progress-spinner>
        </div>
    </div>
</ng-template>

<ng-template #subtitle>
    <div class="subtitle" style="align-items: center;display: flex;">
        <span>{{
            "Write the text that will appear on the email" | translate
        }}</span>
    </div>
</ng-template>

<ng-template #subtitleWhatsapp>
    <div class="subtitle" style="align-items: center;display: flex;">
        <span>{{ "Whatsapp preview" | translate }}</span>
    </div>
</ng-template>
<ng-template #subtitleSms>
    <div class="subtitle" style="align-items: center;display: flex;">
        <span>{{ "Sms preview" | translate }}</span>
    </div>
</ng-template>

<ng-template #subtitlePersonalSend>
    <div
        class="subtitle"
        style="margin-top: 40px; font-size:20px;align-items: flex-end;display: flex;justify-content: center;text-align: center;"
    >
        <span *ngIf="personalSend.flag == 'AUTOFIRMA'">
            {{ "We sent you and email with the signed document" | translate }}
        </span>
        <span
            *ngIf="
                personalSend.flag == 'FIRMA_EN_TIENDA' ||
                personalSend.flag == 'FIRMA_LINK'
            "
        >
            {{ "We are generating the link to the sign page" | translate }}
        </span>
    </div>
</ng-template>

<ng-template #textarea>
    <div>
        <mat-form-field
            appearance="fill"
            class="mar-t10"
            style="width: 100%;"
            [hintLabel]="'hint_label' | translate"
        >
            <mat-label translate>Text for email</mat-label>
            <textarea
                [placeholder]="'email_template_text' | translate"
                style="height: 40px"
                required
                matInput
                [(ngModel)]="tpl.mail_text"
            ></textarea>
        </mat-form-field>
    </div>
</ng-template>
<ng-template #personalExplanation>
    <div *ngIf="personalSend.flag == 'AUTOFIRMA'" class="personal-explanation">
        <p>
            {{
                "To continue, please, click on the send document button and you will receive an email with the signed document in a few minutes"
                    | translate
            }}
        </p>
    </div>
    <div
        *ngIf="
            personalSend.flag == 'FIRMA_EN_TIENDA' ||
            personalSend.flag == 'FIRMA_LINK'
        "
        class="personal-explanation"
    >
        <p>
            {{
                "To continue, please, click on the send document button and we will generate a link to the signature page"
                    | translate
            }}
        </p>
    </div>
</ng-template>

<ng-template #previewTitle>
    <div class="subtitle">
        <span>{{ "Email preview" | translate }}</span>
    </div>
</ng-template>

<ng-template #preview>
    <div
        class="preview-holder"
        *ngIf="contacts && contacts[0] && contacts[0][0]"
    >
        <app-message-preview
            [template]="tpl"
            [contacts]="contacts"
        ></app-message-preview>
    </div>
</ng-template>

<ng-template #previewWhatsapp>
    <div
        class="preview-holder"
        *ngIf="contacts && contacts[0] && contacts[0][0]"
    >
        <app-whatsapp-preview
            style="background-image: url(assets/png/whatsapp-wheat.png);"
            [template]="tpl"
            [contacts]="contacts"
        ></app-whatsapp-preview>
    </div>
</ng-template>

<ng-template #previewSms>
    <div
        class="preview-holder"
        *ngIf="contacts && contacts[0] && contacts[0][0]"
    >
        <app-sms-preview
            [template]="tpl"
            [contacts]="contacts"
        ></app-sms-preview>
    </div>
</ng-template>

<ng-template #footer>
    <div class="button-row">
        <button mat-stroked-button color="primary" (click)="previous()">
            {{ "Back" | translate }}
        </button>
        <button
            *ngIf="!personalSend.isPersonal"
            mat-raised-button
            color="accent"
            (click)="next()"
        >
            {{ "Send document" | translate }}
        </button>
    </div>
</ng-template>
