import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginatorService } from 'src/app/shared/auto-table/services/paginator.service';
import { BaseTableComponent } from '../../../shared/auto-table/components/base-table/base-table.component';
import { DocumentTableAdapterService } from '../../services/document-table-adapter.service';

@Component({
    selector: 'app-document-table',
    templateUrl: './documents-table.component.html',
    styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent extends BaseTableComponent implements OnInit, OnDestroy
{
    private unsubscribe$: Subject<boolean> = new Subject<boolean>();
    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    @Input('tableAdapter') tableAdapter: DocumentTableAdapterService;

    constructor(public service: PaginatorService)
    {
        super();
    }

    ngOnInit()
    {
        this.service.resetPagination$
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe(
                r =>
                {
                    this.paginator.firstPage();
                }
            );

    }
    ngOnDestroy(): void
    {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
