import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

    public sidenav: MatSidenav;

    public open(): void
    {
        if (this.sidenav)
        {
            this.sidenav.open();
        }
    }
  constructor() { }
}
