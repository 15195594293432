import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { localesDictionary } from 'src/app/configs/languages.dictionary';
import { TenantMailFooter } from 'src/app/core/models/tenant-management/tenant-mail-footer.model';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

const ERROR_NO_ARG = 'Variable data was not passed to this component';

@Component({
    selector: 'app-customizable-mail-footer',
    templateUrl: './customizable-mail-footer.component.html',
    styleUrls: ['./customizable-mail-footer.component.scss']
})
export class CustomizableMailFooterComponent implements AfterViewInit, OnDestroy
{
    @Input() data: Array<TenantMailFooter>;
    @Output() done = new EventEmitter<Array<TenantMailFooter>>();
    @Output() valid = new EventEmitter<boolean>();
    public form: FormGroup;
    public mapping = {} as { key: string };
    private listeners: Array<Subscription> = new Array();

    constructor(
        public responsive: ResponsiveService
    )
    {
        localesDictionary.forEach((reg) =>
        {
            this.mapping[reg.code] = reg.lang;
        });
    }

    ngAfterViewInit(): void
    {
        if (!this.data)
            throw new Error(ERROR_NO_ARG);

        this.form = new FormGroup({});
        this.data
            .forEach(locale =>
                this.form.addControl(
                    locale.lang,
                    this.buildFormField(locale.lang, locale.tpl)
                )
            );
    }
    ngOnDestroy(): void
    {
        this.listeners
            .forEach(sub => sub.unsubscribe());
    }

    private submit(): void
    {
        if (this.form.valid)
        {
            const result = this.parseFormValue(this.form.value);
            this.done.emit(result);
            this.valid.emit(true);
        }
        else
            this.valid.emit(false);
    }
    private buildFormField(locale: string, tpl: string): FormControl
    {
        const field = new FormControl(
            tpl,
            Validators.required
        );

        this.listeners.push(
            field.valueChanges
                .pipe(
                    debounceTime(500),
                    distinctUntilChanged(),
                )
                .subscribe(
                    val =>
                    {
                        this.submit();
                    }
                )
        );

        return field;
    }
    private parseFormValue(data: { key: string }): Array<TenantMailFooter>
    {
        const structure = [];
        Object.keys(data).forEach(lang =>
        {
            structure.push({
                lang,
                tpl: data[lang]
            } as TenantMailFooter)
        });

        return structure;
    }
}
