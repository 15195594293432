import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';

@Component({
    selector: 'app-mobile-stepper',
    templateUrl: './mobile-stepper.component.html',
    styleUrls: ['./mobile-stepper.component.scss']
})
export class MobileStepperComponent implements OnInit, OnDestroy
{
    @Input() currentStep: number;

    public iterable: Array<number>;

    private unsubscriber: Subject<void>;

    constructor(private service: MobileStepperService)
    {
        this.unsubscriber = new Subject();
    }

    ngOnInit(): void
    {
        this.service.steps$
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(
            x =>
            {
                if (x)
                    // Solo para poder iterar en el HTML.
                    this.iterable = new Array(x).fill(1);
            }
        )
    }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
}
