import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { WebServiceHelperService } from '../shared/web-service-helper.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    // PROPIEDADES


    // CONSTRUCTOR
    constructor(private WsHelper: WebServiceHelperService, private router: Router) { }

    ngOnInit() {
        if (this.WsHelper.initAuth()) {
            this.router.navigate(['/document']);
        }
    }


    // MÉTODOS PÚBLICOS


    // MÉTODOS PRIVADOS
}
