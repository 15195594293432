import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'app-tenant-management',
    templateUrl: './tenant-management.component.html',
    styleUrls: ['./tenant-management.component.scss']
})
export class TenantManagementComponent implements OnInit
{
    @HostBinding('class') classes = 'bg mat-typography';

    constructor() { }

    ngOnInit(): void
    {
    }
}
