import { Component, OnInit } from '@angular/core';
import { SignatureEditorComponent } from '../../signature-editor.component';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { Router } from '@angular/router';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { FormTemplateManagementService } from 'src/app/core/services/content/form-template-management.service';
import { take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';

@Component({
  selector: 'app-signature-editor-unattended',
  templateUrl: './signature-editor-unattended.component.html',
  styleUrls: ['./signature-editor-unattended.component.scss']
})
export class SignatureEditorUnattendedComponent extends SignatureEditorComponent implements OnInit{

    constructor(
        public responsive: ResponsiveService,
        public pdfControls: PdfControlsService,
        protected templateEditor: TemplateEditorService,
        protected router: Router,
        protected toast: I18nToastService,
        protected manager: FormTemplateManagementService
    ) {
        super(responsive, pdfControls, templateEditor, router, toast, manager);
    }

    public continue(): void
    {
        const template = this.templateEditor.getTemplate();
        const serialize = this.pdfControls.serializeAndSave().pipe(take(1));
        forkJoin([this.updateTemplateInfo(template.tpl), serialize])
            .pipe(take(1))
            .subscribe(
                serialized =>
                {
                    const template = this.templateEditor.getTemplate();
                    this.router.navigate([
                        'editable_pdf',
                        'share',
                        template.tpl.id]);
                },
                error =>
                {
                    let msg = '';

                    if (error instanceof HttpErrorResponse)
                        msg = error.error.message;
                    else
                        msg = error;

                    this.toast.open(msg, 'Accept', 7000, false);
                }
            )
    }

    public continueButBack(): void{
        const template = this.templateEditor.getTemplate();
        const serialize = this.pdfControls.serializeAndSave().pipe(take(1));
        forkJoin([this.updateTemplateInfo(template.tpl), serialize])
            .subscribe(
                obs =>
                {
                    this.templateEditor.reset();
                    this.router.navigate([
                        'editable_pdf',
                        'list_unattended'
                    ]);
                },
                error =>
                {
                    let msg = '';

                    if (error instanceof HttpErrorResponse)
                        msg = error.error.message;
                    else
                        msg = error;

                    this.toast.open(msg, 'Accept', 7000, false);
                }
            )
    }

    protected updateTemplateInfo(template: TemplateData): Observable<any>
    {
       return this.manager.updateFormTemplateUnattended(template, this.templateEditor.getPdfEditableId());
    }

}
