import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { UserModel } from 'src/app/shared/Models/UserModel';
import * as moment from 'moment/moment';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit
{
    @Input() User: UserModel;
    @Input() BaseColor: string;
    @Input() Color: string;
    @Output() delete = new EventEmitter<UserModel>();
    @Output() edit = new EventEmitter<UserModel>();
    @Output() passwordReset = new EventEmitter<UserModel>();
    constructor(
        public userMenuService: UserMenuService,
        private confirmation: ConfirmationBoxService,
        private translator: TranslateService
    ) { }

    ngOnInit()
    {
    }

    public CreatedAt(): string
    {
        return moment(this.User.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
    }

    public Delete(): void
    {
        this.delete.emit(this.User);
    }

    public Edit(): void
    {
        this.edit.emit(this.User);
    }

    PasswordReset()
    {
        this.confirmation.open({
            title: this.translator.instant('Password change request'),
            body: this.translator.instant('We are going to send a password change request via mail. Are you sure?'),
            btn_ok: this.translator.instant('Yes'),
            btn_cancel: this.translator.instant('No')
        })
        .pipe(take(1))
        .subscribe(
            x =>
            {
                if (x)
                    this.passwordReset.emit(this.User);
            }
        );
    }
}
