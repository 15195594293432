
<div *ngIf="!isLoading && !(status$ | async)" class="content mat-typography" [formGroup]="formGroup">
    <div class="form">
        <div class="image-container">
            <img src="assets/svg/my_rubric_logo_05.svg" alt="my rubic logo" />
        </div>
        <!-- <div
            class="title"
            style="padding-top: 2px; padding-bottom: 20px;"
        >
            {{ "otp_auth_title"| translate }}
        </div> -->
        <p>
            {{ "tf_qr_scan"| translate }}
        </p>
        <div style="width: 100%; text-align: center;">
            <img [src]="qrCode" alt="QR Code" style="width:200px;"/>
        </div>
        <p>
            {{ "tf_enter_code"| translate }}
        </p>    
        <mat-form-field class="low-density">
            <input
                disabled="true"
                matInput
                value="{{secret}}"
                style="text-align: center; font-size: 1.5em; font-weight: bold; color: #000;"
            />
        </mat-form-field>
        <p>{{ "tf_link_code" | translate }}</p>
        <mat-form-field class="low-density">
            <mat-label>{{ "Código de la aplicación" | translate }}</mat-label>
            <input
                required
                matInput
                formControlName="otp_code"
            />
            <ng-container *ngIf="formGroup.controls['otp_code'] as frmCtrl">
                <ng-container *ngIf=" frmCtrl.dirty || frmCtrl.touched">
                    <mat-error
                        *ngFor="let err of frmCtrl.errors | keyvalue"
                    >
                        {{ err.key | translate }}
                    </mat-error>
                </ng-container>
            </ng-container>
        </mat-form-field>
    </div>    
    <div class="footer" style="gap: 10px;">
        <button
            mat-raised-button
            color="primary"
            (click)="link()"
        >
            {{ "tf_activate" | translate }}
        </button>
        <button
            mat-raised-button
            (click)="closeDialog()"
        >
            {{ "Cancel" | translate }}
        </button>
    </div>
</div>
<div *ngIf="!isLoading && (status$ | async)" class="content mat-typography" [formGroup]="formGroup">
    <div class="form">
        <div class="image-container">
            <img src="assets/svg/my_rubric_logo_05.svg" alt="my rubic logo"/>
        </div>
        <p>
            {{ "tf_deactivate_description" | translate }}
        </p>
    </div>    
    <div class="footer" style="gap: 10px;">
        <button
            mat-raised-button
            color="primary"
            (click)="unlink()"
        >
            {{ "tf_deactivate" | translate }}
        </button>
        <button
            mat-raised-button
            (click)="closeDialog()"
        >
            {{ "Cancel" | translate }}
        </button>
    </div>
</div>
<div *ngIf="isLoading" class="content mat-typography">
    <div class="form">
        <div class="image-container">
            <img src="assets/svg/my_rubric_logo_05.svg" alt="my rubic logo"/>
        </div>
        <mat-spinner></mat-spinner>
    </div>    
    
</div>