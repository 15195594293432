import { environment } from 'src/environments/environment';
import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeCa from '@angular/common/locales/ca';
import localeExtrEs from '@angular/common/locales/extra/es';
import localeExtrEn from '@angular/common/locales/extra/en';
import localeExtrCa from '@angular/common/locales/extra/ca';
import { registerLocaleData } from '@angular/common';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import
    {
        MissingTranslationHandler,
        MissingTranslationHandlerParams,
        TranslateLoader,
        TranslateModule
    } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular2-signaturepad';

import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { LogInCallbackComponent } from './log-in-callback/log-in-callback.component';
import { LoginModule } from './login/login.module';

import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ConfirmationBoxComponent } from './shared/confirmation-box/confirmation-box.component';
import { SignatureGrabberComponent } from './shared/signature-grabber/signature-grabber.component';
import { MessageBoxComponent } from './shared/message-box/message-box.component';

import { SidenavModule } from './sidenav/sidenav.module';
import { BaseComponent } from './base/base.component';
import { CrossPlatformDialogModule } from './cross-platform-dialog/cross-platform-dialog.module';
import * as Hammer from 'hammerjs';
import { UseChrmPlzComponent } from './shared/use-chrm-plz/use-chrm-plz.component';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AutoTableModule } from './shared/auto-table/auto-table.module';
import { DocumentExtrasModalComponent } from './document-tray/components/document-extras-modal/document-extras-modal.component';
import { DocumentTrayModule } from './document-tray/document-tray.module';
import { MatPaginatorIn18Service } from './shared/mat-paginator-in18.service';
import { DatePickerAdapter } from './shared/date-picker.adapter';
import { BudgetShortageAlertComponent } from './shared/budget-shortage-alert/budget-shortage-alert.component';
import { DateAdapter } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserManagementModule } from './pages/user-management/user-management.module';
import { Router } from '@angular/router';
import { BaseTableComponent } from './shared/base-table/base-table.component';
import { RootStoreModule } from './root-store';

Sentry.init({
    dsn: 'https://34e49eed7ceb4e099a2fe55a4a30ae0f@o235897.ingest.sentry.io/1401822',
    dist: environment.sentry_dist ?? 'dev',
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
            tracingOrigins: ['*'],
            routingInstrumentation: Sentry.routingInstrumentation
        })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
});


registerLocaleData(localeEs, 'es-ES', localeExtrEs);
registerLocaleData(localeCa, 'ca-ES', localeExtrCa);
registerLocaleData(localeEn, 'en-US', localeExtrEn);

export function createTranslateLoader(http: HttpClient)
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler
{
    handle(params: MissingTranslationHandlerParams)
    {
        // console.log('trasnation missing key -> "' + params.key + '"');
        return params.key;
    }
}

export class HammerConfig extends HammerGestureConfig
{
    overrides = {
        pinch: { direction: Hammer.DIRECTION_ALL }
    } as any;
}


@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        LogInCallbackComponent,
        SpinnerComponent,
        ConfirmationBoxComponent,
        SignatureGrabberComponent,
        MessageBoxComponent,
        UseChrmPlzComponent,
        BudgetShortageAlertComponent,
        BaseTableComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
        MaterialModule,
        FormsModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        AppRoutingModule,
        LoginModule,
        HttpClientModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        SignaturePadModule,
        SidenavModule,
        CrossPlatformDialogModule,
        AutoTableModule,
        FormsModule,
        ReactiveFormsModule,
        RootStoreModule
    ],
    exports: [
        //   DatePickerAdapter
        //  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],

    providers: [
        { provide: LOCALE_ID, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: DatePickerAdapter },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIn18Service }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        SpinnerComponent,
        ConfirmationBoxComponent,
        MessageBoxComponent,
        SignatureGrabberComponent,
        UseChrmPlzComponent,
        BudgetShortageAlertComponent
    ]
})
export class AppModule
{
    constructor(update: SwUpdate, toast: MatSnackBar, dateAdapter: DateAdapter<Date>)
    {

        (window as any).pdfWorkerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/es5/build/pdf.worker.min.js';

        dateAdapter.setLocale('es-ES');

        update.available.subscribe(x =>
        {
            console.log('Actualización de la app disponible.');
            const notification = toast.open(
                'Hay una nueva versión de la aplicación.',
                'Instalar'
            );
            notification.onAction().subscribe(() =>
            {
                update.activateUpdate().then(() =>
                {
                    toast.open('Instalación completada', 'Aceptar');
                    window.location.reload();
                });
            });
        });
    }
}
