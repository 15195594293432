import { Injectable } from '@angular/core';
import { DialogStructure } from './DialogStructure';
import { Observable } from 'rxjs';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { DialogComponent } from '../dialog/dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class CrossPlatformDialogService
{

    constructor(private bottomSheet: MatBottomSheet, public dialog: MatDialog) { }

    private openBottomSheet<T>(dataToInject: DialogStructure<T>): Observable<any>
    {
        return this.bottomSheet.open(BottomSheetComponent, dataToInject).afterDismissed();
    }

    private openDialog<T>(dataToInject: DialogStructure<T>): Observable<any>
    {
        return this.dialog.open(DialogComponent, dataToInject).afterClosed();
    }

    public open<T>(dataToInject: DialogStructure<T>): Observable<any>
    {
        if (window.screen.availWidth <= 768)
        {
            return this.openBottomSheet(dataToInject);
        }
        else
        {
            return this.openDialog(dataToInject);
        }
    }
}
