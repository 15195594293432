import { Component, OnInit } from '@angular/core';
import { PdfEditableListComponent } from '../../pdf-editable-list.component';

@Component({
  selector: 'app-pdf-editable-unattended-list',
  templateUrl: './pdf-editable-unattended-list.component.html',
  styleUrls: ['./pdf-editable-unattended-list.component.scss']
})
export class PdfEditableUnattendedListComponent extends PdfEditableListComponent implements OnInit {

  public filter(): void {
    const filters = Object.assign({}, this.filtersGroup.value);
    this.service.listAllUnattendedForms(filters)
    .subscribe(
        x =>
        {
            this.templates$.next([]);
            this.templates$.next(x);
        }
    )
  }


}
