import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CONTACT_QUERY_TIPS } from 'src/app/configs/dicctionaries/send-assistan/contact-query-tips.dictionary';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { MakeThingsLookGoodService } from 'src/app/shared/MakeThingsLookGood.service';
import { ContactDefinitionService } from 'src/app/core/services/pages/contact-definition.service';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { mergeMap, take } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import { Contact } from 'src/app/core/models/contacts/contact';
import { ContactSearchComponent } from 'src/app/content/contacts/components/contact-search/contact-search.component';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { SelectedGroupService } from 'src/app/core/services/content/groups/selected-group.service';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';
import { ContactFilters } from 'src/app/core/models/contacts/contactFilters';
import { Observable, Subject } from 'rxjs';
import { UserSignaturesService } from 'src/app/core/services/content/user-signatures.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { ContactPickerBaseComponent } from 'src/app/content/contacts/components/contact-picker-base/contact-picker-base.component';
import { MessageBoxService } from 'src/app/shared/message-box.service';
import { TenantService } from 'src/app/core/services/pages/tenant-management/tenant.service';

@Component({
    selector: 'app-contact-definition',
    templateUrl: './contact-definition.component.html',
    styleUrls: ['./contact-definition.component.scss'],
    providers: [SelectedGroupService]
})
export class ContactDefinitionComponent extends ContactPickerBaseComponent implements OnInit
{
    @HostBinding('class') class = '';
    @ViewChild('stepper') stepper: MatHorizontalStepper;
    @ViewChild('radio1') radio: any;
    @ViewChild(ContactSearchComponent) contactsList: ContactSearchComponent;
    // @ViewChild('formControlCopies', { static: false }) formControlCopies: MatInput;

    public deployAmount: number = 1;
    public tempDeployAmount: number = 2;
    public template: TemplateData;
    public cvs = new FormControl(false);
    public cvsAllow: string;

    selectedContacts: Array<Array<Contact>> = [];
    public sendType: string = 'simple';
    public flagForPersonalSend: 'FIRMA_EN_TIENDA' | 'AUTOFIRMA' = 'FIRMA_EN_TIENDA';
    public emailCallbacksForPersonalSend = [];

    // Ref.
    public TIPS = CONTACT_QUERY_TIPS;
    public onlyEdit: boolean;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    public formControlCopies = new FormControl(null);

    constructor(
        public crossPlatformDialog: CrossPlatformDialogService,
        public toast: I18nToastService,
        public translator: TranslateService,
        public mtlg: MakeThingsLookGoodService,
        public dialog: MatDialog,
        public bottomSheet: MatBottomSheet,
        private confirmation: ConfirmationBoxService,
        private service: ContactDefinitionService,
        private router: Router,
        private route: ActivatedRoute,
        public responsive: ResponsiveService,
        private stepperService: MobileStepperService,
        public contactService: ConstactsService,
        private selectedGroupService: SelectedGroupService,
        public user: UserMenuService,
        private signatures: UserSignaturesService,
        public msgBox: MessageBoxService,
        public changeDetector: ChangeDetectorRef,
        public tenantService: TenantService,
    )
    {
        super(
            contactService,
            dialog,
            toast,
            bottomSheet,
            msgBox,
            translator,
            changeDetector
        );
    }

    ngOnInit()
    {
        this.tenantService
            .getTenantCvs()
            .subscribe(d =>
            {
                this.cvsAllow = d.value;
            });

        this.sendType = 'simple';

        /**
         *  Como el guard nos valida la entrada de la ruta, pillamos los extras
         *  directamente y los asignamos al template.
         */
        this.template = this.service.getCurrentTemplate();

        if (this.template.text_name && this.template.text_name.length > 0)
            this.stepperService.setSteps(5);
        else
            this.stepperService.setSteps(4);

        const signers = this.service.getCurrentSigners();
        if (signers)
        {
            this.selectedContacts = signers;
            this.deployAmount = signers.length;
            this.tempDeployAmount = signers.length;

            if (this.deployAmount > 1)
                this.sendType = 'batch';

            // check envio personal.
            if (this.selectedContacts.length > 0 && this.selectedContacts[0].length > 0)
            {
                const firstContact = this.selectedContacts[0][0];
                if (firstContact.flags && firstContact.flags.length > 0)
                {
                    const flags = firstContact.flags;
                    const personalFlagFound = flags.findIndex(x => x == 'FIRMA_EN_TIENDA' || x == 'AUTOFIRMA');
                    //flags.indexOf('FIRMA_EN_TIENDA') != -1 || flags.indexOf('AUTOFIRMA') != -1;

                    if (personalFlagFound != -1)
                    {
                        this.sendType = 'personal';
                        this.flagForPersonalSend = flags[personalFlagFound] as any;

                        // Miramos si hay contactos para reenviar.
                        if (firstContact.email_callbacks && firstContact.email_callbacks.length > 0)
                        {
                            firstContact.email_callbacks.forEach(x => this.emailCallbacksForPersonalSend.push(x));
                        }
                    }
                }
            }
        }

        if (this.service.wasGroup)
        {
            this.sendType = 'groups';
            this.selectedGroupService.setSelectedNode(this.service.group);
        }

        this.cvs.setValue(this.service.cvs);

        this.onlyEdit = (this.route.snapshot.params.FLAG_ONLY_EDIT === 'true' ?? false);

        this.setCssClass();
    }
    public setCssClass(): void
    {
        // Configura la clase al componente contact-definition para que en mbl
        // se vean bien los envíos "por grupos" y "firma personal"
        if (this.sendType != 'simple')
            this.class = 'no-simple';
        else
            this.class = '';
    }
    private validateEmail(email)
    {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: this.TIPS
        });
    }
    public setChoice(evt: MatRadioChange)
    {
        this.sendType = evt.value;
        this.setCssClass();
    }
    public previous()
    {
        this.confirmation.open({
            body: this.translator.instant('You will lose the progress'),
            title: this.translator.instant('Are you sure you want to go back?'),
            btn_ok: this.translator.instant('Accept'),
            btn_cancel: this.translator.instant('Cancel')
        })
            .pipe(take(1))
            .subscribe(x =>
            {
                if (x)
                    this.router.navigate([
                        'send_assistant',
                        'template',
                        this.template.id
                    ]);
            });
    }
    public repeatContactOverBatches(contact: Contact, positionAt: number)
    {
        for (let i = 0; i <= this.deployAmount - 1; i++)
        {
            let batch = this.selectedContacts[i];
            if (!batch)
            {
                this.selectedContacts[i] = Array(this.template.contact_count).fill(null);
                batch = this.selectedContacts[i];
            }

            // El primer batch no se toca, es el que sirve de configurador para
            // el resto.
            if (i != 0)
            {
                if (contact.repeated)
                {
                    const clonedContact = Object.assign({}, contact);
                    batch[positionAt] = clonedContact;
                } else
                    batch[positionAt] = null;
            }
        }
    }
    public adaptBatchesWithRepeatedContacts(): void
    {
        this.stepper.selectedIndex = 0;
        this.deployAmount = this.tempDeployAmount;

        /**
         * En el caso en que la array de nivel 1 se más larga que los
         * deploy amount, hay que correguir el array.
         */
        if (this.selectedContacts.length > this.deployAmount)
        {
            const deleteAmount = this.selectedContacts.length - this.deployAmount;
            this.selectedContacts.splice(this.deployAmount, deleteAmount);
        }

        /* Hay que recorrer el primer envío. Si el primer envío tiene contactos,
         * hay que evaluar si hay que repetirlo. De ser así, llamar a la fnc de
         * repeat.
        **/

        if (this.selectedContacts[0] && this.selectedContacts[0].length > 0)
        {
            this.selectedContacts[0].forEach((contact, index) =>
            {
                if (contact && contact.repeated)
                    this.repeatContactOverBatches(contact, index);
            });
        }
    }
    public setMyselfAsSigner(): Observable<boolean>
    {
        const done = new Subject<boolean>();
        this.user.user$
            .pipe(
                mergeMap(
                    user =>
                    {
                        const filters = new ContactFilters();
                        filters.user_id = user.id;

                        return this.contactService.post(0, filters);
                    }
                )
            )
            .subscribe(
                contacts =>
                {
                    contacts.data[0].flags = [this.flagForPersonalSend];
                    contacts.data[0].email_callbacks = this.emailCallbacksForPersonalSend;

                    if (this.selectedContacts && this.selectedContacts[0] && this.selectedContacts[0][0])
                    {
                        const existingFlags = this.selectedContacts[0][0].flags ?? [];

                        // Antes de añadir la flag personal, hay que mirar si en las existenes ya está.
                        const found = existingFlags.findIndex(x => x == 'FIRMA_EN_TIENDA' || x == 'AUTOFIRMA');

                        if (found != -1)
                        {
                            existingFlags[found] = this.flagForPersonalSend;
                            contacts.data[0].flags = existingFlags;
                        }
                        else
                            // IMPORTANTE MANTENER ESTE ORDEN.
                            contacts.data[0].flags = [...existingFlags, this.flagForPersonalSend];
                    }
                    else
                    {
                        contacts.data[0].flags = ['MAIL', this.flagForPersonalSend];
                    }

                    this.selectedContacts = [contacts.data];
                    done.next(true);
                    done.complete();
                },
                err =>
                {
                    done.next(false);
                    done.complete();
                }
            );

        return done;
    }
    private nextStep(): void
    {
        const template = this.service.getCurrentTemplate();

        this.router.navigate(
            [
                'send_assistant',
                'arbitrarytext',
                template.id
            ],
            {
                state: {
                    template,
                    signers: this.selectedContacts,
                    arbitraryText: this.service.arbitraryText,
                    from: 'contacts',
                    cvs: this.cvs.value
                }
            });
    }
    next()
    {
        if (this.sendType)
        {
            if (this.sendType == 'groups')
            {
                const group = this.selectedGroupService.getSelectedGroup();
                if (!group.id)
                    this.toast.open('You must select a group', 'Accept', 7000, true);
                else
                    this.contactService.generateStructureForSendByGroups(group)
                        .subscribe(
                            x =>
                            {
                                if (x && x.length > 0)
                                {
                                    const template = this.service.getCurrentTemplate();

                                    this.router.navigate(
                                        [
                                            'send_assistant',
                                            'arbitrarytext',
                                            template.id
                                        ],
                                        {
                                            state: {
                                                template,
                                                signers: x,
                                                arbitraryText: this.service.arbitraryText,
                                                from: 'contacts',
                                                wasGroup: true,
                                                group,
                                                cvs: this.cvs.value
                                            }
                                        });
                                }
                                else
                                    this.toast.open(
                                        'This group does not have contacts',
                                        'Accept',
                                        7000,
                                        true
                                    );
                            }
                        );
            }
            else
            {
                if (this.sendType == 'personal')
                {
                    if (this.formControlCopies.value)
                        if (this.validateEmail(this.formControlCopies.value))
                        {
                            const mail = this.formControlCopies.value;
                            this.emailCallbacksForPersonalSend.push(mail);
                            this.formControlCopies.setValue('');
                        }

                    if (!this.formControlCopies.value)
                    {
                        // Check si tenemos firma.
                        if (this.flagForPersonalSend == 'AUTOFIRMA')
                            this.signatures.signatures$
                                .subscribe(
                                    x =>
                                    {
                                        if (x)
                                        {
                                            this.setMyselfAsSigner()
                                                .subscribe(
                                                    done =>
                                                    {
                                                        if (done)
                                                        {
                                                            this.nextStep();
                                                        }
                                                    }
                                                );
                                        }
                                        else
                                            this.toast.open(
                                                'You have to create your signature before continue',
                                                'Accept',
                                                5000,
                                                true
                                            );
                                    }
                                );
                        else
                            this.setMyselfAsSigner()
                                .subscribe(
                                    done =>
                                    {
                                        if (done)
                                        {
                                            this.nextStep();
                                        }
                                    }
                                );
                    }
                    else
                        this.toast.open(
                            'This is not a valid email',
                            'Accept',
                            5000,
                            true
                        );
                }
                else
                {
                    try
                    {
                        if (this.selectedContacts.length != this.deployAmount || this.selectContact.length == 0)
                        {
                            this.toast.open(
                                'Wrong number of contacts. Please add the necessary ones',
                                'Accept',
                                7000
                            );
                            throw new Error('worng numebr of contacts in ->');
                        }
                        this.selectedContacts.forEach((x, i) =>
                        {
                            const selectedLength = x.filter(y => y).length;
                            if (selectedLength != this.template.contact_count)
                            {
                                // this.stepper.selectedIndex = i;
                                this.toast.open(
                                    'Wrong number of contacts. Please add the necessary ones',
                                    'Accept',
                                    7000
                                );
                                throw new Error('worng numebr of contacts in -> ' + i);
                            }
                        });

                        const template = this.service.getCurrentTemplate();

                        this.router.navigate(
                            [
                                'send_assistant',
                                'arbitrarytext',
                                template.id
                            ],
                            {
                                state: {
                                    template,
                                    signers: this.selectedContacts,
                                    arbitraryText: this.service.arbitraryText,
                                    from: 'contacts',
                                    cvs: this.cvs.value
                                }
                            });

                    } catch (e)
                    {
                        console.warn(e);
                    }
                }
            }
        } else
            this.toast.open(
                'Please, choose a shipment type before continue',
                'Accept',
                7000
            );
    }

    isEditPrevious()
    {
        this.router.navigate(['/template_management/list']);
    }

    isEditNext()
    {
        if (this.sendType)
        {
            if (this.sendType == 'groups')
            {
                const group = this.selectedGroupService.getSelectedGroup();
                if (!group.id)
                    this.toast.open('You must select a group', 'Accept', 7000, true);
                else
                    this.contactService.generateStructureForSendByGroups(group)
                        .subscribe(
                            x =>
                            {
                                if (x && x.length > 0)
                                {
                                    const template = this.service.getCurrentTemplate();

                                    this.router.navigate(
                                        [
                                            'send_assistant',
                                            'arbitrarytext',
                                            template.id
                                        ],
                                        {
                                            state: {
                                                template,
                                                signers: x,
                                                arbitraryText: this.service.arbitraryText,
                                                from: 'contacts',
                                                wasGroup: true,
                                                group,
                                                cvs: this.cvs.value
                                            }
                                        });
                                }
                                else
                                    this.toast.open(
                                        'This group does not have contacts',
                                        'Accept',
                                        7000,
                                        true
                                    );
                            }
                        );
            }
            else
            {
                try
                {
                    if (this.selectedContacts.length != this.deployAmount || this.selectContact.length == 0)
                    {
                        this.toast.open(
                            'Wrong number of contacts. Please add the necessary ones',
                            'Accept',
                            7000
                        );
                        throw new Error('worng numebr of contacts in ->');
                    }
                    this.selectedContacts.forEach((x, i) =>
                    {
                        const selectedLength = x.filter(y => y).length;
                        if (selectedLength != this.template.contact_count)
                        {
                            // this.stepper.selectedIndex = i;
                            this.toast.open(
                                'Wrong number of contacts. Please add the necessary ones',
                                'Accept',
                                7000
                            );
                            throw new Error('worng numebr of contacts in -> ' + i);
                        }
                    });

                    const template = this.service.getCurrentTemplate();

                    this.router.navigate(
                        [
                            'send_assistant',
                            'arbitrarytext',
                            template.id,
                            { FLAG_ONLY_EDIT: true }
                        ],
                        {
                            state: {
                                template,
                                signers: this.selectedContacts,
                                arbitraryText: this.service.arbitraryText,
                                from: 'contacts',
                                cvs: this.cvs.value
                            }
                        });

                } catch (e)
                {
                    console.warn(e);
                }
            }
        } else
            this.toast.open(
                'Please, choose a shipment type before continue',
                'Accept',
                7000
            );
    }

    public addEmail(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        if (value && this.validateEmail(value))
        {
            // Add our fruit
            if ((value || '').trim())
            {
                this.emailCallbacksForPersonalSend.push(value.trim());
                this.formControlCopies.setValue('');
            }

            // Reset the input value
            if (input)
            {
                input.value = '';
            }
        }
        else
            this.toast.open(
                'This is not a valid email',
                'Accept',
                5000,
                true
            );
    }
    public removeEmail(email: string): void
    {
        const index = this.emailCallbacksForPersonalSend.indexOf(email);

        if (index >= 0)
        {
            this.emailCallbacksForPersonalSend.splice(index, 1);
        }
    }
}
