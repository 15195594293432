import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { WebServiceHelperService } from './shared/web-service-helper.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    // PROPIEDADES


    // CONSTRUCTOR
    constructor(private oauthService: OAuthService, private router: Router, private WsHelper: WebServiceHelperService) { }


    // MÉTODOS PÚBLICOS
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.WsHelper.initAuth()) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }


    // MÉTODOS PRIVADOS
}
