<div>
    <div mat-dialog-title>
        {{"More information" |translate}}
    </div>
    <div mat-dialog-content>
        <ng-container *ngFor="let col of data.conf.columns">

            <ng-container [ngSwitch]="col.type_id">

                <p *ngSwitchCase="6" class="mat-typography">

                    <span> <b> {{ col.columnTitle }} :  </b> </span>

                    <ng-template #noMetaData>
                        <button mat-icon-button
                            (click)="docSer.downloadAttachment(data.rows.data[0].id, col.input_id, data.rows.data[0][col.column])">
                            <i class="material-icons"  >
                                cloud_download
                            </i>
                        </button>
                    </ng-template>

                    <ng-container *ngIf="JSON.parse(data.rows.data[0]['meta']) as meta; else noMetaData">

                        <ng-container *ngIf="data.rows.data[0][col.column] !== 'null' && meta !== false
                        && ( meta.validations === undefined || meta.validations['aditional_doc_' + col.columnTitle] === undefined )">

                            <button mat-icon-button
                                (click)="docSer.downloadAttachment(data.rows.data[0].id, col.input_id, data.rows.data[0][col.column])">
                                <i class="material-icons" style="color: red; fill: red;"  >
                                    cloud_download
                                </i>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="data.rows.data[0][col.column] !== 'null' && meta !== false
                        && meta.validations !== undefined && meta.validations['aditional_doc_' + col.columnTitle] !== undefined">

                            <button mat-icon-button
                                    (click)="docSer.downloadAttachment(data.rows.data[0].id, col.input_id, data.rows.data[0][col.column])">
                                <i class="material-icons" style="color: #00ff00; fill: #00ff00;" >
                                cloud_download
                                </i>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="meta.validations !== undefined && meta.validations['aditional_doc_' + col.columnTitle] !== undefined">
                            {{meta.validations['aditional_doc_' + col.columnTitle]}}
                        </ng-container>

                        <ng-container *ngIf="meta.warning !== undefined && meta.warning['aditional_doc_' + col.columnTitle] !== undefined">
                            {{meta.warning['aditional_doc_' + col.columnTitle]}}
                        </ng-container>

                    </ng-container>

                    <ng-container *ngIf="data.rows.data[0][col.column] === 'null'">
                        {{"Attached document not available" | translate}}
                    </ng-container>

                </p>

                <ng-container *ngSwitchCase="'batch_name'">
                    <ng-container *ngIf="data.rows.data[0][col.column]">
                        <p class="mat-typography">
                            <span> <b> {{ col.columnTitle }} : </b> </span>
                            {{ data.rows.data[0][col.column] }}
                        </p>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'contact_id'">
                        <p class="mat-typography">
                            <span> <b> {{ col.columnTitle }} : </b> </span>
                            {{ data.rows.data[0][col.column] }}
                            {{ '(' + (getContacStatusText(col.column, data.rows.data[0]) | translate) }}
                            <ng-container *ngIf="getContacStatus(col.column, data.rows.data[0]) == 0">
                                {{  ' - ' + (getContacStatusDate(col.column, data.rows.data[0]) | prepareDate | date:'short') + ')'}}
                            </ng-container>
                        </p>
                </ng-container>

                <p *ngSwitchDefault class="mat-typography">

                    <span> <b> {{ col.columnTitle }} : </b> </span>
                    <ng-container [ngSwitch]="col.column">

                        <ng-container *ngSwitchCase="'min'">
                            {{  data.rows.data[0][col.column] | prepareDate | date:'short' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'max'">
                            {{  data.rows.data[0][col.column] | prepareDate | date:'short' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'max_action'">
                            {{  docSer.translateAtActionKey(data.rows.data[0][col.column]) | async  }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'status_id'">
                            {{  docSer.translateStatusKey(data.rows.data[0][col.column]) | async | translate}}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{ data.rows.data[0][col.column] }}
                        </ng-container>
                    </ng-container>
                </p>

            </ng-container>

        </ng-container>
    </div>
</div>



