import {SpinnerComponent} from './spinner/spinner.component';
import {Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {SpinnerMessagesParcelService} from './spinner-messages-parcel.service';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    public c = 0;
    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay, private messageParcel: SpinnerMessagesParcelService) {
    }

    private handle(n) {
        this.c = this.c + n;
        if (this.c < -1)
            this.c = 0;
        if (this.c === 1 && n === 1) {
            this.addOverlay();
        }

        if (this.c === 0 && n === -1) {
            this.removeOverlay();
            this.messageParcel.whipe();
        }
    }

    add(text?: Array<string>) {
        if (text) {
            this.messageParcel.add(text);
        }
        this.handle(1);
    }

    remove(text?: string) {
        this.handle(-1);
    }

    removeOverlay() {
        this.overlayRef.dispose();
    }

    addOverlay() {
        this.overlayRef = this.overlay.create({});
        const componentPortal = new ComponentPortal(SpinnerComponent);
        this.overlayRef.attach(componentPortal);
    }
}
