import { Tip } from 'src/app/core/models/tip';

export const SIGNATURE_EDITOR_TIPS: Tip[] = [
    {
        title: 'Choose a pdf form',
        body: `tip_body_editable_1`
    },
    {
        title: 'Place signature',
        body: 'tip_body_editable_2'
    },
    {
        title: 'Use page controls',
        body: 'tip_body_editable_3'
    },
    {
        title: 'Use zoom controls',
        body: 'tip_body_editable_4'
    }
];
