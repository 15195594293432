import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SignatureFromPicker } from '../../models/signature-picker/signature-from-picker.model';
import { Signature } from '../../models/signature-picker/signature.model';
import { UserSignaturesRepositoryService } from '../../repositories/content/user-signatures-repository.service';
import { I18nToastService } from '../i18n-toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserSignaturesService
{
    private signatures: BehaviorSubject<Signature>;
    public signatures$: Observable<Signature>;

    constructor(
        private repository: UserSignaturesRepositoryService,
        private toast: I18nToastService
    )
    {
        this.signatures = new BehaviorSubject(null);
        this.signatures$ = this.signatures.asObservable();

        this.getSignatures();
    }

    public getSignatures(): void
    {
        this.repository.getUserSignatures()
        .subscribe(
            x =>
            {
                if (x)
                {
                    this.signatures.next(x);
                }
            }
        )
    }

    public createSignature(signature: SignatureFromPicker): Observable<boolean>
    {
        const done = new Subject<boolean>();

        const payload = new FormData();

        payload.set('name', signature.name);
        payload.set('payload', JSON.stringify(signature.payload));
        payload.set('image', this.b64toBlob(signature.image), signature.name);
        payload.set('status_id', signature.status_id ? '1' : '0');

        this.repository.createUserSignature(payload)
        .subscribe(
            x =>
            {
                if (x)
                {
                    done.next(true);
                    this.getSignatures();
                }
                else
                    done.next(false);

                done.complete();
            },
            err =>
            {
                this.toast.open(
                    'There was an error creating the signature',
                    'Accept',
                    5000,
                    true
                );

                done.next(false);
                done.complete();
            }
        )

        return done;
    }
    public editSignature(signature: SignatureFromPicker): Observable<boolean>
    {
        const done = new Subject<boolean>();

        const payload = new FormData();

        payload.set('name', signature.name);
        payload.set('payload', JSON.stringify(signature.payload));
        payload.set('image', this.b64toBlob(signature.image), signature.name);
        payload.set('status_id', signature.status_id ? '1' : '0');
        payload.set('id', signature.id);

        this.repository.editUserSignature(payload)
        .subscribe(
            x =>
            {
                if (x)
                {
                    done.next(true);
                    this.getSignatures();
                }
                else
                    done.next(false);

                done.complete();
            },
            err =>
            {
                this.toast.open(
                    'There was an error editing the signature',
                    'Accept',
                    5000,
                    true
                );

                done.next(false);
                done.complete();
            }
        )

        return done;
    }
    private b64toBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }
}
