import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PDF_STATE } from 'src/app/configs/enums/pdf-elements-state.enum';
import { TemplateOutputDragged } from 'src/app/core/models/api/template/template-output.model';
import { ColorService } from 'src/app/core/services/color.service';
import { PdfElementsService } from 'src/app/core/services/content/pdf-elements.service';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
    selector: 'app-control-ribbon',
    templateUrl: './control-ribbon.component.html',
    styleUrls: ['./control-ribbon.component.scss']
})
export class ControlRibbonComponent implements OnInit, OnDestroy
{
    public signers: Array<any>;
    public signersForm: FormGroup;
    private unsubscriber: Subject<void>;

    //REFS.
    public PDF_STATE = PDF_STATE;

    constructor(
        public pdfControls: PdfControlsService,
        public colorService: ColorService,
        public pdfElements: PdfElementsService,
        public responsive: ResponsiveService
    )
    {
        this.signersForm = new FormGroup({
            signer: new FormControl(0, Validators.nullValidator)
        });
        this.unsubscriber = new Subject<void>();
    }

    ngOnInit(): void
    {
        this.pdfControls.template$
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(x =>
            {
                if (x && x.tpl.id)
                    this.signers = new Array(x.tpl.contact_count).fill(1);
            });
    }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public stateManagement(): void
    {
        this.pdfElements.onStateEnter$
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(
                state =>
                {
                    switch (state)
                    {
                        case PDF_STATE.PENDING:
                            break;
                        case PDF_STATE.ADDING:
                            break;
                    }
                });

        this.pdfElements.onStateExit$
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(
                state =>
                {
                    switch (state)
                    {
                        case PDF_STATE.PENDING:
                            break;
                        case PDF_STATE.ADDING:
                            this.pdfControls.configureOutputToBeAdded(null);
                            break;
                    }
                });
    }
    public addElement(output: TemplateOutputDragged): void
    {
        const newOutput = cloneDeep(output);
        newOutput.meta.contac_idx = this.signersForm.controls.signer.value;
        this.pdfControls.configureOutputToBeAdded(newOutput);
        this.pdfElements.changeState(PDF_STATE.ADDING);
    }
}
