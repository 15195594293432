import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { subtenantEditReducer } from './reducer';
import { SubtenantEditEffects } from './effects';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatDialogModule,
        StoreModule.forFeature('subtenantEdit', subtenantEditReducer),
        EffectsModule.forFeature([SubtenantEditEffects])
    ]
})
export class SubtenantEditStoreModule { }
