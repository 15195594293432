import { DataSource, CollectionViewer, ListRange } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { Contact } from './contact';
import { ConstactsService } from '../../../core/services/pages/contacts/constacts.service';
import { ContactFilters } from './contactFilters';

export class ContactsDataSource extends DataSource<Contact | undefined>
{
    // From constructor
    private pageSize: number;

    // By Class
    private fetchedPages = new Set<number>();
    private subscription = new Subscription();
    private cachedData: Contact[];
    private dataStream: BehaviorSubject<(Contact | undefined)[]>;
    private filters: ContactFilters;

    constructor(
        private contactService: ConstactsService,
        totalItems: number,
        pageSize: number,
        cachedData?: Contact[],
        trackedPage?: number
    )
    {
        super();

        // Inicializaciones base
        this.pageSize = pageSize;
        this.cachedData = Array.from<Contact>({ length: totalItems });

        // Inicialización de la cache y la página trackeada.
        if (cachedData && trackedPage != undefined)
        {
            this.cachedData.splice(trackedPage * this.pageSize, this.pageSize, ...cachedData);
            this.fetchedPages.add(trackedPage);
        }

        this.dataStream = new BehaviorSubject<Contact[]>(this.cachedData);
    }

    public connect(collectionViewer: CollectionViewer): Observable<(Contact[] | undefined)>
    {
        this.subscription.add(collectionViewer.viewChange.subscribe(range =>
        {
            this.fetchPages(range, this.filters ? this.filters : null);
        }));
        return this.dataStream;
    }
    public currentData(): Contact[]
    {
        return this.dataStream.value;
    }

    public disconnect()
    {
        this.subscription.unsubscribe();
    }

    public fetchPages(range: ListRange, filters?: ContactFilters): void
    {
        const startPage = this.getPageForIndex(range.start);
        const endPage = this.getPageForIndex(range.end - 1);

        for (let i = startPage; i <= endPage; i++)
        {
            this.fetchPage(i, filters);
        }
    }

    public setFilters(filters: ContactFilters): void
    {
        this.filters = filters;
    }

    private getPageForIndex(index: number): number
    {
        return Math.floor(index / this.pageSize);
    }

    private fetchPage(page: number, filters?: ContactFilters)
    {
        if (this.fetchedPages.has(page))
        {
            return;
        }
        this.fetchedPages.add(page);

        this.fetchServerData(page, filters).subscribe(response =>
        {
            this.cachedData.splice(page * this.pageSize, this.pageSize, ...response.data);
            this.dataStream.next(this.cachedData);
        });
    }

    private fetchServerData(page: number, filters?: ContactFilters): Observable<any>
    {
        return this.contactService.post(page + 1, filters);
    }
}