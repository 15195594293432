import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';
import { ContactGroupPayload } from '../../models/api/contacts-groups/contact-group-payload.model';

@Injectable({
    providedIn: 'root'
})
export class ContactsGroupsRepositoryService
{

    constructor(
        private wsh: WebServiceHelperService
    ) { }

    public getGroupHierarchy(): Observable<any>
    {
        const url = environment.backEndDomain + 'api/group_managment';
        return this.wsh.get(url);
    }
    public addGroup(group: ContactGroupPayload): Observable<any>
    {
        const url = environment.backEndDomain + 'api/group_managment'
        return this.wsh.post(url, group);
    }
    public deleteGroup(groupId:number): Observable<any>
    {
        const url = environment.backEndDomain + 'api/group_managment/' + groupId;
        return this.wsh.delete(url, null);
    }
    public editGroup(group: ContactGroupPayload, id: number): Observable<any>
    {
        const url = environment.backEndDomain + 'api/group_managment/' + id;
        return this.wsh.post(url, group);
    }
    public getContactsOfGroup(id: number): Observable<any>
    {
        const url = environment.backEndDomain + 'api/group_managment/' + id;
        return this.wsh.get(url);
    }
    public addContactToGroup(groupId: number, contactId: number): Observable<any>
    {
        const url = environment.backEndDomain + 'api/group_managment/add_contact_to_group';
        const data = {
            contact_id: contactId,
            group_id: groupId
        }

        return this.wsh.post(url, data);
    }
    public removeContactFromGroup(groupId: number, contactId: number): Observable<any>
    {
        const url = `${environment.backEndDomain}api/group_managment/${groupId}/remove_contact/${contactId}`;
        return this.wsh.delete(url, null);
    }
}
