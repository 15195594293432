import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ConstactsService } from '../../core/services/pages/contacts/constacts.service';
import { Contact } from '../../core/models/contacts/contact';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matBaseAnimations, BaseComponent } from 'src/app/base/base.component';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { MessageBoxService } from 'src/app/shared/message-box.service';
import { MsgBoxConfig } from 'src/app/shared/message-box/MsgBoxConfig';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { ContactFilters } from 'src/app/core/models/contacts/contactFilters';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject } from 'rxjs';
import {map, mergeMap, take, takeUntil} from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { GROUP_DIAG_HEIGHT, GROUP_DIAG_WIDTH } from 'src/app/configs/constants/group-dialogs.constants';
import { ContactFormComponent } from 'src/app/content/contacts/components/contact-form/contact-form.component';
import { ContactVirtualScrollComponent } from 'src/app/content/contacts/components/contact-virtual-scroll/contact-virtual-scroll.component';
import { ContactGroupEditorComponent } from 'src/app/content/groups/components/contact-group-editor/contact-group-editor.component';
import { CreateGroupComponent } from 'src/app/content/groups/components/create-group/create-group.component';
import { GroupOfGroupsEditorComponent } from 'src/app/content/groups/components/group-of-groups-editor/group-of-groups-editor.component';
import { ExcelUploadReportComponent } from 'src/app/content/contacts/components/excel-upload-report/excel-upload-report.component';
import { CustomTextConf } from 'src/app/core/models/contacts/custom-text-conf.model';
import {PdfBlobOpenerService} from '../../shared/pdf-blob-opener.service';

@Component({
    selector: 'app-contacts-management',
    templateUrl: './contacts-management.component.html',
    styleUrls: ['./contacts-management.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation],
    providers: [MatBottomSheet]
})
export class ContactsManagementComponent extends BaseComponent
    implements OnInit, OnDestroy
{
    @HostBinding('class') classes = 'bg mat-typography';
    @ViewChild('scroller') scroller: ContactVirtualScrollComponent;
    public filterObj = { name: null, email: null, legalid: null, status: 1};
    public contacts: Array<Contact>;
    public contactsFiltered: Array<Contact>;
    public countries: Array<any>;
    public locales: Array<any>;
    public AuxContacts: any;
    private unsubscriber: Subject<void>;

    public groupsView: boolean;
    public treeControl: NestedTreeControl<ContactNode>;
    public showPlaceholder = false;

    public currentGroup: { element: HTMLElement, node: ContactNode };

    constructor(
        private NavSidebar: SidenavService,
        public dialog: MatDialog,
        public contactsService: ConstactsService,
        private toast: MatSnackBar,
        private msgBoxService: MessageBoxService,
        private translator: TranslateService,
        public responsive: ResponsiveService,
        public route: ActivatedRoute,
        private blobOpener: PdfBlobOpenerService
    )
    {
        super(NavSidebar);
        this.unsubscriber = new Subject<void>();
        this.treeControl = new NestedTreeControl<ContactNode>(node => node.children);
    }

    ngOnInit()
    {
        this.route.data
            .pipe(take(1))
            .subscribe(
                (data: Data) =>
                {
                    this.groupsView = data.groupsView || false;

                    if (!this.groupsView)
                        this.firstLoad();
                    else
                    {
                        this.showPlaceholder = true;
                        this.AuxContacts = {};
                    }
                }
            );

        this.countries = [];
        this.locales = [];
    }
    ngOnDestroy()
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public hasChild = (idx: number, node: ContactNode) => !!node.children && node.children.length > 0;
    public ApplyFilters(keepPosition = false): void
    {
        try
        {
            const filters = {
                fullName: this.filterObj.name ? this.filterObj.name : null,
                email: this.filterObj.email ? this.filterObj.email : null,
                customerLegalId: this.filterObj.legalid
                    ? this.filterObj.legalid
                    : null,
                status: this.filterObj.status
            } as ContactFilters;

            if (this.currentGroup && this.currentGroup.node)
                filters.group_id = this.currentGroup.node.id;

            this.scroller.ReloadDataSource(keepPosition, filters);
        } catch (e)
        {
            console.log('DataSource no está disponible todavía', e);
            Sentry.captureException(e);
        }
    }
    public openModal(user: Contact, isEdit: boolean, type: 'group' | 'contact'): void
    {
        if (type == 'group')
        {
            const isMobile = this.responsive.IsMobile();
            const conf = {
                panelClass: 'extended_diag_mbl',
                width: isMobile ? '99%' : GROUP_DIAG_WIDTH,
                height: isMobile ? '85%' : GROUP_DIAG_HEIGHT
            }
            this.dialog.open(CreateGroupComponent, conf)
                .afterClosed()
                .subscribe(x =>
                {
                    if (x)
                    {
                        this.contactsService.createGroup(x)
                            .subscribe();
                    }
                });
        }
        else
        {
            this.contactsService.customFieldConf$
            .pipe(
                takeUntil(this.unsubscriber),
                map(x => x.customText),
                map((arr: Array<CustomTextConf>) => {
                    return arr.filter(c => {
                            return c.flag_value > 0;
                        }
                    )
                }),
                mergeMap(c => {
                    const structure = this.buildModalConf(user, isEdit, c);

                    return this.dialog.open(ContactFormComponent, structure).afterClosed()
                })
            )
            .subscribe(x => {
                this.handleModalResponse(x, isEdit);
            });
        }
    }
    public AdaptScroller(): void
    {
        setTimeout(() =>
        {
            this.scroller.AdaptScrollerToViewportChanges();
        }, 500);
    }
    public fileChangeHandler(event): void
    {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const files = target.files;

        if (files.length > 0)
        {
            this.contactsService.uploadExcel(files[0]).subscribe(
                r =>
                {
                    const conf: MatDialogConfig = {
                        data: r,
                        width: '700px'
                    };

                    this.dialog
                        .open(ExcelUploadReportComponent, conf)
                        .afterClosed()
                        .subscribe(r2 =>
                        {
                            console.log(r2);
                        });
                    this.scroller.ReloadDataSource(false);
                },
                e =>
                {
                    this.msgBoxService.instant({
                        data: {
                            title: this.translator.instant(
                                'error_while_upload_file'
                            ),
                            body: e.error.message,
                            btn_ok: this.translator.instant('accept')
                        },
                        width: '450px',
                        height: '150px'
                    } as MsgBoxConfig);
                },
                () => { }
            );
            target.value = '';
        }
    }
    public downloadTemplate()
    {
        window.open('/spa/assets/ImportExelTpl.xlsx');
    }
    public editGroup(group: ContactNode): void
    {
        const isMobile = this.responsive.IsMobile();
        const conf = {
            panelClass: 'extended_diag_mbl',
            width: isMobile ? '99%' : GROUP_DIAG_WIDTH,
            height: isMobile ? '85%' : GROUP_DIAG_HEIGHT,
            data: group
        } as MatDialogConfig;
        if (group.type == 'group')
            this.dialog.open(GroupOfGroupsEditorComponent, conf)
                .afterClosed()
                .subscribe(
                    x =>
                    {
                        if (x == 'save')
                            this.contactsService.editGroup(group);
                        if (x == 'delete')
                            this.contactsService.deleteGroup(group.id)
                        if (x instanceof Object) // Si pasamos el objeto, es edit
                            this.editGroup(x);
                    }
                );
        else
            this.dialog.open(ContactGroupEditorComponent, conf)
                .afterClosed()
                .subscribe(
                    x =>
                    {
                        if (x == 'save')
                            this.contactsService.editGroup(group);
                        if (x == 'delete')
                            this.contactsService.deleteGroup(group.id)
                    }
                );
    }
    public loadContacts(element: HTMLElement, node: ContactNode): void
    {
        if (this.currentGroup && this.currentGroup.element)
        {
            const newClassList = this.currentGroup.element.className.replace(' selected', '');
            this.currentGroup.element.className = newClassList;
        }

        this.currentGroup = { element, node };

        this.currentGroup.element.className += ' selected';

        this.showPlaceholder = false;
        this.ApplyFilters(false);
    }

    private firstLoad(): void
    {

        const filters = {
            fullName: this.filterObj.name ? this.filterObj.name : null,
            email: this.filterObj.email ? this.filterObj.email : null,
            customerLegalId: this.filterObj.legalid
                ? this.filterObj.legalid
                : null,
            status: this.filterObj.status
        } as ContactFilters;

        if (this.currentGroup && this.currentGroup.node)
            filters.group_id = this.currentGroup.node.id;

       this.contactsService.post(0, filters).subscribe(
           r =>
           {
               this.AuxContacts = r;
           },
           e =>
           {
               console.error(e);
           },
           () => { }
       );
    }
    private buildModalConf(user: Contact, isEdit: boolean, conf: Array<CustomTextConf>)
    {
        user = user || (new Contact());
        const structure = {
            data: {
                title: isEdit ? 'edit contact' : 'new contact',
                positiveButton: isEdit ? 'save' : 'create',
                countries: this.countries,
                locales: this.locales,
                data: {
                    contact: isEdit ? user : new Contact(),
                    config: conf
                },
                isEdit,
                dynamycComponent: ContactFormComponent,
                hideCloseButton: false
            },
            width: '730px',
            height: '600px',
            disableClose: false
        } as DialogStructure<{ contact: Contact, config: Array<CustomTextConf> }>;

        return structure;
    }
    private handleModalResponse(x, isEdit: boolean) {
        if (x) {
            this.ApplyFilters(true);
            if (
                !isEdit &&
                (this.filterObj.email ||
                    this.filterObj.legalid ||
                    this.filterObj.name)
            ) {
                this.toast.open(
                    this.translator.instant('Clear the filters to see the new content'),
                    this.translator.instant('Accept'),
                    {duration: 5000}
                );
            } else {
                if (isEdit) {
                    this.toast.open(
                        this.translator.instant('The contact has been edited sucessful'),
                        this.translator.instant('accept'),
                        {duration: 5000}
                    );
                } else {
                    this.toast.open(
                        this.translator.instant('The contact has been created sucessful'),
                        this.translator.instant('accept'),
                        {duration: 5000}
                    );
                }
            }
        }
    }

    contactDeleted($event: Contact)
    {
        this.ApplyFilters(false);
    }

    public getExcel(keepPosition = false): void
    {
        const filters = {
            fullName: this.filterObj.name ? this.filterObj.name : null,
            email: this.filterObj.email ? this.filterObj.email : null,
            customerLegalId: this.filterObj.legalid
                ? this.filterObj.legalid
                : null,
            status: this.filterObj.status
        } as ContactFilters;

        if (this.currentGroup && this.currentGroup.node)
            filters.group_id = this.currentGroup.node.id;

        this.contactsService.getExcel(filters)
            .subscribe(
                r =>
                {
                    this.blobOpener.openArbitrary(r, 'application/vnd.ms-excel')

                },
                e =>
                {

                    console.error(e);
                },
                () =>
                {
                    console.log('finisah')
                })
    }

}
