<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
</ng-container>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="helpCol"></ng-container>
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
</ng-template>

<ng-template #helpCol>
    <div class="help-col mat-typography">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ "Help" | translate }}</span>
        </div>
        <app-tip [idx]="0" [tip]="legalTip"></app-tip>
        <app-tip [idx]="1" [tip]="mailTip"></app-tip>
        <app-tip [idx]="2" [tip]="localeTip"></app-tip>
        <app-tip [idx]="3" [tip]="asteriscTip"></app-tip>
    </div>
</ng-template>

<ng-template #contentCol>
    <div class="content mat-typography" [formGroup]="formGroup">
        <div class="form">
            <ng-container *ngIf="responsive.isMobile$ | async; else deskTitle">
                <div
                    class="subtitle flex-row-between"
                    style="align-items:center; padding-top: 2px; padding-bottom: 12px;"
                >
                    {{ data.title | translate }}
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="openHelp()"
                    >
                        <mat-icon>help</mat-icon>
                    </button>
                </div>
            </ng-container>
            <ng-template #deskTitle>
                <div
                    class="subtitle"
                    style="padding-top: 2px; padding-bottom: 12px;"
                >
                    {{ data.title | translate }}
                </div>
            </ng-template>
            <mat-form-field class="low-density" style="margin-bottom: 10px;">
                <mat-label>{{ "Legal identity" | translate }}</mat-label>
                <input
                    required
                    matInput
                    placeholder="12345678A"
                    formControlName="customer_legal_identifier"
                />

                <ng-container
                    *ngIf="
                        formGroup.controls[
                            'customer_legal_identifier'
                        ] as frmCtrl
                    "
                >
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <div class="mat-error" *ngFor="let err of frmCtrl.errors | keyvalue">{{ err.key | translate }}</div>
                    </ng-container>
                </ng-container>
                <mat-hint align="start"><strong>{{ "identification_verify" | translate }}</strong></mat-hint>
            </mat-form-field>
            <mat-form-field class="low-density">
                <mat-label>{{ "name" | translate }}</mat-label>
                <input
                    required
                    matInput
                    placeholder="{{ 'Name' | translate }}"
                    formControlName="name"
                />

                <ng-container *ngIf="formGroup.controls['name'] as frmCtrl">
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <mat-error
                            *ngFor="let err of frmCtrl.errors | keyvalue"
                        >
                            {{ err.key | translate }}
                        </mat-error>
                    </ng-container>
                </ng-container>
            </mat-form-field>

            <mat-form-field class="low-density">
                <mat-label>{{ "surname1" | translate }}</mat-label>
                <input
                    required
                    matInput
                    placeholder="{{ 'surname_1' | translate }}"
                    formControlName="surname_1"
                />
                <ng-container
                    *ngIf="formGroup.controls['surname_1'] as frmCtrl"
                >
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <mat-error
                            *ngFor="let err of frmCtrl.errors | keyvalue"
                        >
                            {{ err.key | translate }}
                        </mat-error>
                    </ng-container>
                </ng-container>
            </mat-form-field>

            <mat-form-field class="low-density">
                <mat-label>{{ "surname2" | translate }}</mat-label>
                <input
                    matInput
                    placeholder="{{ 'surname_2' | translate }}"
                    formControlName="surname_2"
                />
                <ng-container
                    *ngIf="formGroup.controls['surname_2'] as frmCtrl"
                >
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <mat-error
                            *ngFor="let err of frmCtrl.errors | keyvalue"
                        >
                            {{ err.key | translate }}
                        </mat-error>
                    </ng-container>
                </ng-container>
            </mat-form-field>

            <mat-form-field class="low-density">
                <mat-label>{{ "company" | translate }}</mat-label>
                <input
                    matInput
                    placeholder="ACME S.A."
                    formControlName="company"
                />
                <ng-container *ngIf="formGroup.controls['company'] as frmCtrl">
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <mat-error
                            *ngFor="let err of frmCtrl.errors | keyvalue"
                        >
                            {{ err.key | translate }}
                        </mat-error>
                    </ng-container>
                </ng-container>
            </mat-form-field>

            <mat-form-field class="low-density">
                <mat-label>{{ "email" | translate }}</mat-label>
                <input
                    required
                    matInput
                    placeholder="name@gmail.com"
                    formControlName="corporate_email"
                />
                <ng-container
                    *ngIf="formGroup.controls['corporate_email'] as frmCtrl"
                >
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <mat-error
                            *ngFor="let err of frmCtrl.errors | keyvalue"
                        >
                            {{ err.key | translate }}
                        </mat-error>
                    </ng-container>
                </ng-container>
            </mat-form-field>

            <mat-form-field class="low-density">
                <mat-label>{{ "mobile_phone" | translate }}</mat-label>
                <lac-mat-country-selector
                    [preferredCountries]="['es']"
                    matPrefix
                    [selectorFor]="input"
                ></lac-mat-country-selector>
                <lac-mat-tel-input
                    #input
                    formControlName="mobile_phone"
                ></lac-mat-tel-input>
                <mat-error
                    *ngIf="
                        formGroup.controls['mobile_phone']?.errors?.invalidPhone
                    "
                    >Invalid phone</mat-error
                >
            </mat-form-field>
            <mat-form-field class="low-density">
                <mat-select
                    formControlName="locale"
                    placeholder="{{ 'locale' | translate }}"
                    required
                >
                    <mat-option
                        *ngFor="let l of localesDictionary"
                        [value]="l.code"
                        >{{ l.lang | translate }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <mat-form-field
                class="low-density"
                *ngFor="let c of this.data.data.config"
            >
                <mat-label>{{ c.data_value }}</mat-label>
                <input
                    *ngIf="c.required === true"
                    required
                    matInput
                    placeholder=""
                    formControlName="{{ 'custom_value_' + c.getIdx() }}"
                />
                <input
                    *ngIf="c.required === null"
                    matInput
                    placeholder=""
                    formControlName="{{ 'custom_value_' + c.getIdx() }}"
                />
                <ng-container
                    *ngIf="
                        formGroup.controls[
                            'custom_value_' + c.getIdx()
                        ] as frmCtrl
                    "
                >
                    <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                        <mat-error
                            *ngFor="let err of frmCtrl.errors | keyvalue"
                        >
                            {{ err.key | translate }}
                        </mat-error>
                    </ng-container>
                </ng-container>
            </mat-form-field>
        </div>
        <div class="footer">
            <button mat-stroked-button mat-dialog-close>
                {{ "Cancel" | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="action()"
                *ngIf="data.isEdit"
            >
                {{ "save" | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="action()"
                *ngIf="!data.isEdit"
            >
                {{ "create" | translate }}
            </button>
        </div>
    </div>
</ng-template>
