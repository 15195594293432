import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MakeThingsLookGoodService} from '../../../../shared/MakeThingsLookGood.service';
import {DocumentService} from '../../../../core/services/content/document.service';
import {DocumentTemplateManagementService} from '../../../../core/services/content/document-template-management.service';
import {ConfirmationBoxService} from '../../../../shared/confirmation-box.service';
import {mapTo, mergeMap} from 'rxjs/operators';
import {MessageBoxService} from '../../../../shared/message-box.service';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { TemplateNewNameDialogComponent } from '../template-new-name-dialog/template-new-name-dialog.component';

@Component({
    selector: 'app-template-card',
    templateUrl: './template-card.component.html',
    styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent implements OnInit
{

    @Input() template: any;
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        public mtlg: MakeThingsLookGoodService,
        public docService: DocumentService,
        public docTplService: DocumentTemplateManagementService,
        public alertBox: ConfirmationBoxService,
        public msgBox: MessageBoxService,
        public trans: TranslateService,
        public toast: I18nToastService,
        public dialog: CrossPlatformDialogService
    )
    {

    }

    ngOnInit(): void
    {

    }

    delete()
    {
        this.docTplService.deleteTpl(this.template.id)
        .subscribe(
            x =>
            {
                let msg = this.trans.instant('template x has been disabled', {contact_name: this.template.name});
                if (this.template.status == 2)
                    msg = this.trans.instant('template x has been enabled', {contact_name: this.template.name});

                this.toast.open(msg, this.trans.instant('Accept'), 5000, false);
                this.deleted.emit();
            },
            e =>
            {
                console.log(e);
                this.msgBox.instant({
                    data: {
                        title: this.trans.instant('error'),
                        body: this.trans.instant('Can not delete'),
                        btn_ok: this.trans.instant('ok')
                    }
                })
            }
        );
    }

    clone()
    {
        this.dialog.open({
            data: {
                data: this.template,
                dynamycComponent: TemplateNewNameDialogComponent,
                countries: [],
                isEdit: false,
                hideCloseButton: false,
                locales: [],
                positiveButton: 'save',
                message: this.trans.instant('Type the new name you want for the new template'),
                title: this.trans.instant('Duplicate template')
            },
            disableClose: false,
            width: '500px'
        })
        .subscribe(
            newName =>
            {
                if (newName)
                {
                    const newTpl = {
                        tplId: this.template.id,
                        newName: newName
                    };
                    this.docTplService.cloneTemplate(newTpl)
                    .subscribe(r =>
                    {
                        // LOL SORRY: FORCING LIST RELOAD
                        this.deleted.emit();
                    });
                }
            }
        );
    }
}

