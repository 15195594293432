import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { DOC_DEF_TIPS, TPL_DEF_TIPS, TPL_DEF_TIPS_MBL } from 'src/app/configs/dicctionaries/send-assistan/doc-definition-tips.dictionary';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { DocumentTemplateManagementService } from 'src/app/core/services/content/document-template-management.service';
import { PdfBlobOpenerService } from 'src/app/shared/pdf-blob-opener.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {of, zip} from 'rxjs';

@Component({
  selector: 'app-document-definition',
  templateUrl: './document-definition.component.html',
  styleUrls: ['./document-definition.component.scss']
})
export class DocumentDefinitionComponent implements OnInit, AfterViewInit
{
    @HostBinding('class') classes = '';
    @ViewChild('file') fileInput: ElementRef;
    @ViewChild('radioNew') radioNew: MatRadioButton;
    @ViewChild('radioExisting') radioExisting: MatRadioButton;
    selectedTemplate: any;
    tpl: TemplateData = new TemplateData();
    baseTpl: TemplateData = null;
    docNameErr: string = '';
    inputFileErr: string = '';

    public choice: string;

    // Ref.
    public TIPS = DOC_DEF_TIPS;

    constructor(
        protected router: Router,
        protected tplService: DocumentTemplateManagementService,
        protected blobOpener: PdfBlobOpenerService,
        protected toast: I18nToastService,
        protected translator: TranslateService,
        public responsive: ResponsiveService,
        protected dialog: MatDialog,
        protected mobileStepper: MobileStepperService,
        protected activatedRoute: ActivatedRoute
    )
    {
        this.choice = 'new';
    }

    ngOnInit()
    {
        zip(this.activatedRoute.data, this.activatedRoute.params)
        .pipe(
            take(1),
            map((x: Array<any>) => {
                return x.reduce((carry, current) => {
                    return Object.assign(carry, current);
                }, {});
            }),
            mergeMap(x => {
                if(x.id){
                    return zip(of(x), this.tplService.getById(x.id))
                }
                return of([x]);
            })
            )
        .subscribe(
            (data: Array<any>) =>
            {
                this.tpl.is_tpl = data[0].isTemplate || false;
                if (this.tpl.is_tpl)
                {
                    this.classes = 'template-creation';
                    this.TIPS = TPL_DEF_TIPS;

                    if (this.responsive.IsMobile())
                        this.TIPS = TPL_DEF_TIPS_MBL;
                }

                if(data[1]){
                    this.tpl = data[1];
                    if(this.tpl.doc_count > 0){
                        const txt = this.translator.instant("Esta plantilla se ya se ha utilizado para firma documentos, algunas propiedades no son editables.");
                        this.toast.open(txt, 'Accept', 5000)
                    }
                }

            }
        )
    }

    ngAfterViewInit(): void
    {
        console.log(this.fileInput);
    }

    setChoice(evt: MatRadioChange)
    {
        this.choice = evt.value;
    }

    next()
    {
        this.cleanErrors();
        if (this.choice)
        {
            if (this.choice == 'existing' && !this.tpl.id)
            {
                this.toast.open('You have to select a document before continue', 'Accept', 5000);
                return;
            }
            if (this.tpl.is_tpl && this.tpl.description == '')
            {
                this.toast.open('The description field is mandatory', 'Accept', 5000);
                return;
            }
            const o = this.choice == 'existing' ? this.cloneTpl() : this.uploadNewTpl();
            if (o)
                o.subscribe(
                    r => {
                        if (this.tpl.is_tpl)
                            this.router.navigate(['template_management', 'configure', r.id]);
                        else
                            this.router.navigate(['send_assistant', 'template', r.id])
                    },
                    e => this.handleErrors(e)
                );
        }
        else
            this.toast.open('You have to choose a send method before continue', 'Accept');
    }

    protected cleanErrors()
    {
        this.docNameErr = '';
        this.inputFileErr = '';
    }

    cloneTpl()
    {
        const newTpl = { tplId: this.tpl.id, newName: this.tpl.name };

        return this.tplService.cloneTemplate(newTpl);
    }

    setSelectedTemplate($event)
    {
        this.choice = 'existing';
        this.baseTpl = $event;
        this.tpl = Object.assign({}, $event);
        this.tpl.name = this.tpl.name + ' - copia (cámbiame)';

        if (this.tpl.text_name && this.tpl.text_name.length > 0)
            this.mobileStepper.setSteps(5);
        else
            this.mobileStepper.setSteps(4);

    }

    protected uploadNewTpl()
    {
        const auxFileInput = this.fileInput.nativeElement;

        console.log('YEYEYYEYE');
        console.log(this.tpl.id);

        if (auxFileInput.files.length === 0 && this.tpl.id === null)
        {
            // if (!this.newAccordeonPanel._isExpanded()) {
            //     this.newAccordeonPanel._toggle();
            // }
            this.choice = 'new';
            this.radioNew.checked = true;

            if (!this.tpl.is_tpl)
                this.radioExisting.checked = false;

            this.inputFileErr = this.translator.instant('Please, choose a file to upload');
            return;
        }

        if(!(this.tpl.id)){
            this.tpl.id = undefined;
        }
        return this.tplService.createTemplate(this.tpl, auxFileInput.files[0]);
    }
    protected handleErrors(e)
    {
        console.error(e);
        if (e instanceof HttpErrorResponse)
        {
            if (undefined !== e.error.errors && undefined !== e.error.errors.name)
            {
                this.docNameErr = e.error.errors.name.join(' | ');
            }
            else if (e.error.message === 'File not normalizable')
            {
                this.inputFileErr = this.translator.instant('No hemos podido procesar este documento');
            }
            else
            {
                if (e.error.message)
                    this.toast.open(
                        e.error.message,
                        'Aceptar',
                        5000
                    );
                else
                    this.toast.open(
                        'error_while_upload_file',
                        'Aceptar',
                        5000
                    );
            }
        }
    }

    public preview(template: TemplateData): void
    {
        if (this.choice == 'existing')
            this.previewSelectedTpl(template);
        else if (this.choice == 'new')
            this.previewSelectedDoc();
    }
    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: this.TIPS
        });
    }
    public previewSelectedDoc(): void
    {
        const files = this.fileInput.nativeElement.files;
        console.log(this.fileInput.nativeElement);
        if (files && files.length > 0)
        {
            const filePath = URL.createObjectURL(files[0]);
            window.open(filePath, '_blank');
        }
        else
            this.toast.open(
                'You must select a file before the preview',
                'Accept',
                5000
            );
    }
    public previewSelectedTpl(template: TemplateData)
    {
        this.tplService.getTplPreview(template.id)
            .subscribe(
                r => this.blobOpener.open(r),
                (e: HttpErrorResponse) =>
                {
                    if (e.status === 404)
                    {
                        this.toast.open(
                            'The file was not found',
                            'Accept',
                            3000
                        );
                    }
                }
            );
    }
    public cancel()
    {
        if (this.tpl.is_tpl)
            this.router.navigate(['template_management/list']);
        else
            this.router.navigate(['document']);
    }
    public fileChange($event: any)
    {
        const files = $event.target.files;

        if (files && files.length > 0)
        {
            this.tpl.name = files[0].name.split('.')[0];
        }

        this.cleanErrors();
    }
}
