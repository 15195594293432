import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Bowser from 'bowser';
import { environment } from 'src/environments/environment';
import { localesDictionary } from './configs/languages.dictionary';
import { CrossPlatformDialogService } from './cross-platform-dialog/shared/cross-platform-dialog.service';
import { DialogStructure } from './cross-platform-dialog/shared/DialogStructure';
import { UseChrmPlzComponent } from './shared/use-chrm-plz/use-chrm-plz.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit
{

    // PROPIEDADES


    // CONSTRUCTOR
    constructor(
        private crossPlataformD: CrossPlatformDialogService,
        private translate: TranslateService
    )
    {

        this.translate.addLangs(localesDictionary.map(locale => locale.code));
        this.translate.setDefaultLang(environment.defaultLang);
        const browserLang = this.translate.getBrowserCultureLang();
        const availableLangs = this.translate.getLangs();

        if (availableLangs.indexOf(browserLang) != -1)
        {
            this.translate.use(browserLang);
        }
    }

    ngOnInit()
    {
        console.log('browserManagement');
        setTimeout(() =>
        {
            //this.browserManagement()
        }, 3000);

    }


    browserManagement()
    {

        const browser = Bowser.getParser(window.navigator.userAgent);

        // @ts-ignore
        const curretnBrowser = browser.parsedResult.browser.name;

        const browsers = [
            'Microsoft Edge',
            'Chrome'
        ];

        const found = browsers.find(x => curretnBrowser === x);

        if (!found)
        {
            const structure = {
                data: {
                    title: this.translate.instant('Warn!'),
                    positiveButton: this.translate.instant('Copy'),
                    countries: [],
                    locales: [],
                    data: {},
                    isEdit: false,
                    dynamycComponent: UseChrmPlzComponent,
                    hideCloseButton: false
                },
                height: '300px',
                width: '600px',
                disableClose: false
            } as DialogStructure<any>;
            this.crossPlataformD.open(structure).subscribe(x =>
            {

            });
        }
    }


    // MÉTODOS PÚBLICOS


    // MÉTODOS PRIVADOS
}
