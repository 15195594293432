import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';

@Injectable({
    providedIn: 'root'
})
export class SelectedGroupService
{
    private selectedNode: BehaviorSubject<ContactNode>;
    public selectedNode$: Observable<ContactNode>;

    constructor()
    {
        this.selectedNode = new BehaviorSubject(new ContactNode());
        this.selectedNode$ = this.selectedNode.asObservable();
    }

    public setSelectedNode(node: ContactNode): void
    {
        if (this.selectedNode.value.id != node.id)
            this.selectedNode.next(node);
    }
    public getSelectedGroup(): ContactNode
    {
        return this.selectedNode.value;
    }
}
