import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateGroupComponent } from './components/create-group/create-group.component';
import { ContactGroupEditorComponent } from './components/contact-group-editor/contact-group-editor.component';
import { GroupOfGroupsEditorComponent } from './components/group-of-groups-editor/group-of-groups-editor.component';
import { MaterialModule } from 'src/app/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupHierarchyPipe } from './pipes/group-hierarchy.pipe';
import { ContactsModule } from '../contacts/contacts.module';
import { GroupHierarchyListComponent } from './components/group-hierarchy-list/group-hierarchy-list.component';
import { GroupComponent } from './components/group/group.component';
import { GroupTreeComponent } from './components/group-hierarchy-list/components/group-tree/group-tree.component';
import { TipModule } from '../tip/tip.module';



@NgModule({
    declarations: [
        CreateGroupComponent,
        ContactGroupEditorComponent,
        GroupOfGroupsEditorComponent,
        GroupHierarchyPipe,
        GroupHierarchyListComponent,
        GroupComponent,
        GroupTreeComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        ContactsModule,
        TipModule
    ],
    exports: [
        CreateGroupComponent,
        ContactGroupEditorComponent,
        GroupOfGroupsEditorComponent,
        GroupHierarchyPipe,
        GroupHierarchyListComponent,
        GroupComponent
    ]
})
export class GroupsModule { }
