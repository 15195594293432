<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title" style="display: flex;align-items: center;padding-top: 4px;">{{ 'Fill the form' | translate}}</div>
    <app-editable-editor #editor></app-editable-editor>
    <div class="footer flex-row-between pad-b10">
        <button color="primary" mat-button (click)="previous()">
            {{ 'Back' | translate }}
        </button>

        <button color="primary" mat-raised-button (click)="continue()">
            {{ 'Next' | translate }}
        </button>
    </div>
</ng-container>

<!-- Desktop -->
<ng-template #desktop>
    <div class="help-col">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <div class="scroller">
            <app-tip *ngFor="let tip of TIPS; let i = index" [idx]="i" [tip]="tip"></app-tip>
        </div>
    </div>
    <div style="height: 100%; overflow: hidden;">
        <ng-container *ngIf="service.data$ | async as templateData">
            <app-editable-editor [templateId]="templateData.editablePdfId" #editor></app-editable-editor>
        </ng-container>
    </div>
    <div class="empty-col"></div>
    <div class="footer flex-row-between pad-b10">
        <button color="primary" mat-button (click)="previous()">
            {{ 'Back' | translate }}
        </button>

        <button color="primary" mat-raised-button (click)="continue()">
            {{ 'Next' | translate }}
        </button>
    </div>
</ng-template>