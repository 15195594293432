import { Contact } from './contact';

export class ContactNode
{
    public name: string;
    public children?: ContactNode[];
    public contacts?: Array<Contact>;
    public created_at: string;
    public parent_id: number;
    public type: 'group' | 'contacts';
    public id: number;
    public updated_at: string;
    public contact_count: number;

    constructor() {}
}