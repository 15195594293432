import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { AutoTableColumnDefinition } from 'src/app/shared/auto-table/definitions/auto-table-column-definition';
import { BaseTableAdapterService } from 'src/app/shared/auto-table/services/base-table-adapter.service';
import { PaginatorService } from 'src/app/shared/auto-table/services/paginator.service';
import { TenantService } from './tenant.service';

@Injectable({
    providedIn: 'root'
})
export class InvoncesTableAdapterService extends BaseTableAdapterService
{

    constructor(
        private tenantService: TenantService,
        public responsive: ResponsiveService,
        public paginator: PaginatorService
    )
    {
        super(responsive, paginator);
    }

    getStdCols(): Array<AutoTableColumnDefinition>
    {
        const cols = [
            {
                order: 2,
                column: 'concept',
                columnTitle: 'Concept',
                isSticky: false
            },
        ];
        const col = {
            order: 4,
            column: 'nav_id',
            columnTitle: 'Document No',
            isSticky: false
        };

        if (!this.responsive.IsMobile())
        {
            cols.push(col);
        }

        return cols;
    }

    getNonStdCols(): Array<AutoTableColumnDefinition>
    {
        const col = {
            order: 1,
            column: 'created_at',
            columnTitle: 'Date',
            isSticky: false
        };

        const cols = [
            {
                order: 3,
                column: 'amount',
                columnTitle: 'Amount',
                isSticky: false,
                aligment: 'right',
                paddingRight: '15px'
            },
            {
                order: 5,
                column: 'download',
                columnTitle: 'Download document',
                isSticky: false
            }
        ];

        if (!this.responsive.IsMobile())
            cols.push(col);

        return cols;
    }

    getAllColumnsName()
    {
        const cols = this.getStdCols()
            .concat(this.getNonStdCols())
            .sort((a, b) =>
            {
                return Number(a.order) - Number(b.order)
            })
            .map(c =>
            {
                return c.column;
            });


        return cols;
    }

    getLaravelPaginatedList()
    {
        return this.tenantService.getInvoicesList(this.currentPage);
    }

    downloadAttachment(row, val1, val2)
    {
        this.tenantService.downloadAndOpen(row);
    }

    warmUp(): Observable<boolean>
    {
        this.getData();
        return this.data
            .pipe(
                delay(100),
                map(x => true)
            );
    }

}
