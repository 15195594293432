export class TemplateData
{
    public description: string;
    public contact_count: number;
    public created_at: string;
    public has_pdf: boolean;
    public id: number;
    public mail_text: string;
    public name: string;
    public pageCount: number;
    public pageHeight: number;
    public sign_welcome_text: string;
    public sms_text: string;
    public status: number;
    public totalHeight: number;
    public updated_at: string;
    public uuid: string;
    public width: number;
    public text_count: number;
    public text_name: Array<string>;
    public is_tpl: boolean;
    public is_public: boolean;
    public doc_count: number;
    public is_form: number;
    public form_id: number;


    constructor ()
    {
        this.description = '';
        this.contact_count = 1;
        this.created_at = null;
        this.has_pdf = true;
        this.id = null;
        this.mail_text = '';
        this.name = null;
        this.pageCount = null;
        this.pageHeight = null;
        this.sign_welcome_text = '';
        this.sms_text = null;
        this.status = null;
        this.totalHeight = null;
        this.updated_at = null;
        this.uuid = null;
        this.width = null;
        this.is_tpl = false;
        this.is_public = true;
        this.doc_count = 0;
        this.is_form = 0;
        this.form_id = null;
    }
}
