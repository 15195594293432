import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
    providedIn: 'root'
})

export class MatPaginatorIn18Service extends MatPaginatorIntl
{

    public itemsPerPageLabel = '';
    public nextPageLabel     = '';
    public previousPageLabel = '';

    constructor(
        private translator: TranslateService
    )
    {
        super();

        this.translator.onLangChange.subscribe(lang =>
        {
            this.itemsPerPageLabel = translator.instant('Per page elements');
            this.nextPageLabel = translator.instant('Next page');
            this.previousPageLabel = translator.instant('Previous page');
        });
    }

    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };

}
