import { ArrayDataSource } from '@angular/cdk/collections';
import { ContactNode } from '../models/contacts/contact-node.model';

export class GroupArrayDataSource extends ArrayDataSource<ContactNode>
{
    private _rawData: Array<ContactNode>;
    constructor(structure: Array<ContactNode>)
    {
        super(structure);
        this._rawData = structure;
    }

    public rawData(): Array<ContactNode>
    {
        return this._rawData;
    }
}