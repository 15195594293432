<div class="ribborn">
    <div class="controls">
        <button
            (click)="previousPage()"
            [disabled]="totalPages == 1"
            mat-stroked-button
        >
            <ng-container
                *ngIf="!(responsive.isMobile$ | async); else mobileLeftBtn"
                >{{ "Previous page" | translate }}</ng-container
            >
            <ng-template #mobileLeftBtn>
                <mat-icon>arrow_left</mat-icon>
            </ng-template>
        </button>
        <span class="mar-l10"> {{ currentPage }} / {{ totalPages }} </span>
        <button
            (click)="nextPage()"
            [disabled]="totalPages == 1"
            mat-stroked-button
            class="mar-l10"
        >
            <ng-container
                *ngIf="!(responsive.isMobile$ | async); else mobileRightBtn"
                >{{ "Next page" | translate }}</ng-container
            >
            <ng-template #mobileRightBtn>
                <mat-icon>arrow_right</mat-icon>
            </ng-template>
        </button>
    </div>
    <div class="controls" style="justify-content: flex-end;">
        <button color="primary" mat-mini-fab (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <button color="primary" mat-mini-fab class="mar-l10" (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </button>
    </div>
</div>
<div
    *ngIf="!(responsive.isMobile$ | async); else mobile"
    class="pdf-container"
    cdk-scrollable
    [ngStyle]="{
        'justify-content': (zoom$ | async) <= 1.25 ? 'center' : 'flex-start'
    }"
>
    <ng-container *ngTemplateOutlet="pdfViewerTemplate"></ng-container>
</div>

<ng-template #mobile>
    <div class="pdf-container" cdk-scrollable>
        <ng-container *ngTemplateOutlet="pdfViewerTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #pdfViewerTemplate>
    <div class="form-container">
        <form id="input-form" [formGroup]="myForm" novalidate>
            <!-- Cycle through all the inputs -->
            <div
                *ngFor="let input of inputList; let i = index"
                [ngSwitch]="input.type"
            >
                <div
                    style="position: absolute;"
                    [style.border]="
                        myForm.controls[input.name].errors
                            ? '2px solid red'
                            : 'none'
                    "
                    [ngStyle]="getInputPosition(input)"
                >
                    <input
                        *ngSwitchCase="'checkbox'"
                        [name]="input.name"
                        type="checkbox"
                        [formControlName]="input.name"
                        [value]="input.value"
                        (change)="checkboxChange(input)"
                    />

                    <input
                        *ngSwitchCase="'radio'"
                        [name]="input.name"
                        type="radio"
                        [formControlName]="input.name"
                        [value]="input.value"
                    />

                    <select
                        *ngSwitchCase="'select'"
                        [name]="input.name"
                        [formControlName]="input.name"
                        [value]="input.value"
                    >
                        <option
                            *ngFor="let opt of input.options"
                            [value]="opt.exportValue"
                        >
                            {{ opt.displayValue }}
                        </option>
                    </select>

                    <select
                        *ngSwitchCase="'list'"
                        [name]="input.name"
                        [formControlName]="input.name"
                        [value]="input.value"
                        multiple
                    >
                        <option
                            *ngFor="let opt of input.options"
                            [value]="opt.exportValue"
                        >
                            {{ opt.displayValue }}
                        </option>
                    </select>

                    <textarea
                        *ngSwitchCase="'text_area'"
                        [name]="input.name"
                        [formControlName]="input.name"
                        [value]="input.value"
                    >
                    </textarea>

                    <!-- Insert an input accordingly and get the style/position from the component -->
                    <input
                        *ngSwitchDefault
                        [name]="input.name"
                        type="text"
                        [formControlName]="input.name"
                        [value]="input.value"
                    />
                    <!-- <mat-error
                        style="position: relative; font-size: 14px; top: 23px"
                        *ngIf="myForm.controls[input.name].errors"
                    >
                        {{ "Not a valid value" | translate }}
                    </mat-error> -->
                </div>
            </div>
        </form>
    </div>
    <pdf-viewer
        [render-text]="false"
        [show-all]="false"
        [page]="currentPage"
        [zoom]="zoom$ | async"
        [src]="src || (editor.pdf$ | async)"
        (after-load-complete)="loadComplete($event)"
    >
    </pdf-viewer>
</ng-template>
<!-- [src]="'/spa/assets/pdfs/pdftest.pdf'" -->
