import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContactsManagementComponent } from './contacts-management.component';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { TipModule } from 'src/app/content/tip/tip.module';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ContactsRoutingModule } from './contacts-routing.module';
import { GroupsModule } from 'src/app/content/groups/groups.module';
import { ContactsModule } from 'src/app/content/contacts/contacts.module';

const routes: Route[] = [
    { path: '', component: ContactsManagementComponent }
];

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        UserMenuModule,
        ReactiveFormsModule,
        TipModule,
        ResponsiveTopbarModule,
        ContactsRoutingModule,
        GroupsModule,
        ContactsModule
    ],
    declarations: [
        ContactsManagementComponent,
    ]
})
export class ContactsManagementModule { }
