import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentDefinitionComponent } from './components/document-definition/document-definition.component';
import { SendAssistantComponent } from './send-assistant.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { TemplateEditorGuard } from 'src/app/core/guards/template-editor.guard';
import { ContactDefinitionComponent } from './components/contact-definition/contact-definition.component';
import { ContactDefinitionGuard } from 'src/app/core/guards/contact-definition.guard';
import { TextDefinitionComponent } from './components/text-definition/text-definition.component';
import { TextDefinitionGuard } from 'src/app/core/guards/text-definition.guard';
import { ArbitraryTextDefinitionComponent } from './components/arbitrary-text-definition/arbitrary-text-definition.component';
import { ArbitraryTextGuard } from 'src/app/core/guards/arbitrary-text.guard';
import { BylinkComponent } from './components/bylink/bylink.component';
import { BylinkGuard } from 'src/app/core/guards/bylink.guard';

const routes: Routes = [
    {
        path: '',
        component: SendAssistantComponent,
        children: [
            {
                path: 'file',
                component: DocumentDefinitionComponent
            },
            {
                path: 'template/:id',
                component: TemplateEditorComponent,
                canActivate: [TemplateEditorGuard]
            },
            {
                path: 'contacts/:id',
                component: ContactDefinitionComponent,
                canActivate: [ContactDefinitionGuard]
            },
            {
                path: 'arbitrarytext/:tpl_id',
                component: ArbitraryTextDefinitionComponent,
                canActivate: [ArbitraryTextGuard]
            },
            {
                path: 'text',
                component: TextDefinitionComponent,
                canActivate: [TextDefinitionGuard]
            },
            {
                path: 'bylink',
                component: BylinkComponent,
                canActivate: [BylinkGuard]
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SendAssistantRoutingModule { }
