import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';

@Component({
    selector: 'subtenant-list-product-resume',
    template: `
        <div>
            <b>{{'Transfer' | translate}}:</b>
            <ng-container [ngSwitch]="field"]>
                <span *ngSwitchCase="'documents'">
                    {{ data.doc_transferred_in - data.doc_transferred_out }}
                </span>
                <span *ngSwitchCase="'whatsapp'">
                    {{ data.whats_transferred_in - data.whats_transferred_out }}
                </span>
                <span *ngSwitchCase="'sms'">
                    {{ data.sms_transferred_in - data.sms_transferred_out }}
                </span>
            </ng-container>
        </div>
        <div>
            <b>{{'Buy_short' | translate}}:</b>
            <ng-container [ngSwitch]="field"]>
                <span *ngSwitchCase="'documents'">
                    {{ data.doc_bougth }}
                </span>
                <span *ngSwitchCase="'whatsapp'">
                    {{ data.whats_bougth }}
                </span>
                <span *ngSwitchCase="'sms'">
                    {{ data.sms_bougth }}
                </span>
            </ng-container>
        </div>
        <div>
            <b>{{'Spent' | translate}}:</b>
            <ng-container [ngSwitch]="field"]>
                <span *ngSwitchCase="'documents'">
                    {{ data.doc_spent }}
                </span>
                <span *ngSwitchCase="'whatsapp'">
                    {{ data.whats_spent }}
                </span>
                <span *ngSwitchCase="'sms'">
                    {{ data.sms_spent }}
                </span>
            </ng-container>
        </div>
        <div>
            <b>{{'Balance' | translate}}:</b>
            <ng-container [ngSwitch]="field"]>
                <span *ngSwitchCase="'documents'">
                    {{ data.doc_bougth + data.doc_transferred_in - data.doc_transferred_out - data.doc_spent }}
                </span>
                <span *ngSwitchCase="'whatsapp'">
                    {{ data.whats_bougth + data.whats_transferred_in - data.whats_transferred_out - data.whats_spent }}
                </span>
                <span *ngSwitchCase="'sms'">
                    {{ data.sms_bougth + data.sms_transferred_in - data.sms_transferred_out - data.sms_spent }}
                </span>
            </ng-container>
        </div>
  `,
    styleUrls: ['subtenant-list-product-resume.component.scss']
})
export class SubtenantListProductResumeComponent implements AgRendererComponent
{
    public data: Tenant;
    public field: string;

    constructor() { }

    agInit(params: ICellRendererParams): void
    {
        this.data = params.data;
        this.field = params.colDef.field;
    }

    refresh(params: ICellRendererParams): boolean
    {
        return false;
    }
}
