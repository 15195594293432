import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {MsgBoxConfig} from './message-box/MsgBoxConfig'
import {MessageBoxComponent} from './message-box/message-box.component';

@Injectable({
    providedIn: 'root'
})
export class MessageBoxService
{
    constructor(public dialog: MatDialog)
    {
    }

    public open(conf: MsgBoxConfig): Observable<boolean>
    {
        return new Observable(o =>
        {
            this.dialog
                .open(MessageBoxComponent, conf)
                .afterClosed()
                .subscribe(r =>
                {
                    if (r === true)
                    {
                        o.next(r);
                    } else
                    {
                        o.error(r);
                    }
                    o.complete();
                });
        });
    }

    public instant(conf: MsgBoxConfig)
    {
        this.open(conf).subscribe(r =>
        {
        }, e =>
        {
        }, () =>
        {
        });
    }
}
