import { Pipe, PipeTransform } from '@angular/core';
import { TemplateOutputDragged } from 'src/app/core/models/api/template/template-output.model';
import { Template } from 'src/app/core/models/api/template/template.model';

@Pipe({
    name: 'outputFilter'
})
export class OutputFilterPipe implements PipeTransform
{

    transform(value: Template): Array<TemplateOutputDragged>
    {
        return value.outputs[0].dragged
            .filter(x => x.meta.output_type != 'aditional_doc');
    }

}
