import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { CreateGroupComponent } from '../create-group/create-group.component';
import { GROUP_DIAG_HEIGHT, GROUP_DIAG_WIDTH } from 'src/app/configs/constants/group-dialogs.constants';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { FormControl, Validators } from '@angular/forms';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { GROUP_OF_GROUPS_EDIT_TIPS } from 'src/app/configs/dicctionaries/groups/group-management.dictionary';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';

@Component({
    selector: 'app-group-of-groups-editor',
    templateUrl: './group-of-groups-editor.component.html',
    styleUrls: ['./group-of-groups-editor.component.scss']
})
export class GroupOfGroupsEditorComponent implements OnInit
{
    @HostBinding('class') class = 'mat-typography';
    public isEdit = false;
    public name: FormControl;
    public tips = GROUP_OF_GROUPS_EDIT_TIPS;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ContactNode,
        public dialog: MatDialog,
        private service: ConstactsService,
        private toast: I18nToastService,
        public dialogRef: MatDialogRef<GroupOfGroupsEditorComponent>,
        private translator: TranslateService,
        public responsive: ResponsiveService
    ) { }

    ngOnInit(): void
    {
        this.name = new FormControl(
            this.data.name,
            Validators.required);
    }

    public addGroup(): void
    {
        const isMobile = this.responsive.IsMobile();
        this.dialog.open(CreateGroupComponent, {
            panelClass: 'extended_diag_mbl',
            width: isMobile ? '99%' : GROUP_DIAG_WIDTH,
            height: isMobile ? '85%': GROUP_DIAG_HEIGHT,
            data: this.data.id
        })
        .afterClosed()
        .subscribe( x =>
            {
                if (x)
                {
                    this.service.createGroup(x)
                    .subscribe(
                        newGroup=>
                        {
                            this.data.children.push(newGroup);
                        }
                    );
                }
            }
        );
    }
    public editGroupName(): void
    {
        // Si el estado es edit, hay que verificar y guardar, de lo contrario, es togle.
        if (this.isEdit)
        {
            const isNotRepeated = this.service.validateGroupName(this.name.value, this.data.id);
            if (this.name.valid && isNotRepeated)
            {
                this.isEdit = !this.isEdit;
            }
            else
            {
                this.toast.open('You have to introduce a valid name', 'Accept', 7000);
            }
        }
        else
            this.isEdit = !this.isEdit;
    }
    public save(): void
    {
        const isNotRepeated = this.service.validateGroupName(this.name.value, this.data.id);
        if (this.data.name != this.name.value && this.name.valid && isNotRepeated)
        {
            // Asignamos para que se haga el update.
            this.data.name = this.name.value;
            this.dialogRef.close('save');
        }
        else
            this.dialogRef.close();
    }

    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: this.tips
        });
    }
}
