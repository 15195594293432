<div class="title">
    {{ 'Choose a name for the document' | translate }}
</div>
<div>
    <mat-form-field class="w100">
        <mat-label>{{ 'Document name' | translate }}</mat-label>
        <input matInput type="text" [formControl]="name" name="{{ 'Document name' | translate }}">
    </mat-form-field>
</div>
<div class="footer">
    <button mat-button color="warn" mat-dialog-close>
        {{ 'Cancel' | translate}}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="name.value">
        {{ 'Accept' | translate}}
    </button>
</div>