import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SubtenantListComponent } from '../subtenant-list.component';

@Component({
    selector: 'subtenant-list-actions-component',
    template: `
         <a mat-icon-button [routerLink]="['/subtenant_management/edit', uuid]">
             <mat-icon>edit</mat-icon>
         </a>
         <a mat-icon-button class="transfer" (click)="transfer()">
             <mat-icon>swap_horiz</mat-icon>
         </a>
         <a mat-icon-button (click)="unlink()">
             <mat-icon>link_off</mat-icon>
         </a>
   `,
    styleUrls: ['./subtenant-list-actions.component.scss']
})
export class SubtenantListActionsComponent implements AgRendererComponent
{
    public uuid: string = '';
    private params?: ICellRendererParams;

    agInit(params: ICellRendererParams): void
    {
        this.uuid = params.data.uuid;
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean
    {
        return false;
    }

    public transfer(): void
    {
        if (this.params?.context.componentParent instanceof SubtenantListComponent)
        {
            const parent = this.params?.context.componentParent as SubtenantListComponent;
            parent.transferCredit(this.uuid);
        }
        else
            throw new Error(`La instancia del contexto para el cell render del
                subtenant-list-actions.component.ts no es del tipo SubtenantListComponent.
                Asegurate que estas usando el render correcto`);
    }
    public unlink(): void
    {
        if (this.params?.context.componentParent instanceof SubtenantListComponent)
        {
            const parent = this.params?.context.componentParent as SubtenantListComponent;
            parent.unlinkSubtenant(this.uuid);
        }
        else
            throw new Error(`La instancia del contexto para el cell render del
                subtenant-list-actions.component.ts no es del tipo SubtenantListComponent.
                Asegurate que estas usando el render correcto`);
    }
}