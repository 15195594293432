import { Injectable } from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import { TwoFactorAuthRepositoryService } from 'src/app/core/repositories/pages/two-factor-auth/two-factor-auth-repository.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthService {

  public tfAuthStatus$: ReplaySubject<boolean>;

  constructor(
    private repository: TwoFactorAuthRepositoryService
  ){
    this.tfAuthStatus$ = new ReplaySubject<boolean>();
    this.getTwoFactorAuthStatus();
  }

  public getTwoFactorAuthStatus():void{

    this.repository.getTwoFactorAuthStatus()
        .subscribe(
          (data) => {
            this.tfAuthStatus$.next(data.status == 1);
          }
        )
  }

  public getTwoFactorAuthStatusInUser():Observable<{status: number}>{
    return this.repository.getTwoFactorAuthStatusInUser();
  }

  public getQRCode():Observable<{qr_code: string, secret_code: string}>{
    return this.repository.getQRCode();
  }

  public linkTwoFactorAuth(otpCode, secretCode): Observable<any>{
    return this.repository.linkTwoFactorAuth(otpCode, secretCode);
  }

  public unlinkTwoFactorAuth(){
    return this.repository.unlinkTwoFactorAuth();
  }
}
