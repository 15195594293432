import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { SubtenantInvoicesState } from "./state";

export const getSubtenantInvoicesError = (state: SubtenantInvoicesState) => state.errorMsg;
export const getIsLoading = (state: SubtenantInvoicesState): boolean => state.isLoading;

export const selectSubtenantInvoicesState: MemoizedSelector<
    object,
    SubtenantInvoicesState
> = createFeatureSelector<SubtenantInvoicesState>('subtenantInvoices');

export const selectSubtenantInvoicesError: MemoizedSelector<
    object,
    any
> = createSelector(selectSubtenantInvoicesState, getSubtenantInvoicesError);

export const selectSubtenantInvoicesIsLoading: MemoizedSelector<
    object,
    boolean
> = createSelector(
    selectSubtenantInvoicesState,
    getIsLoading
);
