import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

import {GeoNamesService} from 'src/app/shared/geo-names.service';
import {UserModel} from 'src/app/shared/Models/UserModel';
import {iDialogBase} from 'src/app/cross-platform-dialog/shared/iDialogBase';
import {UserManagementService} from '../shared/user-management.service';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Tip} from 'src/app/core/models/tip';
import {localesDictionary} from 'src/app/configs/languages.dictionary';
import {environment} from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { Contact } from 'src/app/core/models/contacts/contact';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';
import {CustomTextConf} from '../../../core/models/contacts/custom-text-conf.model';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';

@Component({
    selector: 'app-user-form-data',
    templateUrl: './user-form-data.component.html',
    styleUrls: ['./user-form-data.component.scss']
})
export class UserFormDataComponent implements OnInit, iDialogBase {
    public locales: Array<any>;
    public countries: Array<any>;
    public region: Array<any>;
    public towns: Array<any>;
    public errors = {};
    public formGroup: FormGroup;
    public contactFormGroup: FormGroup;
    // #region Interface Opts.
    Array: any = Array;
    public binding: EventEmitter<boolean>;
    public localesDictionary = localesDictionary;
    public passwordTip: Tip = {
        title: 'Passwords',
        body: 'contact_password'
    };

    public custoTextConf$ :Observable<Array<CustomTextConf>>;

    public roleTip: Tip = {
        title: 'Roles',
        body: `<p>
            ${this.translator.instant('role_tip_part_1')}
            <ul style="padding-left: 18px">
                <li>
                    ${this.translator.instant('role_tip_part_2')}
                </li>
                <li>
                    ${this.translator.instant('role_tip_part_3')}
                </li>
                <li>
                    ${this.translator.instant('role_tip_part_4')}
                </li>
            </ul>
        </p>`
    };
    private sub_region;

    private errs = {
        '*': {
            pattern: 'valor no valido',
            required: 'campo requerido'
        }
    };

    constructor(
        private geoNames: GeoNamesService,
        private userManagement: UserManagementService,
        private toast: MatSnackBar,
        private translator: TranslateService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: UserFormComponentParam,
        public dialogRef: MatDialogRef<UserFormDataComponent>,
        public currentUser: UserMenuService,
        public responsive: ResponsiveService,
        private dialog: MatDialog,
        private customTextService: ConstactsService
    ) {
        this.binding = new EventEmitter();

        // tslint:disable-next-line:max-line-length
        const emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const mailValidator = Validators.pattern(emailregex);

        this.custoTextConf$ = this.customTextService.getCustomTextStatus().pipe(
            map((arr) => {
                return arr.customText.filter(c => {
                        return c.flag_value > 0;
                    }
                )
            })
        );

        const cnf = new Contact();

        // @ts-ignore
        cnf.id = '';
        // @ts-ignore
        cnf.customer_legal_identifier = [null, [Validators.required]];
        // @ts-ignore
        cnf.name = [null, [Validators.required]];
        // @ts-ignore
        cnf.surname_1 = [null, [Validators.required]];
        // @ts-ignore
        cnf.surname_2 = '';
        cnf.company = '';
        cnf.personal_email = '';
        // @ts-ignore
        cnf.corporate_email = [null, [Validators.required, mailValidator]];
        cnf.mobile_phone = '';
        cnf.address = '';
        cnf.postal_code = '';
        cnf.city = '';
        cnf.region = '';
        cnf.country = '';
        cnf.custom_field1 = '';
        cnf.company = '';
        cnf.locale = environment.defaultLang;

        const cntactFrmGrp = formBuilder.group(cnf);

        this.formGroup = formBuilder.group({
            id: null,
            status_id: '',
            name: [null, [Validators.required]],
            email: [null, [Validators.required]],
            surname: [null, [Validators.required]],
            rol: [null, []],
            created_at: '',
            updated_at: '',
            country: '',
            region: '',
            city: '',
            address: '',
            locale: [null, Validators.required],
            contact: cntactFrmGrp
        });

        this.contactFormGroup = this.formGroup.controls.contact as FormGroup;

        this.formGroup.statusChanges
            .pipe(filter(x => x === 'INVALID'))
            .subscribe(x => {
                const fg = this.formGroup;
                this.checkErrors(fg);
            });
    }

    private checkErrors(fg: FormGroup): void
    {
        Object.keys(fg.controls).forEach(inputName => {
            const currentControl = fg.controls[inputName] as FormGroup;

            if (currentControl instanceof FormGroup)
            {
                this.checkErrors(currentControl);
            }
            else
            {
               this.checkErrorsOnFormGroup(fg, inputName);
            }
        });
    }

    private checkErrorsOnFormGroup(fg: FormGroup, inputName: string): void
    {
        const errors = fg.controls[inputName].errors || {};

        if (Array.isArray(errors) && errors.length > 0) {
            return;
        }

        const newErrs = [];
        Object.keys(errors).forEach(validatorName => {
            if (undefined !== (this.errs[inputName] || {})[validatorName]) {
                newErrs.push(this.errs[inputName][validatorName]);
            } else if (this.errs['*'][validatorName]) {
                newErrs.push(this.errs['*'][validatorName]);
            }
        });
        if (newErrs.length > 0) {
           fg.controls[inputName].setErrors(newErrs);
        }
    }

    public setData(data: any) {
        this.data = data;
        this.data.data = Object.assign({}, this.data.data);
        this.countries = this.data.countries;
        this.locales = this.data.locales;

        this.initSelectsIfTheresData();
    }

    // endregion

    public action(): void {


        this.EditOrCreate(this.formGroup.value);
    }

    ngOnInit() {
        const base = {
            id: null,
            status_id: null,
            name: null,
            email: null,
            surname: null,
            rol: null,
            created_at: null,
            updated_at: null,
            country: null,
            region: null,
            city: null,
            address: null,
            locale: null
        };
        this.configureLangForForm();

        const contact = this.data.data.contact;
        //delete this.data.user.contact;
        this.formGroup.patchValue(Object.assign(base, this.data.data));
        //this.formGroup.get('contact').patchValue(contact);
        this.countries = this.data.countries;
        this.locales = this.data.locales;

        this.initSelectsIfTheresData();
    }

    public onCountryChange(event: MatSelectChange) {
        return new Observable(o => {
            this.data.data.country = event.value;
            this.geoNames.loadRegions(this.data.data.country).subscribe(r => {
                this.region = r;
                o.complete();
            });
        });
    }

    public onRegionChange(event: MatSelectChange): Observable<any> {
        return new Observable(o => {

            const aux = this.region.filter(x => {
                return x.data.admin2_code === event.value;
            });

            this.data.data.region = aux[0].data.admin2_code;
            this.sub_region = aux[0].data.admin1_code;

            this.geoNames
                .loadTowns(
                    this.data.data.country,
                    this.sub_region,
                    this.data.data.region
                )
                .subscribe(
                    r => {
                        this.towns = r;
                    },
                    e => {
                    },
                    () => {
                        o.complete();
                    }
                );
        });
    }

    public initSelectsIfTheresData() {
        if (this.data.data.country && this.data.data.country.length > 0) {
            const e = new MatSelectChange(null, this.data.data.country);
            this.onCountryChange(e).subscribe(
                () => {
                },
                () => {
                },
                () => {
                    if (this.data.data.region.length > 0) {
                        const e2 = new MatSelectChange(
                            null,
                            this.data.data.region
                        );
                        this.onRegionChange(e2).subscribe(
                            () => {
                            },
                            () => {
                            },
                            () => {
                            }
                        );
                    }
                }
            );
        }
    }
    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: [this.roleTip, this.passwordTip]
        });
    }
    public EditOrCreate(userModel: UserModel): void {

        if (userModel.id === null) {
            delete userModel.id;
        }

        userModel.name = userModel.contact.name;
        userModel.surname = [userModel.contact.surname_1, userModel.contact.surname_2].join(' ').trim();
        userModel.email = userModel.contact.corporate_email;
        userModel.locale = userModel.contact.locale;


        this.userManagement.post(userModel).subscribe(r => {
                this.dialogRef.close(true);
                this.currentUser.whoAmI();
            },
            e => {
                console.log(e);

                const errors = e.error.errors;
                this.setErrors(errors, this.formGroup.controls);
                //this.dialogRef.close(false);
            });
    }

    private setErrors(errors, controls) {
        Object.keys(errors).forEach(k => {
            const currentErrors = errors[k];
            const control = controls[k];
            if (Array.isArray(currentErrors)) {
                control.setErrors(currentErrors);
            } else {
                this.setErrors(currentErrors, control.controls);
            }
        });
    }

    private configureLangForForm(): void {
        const availableLangs = this.translator.getLangs();

        if (availableLangs.indexOf(this.data.data.locale) == -1)
            this.data.data.locale = environment.defaultLang;
    }
}


export interface UserFormComponentParam extends DialogData<UserModel>
{
    roles$: ReplaySubject<Array<string>>;
}
