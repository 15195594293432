import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SubtenantListStoreModule } from './subtenant-list-store/subtenant-list-store.module';
import { GeonamesStoreModule } from './geonames-store';
import { SubtenantEditStoreModule } from './subtenant-edit-store';
import { SubtenantInvoicesStoreModule } from './subtenant-invoices-store';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({ maxAge: 25 }),
        SubtenantListStoreModule,
        SubtenantEditStoreModule,
        SubtenantInvoicesStoreModule,
        GeonamesStoreModule
    ]
})
export class RootStoreModule { }
