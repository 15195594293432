import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TemplateEditorService } from '../services/pages/template-editor.service';
import { I18nToastService } from '../services/i18n-toast.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SignatureEditorGuard implements CanActivate
{
    constructor (
        private template: TemplateEditorService,
        private toast: I18nToastService,
        private router: Router
    )
    {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        if (next.queryParams.id && next.queryParams.editablePdfId)
        {
            // Antes de lanzar la req, comprobamos si es preview.
            //this.checkIfPreview(next);

            // Lanzamos el load y evaluamos el resultado
            return this.template.load(next.queryParams.id, next.queryParams.editablePdfId)
            .pipe(
                map(data =>
                {
                    if (data == undefined)
                        return false;
                    else
                        return true;
                }),
                catchError(err =>
                {
                    this.toast.open(
                        'The template was not found, if the error persists, contact with support',
                        'Accept'
                    );

                    return of(true);
                })
            );
        }
        else
        {
            return true;
        }
    }

    // private checkIfPreview(next: ActivatedRouteSnapshot): void
    // {
    //     this.template.setIsPreview(false);

    //     if(next.queryParams.isPreview) {
    //         this.template.setIsPreview(true);
    //     }

    //     if(next.params.FLAG_ONLY_EDIT){
    //         this.template.setIsPreview(true);
    //     }

    // }
}
