import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialTableModule } from 'src/app/material-table/material-table.module';
import { MaterialModule } from 'src/app/material/material.module';
import { AutoTableModule } from 'src/app/shared/auto-table/auto-table.module';
import { TenantManagementComponent } from './tenant-management.component';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { TenantManagementRoutingModule } from './tenant-management-routing.module';
import { ApiKeysTableComponent } from './components/api-keys-table/api-keys-table.component';
import { BuyAlertComponent } from './components/buy-alert/buy-alert.component';
import { InvoicesTableComponent } from './components/invoices/invoices-table/invoices-table.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { TemplateTableComponent } from './components/template-table/template-table.component';
import { TenantUpdateComponent } from './components/tenant-update/tenant-update.component';
import { TipModule } from 'src/app/content/tip/tip.module';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { ApiKeyFormComponent } from '../user-management/api-key-form/api-key-form.component';
import { BrowserStack } from 'protractor/built/driverProviders';
import { BrowserModule } from '@angular/platform-browser';
import { CustomizableMailFooterComponent } from './components/tenant-update/components/customizable-mail-footer/customizable-mail-footer.component';
import { FirstTwoLettersPipe } from './components/tenant-update/components/customizable-mail-footer/pipes/first-two-letters.pipe';
import { BuyPackagesCarrouselComponent } from './components/invoices/components/buy-packages-carrousel/buy-packages-carrousel.component';
import { ToFloatPipe } from './components/invoices/pipes/to-float.pipe';

export function createTranslateLoader(http: HttpClient)
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        InvoicesComponent,
        TenantUpdateComponent,
        BuyAlertComponent,
        InvoicesTableComponent,
        ApiKeyFormComponent,
        ApiKeysTableComponent,
        TemplateTableComponent,
        TenantManagementComponent,
        CustomizableMailFooterComponent,
        FirstTwoLettersPipe,
        BuyPackagesCarrouselComponent,
        ToFloatPipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        UserMenuModule,
        MaterialTableModule,
        AutoTableModule,
        TipModule,
        ResponsiveTopbarModule,
        TenantManagementRoutingModule,
    ],
    exports: [
        ApiKeysTableComponent
    ],
    entryComponents: [BuyAlertComponent, ApiKeyFormComponent]
})
export class TenantManagementModule { }
