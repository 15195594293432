<div class="title" fxLayout="row">
    {{ "Bought confirmation" | translate }}
</div>
<div *ngIf="this.tenant$ | async as t" style="margin-top: 1em">
    <p
        *ngIf="t.country_id != 29"
        translate="{{
            data.pack.type_id === 0
                ? 'bought description'
                : data.pack.type_id == 1
                ? 'bought description whatsapp'
                : 'bought description sms'
        }}"
        [translateParams]="{
            unities: data.pack.units_included,
            money: data.pack.amount,
            currency: data.pack.currency,
            digits: data.tenant.last_four_digits
        }"
    ></p>

    <p
        *ngIf="t.country_id == 29"
        translate="{{
            data.pack.type_id === 0
                ? 'bought description no vat'
                : data.pack.type_id == 1
                ? 'bought description  no vat whatsapp'
                : 'bought description  no vat sms'
        }}"
        [translateParams]="{
            unities: data.pack.units_included,
            money:
                (data.pack.amount / (1 + parseFloat(data.pack.included_vat))) *
                1,
            currency: data.pack.currency,
            digits: data.tenant.last_four_digits
        }"
    ></p>
</div>

<div translate="Your bill will be available soon"></div>

<div style="margin-top: 1em" translate="thanks for trusting MyRubric."></div>
<div></div>
<div class="flex-row-between" style="margin-top: 1em">
    <button mat-stroked-button [mat-dialog-close]="false" color="danger">
        {{ "cancel" | translate }}
    </button>
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
        {{ "accept" | translate }}
    </button>
</div>
