<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title">{{ 'Prepare the template' | translate}}</div>
    <app-editor [signatureOnly]="true"></app-editor>
    <div class="footer flex-row-between pad-b10">
        <button color="primary" mat-button [routerLink]="'/editable_pdf/list'">
            {{ 'Back' | translate }}
        </button>

        <button *ngIf="!(pdfControls.isPreview$ | async)" color="primary" mat-raised-button (click)="continue()">
            {{ 'Next' | translate }}
        </button>
    </div>
</ng-container>

<!-- Desktop -->
<ng-template #desktop>
    <div class="help-col">
        <ng-container *ngIf="!(pdfControls.isPreview$ | async)">
            <div class="help-title">
                <mat-icon class="mar-r10 primary-color">help</mat-icon>
                <span class="subtitle">{{ 'Help' | translate }}</span>
            </div>
            <div class="scroller">
                <app-tip *ngFor="let tip of TIPS; let i = index" [idx]="i" [tip]="tip"></app-tip>
            </div>
        </ng-container>
    </div>
    <app-editor [signatureOnly]="true"></app-editor>
    <div class="empty-col"></div>
    <div class="footer flex-row-between pad-b10">
        <button color="primary" mat-button [routerLink]="'/editable_pdf/list_unattended'">
            {{ 'Back' | translate }}
        </button>
        <div>
            <button style="margin-right: 12px;" [disabled]="!(pdfControls.pdf$ | async)" *ngIf="!(pdfControls.isPreview$ | async)" color="primary" mat-raised-button (click)="continueButBack()">
                {{ 'Save and close' | translate }}
            </button>
        </div>
    </div>
</ng-template>