import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebServiceHelperService } from '../../../shared/web-service-helper.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TemplateData } from '../../models/api/template/template-data.model';

@Injectable({
    providedIn: 'root'
})
export class FormTemplateManagementService
{
    constructor(
        private wsh: WebServiceHelperService,
        private translator: TranslateService
    ) { }

    public listForms(filter?: { template: any, dateIni: any, dateEnd: any }): Observable<Array<any>> {

        if (filter) {
            filter.dateIni = (filter.dateIni as Date).toISOString();
            filter.dateEnd = (filter.dateEnd as Date).toISOString();
        }


        let url = environment.backEndDomain;
        url += 'api/form_template';
        return this.wsh.get(url);
    }

    public listAllForms(filter?: any): Observable<Array<any>>
    {
        let qs = '';

        if (filter)
        {
            filter.dateIni = (filter.dateIni as Date).toISOString();
            filter.dateEnd = (filter.dateEnd as Date).toISOString();

            let status = '';
            if (filter.status == 1)
                status = 'ON';
            else
                status = 'OFF';

            delete filter.status
            filter['status[]'] = status;

            if (!filter.template)
                delete filter.template;

            qs = '?' + Object.keys(filter).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
            }).join('&');
        }

        let url = environment.backEndDomain;
        url += 'api/form_template' + qs;
        return this.wsh.get(url);
    }

    public listAllUnattendedForms(filter?: any): Observable<Array<any>>
    {
        let qs = '';

        if (filter)
        {
            filter.dateIni = (filter.dateIni as Date).toISOString();
            filter.dateEnd = (filter.dateEnd as Date).toISOString();

            let status = '';
            if (filter.status == 1)
                status = 'ON';
            else
                status = 'OFF';

            delete filter.status
            filter['status[]'] = status;

            if (!filter.template)
                delete filter.template;

            qs = '?' + Object.keys(filter).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
            }).join('&');
        }

        let url = environment.backEndDomain;
        url += 'api/form_template_unattended/getAll' + qs;
        return this.wsh.get(url);
    }

    public deleteTpl(id: number): Observable<any>
    {
        const url = environment.backEndDomain + 'api/form_template/' + id;
        return this.wsh.delete(url, {});
    }

    public createFormTemplate(template: TemplateData, file?): Observable<any> {
        const url = environment.backEndDomain + 'api/form_template';

        if (undefined !== file) {
            const payload = new FormData();
            payload.set('name', template.name);
            payload.set('status', 'ON');
            payload.set('pdf', file, file.name);
            payload.set('public', template.is_public ? '1' : '0');
            return this.wsh.post(url, payload);
        } else {
            return this.wsh.post(url, template);
        }
    }

    public createUnattendedFormTemplate(template: TemplateData, file?): Observable<any> {
        const url = environment.backEndDomain + 'api/form_template_unattended';

        if (undefined !== file) {
            const payload = new FormData();
            payload.set('name', template.name);
            payload.set('status', 'ON');
            payload.set('pdf', file, file.name);
            payload.set('public', template.is_public ? '1' : '0');
            return this.wsh.post(url, payload);
        } else {
            return this.wsh.post(url, template);
        }
    }

    public cloneForm(template: { id: number, name: string }): Observable<any> {
        const url = environment.backEndDomain + 'api/form_template/clone';
        return this.wsh.post(url, template);
    }

    public updateFormTemplateUnattended(template: TemplateData, formId: number): Observable<any> {
        const url = environment.backEndDomain + 'api/form_template_unattended/' + formId;

        const payload = new FormData();
        payload.set('name', template.name);
        payload.set('status', 'ON');
        //payload.set('pdf', null);
        payload.set('public', template.is_public ? '1' : '0');

        return this.wsh.post(url, payload);
    }

    public updateFormTemplate(template: TemplateData, formId: number): Observable<any> {
        const url = environment.backEndDomain + 'api/form_template/' + formId;

        const payload = new FormData();
        payload.set('name', template.name);
        payload.set('status', 'ON');
        //payload.set('pdf', null);
        payload.set('public', template.is_public ? '1' : '0');
        return this.wsh.post(url, payload);
    }
}
