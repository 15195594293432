<table mat-table #table [dataSource]="tableAdapter.data | async" style="width: 100%">
    <ng-container [matColumnDef]="'title'">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'Title'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="tableAdapter.responsive.isMobile$ | async; else docnameNombl">
                {{row['title']}}
                <div class="mbl-date flex-row-start-center" style="margin-bottom: 2px">
                    <mat-icon style="font-size: 15px;height: 17px;">calendar_today</mat-icon>{{row.created_at != null ? (row.created_at| prepareDate | date:'short') : '-' }}
                </div>
            </ng-container>
            <ng-template #docnameNombl>
                <div class="table-label" [matTooltip]="row['title']">{{row['title']}}</div>
            </ng-template>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'full_name'">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'Signers name'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="tableAdapter.responsive.isMobile$ | async; else statusNombl">
                <ng-container *ngTemplateOutlet="statusNombl"></ng-container>
                <div>
                    <div class="table-status" style="width: auto!important;">
                        {{ row.status | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-template #statusNombl>
                <div class="signer-balls-table" [ngClass]="{'mbl-date': tableAdapter.responsive.isMobile$ | async}">
                    <div class="ball-24px mar-r10 ball" [matTooltip]="signer.name"
                        [ngClass]="{'link-expired': signer.status == 'MAIL_FAILED', 'unsigned': signer.status != 'MAIL_FAILED'}"
                         *ngFor="let signer of row['parsedSigners']">
                        {{ signer.initials }}
                    </div>
                </div>
            </ng-template>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'created_at'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ 'created_date'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;" [matTooltip]="row.created_at != null ? (row.created_at| prepareDate | date:'short') : '-'" >
            {{row.created_at != null ? (row.created_at| prepareDate | date:'short') : '-' }}
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'status'">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'Status'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.status | translate">
            <span >
                {{ row.status | translate }}
            </span>
        </td>
    </ng-container>

    <!-- REINTENTAR -->
    <ng-container [matColumnDef]="'retry'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ 'Retry' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button [matTooltip]="'Retry' | translate" mat-icon-button
                    [disabled]="row.doc_status == 8"
                    (click)="tableAdapter.retrySend(row.id)">
                <mat-icon>
                    redo
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Download -->
    <ng-container [matColumnDef]="'download'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ 'Download' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button [matTooltip]="'Download' | translate" mat-icon-button
                    (click)="tableAdapter.getPdf(row.id)">
                <mat-icon>
                    cloud_download
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Delete -->
    <ng-container [matColumnDef]="'delete'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">
            {{ 'Delete' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button matTooltip="{{ 'Delete' | translate }}" mat-icon-button
                    (click)="tableAdapter.delete(row.id)">
                <mat-icon style="color:red;">
                    delete
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- MBL ACTIONS -->
    <ng-container [matColumnDef]="'actions'">
        <th mat-header-cell *matHeaderCellDef style="text-align: right;">{{'Actions' | translate}}</th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
            <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu class="mat-typography">
                <div class="menu-doc-name">
                    {{ row.title }}
                </div>
                <mat-divider></mat-divider>
                <button matTooltip="{{ 'Descargar' | translate }}" mat-menu-item
                    (click)="tableAdapter.getPdf(row.id)">
                    <mat-icon>
                        cloud_download
                    </mat-icon>
                    <span> {{'Descargar' | translate }}</span>

                </button>
                <button matTooltip="{{ 'Reintentar' | translate }}" *ngIf="row.doc_status != 4" mat-menu-item
                    (click)="tableAdapter.retrySend(row.id)">
                    <mat-icon>
                        redo
                    </mat-icon>
                    <span> {{'Reintentar' | translate }}</span>
                </button>
                <button matTooltip="{{ 'Eliminar' | translate }}" mat-menu-item
                    (click)="tableAdapter.delete(row.id)">
                    <mat-icon>
                        delete
                    </mat-icon>
                    <span> {{'Eliminar' | translate }}</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableAdapter.getAllColumnsName(); sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: tableAdapter.getAllColumnsName();"></tr>
</table>

<mat-paginator class="mat-paginator-sticky" #paginator [pageIndex]="tableAdapter.currentPage"
               [length]="tableAdapter.elementCount"
               [pageSize]="tableAdapter.pageSize" (page)="tableAdapter.paginatorEventHandler($event)">
</mat-paginator>
