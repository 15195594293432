<div>
    <div class="ball">
        <div class="label">
            <ng-container *ngIf="node.type=='group'; else contactCount">
                {{ node.children ? node.children.length : 0 }}
            </ng-container>
            <ng-template #contactCount>
                {{ node.contact_count }}
            </ng-template>
        </div>
        <mat-icon>
            {{ node.type == 'contacts' ? 'people' : 'group_work' }}
        </mat-icon>
    </div>
</div>
<div class="group-content">
    <b>{{ node.name }}</b>
    <div class="scroller-chips" *ngIf="showBreadcrumbs">
        <mat-chip-list class="parent-chips" aria-label="Breadcrumbs">
            <ng-container *ngIf="(node | groupHierarchy: true) as breadcrumbs; else noparents">
                <ng-container *ngIf="breadcrumbs.length > 0; else noparents">
                    <mat-chip *ngFor="let breadcrumb of breadcrumbs">{{ breadcrumb }}</mat-chip>
                </ng-container>
            </ng-container>

            <ng-template #noparents>
                <mat-chip color="primary">{{ node.name }}</mat-chip>
            </ng-template>
        </mat-chip-list>
    </div>
    <div class="gray-label">
        {{ (node.type == 'contacts' ? 'Contact agrupation' : 'Group agrupation') | translate }}
    </div>
</div>
<button *ngIf="showNavArrow" (click)="selected.emit(node)" class="acces-group" mat-icon-button color="primary"><mat-icon>forward</mat-icon></button>
