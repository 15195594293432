<div class="actions">
    <button mat-raised-button color="primary" (click)="transferCredit()">
        {{ "Credit transfer" | translate }}
    </button>
</div>
<div class="grid">
    <ng-container *ngIf="state$ | async as state; else loading">
        <ag-grid-angular
            *ngIf="state?.invoices; else loading"
            class="ag-theme-material"
            [gridOptions]="gridOptions"
            [rowData]="state.invoices?.data"
            (gridReady)="gridReady($event)"
        >
        </ag-grid-angular>
    </ng-container>
    <ng-template #loading>
        <mat-icon>hourglass_top</mat-icon>
        <span class="ml-12">{{ "Loading..." | translate }}</span>
    </ng-template>
</div>
