import { SpinnerService } from './../spinner.service';
import { Component, Input } from '@angular/core';
import {SpinnerMessagesParcelService} from '../spinner-messages-parcel.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
    constructor(public messageParcel: SpinnerMessagesParcelService) {
    }
}
