
<div>
    <h1 class="title" mat-dialog-title>{{ injectedData.title | translate}}</h1>
</div>

<div style="overflow-y: auto; padding-right: 10px;">
    <ng-template #target></ng-template>
</div>
<div class="flex-row-between">
    <div style="display: flex; flex: 1" *ngIf="injectedData.hideCloseButton"></div>
    <button *ngIf="!injectedData.hideCloseButton" color="primary" mat-stroked-button (click)="close(null)">{{'cancel' | translate}}</button>
    <button color="primary" mat-raised-button (click)="action(injectedData)">{{ injectedData.positiveButton | translate}}</button>
</div>
