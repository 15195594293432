import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GeonamesStoreSelectors } from './geonames-store';
import { SubtenantListStoreSelectors } from './subtenant-list-store';
import { SubtenantEditStoreSelectors } from './subtenant-edit-store';
import { SubtenantInvoicesStoreSelectors } from './subtenant-invoices-store';

export const selectError: MemoizedSelector<object, string> = createSelector(
    SubtenantListStoreSelectors.selectSubtenantError,
    SubtenantEditStoreSelectors.selectSubtenantEditError,
    SubtenantInvoicesStoreSelectors.selectSubtenantInvoicesError,
    GeonamesStoreSelectors.selectGeonamesError,
    (SubtenantError: string, SubtenantEditError: string, SubtenantInvoicesError: string, GeonamesError: string) =>
    {
        return SubtenantError || SubtenantEditError || SubtenantInvoicesError || GeonamesError;
    }
);