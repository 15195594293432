import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { Template } from '../../models/api/template/template.model';
import { TemplateData } from '../../models/api/template/template-data.model';
import { HttpHeaders } from '@angular/common/http';
import { PdfEditable } from '../../models/pdf-editable/pdf-editable.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateEditorRepositoryService
{

    constructor(
        private wsh: WebServiceHelperService
    ) { }

    public getTplInput(id): Observable<Template>
    {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id + '/outputs';

        return this.wsh.get(url);
    }
    public getFormInput(id): Observable<PdfEditable>
    {
        let url = environment.backEndDomain + 'api/form_template/';
        url += id;

        return this.wsh.get(url);
    }
    public getTplPreviewAsB64(id, page): Observable<Blob>
    {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id + '/';
        url += page + '/previewB64';

        return this.wsh.get<any>(url, { responseType: 'blob' });
    }
    public getTplPreviewAsArrayBuffer(id): Observable<ArrayBuffer>
    {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id;
        url += '/preview';

        return this.wsh.get<any>(url, { responseType: 'arraybuffer' });
    }
    public getFormPreviewAsArrayBuffer(id): Observable<ArrayBuffer>
    {
        let url = environment.backEndDomain + 'api/form_template/';
        url += id;
        url += '/preview';

        return this.wsh.get<any>(url, { responseType: 'arraybuffer' });
    }
    public getTplPdf(id): any
    {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id;
        url += '/preview';

        const src = {
            url,
            httpHeaders: this.wsh.getOptionsWhitAuthHeader().headers,
            withCredentials: true
        }

        return src;
    }
    public uploadTplInputsConf(id, serialized): Observable<any>
    {
        let url = environment.backEndDomain + 'api/document_template/';
        url += id + '/outputs';

        return this.wsh.post(url, serialized);
    }
    public createTemplate(template: TemplateData, file?): Observable<any> {
        const url = environment.backEndDomain + 'api/document_template';

        if (undefined !== file) {
            const payload = new FormData();
            payload.set('data', JSON.stringify(template));
            payload.set('file', file, file.name);
            return this.wsh.post(url, payload);
        } else {
            return this.wsh.post(url, template);
        }
    }
    public checkStatus(docId: string): Observable<any>
    {
        const url = environment.backEndDomain + 'api/document/' + docId + '/checkStatus';
        return this.wsh.get(url);
    }
    public formDeploy(struc: any): Observable<any>
    {
        let url = environment.backEndDomain + 'api/form';

        return this.wsh.post(url, struc);
    }
}
