import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { DialogData, DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { ApiKeyFormComponent } from 'src/app/pages/user-management/api-key-form/api-key-form.component';
import { AutoTableColumnDefinition } from 'src/app/shared/auto-table/definitions/auto-table-column-definition';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';
import { TableAdapterInterface } from 'src/app/shared/auto-table/definitions/table-adapter.interface';
import { BaseTableAdapterService } from 'src/app/shared/auto-table/services/base-table-adapter.service';
import { PaginatorService } from 'src/app/shared/auto-table/services/paginator.service';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { ConfirmationBoxConf } from 'src/app/shared/confirmation-box/confirmation-box.component';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiKeysTableAdapterService extends BaseTableAdapterService implements TableAdapterInterface
{

    constructor(
        private wsh: WebServiceHelperService,
        private translateService: TranslateService,
        private dialog: CrossPlatformDialogService,
        private confirmatioBox: ConfirmationBoxService,
        public responsive: ResponsiveService,
        public paginator: PaginatorService
    )
    {
        super(responsive, paginator);
    }

    getStdCols(): Array<AutoTableColumnDefinition>
    {
        return [
            {
                column: 'id',
                columnTitle: 'id',
                order: 0
            }, {
                column: 'name',
                columnTitle: 'name',
                order: 1
            }, {
                column: 'redirect',
                columnTitle: 'redirect',
                order: 3
            }, {
                column: 'revoked',
                columnTitle: 'revoked',
                order: 4
            }
        ];
    }

    getNonStdCols(): Array<AutoTableColumnDefinition>
    {

        return [
            {
                column: 'secret',
                columnTitle: 'secret',
                order: 2
            },
            {
                column: 'edit',
                columnTitle: this.translateService.instant('edit'),
                order: 4
            }, {
                column: 'revoked_action',
                columnTitle: this.translateService.instant('revoked'),
                order: 6
            }];
    }

    getLaravelPaginatedList(loader?: boolean): Observable<LaravelPaginatedList<any>>
    {
        let url = environment.backEndDomain;
        url += 'api/customer/apiKeys';
        url += '?page=' + (this.currentPage + 1);
        return this.wsh.get<LaravelPaginatedList<any>>(url);
    }

    warmUp(): Observable<boolean>
    {
        this.getData();
        return this.data
            .pipe(
                delay(100),
                map(x => true)
            );
    }

    updateKey(key)
    {

        const da: DialogData<ApiKeyFormComponent> = {
            title: 'Create key',
            positiveButton: 'create',
            data: key,
            locales: [],
            countries: [],
            isEdit: false,
            dynamycComponent: ApiKeyFormComponent,
            hideCloseButton: false
        };

        const ds: DialogStructure<ApiKeyFormComponent> = {
            data: da,
            width: '718px',
            // @ts-ignore
            height: '600px',
            disableClose: false
        };

        this.dialog.open(ds).subscribe(r =>
        {
            this.getData();
        });
    }

    revokeKey(key)
    {
        const conf: ConfirmationBoxConf = {
            body: 'the key will be revoked, are you sure?',
            btn_cancel: 'cancel',
            btn_ok: 'accept',
            title: 'warning! revoking key'
        };

        let url = environment.backEndDomain;
        url += '/api/customer/revokeKey';
        this.confirmatioBox.open(conf)
            .pipe(mergeMap(r => this.wsh.post(url, key)))
            .subscribe(r =>
            {
                this.getData();
            });
    }

}
