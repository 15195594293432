import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';
import { CreditTransferConfig } from '../../models/subtenants-management/credit-transfer-config.model';
import { SubtenantMovement } from '../../models/subtenants-management/subtenant-movement.model';
import { Tenant } from '../../models/tenant-management/tenant';
import { TenantConfig } from '../../models/tenant-management/tenant-config';

@Injectable({
    providedIn: 'root'
})
export class SubtenantService
{

    constructor(private wsh: WebServiceHelperService) { }
    fetchSubtenant(uuid: string): Observable<Tenant>
    {
        return this.wsh.get<LaravelPaginatedList<Tenant>>(environment.backEndDomain + 'api/subtenats?uuid=' + uuid)
            .pipe(
                map(response => response.data[0]),
            );
    }
    fetchSubtenantInvoices(uuid: string): Observable<LaravelPaginatedList<SubtenantMovement>>
    {
        return this.wsh.get<LaravelPaginatedList<SubtenantMovement>>(environment.backEndDomain + 'api/subtenats/transfer_report?uuid=' + uuid);
    }
    fetchSubtenantList(page: number): Observable<LaravelPaginatedList<Tenant>>
    {
        return this.wsh.get(environment.backEndDomain + 'api/subtenats?page=' + page);
    }
    postSubtenant(tenant: Tenant): Observable<any>
    {
        return this.wsh.post(environment.backEndDomain + 'api/subtenats', tenant);
    }
    updateSubtenant(tenant: Tenant): Observable<any>
    {
        return this.wsh.post(environment.backEndDomain + 'api/subtenats/' + tenant.uuid, tenant);
    }
    postSubtenantConfig(config: TenantConfig): Observable<any>
    {
        return this.wsh.post(environment.backEndDomain + 'api/subtenats/set_budget_trigger', config);
    }
    creditTransfer(creditTransferConfig: CreditTransferConfig): Observable<any>
    {
        const payload = {
            to: creditTransferConfig.uuid,
            type: creditTransferConfig.product,
            amount: creditTransferConfig.quantity * creditTransferConfig.movementType
        };
        return this.wsh.post(environment.backEndDomain + 'api/subtenats/transfer_budget', payload);
    }
    unlink(uuid: string): Observable<any>
    {
        return this.wsh.delete(environment.backEndDomain + 'api/subtenats/' + uuid, {});
    }
}
