import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogInCallbackComponent } from './log-in-callback/log-in-callback.component';
import { AuthGuard } from './auth-guard.service';
import { LoginComponent } from './login/login.component';
import { TenantManagementModule } from './pages/tenant-management/tenant-management.module';
import { SendAssistantModule } from './pages/send-assistant/send-assistant.module';
import { DocumentTrayModule } from './document-tray/document-tray.module';
import { HomeComponent } from './pages/home/home.component';
import { TemplateManagementModule } from './pages/template-management/template-management.module';
import { UserManagementModule } from './pages/user-management/user-management.module';
import { ContactsManagementModule } from './pages/contacts-management/contacts-management.module';
import { PdfEditableModule } from './pages/pdf-editable/pdf-editable.module';
import { EditableTrayModule } from './pages/editable-tray/editable-tray.module';
import { SubtenantManagementModule } from './pages/subtenant-management/subtenant-management.module';
import { SecureDownloadRoutingModule } from './secure-download/secure-download-routing.module';
import { DocumentTemplateFormManagementModule } from './pages/document-form-management/document-template-form-management.module';

// Set constant for all routes
const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'logincallback', component: LogInCallbackComponent },
    { path: 'sign', loadChildren: './sign/sign.module#SignModule' },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'user_managament', loadChildren: () => UserManagementModule, canActivate: [AuthGuard] },
    { path: 'contacts', loadChildren: () => ContactsManagementModule, canActivate: [AuthGuard] },
    { path: 'document', loadChildren: () => DocumentTrayModule, canActivate: [AuthGuard] },
    { path: 'editable', loadChildren: () => EditableTrayModule, canActivate: [AuthGuard] },
    { path: 'tenant_management', loadChildren: () => TenantManagementModule, canActivate: [AuthGuard] },
    { path: 'subtenant_management', loadChildren: () => SubtenantManagementModule, canActivate: [AuthGuard] },
    { path: 'send_assistant', loadChildren: () => SendAssistantModule, canActivate: [AuthGuard] },
    { path: 'document_template_form_management', loadChildren: () => DocumentTemplateFormManagementModule, canActivate: [AuthGuard] },
    { path: 'template_management', loadChildren: () => TemplateManagementModule, canActivate: [AuthGuard] },
    { path: 'editable_pdf', loadChildren: () => PdfEditableModule, canActivate: [AuthGuard] },
    { path: 'secure_download', loadChildren: './secure-download/secure-download.module#SecureDownloadModule'}
];

// Import the routes and export the updated module
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

// Export class
export class AppRoutingModule
{
}


