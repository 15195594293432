import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SpinnerMessagesParcelService {

    public messages = [];

    public add(texts: Array<string>) {

        if(texts === undefined) return;

        texts.forEach(text => {
            if (this.messages.indexOf(text) === -1) {
                this.messages.push(text);
            }
        })

    }

    public whipe() {
        this.messages.length = 0;
    }
}
