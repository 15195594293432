import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WebServiceHelperService } from './web-service-helper.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SendingsService
{
    private _availableSends: number;
    private _availableWhatsapp: number;

    constructor(
        private WsHelper: WebServiceHelperService
    )
    {
        this._availableSends = 0;
        this._availableWhatsapp = 0;
        //this.RecalculateSendings();
    }


    /**
     * Getter availableSends
     * @return {number}
     */
    public get availableSends(): number
    {
        return this._availableSends;
    }
    /**
     * Getter availableWhatsapp
     * @return {number}
     */
    public get availableWhatsapp(): number
    {
        return this._availableWhatsapp;
    }


    public RecalculateSendings(loader?: boolean): void
    {
        // this.getBudget(loader).subscribe(r =>
        // {
        //     this._availableSends = r;
        // });
        // this.getWhatsappBudget(loader).subscribe(r =>
        // {
        //     this._availableWhatsapp = r;
        // });
    }

    private getBudget(loader?: boolean): Observable<any>
    {
        const url = environment.backEndDomain + 'api/customer/budget';
        return this.WsHelper.get(url, { nospiner: loader });
    }
    private getWhatsappBudget(loader?: boolean): Observable<any>
    {
        const url = environment.backEndDomain + 'api/customer/whatsapp_budget';
        return this.WsHelper.get(url, { nospiner: loader });
    }
}
