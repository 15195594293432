import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { TenantService } from 'src/app/core/services/pages/tenant-management/tenant.service';
import { UserModel } from 'src/app/shared/Models/UserModel';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserMenuService
{
    public tenantData: Tenant;
    public userData: UserModel;

    public user$ = new ReplaySubject<UserModel>(1);

    constructor(
        public tenants: TenantService,
        private wsh: WebServiceHelperService,
        private translator: TranslateService
    )
    {
        // Al montar la instancia del servicio UserManagemenet, directamente llamamos al tenant
        // service para recoger la información del usuario actual.
        // Esta parte seguramente habrá que cambiarla cuando llegue el adri de vacaciones.
        // marco TODO.

        // TODO: Comentar con adri la parte de mostrar los datos del usuario / empresa que ha
        // hecho login en la app.

        this.tenants.getTenantData().subscribe((r: Tenant) =>
        {
            this.tenantData = r;

        });

        this.whoAmI();
    }

    whoAmI()
    {
        this.wsh.get<UserModel>(environment.backEndDomain + 'api/whoAmI')
            .subscribe(
                r =>
                {
                    this.userData = r;
                    this.configureUserLang(r);
                    this.user$.next(r);
                }
            );

    }

    public configureUserLang(user: UserModel): void
    {
        const availableLangs = this.translator.getLangs();
        if (availableLangs.indexOf(user.locale) != -1)
            this.translator.use(user.locale);
    }

}
