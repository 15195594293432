import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSignaturesComponent } from './user-signatures.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material/material.module';
import { SingaturePickerComponent } from './components/singature-picker/singature-picker.component';
import { SignaturePadModule } from 'angular2-signaturepad';

@NgModule({
    declarations: [UserSignaturesComponent, SingaturePickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MaterialModule,
        RouterModule,
        SignaturePadModule
    ],
    exports: [UserSignaturesComponent],
})
export class UserSignaturesModule { }
