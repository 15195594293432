export class CustomTextConf
{
    flag_id: number;
    flag_key: string;
    flag_value: number;
    data_id: number;
    data_key: string;
    data_value: string;
    use_as_name_flag_id: number;
    use_as_name_flag_key: string;
    use_as_name_flag_value: number;
    required: boolean;

    getIdx()
    {
        return parseInt(this.flag_key.match(/([0-9])/).pop(), 10);
    }
}

