<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
    <div class="buttons">
        <button mat-dialog-close mat-button color="primary">{{ 'Cancel' | translate }}</button>
        <button (click)="save()" mat-raised-button color="primary">{{ 'Save' | translate }}</button>
    </div>
</ng-container>

<ng-template #helpCol>
    <div class="help-col mat-typography">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <app-tip *ngFor="let tip of tips; let idx = index" [idx]="idx" [tip]="tip"></app-tip>
    </div>
</ng-template>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="helpCol"></ng-container>
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
    <div></div>
    <div class="buttons">
        <button mat-dialog-close mat-button color="primary">{{ 'Cancel' | translate }}</button>
        <button (click)="save()" mat-raised-button color="primary">{{ 'Save' | translate }}</button>
    </div>
</ng-template>

<ng-template #contentCol>
    <div class="content">
        <div class="subtitle flex-row">
            <div class="editable-name" [ngClass]="{'hidden': !isEdit}">
                <input type="text" [formControl]="name" name="name">
            </div>
            <div class="info-name" [ngClass]="{'hidden': isEdit}">
                <button (click)="openHelp()" mat-icon-button color="primary" *ngIf="responsive.isMobile$ | async">
                    <mat-icon>help</mat-icon>
                </button>
                <div [matTooltip]="data.name != name.value ? name.value : data.name">
                    {{ data.name != name.value ? name.value : data.name }} <ng-container *ngIf="data.name != name.value">*</ng-container>
                </div>
            </div>
            <span *ngIf="!(responsive.isMobile$ | async)" style="opacity: 0.6; margin-left: 5px;">- {{ 'Group agrupation' | translate }}</span>
        </div>
        <div class="subtitle" *ngIf="responsive.isMobile$ | async">
            <span style="opacity: 0.6;">{{ 'Group agrupation' | translate }}</span>
        </div>
        <div style="overflow-x: auto;">
            <mat-chip-list class="parent-chips" aria-label="Breadcrumbs">
                <ng-container *ngIf="data | groupHierarchy as breadcrumbs; else noparents">
                    <ng-container *ngIf="breadcrumbs.length > 0; else noparents">
                        <mat-chip *ngFor="let breadcrumb of breadcrumbs" selected color="primary">{{ breadcrumb }}</mat-chip>
                    </ng-container>
                </ng-container>

                <ng-template #noparents>
                    <mat-chip selected color="primary">{{ data.name }}</mat-chip>
                </ng-template>
            </mat-chip-list>
        </div>
        <div class="add-group" mat-ripple (click)="addGroup()">
            <button  style="box-shadow: none;" mat-mini-fab color="primary">
                <mat-icon>add</mat-icon>
            </button>
            <div class="text">
                {{ 'Add group' | translate }}
            </div>
        </div>
        <div class="list" *ngIf="data.children && data.children.length > 0; else nochilds">
            <app-group
                [node]="child"
                (selected)="dialogRef.close($event)"
                *ngFor="let child of data.children"></app-group>
        </div>
        <ng-template #nochilds>
            <div class="no-list">
                <p>{{ 'Add a new group to see the list' | translate }}</p>
            </div>
        </ng-template>
    </div>
</ng-template>

<div class="actions">
    <button
        [matTooltip]="'Edit group name' | translate"
        mat-icon-button
        color="primary"
        (click)="editGroupName()">
        <mat-icon>{{ isEdit ? 'done' : 'edit' }}</mat-icon>
    </button>
    <button
        [matTooltip]="'Delete group' | translate"
        [mat-dialog-close]="'delete'"
        mat-icon-button
        color="warn"
        class="mar-l10">
        <mat-icon>delete</mat-icon>
    </button>
</div>