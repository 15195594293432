<ng-container *ngIf="(signatureService.signatures$ | async) as signatures; else nosignatures">
    <div class="signatures mar-t10" *ngIf="(signatureService.signatures$ | async) as signatures">
        <div class="signature-holder">
            <div class="subtitle fullcol">{{ signatures.name }}</div>
            <img [src]="'data:image/png;base64,' + signatures.image" alt="" style="width:130px;">
            <div style="display: flex; align-items: center; justify-content: flex-end;">
                <button color="primary" mat-icon-button (click)="editSignature(signatures)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #nosignatures>
    <div class="add-new">
        <div class="dashed" matRipple (click)="newSignature()">
            <mat-icon class="mar-r5">add</mat-icon>
            {{ 'Add new signature' | translate }}
        </div>
    </div>
</ng-template>