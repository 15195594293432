<div>
    <span class="title">{{'About' | translate}}</span>
</div>
<div>
    <img style="width: 40%" src="assets/svg/my_rubric_logo_03.svg">
</div>
<div style="border-top: 2px dashed #e4e4e4;"></div>
<div class="v-center">
    <mat-icon style="margin-right: 12px;">schedule</mat-icon>{{ 'Schedule' | translate }}: 9 - 21h
    {{ 'Mon to Fri' | translate }}
</div >
<div class="v-center">
    <mat-icon style="margin-right: 12px;">call</mat-icon>{{ 'Phone' | translate }}: +34 678 77 08 08
</div>
<div class="v-center">
    <mat-icon style="margin-right: 12px;">mail</mat-icon>{{ 'E-mail' | translate }}:&nbsp;<a
        href="mailto:info@myrubric.com">info@myrubric.com</a>
</div>
<div class="v-center">
    <mat-icon style="margin-right: 12px;">domain</mat-icon>{{'Calle Bobinadora 17, Mataró 08302 Barcelona' | translate}}
</div>
<div class="mar-t10 mar-b10">
    {{ 'MyRubric is developed by' | translate }}
</div>
<div>
    <img style="max-width: 100%;" src="assets/png/logo_smart_retail.png">
</div>
<div>
    <a href="{{ this.master_domain }}/politica-de-privacidad">{{'Privacy policy' | translate}}</a>
</div>
<div>
    <a href="{{ this.master_domain }}/condiciones-de-contratacion/">{{'Contracting terms' | translate}}</a>
</div>