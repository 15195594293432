import { Action } from '@ngrx/store';
import { SubtenantMovement } from 'src/app/core/models/subtenants-management/subtenant-movement.model';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';

export enum SubtenantInvoicesActionTypes
{
    FETCH = '[SUBTENANT_INVOICES] FETCH',
    FETCH_SUCCESS = '[SUBTENANT_INVOICES] FETCH_SUCCESS',
    FETCH_FAIL = '[SUBTENANT_INVOICES] FETCH_FAIL',
    CLEAR = '[SUBTENANT_INVOICES] CLEAR',
    DO_NOTHING = '[SUBTENANT_EDIT] DO_NOTHING'
}

export class Fetch implements Action
{
    readonly type = SubtenantInvoicesActionTypes.FETCH;
    constructor(public payload: string) { }
}
export class FetchSuccess implements Action
{
    readonly type = SubtenantInvoicesActionTypes.FETCH_SUCCESS;
    constructor(public payload: LaravelPaginatedList<SubtenantMovement>) { }
    // TODO: Definir el tipo
}
export class FetchFail implements Action
{
    readonly type = SubtenantInvoicesActionTypes.FETCH_FAIL;
}
export class Clear implements Action
{
    readonly type = SubtenantInvoicesActionTypes.CLEAR;
}
export class DoNothing implements Action
{
    readonly type = SubtenantInvoicesActionTypes.DO_NOTHING;
}
export type SubtenantInvoicesActionsUnion = Fetch | FetchSuccess | FetchFail | Clear | DoNothing;
