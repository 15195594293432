<div class="title">{{ "subtenant edit" | translate }}</div>
<div class="tab-wrapper">
    <mat-tab-group #tabgroup (selectedTabChange)="handleTabChange($event)">
        <mat-tab label="{{ 'Account' | translate }}">
            <ng-container *ngIf="state$ | async as state">
                <app-subtenant-form
                    #form
                    *ngIf="state.tenant"
                    [data]="state.tenant"
                    [isNewTenant]="false"
                ></app-subtenant-form>
                <app-subtenant-configuration
                    #config
                    *ngIf="state.tenant"
                    [tenant]="state.tenant"
                ></app-subtenant-configuration>
            </ng-container>
        </mat-tab>
        <mat-tab label="{{ 'Invoices' | translate }}">
            <ng-container *ngIf="state$ | async as state">
                <app-subtenant-invoices
                    #invoices
                    *ngIf="state.tenant"
                    [uuid]="state.tenant.uuid"
                    style="margin-top: 12px; height: calc(100% - 12px)!important;"
                ></app-subtenant-invoices>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="buttons">
    <button mat-button color="primary" routerLink="/subtenant_management/list">
        {{ "Back" | translate }}
    </button>
    <button
        *ngIf="tabgroup.selectedIndex == 0; else filler"
        [disabled]="!(state$ | async).tenant"
        mat-raised-button
        color="primary"
        (click)="store()"
    >
        {{ "Save" | translate }}
    </button>
    <ng-template #filler>
        <div></div>
    </ng-template>
</div>
