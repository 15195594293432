import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignatureFromPicker } from 'src/app/core/models/signature-picker/signature-from-picker.model';
import { Signature } from 'src/app/core/models/signature-picker/signature.model';
import { UserSignaturesService } from 'src/app/core/services/content/user-signatures.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { SingaturePickerComponent } from './components/singature-picker/singature-picker.component';

@Component({
    selector: 'app-user-signatures',
    templateUrl: './user-signatures.component.html',
    styleUrls: ['./user-signatures.component.scss']
})
export class UserSignaturesComponent implements OnInit
{

    constructor(
        public signatureService: UserSignaturesService,
        private dialog: MatDialog,
        private toast: I18nToastService
    ) { }

    ngOnInit(): void
    {
    }

    public newSignature(): void
    {
        this.dialog.open(SingaturePickerComponent)
        .afterClosed()
        .subscribe(
            (signature: SignatureFromPicker) =>
            {
                if (signature)
                {
                    // TODO poner a true el loader
                    this.signatureService.createSignature(signature)
                    .subscribe(
                        result =>
                        {
                            console.log(result)
                        }
                    );
                }
            }
        );
    }
    public editSignature(signature: Signature): void
    {
        this.dialog.open(SingaturePickerComponent, {
            data: signature
        })
        .afterClosed()
        .subscribe(
            (signature: SignatureFromPicker) =>
            {
                if (signature)
                {
                    this.signatureService.editSignature(signature)
                    .subscribe(
                        result =>
                        {
                            this.toast.open(
                                'The signature was edited correctly',
                                'Accept',
                                7000,
                                true
                            )
                        }
                    );
                }
            }
        );
    }
}
