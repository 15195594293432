<div class="title flex-row-start-center">
    <mat-icon class="mar-r10" color="primary">help</mat-icon>
    {{ 'Help' | translate }}
</div>
<div style="overflow-y: auto;">
    <div *ngFor="let tip of data; let idx = index; let isLast = last">
        <div class="subtitle mar-b5">
            {{ idx + 1 }}. {{ tip.title | translate }}
        </div>
        <div class="tip-modal-body" [innerHTML]="(tip.body | translate) || tip.body">
        </div>
        <div *ngIf="!isLast" class="separator"></div>
    </div>
</div>