<div class="title" mat-dialog-title>{{data.title | translate}}</div>

<div mat-dialog-content class="mat-typography">
    <div>
        <p *ngFor="let chunk of (data.body | translate).split('\n')" >{{chunk}}</p>
    </div>
</div>
<div mat-dialog-actions class="flex-row-between" style="padding-bottom: 24px;">
    <button color="primary" mat-stroked-button [mat-dialog-close]="false">{{data.btn_cancel  | translate}}</button>
    <button color="primary" mat-raised-button [mat-dialog-close]="true">{{data.btn_ok  | translate}}</button>
</div>
