import {OAuthConf} from './OAuthConf';
import {Injectable} from '@angular/core';
import {JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {SpinnerService} from './spinner.service';

@Injectable({
    providedIn: 'root'
})
export class WebServiceHelperService {
    // PROPIEDADES

    // CONSTRUCTOR
    constructor(
        public oauthService: OAuthService,
        private router: Router,
        private http: HttpClient,
        public spinner: SpinnerService
    ) {
        this.oauthService.configure(OAuthConf);
        this.oauthService.setStorage(sessionStorage);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    }

    // MÉTODOS PÚBLICOS
    /**
     * Petición contra API con el token con método GET
     * @param route
     * @param options
     */
    get<T>(route: string, options?: any): Observable<T> {
        return new Observable<T>(o => {

            if (!options || (options && !options.nospiner))
                this.spinner.add();

            options = this.getOptionsWhitAuthHeader(options);

            this.http.get<T>(route, options).subscribe(
                r => {
                    // @ts-ignore
                    o.next(r);
                },
                e => {
                    this.err401hndlr(e);
                    o.error(e);
                    this.spinner.remove();
                },
                () => {
                    o.complete();
                    this.spinner.remove();
                }
            );
        });
    }

    public logOut(): void {
        this.oauthService.logOut();
        window.open(environment.backEndDomain + 'logout', '_self');
    }

    /**
     * Petición contra API con el token con método POST
     * @param route
     * @param payload
     * @param options
     */
    post<T>(route: string, payload, options?: any): Observable<T> {

        return new Observable<any>(o => {

            this.spinner.add();

            options = this.getOptionsWhitAuthHeader(options);

            this.http.post<T>(route, payload, options).subscribe(
                r => {
                    o.next(r);
                },
                e => {
                    this.err401hndlr(e);
                    o.error(e);
                    this.spinner.remove();
                },
                () => {
                    o.complete();
                    this.spinner.remove();
                }
            );
        });
    }

    /**
     * Petición contra API con el token con método POST
     * @param route
     * @param data
     * @param options
     */
    delete(route: string, payload, options?: any): Observable<any> {

        return new Observable<any>(o => {

            this.spinner.add();

            options = this.getOptionsWhitAuthHeader(options);
            options.body = JSON.stringify(payload);

            this.http.delete(route, options).subscribe(
                r => {
                    o.next(r);
                },
                e => {
                    this.err401hndlr(e);
                    o.error(e);
                    this.spinner.remove();
                },
                () => {
                    o.complete();
                    this.spinner.remove();
                }
            );
        });
    }

    /**
     * Redirección del proveedor de OAuth. Registra el Token obtenido
     */
    public handleCallback(): any {
        return new Promise<any>((resolve, reject) => {

            const hashString = this.router.url.substring(
                this.router.url.indexOf('#')
            );

            this.oauthService.tryLogin({
                customHashFragment: hashString
            });

            this.get(environment.backEndDomain + 'api/user').subscribe(
                r => {

                },
                e => {
                    reject({
                        status: 400,
                        data: 'error'
                    });
                },
                () => {
                    resolve({
                        status: 200,
                        data: 'success'
                    });
                }
            );
        });
    }

    /**
     * Comprueba si ya existe token de acceso, si no, inicia el flow para obtenerlo
     */
    public initAuth(): boolean {
        if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.initImplicitFlow();
            return false;
        }
        return true;
    }

    public getOptionsWhitAuthHeader(options?) {
        if (options === undefined) {
            options = {
                headers: new HttpHeaders({
                    Authorization:
                        'Bearer ' + this.oauthService.getAccessToken()
                })
            };
        } else {
            if (options.headers === undefined) {
                options.headers = new HttpHeaders({
                    Authorization:
                        'Bearer ' + this.oauthService.getAccessToken()
                });
            } else {
                options.headers.Authorization =
                    'Bearer: ' + this.oauthService.getAccessToken();
            }
        }
        return options;
    }

    // MÉTODOS PRIVADOS
    private stringifyData(object: any) {
        return JSON.stringify(object, function (key: string, value: any) {
            if (typeof value === 'string') {
                if (value.indexOf('+') > -1) {
                    return value.replace(/\+/g, encodeURIComponent('+'));
                }
                return value;
            }
            return value;
        });
    }

    private err401hndlr(e: HttpErrorResponse) {
        if (e.status === 401) {
            this.oauthService.initImplicitFlow();
        }
    }
}
