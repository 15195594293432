<div class="buy-title">{{ title }}:</div>
<div class="buy-subtitle">
    *
    {{ "Slide the dot in order to see the different plans" | translate }}
</div>
<div class="buy-mid">
    <ng-container *ngIf="!packs"> {{ "Loading" | translate }}... </ng-container>
    <mat-slider
        style="width: 100%;"
        [min]="0"
        [max]="packs && packs.length > 0 ? packs.length - 1 : 0"
        [step]="1"
        [tickInterval]="1"
        #range
    >
    </mat-slider>
    <div *ngIf="packs && packs.length > 0" class="packages">
        <div style="font-size: 60px; margin: 10px;">=</div>
        <div class="prices">
            <span class="title" style="color: white;" *ngIf="countryId != 29">
                {{ packs[range.value].amount | currency: "EUR" }}
            </span>
            <span class="title" style="color: white;" *ngIf="countryId == 29">
                {{
                    (packs[range.value].amount /
                        (1 + (packs[range.value].included_vat | toFloat))) *
                        1 | currency: "EUR"
                }}
            </span>

            <div *ngIf="countryId != 29">
                ({{ "VAT Included" | translate }})
            </div>
            <div *ngIf="countryId == 29">
                ({{ "VAT NOT Included" | translate }})
            </div>
        </div>
    </div>
</div>
<div class="buy-bottom">
    <div *ngIf="packs && packs.length > 0">
        {{ packs[range.value].units_included | number }}
        {{ docType }}
    </div>
    <button
        mat-raised-button
        (click)="startBuyProcess(packs[range.value])"
        style="width: 100%;"
        color="accent"
    >
        <mat-icon style="margin-right: 15px">credit_card</mat-icon>
        {{ "Buy" | translate }}
    </button>
</div>
<ng-container *ngIf="!packs"> {{ "Loading" | translate }}... </ng-container>
<!-- <div>
    <div>{{ title }}:</div>
    <div style="font-size: 10px; color: rgba(255, 255, 255, 0.8)">
        *
        {{ "Slide the dot in order to see the different plans" | translate }}
    </div>
    <div class="description-grid">
        <div>
            <div fxLayout="row">
                <mat-slider
                    style="width: 100%;"
                    [min]="0"
                    [max]="packs && packs.length > 0 ? packs.length - 1 : 0"
                    [step]="1"
                    [tickInterval]="1"
                    #range
                >
                </mat-slider>
            </div>
            <div
                class="title"
                style="color: white;"
                *ngIf="!(responsive.isMobile$ | async)"
            >
                <ng-container *ngIf="packs && packs.length > 0">
                    {{ packs[range.value].units_included | number }}
                    {{ docType }}
                </ng-container>
                <ng-container *ngIf="!packs"
                    >{{ "Loading" | translate }}...</ng-container
                >
            </div>
            <ng-container *ngIf="!packs">
                {{ "Loading" | translate }}...
            </ng-container>
        </div>
        <div *ngIf="packs && packs.length > 0" class="packages">
            <div style="font-size: 60px; margin: 10px;">=</div>
            <div>
                <div>
                    <ng-container *ngIf="packs && packs.length > 0">
                        <span
                            class="title"
                            style="color: white;"
                            *ngIf="countryId != 29"
                        >
                            {{ packs[range.value].amount | currency: "EUR" }}
                        </span>
                        <span
                            class="title"
                            style="color: white;"
                            *ngIf="countryId == 29"
                        >
                            {{
                                (packs[range.value].amount /
                                    (1 +
                                        (packs[range.value].included_vat
                                            | toFloat))) *
                                    1 | currency: "EUR"
                            }}
                        </span>
                    </ng-container>
                </div>
                <div *ngIf="countryId != 29">
                    ({{ "VAT Included" | translate }})
                </div>
                <div *ngIf="countryId == 29">
                    ({{ "VAT NOT Included" | translate }})
                </div>
            </div>
        </div>
    </div>
</div>
<div class="title" style="color: white;" *ngIf="responsive.isMobile$ | async">
    <ng-container *ngIf="packs && packs.length > 0">
        {{ packs[range.value].units_included | number }}
        {{ "Documents" | translate }}
    </ng-container>
    <ng-container *ngIf="!packs">{{ "Loading" | translate }}...</ng-container>
</div>
<div style="align-items: center; display: flex;">
    <button
        mat-raised-button
        (click)="startBuyProcess(packs[range.value])"
        style="width: 100%;"
        color="accent"
    >
        <mat-icon style="margin-right: 15px">credit_card</mat-icon>
        {{ "Buy" | translate }}
    </button>
</div> -->
