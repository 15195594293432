import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { TenantConfig } from 'src/app/core/models/tenant-management/tenant-config';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';

@Component({
    selector: 'app-subtenant-configuration',
    templateUrl: './subtenant-configuration.component.html',
    styleUrls: ['./subtenant-configuration.component.scss']
})
export class SubtenantConfigurationComponent implements OnInit, AfterViewInit
{
    @Input() tenant: Tenant;
    @Output() done: EventEmitter<TenantConfig>;
    public configForm: FormGroup;

    constructor(
        private toast: I18nToastService
    )
    {
        this.configForm = new FormGroup(
            {
                uuid: new FormControl(null, Validators.required),
                budget_trigger_docs_transfer_amount: new FormControl(null, Validators.required),
                budget_trigger_whats_transfer_amount: new FormControl(null, Validators.required),
                budget_trigger_docs: new FormControl(null, Validators.required),
                budget_trigger_whats: new FormControl(null, Validators.required)
            }
        );
        this.done = new EventEmitter<TenantConfig>();
    }

    ngOnInit(): void
    {
    }

    ngAfterViewInit(): void
    {
        if (this.tenant)
            this.configForm.patchValue(this.tenant);
        else
            throw new Error('Se debe pasar al componente la configuración del tenant usando la prop: tenant');
    }

    public submit(): void
    {
        if (this.configForm.valid)
            this.done.emit(this.configForm.value as TenantConfig);
        else
        {
            this.toast.open('you must fill all the fields', 'accept', 5000, true);
            this.configForm.markAllAsTouched();
        }
    }
}
