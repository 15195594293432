import { ColDef } from 'ag-grid-community';

export const SUBTENANT_LIST_COL_DEF = [
    { width: 180, field: 'company', headerName: 'company' },
    { width: 180, field: 'name', headerName: 'name' },
    { width: 180, field: 'surname', headerName: 'surname' },
    { width: 180, field: 'email', headerName: 'email' },
    { width: 100, field: 'phone_no', headerName: 'phone_no' },
    { width: 350, field: 'documents', headerName: 'Documents', cellRenderer: 'SubtenantListProductResume' },
    { width: 350, field: 'whatsapp', headerName: 'whatsapp', cellRenderer: 'SubtenantListProductResume' },
    { width: 350, field: 'sms', headerName: 'sms', cellRenderer: 'SubtenantListProductResume' },
    {
        field: 'actions', headerName: 'actions',
        cellRenderer: 'SubtenantListTableActions',
        pinned: 'right',
        width: 130
    }
] as ColDef[];