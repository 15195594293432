<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <app-mobile-stepper [currentStep]="4"></app-mobile-stepper>
    <div class="title flex-row-between">
        4. {{ 'Set up custom text' | translate }}
        <!-- <button (click)="openHelp()" color="primary" mat-icon-button>
            <mat-icon>help</mat-icon>
        </button> -->
    </div>

    <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    <ng-container *ngTemplateOutlet="form"></ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    <ng-container *ngTemplateOutlet="form"></ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #subtitle>
    <div class="subtitle">
        {{ 'Fill al the custom fields' | translate }}
    </div>
</ng-template>

<ng-template #form>
    <ng-container *ngIf="this.service.data$ | async as tplData">
        <form *ngIf="formGroup" [formGroup]="formGroup">
            <div class="mar-t10" *ngFor="let contacts of tplData.contacts; let contactQt = index" formGroupName="{{contactQt}}">
                <strong>{{ 'Texts for: ' | translate }}</strong>
                <i>{{ contacts | contactsNameExtractor }}</i>
                <ng-container *ngFor="let inputName of tplData.tpl.text_name">
                    <mat-form-field appearance="fill" class="mar-t10" style="width: 100%;"
                                    [hintLabel]="inputName | translate">
                        <mat-label translate>{{inputName}}</mat-label>
                        <textarea [placeholder]="'' | translate" style="height: 40px" matInput
                                  formControlName="{{inputName}}"></textarea>

                        <ng-container *ngIf="formGroup.controls[contactQt].get(inputName) as frmCtrl">
                            <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched" >
                                <mat-error *ngFor="let err of frmCtrl.errors | keyvalue" >
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>

                    </mat-form-field>
                </ng-container>
            </div>
        </form>
    </ng-container>
</ng-template>

<ng-template #footer>
    <div class="button-row">
        <button mat-stroked-button color="primary" (click)="previous()">{{'Back' | translate}}</button>
        <button mat-raised-button color="accent" (click)="next()" [disabled]="formGroup.status == 'INVALID'" >{{ 'Next' | translate }}</button>
    </div>
</ng-template>
