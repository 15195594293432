<div fxLayout="column" #container [style.width.px]="initialWidth ? initialWidth : 'auto'" fxFlex class="height100 w100">
    <div *ngIf="table" fxLayout="row" fxFlex style="overflow-y: auto; overflow-x: auto;">
        <table *ngIf="initialWidth && table && table.columns.length > 0" class="w100" mat-table
            [dataSource]="table.rows">
            <ng-container *ngFor="let cols of table.columns">

                <!-- ACTION COL -->
                <ng-container *ngIf="cols.column == 'action'" [matColumnDef]="'action'" [sticky]="true">
                    <th style="vertical-align: middle;" mat-header-cell *matHeaderCellDef> Accion</th>
                    <td class="rbt" style="vertical-align: middle;font-size: 12px;" mat-cell *matCellDef="let element">
                        <!-- DOCUMENT TRAY -->
                        <ng-container *ngIf="tableId=='document_tray'">
                            <button mat-icon-button (click)="docServ.getPdf(element.id)">
                                <i class="material-icons">
                                    cloud_download
                                </i>
                            </button>
                            <button *ngIf="element.action != 4" mat-icon-button (click)="docServ.retrySend(element.id)">
                                <i class="material-icons">
                                    redo
                                </i>
                            </button>
                        </ng-container>
                        <!-- INVOIVES -->
                        <ng-container *ngIf="tableId == 'invoices'">
                            <button mat-icon-button (click)="tenant.downloadAndOpen(element)">
                                <i class="material-icons">
                                    cloud_download
                                </i>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- AUDIT TRAIL COL -->
                <ng-container *ngIf="cols.column == 'audit_trail'" [matColumnDef]="'audit_trail'" [sticky]="true">
                    <th style="vertical-align: middle;" mat-header-cell *matHeaderCellDef>Audit trail</th>
                    <td class="rbt" style="vertical-align: middle;font-size: 12px;" mat-cell *matCellDef="let element">
                        <a matTooltip="Ver audit trail" mat-icon-button (click)="docServ.getPdfAuditTrail(element.id)"> <!-- </a>[routerLink]="[element.id, 'audit_trail']"> -->
                            <i mat-icon class="material-icons">
                                fingerprint
                            </i>
                        </a>
                    </td>
                </ng-container>

                <!-- ADDITION DOCS COL -->
                <ng-container *ngIf="cols.type_id == '6'" [matColumnDef]="cols.column" [sticky]="cols.isSticky">
                    <th style="vertical-align: middle;" mat-header-cell *matHeaderCellDef> {{cols.columnTitle}} </th>
                    <ng-container *ngIf="cols.column !== 'min' && cols.column !== 'max'">
                        <td class="rbt" style="vertical-align: middle;font-size: 12px;" [style.padding-right]="cols.paddingRight" [style.text-align]="cols.aligment" mat-cell
                            *matCellDef="let element">
                            <ng-container *ngIf="element.status_id == 'Finalizado'">
                                <button mat-icon-button (click)="docServ.downloadAttachment(element.id, cols.input_id, element[cols.column])">
                                    <i class="material-icons">
                                        cloud_download
                                    </i>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>

                <!-- RESTO DE COLUMNAS -->
                <ng-container *ngIf="['audit_trail', 'action'].indexOf(cols.column) == -1 && cols.type_id != '6'" [matColumnDef]="cols.column" [sticky]="cols.isSticky">
                    <th style="vertical-align: middle;" mat-header-cell *matHeaderCellDef> {{cols.columnTitle}} </th>
                    <ng-container *ngIf="cols.column === 'min' || cols.column === 'max' ">
                        <td class="rbt" style="vertical-align: middle;font-size: 12px;" mat-cell
                            *matCellDef="let element">
                            {{element[cols.column] != null ? (element[cols.column]| prepareDate | date:'short') : '-' }} </td>
                    </ng-container>
                    <ng-container *ngIf="cols.column !== 'min' && cols.column !== 'max'">
                        <td class="rbt" style="vertical-align: middle;font-size: 12px;" [style.padding-right]="cols.paddingRight" [style.text-align]="cols.aligment" mat-cell
                            *matCellDef="let element">
                            {{element[cols.column]}} </td>
                    </ng-container>
                </ng-container>
            </ng-container>


            <ng-container *ngIf="table.isHeaderSicky">
                <tr mat-header-row *matHeaderRowDef="table.getRawColumns(); sticky: true"></tr>
            </ng-container>
            <ng-container *ngIf="!table.isHeaderSicky">
                <tr mat-header-row *matHeaderRowDef="table.getRawColumns();"></tr>
            </ng-container>

            <ng-container *ngIf="table.hasFooter && table.isFooterSticky">
                <tr mat-footer-row *matFooterRowDef="table.getRawColumns(); sticky: true"></tr>
            </ng-container>
            <ng-container *ngIf="table.hasFooter && !table.isFooterSticky">
                <tr mat-footer-row *matFooterRowDef="table.getRawColumns();"></tr>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: table.getRawColumns();"></tr>
        </table>
    </div>
    <div *ngIf="table" fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator *ngIf="table.hasPaginator" [pageIndex]="0" [length]="table.paginator.total"
            [pageSize]="table.paginator.per_page" (page)="Navigate($event)">
        </mat-paginator>
    </div>
    <div *ngIf="!table" fxLayout="row" fxFlex fxLayoutAlign="center center">
        <b>Cargando...</b>
    </div>
</div>
