import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { Contact } from 'src/app/core/models/contacts/contact';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';

@Component({
    selector: 'app-contact-simple',
    templateUrl: './contact-simple.component.html',
    styleUrls: ['./contact-simple.component.scss']
})
export class ContactSimpleComponent extends BaseComponent implements OnInit
{

    @Input() contact: Contact;
    @Input() editable: boolean = false;
    @Input() showDeleteButton: boolean = false;
    @Output() editing: EventEmitter<Contact>;
    @Output() selected: EventEmitter<void>;
    constructor(private sideNav: SidenavService, public service: ConstactsService)
    {
        super(sideNav);
        this.editing = new EventEmitter<Contact>();
        this.selected = new EventEmitter<void>();
    }

    ngOnInit()
    {
    }

    public edit(): void
    {
        this.editing.emit(this.contact);
    }
    public select(): void
    {
        this.selected.emit();
    }

}
