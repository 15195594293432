import { Injectable } from '@angular/core';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { CustomTextConf } from '../models/contacts/custom-text-conf.model';
import { TenantCVS } from '../models/tenant-management/tenant-cvs.model';
import { map, catchError, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TenantRepositoryService
{

    constructor(
        private WsHelper: WebServiceHelperService,
    ) { }

    public getTenantSeal(): Observable<Blob>
    {
        const url = environment.backEndDomain + 'api/customer/stamp';
        return this.WsHelper.get<Blob>(url, { responseType: 'blob' });
    }
    public getTenantLogo(): Observable<Blob>
    {
        const url = environment.backEndDomain + 'api/customer/logo';
        return this.WsHelper.get<Blob>(url, { responseType: 'blob' });
    }
    public setTenantSeal(file: File): Observable<void>
    {
        const url = environment.backEndDomain + 'api/customer/stamp';
        const payload = new FormData();
        payload.set('input_img', file, file.name);

        return this.WsHelper.post(url, payload);
    }
    public setTenantLogo(file: File): Observable<void>
    {
        const url = environment.backEndDomain + 'api/customer/logo';
        const payload = new FormData();
        payload.set('input_img', file, file.name);

        return this.WsHelper.post(url, payload);
    }
    public deleteTenantLogo(): Observable<string>
    {
        const url = environment.backEndDomain + 'api/customer/logo';
        return this.WsHelper.delete(url, null);
    }
    public deleteTenantSeal(): Observable<string>
    {
        const url = environment.backEndDomain + 'api/customer/stamp';
        return this.WsHelper.delete(url, null);
    }
    public getPacksList()
    {
        const url = environment.backEndDomain + 'api/customer/all_packs';
        return this.WsHelper.get<any>(url);
    }
    public getWhatsappPacksList()
    {
        const url = environment.backEndDomain + 'api/customer/whatsapp_packs';
        return this.WsHelper.get<any>(url);
    }
    public setPaymentCard(token)
    {
        const url = environment.backEndDomain + 'api/customer/credit_card';
        return this.WsHelper.post(url, token);
    }
    public buy(pack)
    {
        const url = environment.backEndDomain + 'api/customer/packs';
        return this.WsHelper.post(url, { pack: pack.id });
    }
    public downloadPdf(invoice)
    {
        const url =
            environment.backEndDomain +
            'api/customer/invoice/' +
            invoice.id +
            '/pdf';
        return this.WsHelper.get(url, { responseType: 'blob' });
    }
    public getTenantData(): Observable<Tenant>
    {
        const url = environment.backEndDomain + 'api/customer';
        return this.WsHelper.get(url);
    }
    public setTenantData(tenant)
    {
        const url = environment.backEndDomain + 'api/customer';
        return this.WsHelper.post(url, tenant);
    }
    public getInvoicesList(page: number): Observable<any>
    {
        const url =
            environment.backEndDomain + 'api/customer/invoice?page=' + page;

        return this.WsHelper.get(url);
    }


    public getCvsConfig(): Observable<TenantCVS>
    {
        const url = environment.backEndDomain + 'api/customer/cvs';
        return this.WsHelper.get(url);
    }
    public setCvsConfig(config: {
        value: string
    }): Observable<{
        id: number,
        created_at: string,
        updated_at: string,
        key: string,
        type: string,
        value: string
    }>
    {
        const url = environment.backEndDomain + 'api/customer/cvs';
        return this.WsHelper.post(url, config);
    }

   

    public setTenantLegalValidation(legalValidation: boolean): Observable<boolean>
    {
        // const payload = new FormData();
        // payload.set('input_img', file, file.name);
        const url = environment.backEndDomain + 'api/customer/legal_verification';
        return this.WsHelper.post(url, { legal_validation: legalValidation });
    }

    public setOTPAuth(otpAuth: boolean): Observable<boolean>{
        const url = environment.backEndDomain + 'api/customer/otp_auth';
        return this.WsHelper.post(url, {otp_auth: otpAuth});
    }

}
