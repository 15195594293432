import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'contentFilter',
    pure: false
})
export class ContentFilterPipe implements PipeTransform
{
    transform(items: any[], filter: Object): any
    {
        if (!items || !filter || filter === {})
        {
            return items;
        }

        // Iteramos sobre las diferentes keys del objeto filter para montar filtros.
        _.forOwn(filter, (value, key) =>
        {
            if (value)
                items = items.filter(item => { return item[key].toLowerCase().indexOf(value) !== -1 });
        });

        // Una vez todo filtrado, devolvemos los items
        return items;
    }
}
