<mat-tab-group *ngIf="form" [formGroup]="form">
    <ng-container *ngFor="let lang of data">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="lang-tab">
                    <ng-container *ngIf="(responsive.isMobile$ | async) as isMobile; else noMobileTitle">
                        {{ (mapping[lang.lang] | translate) | firstTwoLetters }}
                    </ng-container>
                    <ng-template #noMobileTitle>
                        {{ mapping[lang.lang] | translate }}
                    </ng-template>
                    <mat-error *ngIf="form.controls[lang.lang].errors">
                        {{ 'Not valid' | translate }}
                    </mat-error>
                </div>
              </ng-template>
            <mat-form-field>
                <textarea matInput [name]="lang.lang" [formControlName]="lang.lang" [id]="lang.lang"></textarea>
            </mat-form-field>
        </mat-tab>
    </ng-container>
</mat-tab-group>