import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateEditorGuard } from 'src/app/core/guards/template-editor.guard';
import { DocumentDefinitionComponent } from '../send-assistant/components/document-definition/document-definition.component';
import { TemplateEditorComponent } from '../send-assistant/components/template-editor/template-editor.component';
import { TemplateListComponent } from './components/template-list/template-list.component';
import { TemplateManagementComponent } from './template-management.component';

const routes: Routes = [
    {
        path: '',
        component: TemplateManagementComponent,
        children: [
            {
                path: 'list',
                component: TemplateListComponent,
            },
            {
                path: 'create',
                component: DocumentDefinitionComponent,
                data: { isTemplate: true }
            },
            {
                path: 'edit/:id',
                component: DocumentDefinitionComponent,
                data: { isTemplate: true }
            },
            {
                path: 'configure/:id',
                component: TemplateEditorComponent,
                data: { isEditOnly: true },
                canActivate: [TemplateEditorGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TemplateManagementRoutingModule
{
}
