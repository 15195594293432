<table mat-table #table [dataSource]="tableAdapter.data" style="width: 100%">
    <!-- <ng-container *ngFor="let colDef of tableAdapter.getStdCols()">
        <ng-container [matColumnDef]="colDef.column">
            <th mat-header-cell *matHeaderCellDef>{{ colDef.columnTitle | translate}}</th>
            <td mat-cell *matCellDef="let row">{{ row[colDef.column] }}</td>
        </ng-container>
    </ng-container> -->


    <ng-container [matColumnDef]="'id'">
        <th mat-header-cell *matHeaderCellDef>{{ 'id' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['id'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'name'">
        <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['name'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'redirect'">
        <th mat-header-cell *matHeaderCellDef>{{ 'redirect' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['redirect'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'revoked'">
        <th mat-header-cell *matHeaderCellDef>{{ 'revoked' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['revoked'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'secret'">
        <th mat-header-cell *matHeaderCellDef>{{'secret' | translate}}</th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row['secret_status']">
                {{row['secret']}}
            </ng-container>
            <span class="material-icons" (click)="row['secret_status'] = !(row['secret_status'] || false)" *ngIf="!row['secret_status']" >remove_red_eye</span>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'edit'">
        <th mat-header-cell *matHeaderCellDef>{{'edit' | translate}}</th>
        <td mat-cell *matCellDef="let row">
            <span class="material-icons" (click)="tableAdapter.updateKey(row)"  >edit</span>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'revoked_action'">
        <th mat-header-cell *matHeaderCellDef>{{'revoke' | translate}}</th>
        <td mat-cell *matCellDef="let row">
            <span class="material-icons" (click)="tableAdapter.revokeKey(row)"  >delete</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableAdapter.getAllColumnsName()"></tr>
    <tr mat-row *matRowDef="let row; columns: tableAdapter.getAllColumnsName();"></tr>
</table>

<mat-paginator #paginator [pageIndex]="tableAdapter.currentPage" [length]="tableAdapter.elementCount"
               [pageSize]="tableAdapter.pageSize" (page)="tableAdapter.paginatorEventHandler($event)">
</mat-paginator>
