import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from 'src/app/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TemplateListComponent} from './components/template-list/template-list.component';
import {TemplateManagementRoutingModule} from './template-management-routing.module';
import {ResponsiveTopbarModule} from '../../content/responsive-topbar/responsive-topbar.module';
import {TemplateEditorComponent} from './components/template-editor/template-editor.component';
import {TemplateFormComponent} from './components/template-form/template-form.component';
import {TemplateCardComponent} from './components/template-card/template-card.component';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { TemplateManagementComponent } from './template-management.component';
import { TemplateNewNameDialogComponent } from './components/template-new-name-dialog/template-new-name-dialog.component';

@NgModule({
    declarations: [
        TemplateListComponent,
        TemplateCardComponent,
        TemplateFormComponent,
        TemplateEditorComponent,
        TemplateManagementComponent,
        TemplateNewNameDialogComponent
    ],
    imports: [
        CommonModule,
        TemplateManagementRoutingModule,
        TranslateModule,
        MaterialModule,
        UserMenuModule,
        FormsModule,
        ReactiveFormsModule,
        ResponsiveTopbarModule
    ],
    entryComponents: [
        TemplateFormComponent
    ]
})
export class TemplateManagementModule {

    constructor()
    {

    }

}
