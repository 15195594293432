<div fxLayout="column" fxFlex class="mat-typography" style="width: 400px;">
    <div fxLayout="row" class="title mar-b10">Error!</div>
    <div fxLayout="row" fxFlex>
        <p class="rbt">Presupuesto insuficiente</p>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 24px;">
        <button color="primary" mat-stroked-button [mat-dialog-close]="false">Cancelar</button>
        <button color="primary" mat-raised-button   [routerLink]="'tenant_management/invoices'" [mat-dialog-close]="true">Comprar más</button>
    </div>
</div>
