import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tip } from 'src/app/core/models/tip';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';

@Component({
    selector: 'app-tip-modal',
    templateUrl: './tip-modal.component.html',
    styleUrls: ['./tip-modal.component.scss']
})
export class TipModalComponent implements OnInit
{
    @HostBinding('class') classes = 'mat-typography';
    constructor(@Inject(MAT_DIALOG_DATA) public data: Array<Tip>) { }

    ngOnInit(): void
    {
    }

}
