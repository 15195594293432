import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubtenantManagementRoutingModule } from './subtenant-management-routing.module';
import { SubtenantListComponent } from './components/subtenant-list/subtenant-list.component';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { SubtenantEditComponent } from './components/subtenant-edit/subtenant-edit.component';
import { SubtenantManagementComponent } from './subtenant-management.component';
import { SubtenantFormComponent } from './components/subtenant-edit/components/subtenant-form/subtenant-form.component';
import { NewSubtenantDialogComponent } from './components/subtenant-edit/components/new-subtenant-dialog/new-subtenant-dialog.component';
import { SubtenantConfigurationComponent } from './components/subtenant-edit/components/subtenant-configuration/subtenant-configuration.component';
import { SubtenantInvoicesComponent } from './components/subtenant-edit/components/subtenant-invoices/subtenant-invoices.component';
import { CreditTransferDialogComponent } from './components/credit-transfer-dialog/credit-transfer-dialog.component';
import { SubtenantListActionsComponent } from './components/subtenant-list/extras/subtenant-list-actions.component';
import { SubtenantListProductResumeComponent } from './components/subtenant-list/extras/subtenant-list-product-resume.component';


@NgModule({
    declarations: [
        SubtenantListComponent,
        SubtenantEditComponent,
        SubtenantManagementComponent,
        SubtenantFormComponent,
        NewSubtenantDialogComponent,
        SubtenantConfigurationComponent,
        SubtenantInvoicesComponent,
        CreditTransferDialogComponent,
        SubtenantListActionsComponent,
        SubtenantListProductResumeComponent
    ],
    imports: [
        CommonModule,
        SubtenantManagementRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ResponsiveTopbarModule,
        AgGridModule
    ]
})
export class SubtenantManagementModule { }
