<div class="subtitle">
    {{ 'Field' | translate }} {{ data.data.meta.text }}
</div>
<ng-container [formGroup]="outputForm" [ngSwitch]="data.data.meta.output_type">
    <!-- Adjunto -->
    <ng-container *ngSwitchCase="'aditional_doc'">
        <div>
            <mat-form-field class="low-density full-width">
                <input matInput [placeholder]="'File name' | translate" formControlName="file">
                <mat-error>{{'You have to indicate the file name' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-checkbox class="low-density full-width" formControlName="check_nif">{{ 'comprobar que aparece el nif' | translate }}</mat-checkbox>
        </div>
        <ng-container *ngIf="this.customTextConf$ | async as customTextConf">
            <div *ngFor="let ctc of customTextConf.customText">
                <mat-checkbox [checked]="outputForm.controls['check_' + ctc.data_key].value" class="low-density full-width" [formControlName]="'check_' + ctc.data_key">{{ ('comprobar que aparece el ' | translate) + ctc.data_value }}</mat-checkbox>
            </div>
        </ng-container>

        <mat-divider></mat-divider>

        <div>
            <mat-checkbox class="low-density full-width" formControlName="optional_validation">{{ 'validacion opcional' | translate }}</mat-checkbox>
        </div>
    </ng-container>

    <!-- Texto libre -->
    <ng-container *ngSwitchCase="'arbitrary_text_on_send'">
        <div>
            <mat-form-field class="low-density full-width">
                <input matInput [placeholder]="'Field name' | translate" formControlName="field">
                <mat-error>{{'You have to indicate the file name' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="copyStyles"></ng-container>
    </ng-container>

    <!-- Firma -->
    <ng-container *ngSwitchCase="'firma'">
        <div>
            <mat-checkbox class="low-density full-width" formControlName="repeat">{{ 'Repeat on all pages' | translate }}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="low-density full-width" formControlName="repeat_first_page">{{ 'Repeat on first page' | translate }}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="low-density full-width" formControlName="repeat_second_page">{{ 'Repeat on second page' | translate }}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="low-density full-width" formControlName="repeat_second_last_page">{{ 'Repeat on second last page' | translate }}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="low-density full-width" formControlName="repeat_last_page">{{ 'Repeat on last page' | translate }}</mat-checkbox>
        </div>
        <ng-container *ngTemplateOutlet="copyStyles"></ng-container>
    </ng-container>

    <!-- El resto -->
    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="copyStyles"></ng-container>
    </ng-container>

    <ng-template #copyStyles>
        <ng-container *ngIf="(pdfControls.template$ | async) | outputFilter as outputs">
            <ng-container *ngIf="outputs.length > 1">
                <div class="flex-row-between">
                    <mat-form-field class="full-width">
                        <mat-select #copyFrom [placeholder]="'Copy styles from' | translate" class="low-density">
                            <ng-container *ngFor="let draggable of outputs">
                                <mat-option [value]="draggable" *ngIf="draggable.id != data.data.id">
                                    {{ draggable.meta.text }}
                                    - {{ 'Signer' | translate }} {{ draggable.meta.contac_idx + 1 }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <button mat-stroked-button color="primary"
                            class="low-density full-width"
                            (click)="CopyStylesFrom(copyFrom.value)">
                        <mat-icon class="mar-r10">file_copy</mat-icon>
                        {{ 'Copy' | translate }}
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-container>
<div class="pad-t10">
    <button class="full-width" mat-raised-button color="warn" (click)="remove()">
        <mat-icon class="mar-r10">delete</mat-icon>
        {{ 'Delete field' | translate }}
    </button>
</div>
