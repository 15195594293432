<div class="topbar bg vcenter mat-typography">
    <div class="vcenter">
        <button mat-icon-button (click)="OpenSideNav()">
            <mat-icon>menu</mat-icon>
        </button>
        <img style="height: 35px; margin-left: 15px;" src="assets/svg/my_rubric_logo_04.svg">
    </div>
    <div>
        <app-user-menu mat-button class="btn"></app-user-menu>
    </div>
</div>
<div class="grid mat-typography bg-content mat-elevation-z6">
    <div class="full title vcenter">
        Inicio
    </div>
    <app-breakdown-numeric-kpi class="breakdown"></app-breakdown-numeric-kpi>
    <app-log-kpi class="log"></app-log-kpi>
    <ng-container *ngFor="let item of numericDashboard">
        <app-numeric-kpi class="numeric" [type]="item.subType" ></app-numeric-kpi>
    </ng-container>
</div>
