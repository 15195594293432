export class ContactFilters
{
    public customerLegalId = null;
    public fullName = null;
    public email = null;
    public phone_number = null;
    public group_id = null;
    public status = null;
    public user_id = null;

    constructor()
    {}
}
