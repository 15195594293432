import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable} from 'rxjs';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { environment } from 'src/environments/environment';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit, OnDestroy
{
    private signatureLink: BehaviorSubject<string>;
    public signatureLink$: Observable<string>;
    private iframeLink: BehaviorSubject<string>;
    public iframeLink$: Observable<string>;
    public elementType = NgxQrcodeElementTypes.URL;
    public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    
    constructor(
        public responsive: ResponsiveService,
        private activeRoute: ActivatedRoute,
        private router: Router
    ) {
        this.signatureLink = new BehaviorSubject<string>(null);
        this.signatureLink$ = this.signatureLink.asObservable();
        
        this.iframeLink = new BehaviorSubject<string>(null);
        this.iframeLink$ = this.iframeLink.asObservable();
    }

    ngOnInit(): void
    {
        this.activeRoute.params.subscribe(
            params =>{
                this.signatureLink.next(`${environment.backEndDomain}template_form_unattended?token=${params.id}`);
                this.iframeLink.next(`<iframe src="${environment.backEndDomain}template_form_unattended?token=${params.id}" width="100%" height="100%" frameborder="0" style="border: 0; overflow: hidden; min-height: 500px; min-width: 600px;"></iframe>`);
            }
        );
    }
    ngOnDestroy(): void
    {
        this.signatureLink.next(null);
        this.iframeLink.next(null);
    }

    public next(): void
    {
        this.signatureLink.next(null);
        this.iframeLink.next(null);

        this.router.navigate(['editable_pdf/list_unattended']);
    }

    public downloadQRCode($event): void{
        const image = document.querySelector('ngx-qrcode').querySelector('img');
        const link = document.createElement('a');
        link.href = image.src;
        link.download = 'qr_code.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
