import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseTableComponent } from 'src/app/shared/auto-table/components/base-table/base-table.component';

@Component({
  selector: 'app-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.css']
})
export class InvoicesTableComponent extends BaseTableComponent {

}
