import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './user/user.component';
import { UserManagementComponent } from './user-management.component';
import { UserFormDataComponent } from './user-form-data/user-form-data.component';
import { ApiKeyFormComponent } from './api-key-form/api-key-form.component';
import { ResponsiveTopbarModule } from 'src/app/content/responsive-topbar/responsive-topbar.module';
import { TipModalModule } from 'src/app/content/tip-modal/tip-modal.module';
import { MaterialModule } from 'src/app/material/material.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TipModule } from 'src/app/content/tip/tip.module';
import { UserMenuModule } from 'src/app/content/user-menu/user-menu.module';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserSignaturesModule } from 'src/app/content/user-signatures/user-signatures.module';
import { LacMatTelInputModule } from 'lac-mat-tel-input';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        FormsModule,
        UserMenuModule,
        PipesModule,
        ReactiveFormsModule,
        TipModule,
        ResponsiveTopbarModule,
        TipModalModule,
        UserManagementRoutingModule,
        UserSignaturesModule,
        LacMatTelInputModule
    ],
    declarations: [UserComponent, UserManagementComponent, UserFormDataComponent],
    exports: [UserComponent, UserManagementComponent, UserFormDataComponent],
    entryComponents: [UserFormDataComponent]
})
export class UserManagementModule { }
