import { Injectable } from '@angular/core';
import
    {
        ActivatedRouteSnapshot,
        CanActivate,
        NavigationExtras,
        Router,
        RouterStateSnapshot,
        UrlTree
    } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { Contact } from 'src/app/core/models/contacts/contact';
import { DocumentTemplateManagementService } from 'src/app/core/services/content/document-template-management.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ContactDefinitionService } from 'src/app/core/services/pages/contact-definition.service';


@Injectable({
    providedIn: 'root'
})
export class ContactDefinitionGuard implements CanActivate
{
    constructor(
        private router: Router,
        private toast: I18nToastService,
        private service: ContactDefinitionService,
        private tplMng: DocumentTemplateManagementService
    )
    {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const extras = this.router.getCurrentNavigation().extras;
        return of(true)
            .pipe(
                mergeMap(val =>
                {
                    if (!next.params.id)
                    {
                        throw new Error('NO ID');
                    }

                    // const extras = CONTACT_DEFINITION_ROUTE_MOCK;
                    if (!extras.state)
                    {
                        throw new Error('NO STATE');
                    }// const extras = CONTACT_DEFINITION_ROUTE_MOCK;
                    if (!extras.state.template)
                    {
                        throw new Error('NO TEMPLATE');
                    }

                    const dataFromEditor: TemplateData = extras.state.template;
                    const isDataFromEdirorValid = this.templateDataValidation(dataFromEditor);

                    // Mantenemos el estado de los textos arbitrarios si los hay.
                    const arbitraryText = extras.state.arbitraryText;
                    if (arbitraryText)
                        this.service.arbitraryText = arbitraryText
                    else
                        this.service.arbitraryText = {};

                    const wasGroup = extras.state.wasGroup;
                    const group = extras.state.group;
                    if (wasGroup)
                    {
                        this.service.wasGroup = true;
                        this.service.group = group;
                    }

                    const cvs = extras.state.cvs;
                    this.service.cvs = cvs;

                    if (!isDataFromEdirorValid)
                    {
                        throw new Error();
                    }
                    this.service.setTemplate(dataFromEditor);

                    /**
                     * En este punto interesa revisar si en los extras
                     * está la información de los signers. Puede ser que
                     * navege el usuario hacia esta pantalla des de la
                     * text-definition.
                     */
                    this.checkIfThereAreSigners(extras);

                    return of(true);

                }),
                catchError((e: Error) =>
                {

                    if (e.message !== 'NO STATE')
                    {
                        throw e;
                    }

                    return this.tplMng.getTplInput(next.params.id)
                        .pipe(
                            tap(t =>
                            {
                                this.service.setTemplate(t.tpl);
                            }),
                            mapTo(true)
                        );
                }),
                catchError((e: Error) =>
                {
                    console.error(e.message);

                    this.toast.open(
                        'The template data was not correct',
                        'Accept',
                        5000
                    );

                    this.router.navigate(['send_assistant', 'file']);

                    return of(false);
                }),
                take(1)
            );
    }

    private templateDataValidation(dataFromEditor: TemplateData): boolean
    {
        const toCompare = new TemplateData();
        const keys = Object.keys(dataFromEditor);
        let valid = true;
        Object.keys(toCompare).forEach(key =>
        {
            if (keys.indexOf(key) == -1)
            {
                valid = false;
            }
        });

        return valid;
    }

    private checkIfThereAreSigners(extras: NavigationExtras): void
    {
        const signers: Array<Array<Contact>> = extras.state.signers;
        if (signers)
        {
            const areTheyValid = this.signersValidation(signers);
            if (areTheyValid)
                this.service.setSigners(signers);
        }
    }

    private signersValidation(signers: Array<Array<Contact>>): boolean
    {
        let valid = true;
        signers.forEach(batch =>
        {
            batch.forEach(signer =>
            {
                if (!signer)
                    valid = false;
            });
        });

        return valid;
    }
}
