<div class="search-box">
    <input class="search" matInput [placeholder]="'Contact name' | translate" [(ngModel)]="searchText" (input)="textChanged($event)">
    <mat-icon matSuffix>search</mat-icon>
</div>
<div class="create-contact-btn" matRipple (click)="addContact()">
    <div class="fab-initials create-contact-fab">
        +
    </div>
    <div><b>{{ 'Add contact' | translate }}</b></div>
</div>
<div class="separator"></div>
<div class="contact-list">
    <div class="contact-list-scroller">
        <app-contact-simple
            *ngFor="let contact of contactSubject | async" matRipple [contact]="contact" [editable]="true"
            (selected)="selectContact(contact)"
            (editing)="editingContact($event)"
            class="mar-t10 contact">
        </app-contact-simple>
    </div>
</div>
