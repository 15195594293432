import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateEditorGuard } from 'src/app/core/guards/template-editor.guard';
import { DocumentTemplateFormListComponent } from './components/document-form-list/document-template-form-list.component';
import { DocumentTemplateFormManagementComponent } from './document-template-form-management.component';
import { DocumentTemplateFormDefinitionComponent } from '../send-assistant/components/document-definition/components/document-template-form-definition/document-form-definition.component';
import { DocumentFormEditorComponent } from '../send-assistant/components/template-editor/components/document-form-editor/document-form-editor.component';
import { ShareComponent } from './components/share/share.component';

const routes: Routes = [
    {
        path: '',
        component: DocumentTemplateFormManagementComponent,
        children: [
            {
                path: 'list',
                component: DocumentTemplateFormListComponent,
            },
            {
                path: 'create',
                component: DocumentTemplateFormDefinitionComponent,
                data: { isTemplate: true }
            },
            {
                path: 'edit/:id',
                component: DocumentTemplateFormDefinitionComponent,
                data: { isTemplate: true }
            },
            {
                path: 'configure/:id',
                component: DocumentFormEditorComponent,
                data: { isEditOnly: true },
                canActivate: [TemplateEditorGuard]
            },
            {
                path: 'share/:id',
                component: ShareComponent
            }
        ]
    }
];
                
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DocumentTemplateFormManagementRoutingModule
{
}
