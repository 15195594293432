import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { SelectedGroupService } from 'src/app/core/services/content/groups/selected-group.service';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';

@Component({
    selector: 'app-group-hierarchy-list',
    templateUrl: './group-hierarchy-list.component.html',
    styleUrls: ['./group-hierarchy-list.component.scss']
})
export class GroupHierarchyListComponent implements OnInit
{
    @Input() showButton: boolean = true;
    @Output() public selected: EventEmitter<ContactNode>;

    constructor(
        public service: ConstactsService
    ) { }

    ngOnInit(): void
    {
    }
}
