<table mat-table #table [dataSource]="tableAdapter.data" style="width: 100%">
    <!-- <ng-container *ngFor="let colDef of tableAdapter.getStdCols()">
        <ng-container [matColumnDef]="colDef.column">
            <th mat-header-cell *matHeaderCellDef>{{ colDef.columnTitle | translate}}</th>
            <td mat-cell *matCellDef="let row">{{ row[colDef.column] }}</td>
        </ng-container>
    </ng-container> -->


    <ng-container [matColumnDef]="'id'">
        <th mat-header-cell *matHeaderCellDef>{{ 'id' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['id'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'name'">
        <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['name'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'created_at'">
        <th mat-header-cell *matHeaderCellDef>{{ 'created_date' | translate}}</th>
        <td mat-cell *matCellDef="let row">{{ row['created_at'] }}</td>
    </ng-container>

    <ng-container [matColumnDef]="'preview'">
        <th mat-header-cell *matHeaderCellDef>{{ 'See' | translate}}</th>
        <td mat-cell *matCellDef="let row">
            <button mat-icon-button
            (click)="preview(row['id'])">
                <mat-icon>
                    visibility
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableAdapter.getAllColumnsName()"></tr>
    <tr mat-row *matRowDef="let row; columns: tableAdapter.getAllColumnsName();"></tr>
</table>

<mat-paginator #paginator [pageIndex]="tableAdapter.currentPage" [length]="tableAdapter.elementCount"
               [pageSize]="tableAdapter.pageSize" (page)="tableAdapter.paginatorEventHandler($event)">
</mat-paginator>
