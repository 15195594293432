import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { SelectedGroupService } from 'src/app/core/services/content/groups/selected-group.service';

@Component({
    selector: 'app-group-tree',
    templateUrl: './group-tree.component.html',
    styleUrls: ['./group-tree.component.scss']
})
export class GroupTreeComponent implements OnInit
{
    @Input() showButton: boolean = true;
    @Input() node: ContactNode;

    constructor(
        public service: SelectedGroupService
    ) { }

    ngOnInit(): void
    {
    }
}
