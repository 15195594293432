<div class="centered">
    <img *ngIf="!logo" src="assets/svg/my_rubric_logo_03.svg">
    <img *ngIf="logo" [src]="logo" style="max-width: 80px;">
</div>
<div class="greeting">
    {{ 'Hi' | translate: contacts[0] ? {value: contacts[0][0].name} : {value:'Usuario'} }}
</div>
<div>
    {{ template.mail_text | templateMessage: contacts[0] ? contacts[0][0] : null }}
</div>
<div>
    <b>{{'Document name' | translate}}:</b>
    {{ template.name }}
</div>
<div>
    <b>{{ 'Document signers' | translate }}:</b>
    {{ contacts[0] ? contacts[0][0].name : null }} <ng-container
        *ngIf="contacts[0] && contacts[0][0] && contacts[0][0].company">({{ contacts[0][0].company }})</ng-container>
</div>
<div class="centered">
    <button mat-raised-button color="primary">
        {{ 'Ver document a firmar' | translate }}
    </button>
</div>
<div>
    {{ 'Thanks a lot' | translate }}
</div>
<div>
    {{ 'if you need more help, you can contact us using this email' | translate }}: <a href="#">support@myrubric.com</a>
</div>
<div>
    <p>{{ 'greetings' | translate }},<br>{{ 'MyRubric team' | translate }}</p>
</div>
