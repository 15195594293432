export class Tenant
{
    public address: string;
    public budget_trigger_action: any;
    public budget_trigger_docs_transfer_amount: any;
    public budget_trigger_docs: any;
    public budget_trigger_whats_transfer_amount: any;
    public budget_trigger_whats: any;
    public card_last_four: any;
    public city: string;
    public company: string;
    public country_id: number;
    public created_at: string;
    public doc_bougth: number;
    public docs_budget: number;
    public doc_spent: number;
    public doc_transferred_in: number;
    public doc_transferred_out: number;
    public email: string;
    public fiscal_id: string;
    public id: number;
    public is_partner: number;
    public locale: string;
    public name: string;
    public parent_id: number;
    public phone_no: number;
    public postal_code: number;
    public region: string;
    public status_docs_limit_trigger: string;
    public status_whats_limit_trigger: string;
    public surname: string;
    public twilio_phone: any;
    public twilio_sid: any;
    public twilio_token: any;
    public updated_at: string;
    public uuid: string;
    public whats_bougth: number;
    public whats_budget: number;
    public whats_spent: number;
    public whats_transferred_in: number;
    public whats_transferred_out: number;
    public sms_bougth: number;
    public sms_spent: number;
    public sms_transferred_in: number;
    public sms_transferred_out: number;
    public sms_budget: number;
}
