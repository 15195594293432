import
    {
        Component,
        OnInit,
        ViewChild,
        ViewContainerRef,
        ComponentFactoryResolver,
        Inject,
        ComponentRef,
        AfterViewInit,
        OnDestroy,
        HostBinding
    } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogData } from '../shared/DialogStructure';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent<TComponent> implements OnInit, AfterViewInit, OnDestroy
{
    @HostBinding('class') classes = 'mat-typography';
    @ViewChild('target', { read: ViewContainerRef }) vcRef: ViewContainerRef;
    public componentRef: ComponentRef<any>;

    private unsubscriber: Subject<void>;

    constructor
        (
            private resolver: ComponentFactoryResolver,
            @Inject(MAT_DIALOG_DATA) public injectedData: DialogData<any>,
            private dialogRef: MatDialogRef<DialogComponent<TComponent>>
        )
    {
        this.unsubscriber = new Subject<void>();
    }

    ngOnInit()
    { }
    ngAfterViewInit(): void
    {
        const factory = this.resolver.resolveComponentFactory(this.injectedData.dynamycComponent);
        this.componentRef = this.vcRef.createComponent(factory);

        this.componentRef.instance.setData(this.injectedData);

        // Subscribe al binding.
        this.componentRef.instance.binding
        .pipe(
            takeUntil(this.unsubscriber)
        )
        .subscribe(result =>
        {
            if (result)
            {
                this.close(result);
            }
            else
            {
                console.log('Nome sierro habido un fallo equisde');
            }
        });
    }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public action(args?: any): void
    {
        if (this.componentRef.instance !== undefined)
        {
            this.componentRef.instance.action(args);
        }
    }

    public close(result: any): void
    {
        this.dialogRef.close(result);
    }

}
