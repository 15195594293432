import { Tip } from 'src/app/core/models/tip';

export const DOC_DEF_TIPS: Tip[] = [
    {
        title: 'Choose document',
        body: `tip_1_body`
    },
    {
        title: 'Choose name',
        body: 'tip_2_body'
    },
    {
        title: 'Signers',
        body: 'tip_3_body'
    }
];
export const DOC_FORM_DEF_TIPS: Tip[] = [
    {
        title: 'Choose document',
        body: `tip_1_body`
    },
    {
        title: 'Choose name',
        body: 'tip_2_body'
    }
];

export const TPL_DEF_TIPS: Tip[] = [
    {
        title: 'Choose document',
        body: `tip_1_body`
    },
    {
        title: 'Choose description',
        body: 'tip_14_body'
    },
    {
        title: 'Choose name',
        body: 'tip_2_body'
    },
    {
        title: 'Signers',
        body: 'tip_3_body'
    },
    // {
    //     title: 'Templates',
    //     body: `tip_15_body`
    // }
]

export const TPL_DEF_TIPS_MBL: Tip[] = [
    {
        title: 'Choose document',
        body: `tip_1_body`
    },
    {
        title: 'Choose description',
        body: 'tip_14_body'
    },
    {
        title: 'Choose name',
        body: 'tip_2_body'
    },
    {
        title: 'Signers',
        body: 'tip_3_body'
    },
    {
        title: 'Template',
        body: 'tip_15_body'
    }
]
