import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveTopbarComponent } from './responsive-topbar.component';
import { MaterialModule } from 'src/app/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { UserMenuModule } from '../user-menu/user-menu.module';

@NgModule({
    declarations: [ResponsiveTopbarComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        UserMenuModule,
        RouterModule
    ],
    exports: [ResponsiveTopbarComponent]
})
export class ResponsiveTopbarModule { }
