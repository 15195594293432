import { MessageBoxService } from 'src/app/shared/message-box.service';
import { BuyAlertComponent } from './../buy-alert/buy-alert.component';
import { BaseComponent } from 'src/app/base/base.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { MaterialTableComponent } from 'src/app/material-table/material-table.component';
import { TableProvider } from 'src/app/material-table/shared/iTableProvider';
import { SendingsService } from 'src/app/shared/sendings.service';
import { catchError, flatMap, take } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { InvoncesTableAdapterService } from 'src/app/core/services/pages/tenant-management/invonces-table-adapter.service';
import { TenantService } from 'src/app/core/services/pages/tenant-management/tenant.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { environment } from 'src/environments/environment';
import { Tenant } from '../../../../core/models/tenant-management/tenant';


@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent extends BaseComponent implements OnInit
{

    public invoices: Array<any>;
    public packs: Array<any>;
    public whatsappPacks: Array<any>;
    public smsPacks: Array<any>;
    public tenant: Tenant;
    public currentPage: number;
    public tableProvider: TableProvider;
    public initialWidth = null;
    private stripe: any;
    @ViewChild(MaterialTableComponent) table: MaterialTableComponent;


    constructor(
        public SideNav: SidenavService,
        public tenantService: TenantService,
        public sendings: SendingsService,
        private translator: TranslateService,
        public invoicesTA: InvoncesTableAdapterService,
        private dialog: MatDialog,
        private msgBoxSrv: MessageBoxService,
        public responsive: ResponsiveService
    )
    {
        super(SideNav);
        this.currentPage = 1;
        this.stripe = window['Stripe'](environment.STRIPE_KEY);
    }

    ngOnInit()
    {

        const obs = [
            this.tenantService.getPacksList(),
            this.tenantService.getTenantData()
        ];

        forkJoin(obs)
            .pipe(take(1))
            .subscribe(r =>
            {
                console.log(r[0].docs);
                this.packs = r[0].docs;
                this.whatsappPacks = r[0].whats;
                this.smsPacks = r[0].sms;
                this.tenant = r[1];
            });


        this.invoicesTA
            .warmUp()
            .pipe(take(1))
            // DUMMY SUB SO THE FLOW START
            .subscribe(x => true);
    }

    public download(invoice): void
    {
        this.tenantService.downloadAndOpen(invoice);
    }

    public openBuyConfirmation(pack): Observable<boolean>
    {
        return this.dialog.open(BuyAlertComponent, {
            width: '400px',
            height: '300px',
            data: {
                pack: pack,
                tenant: {
                    //    last_four_digits: this.tenant.card_last_four
                }
            }
        }).afterClosed();
    }

    public startBuyProcess(pack: any): void
    {
        this.openBuyConfirmation(pack)
            .pipe(
                flatMap(r =>
                {
                    if (r)
                    {
                        return this.tenantService.buy(pack);
                    } else
                    {
                        throw new Error('Rejected');
                    }
                }),
                take(1),
                catchError(err =>
                {
                    return of(null);
                })
            )
            .subscribe(r =>
            {
                if (r)
                    this.stripe.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: r[1].id
                    }).then(function (result)
                    {
                        console.error('something\'s fucky');
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    });
            });
    }


    parseFloat(includedVat: string): number
    {
        return parseFloat(includedVat);
    }
}
