import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {of, Subject} from 'rxjs';
import {mapTo, mergeMap, takeUntil} from 'rxjs/operators';
import { Contact } from 'src/app/core/models/contacts/contact';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import {ConfirmationBoxService} from '../../../../shared/confirmation-box.service';
import {MessageBoxService} from '../../../../shared/message-box.service';
import {TranslateService} from '@ngx-translate/core';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy
{
    public isMouseOver: boolean;
    @Input() contact: Contact;
    @Input() tpls: Array<any>;
    @Input() BaseColor: string;
    @Input() Color: string;
    @Output() Delete = new EventEmitter<Contact>();
    @Output() Edit = new EventEmitter<Contact>();

    private unsubscribe = new Subject<void>();


    constructor(public dialog: MatDialog,
                public service: ConstactsService,
                public alertBox: ConfirmationBoxService,
                public msgBox: MessageBoxService,
                public toast: I18nToastService,
                public trans: TranslateService)
    {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void
    {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public EditContact(): void
    {
        this.Edit.emit(this.contact);
    }

    public DeleteContact(): void
    {
        this.service.disableContact(this.contact)
        .subscribe(
            res =>
            {
                let msg = this.trans.instant('contact x has been disabled', {contact_name: this.contact.name});
                if (this.contact.status == 2)
                    msg = this.trans.instant('contact x has been enabled', {contact_name: this.contact.name});

                this.toast.open(msg, this.trans.instant('Accept'), 5000, false);
                this.Delete.emit(this.contact);
            },
            error =>
            {
                this.msgBox.instant({
                    data: {
                        title: this.trans.instant('error'),
                        body: this.trans.instant('Can not delete'),
                        btn_ok: this.trans.instant('ok')
                    }
                })
            }
        );
    }
}
