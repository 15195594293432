import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TextDefinitionService } from 'src/app/core/services/pages/text-definition.service';
import { TEXT_ROUTE_EXTRAS } from 'src/app/core/mocks/pages/text-definition/route.mock';
import { Contact } from 'src/app/core/models/contacts/contact';

@Injectable({
    providedIn: 'root'
})
export class TextDefinitionGuard implements CanActivate
{
    constructor(
        private router: Router,
        private service: TextDefinitionService,
        private toast: I18nToastService
    )
    { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const extras = this.router.getCurrentNavigation().extras;
        //const extras = TEXT_ROUTE_EXTRAS;
        if (extras.state
            && extras.state.template
            && extras.state.signers
            && extras.state.arbitraryText)
        {
            const signers: Array<Array<Contact>> = extras.state.signers as any;
            const dataFromEditor: TemplateData = extras.state.template;
            const isTemplateDataValid = this.templateDataValidation(dataFromEditor);
            const areSignersValid = this.signersValidation(signers);
            if (isTemplateDataValid)
            {
                if (areSignersValid)
                {
                    this.service.setSigners(signers);
                    this.service.setTemplate(dataFromEditor);
                    this.service.setArbitraryText(extras.state.arbitraryText);

                    this.service.wasGroup = extras.state.wasGroup;
                    this.service.group = extras.state.group;
                    this.service.cvs = extras.state.cvs;

                    return true;
                }
                else
                {

                    console.error('The signers were not correct');
                    this.toast.open(
                        'The signers were not correct',
                        'Accept',
                        5000
                    );

                    this.router.navigate(['send_assistant', 'file']);

                    return false;
                }
            }
            else
            {
                console.error('The template data was not correct');
                this.toast.open(
                    'The template data was not correct',
                    'Accept',
                    5000
                );

                this.router.navigate(['send_assistant', 'file']);

                return false;
            }
        }

        console.error('The template data was not correct');
        this.toast.open(
            'The template data was not correct',
            'Accept',
            5000
        );

        this.router.navigate(['send_assistant', 'file']);

        return false;
    }

    private templateDataValidation(dataFromEditor: TemplateData): boolean
    {
        const toCompare = new TemplateData();
        const keys = Object.keys(dataFromEditor);
        let valid = true;
        Object.keys(toCompare).forEach(key =>
        {
            if (keys.indexOf(key) == -1)
            {
                valid = false;
            }
        });

        return valid;
    }
    private signersValidation(signers: Array<Array<Contact>>): boolean
    {
        let valid = true;
        signers.forEach(batch =>
        {
            batch.forEach(signer =>
            {
                if (!signer)
                    valid = false;
            });
        });

        return valid;
    }
}
