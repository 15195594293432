import { Component, Input, OnInit } from '@angular/core';
import { EditableTableAdapterService } from 'src/app/core/services/pages/editable-tray/editable-table-adapter.service';
import { BaseTableComponent } from 'src/app/shared/auto-table/components/base-table/base-table.component';

@Component({
    selector: 'app-editable-pdf-table',
    templateUrl: './editable-pdf-table.component.html',
    styleUrls: ['./editable-pdf-table.component.scss']
})
export class EditablePdfTableComponent extends BaseTableComponent
{
    @Input('tableAdapter') tableAdapter: EditableTableAdapterService;

    ngOnInit(): void
    {
    }
}
