import { GeonamesActionsUnion, GeonamesActionTypes } from './actions';
import { geonamesInitialState, GeonamesState } from './state';

export function geonamesReducer(state: GeonamesState = geonamesInitialState,
    action: GeonamesActionsUnion): GeonamesState
{
    switch (action.type)
    {
        case GeonamesActionTypes.FETCH:
            return {
                ...state,
                countries: [],
                isLoading: true
            }
        case GeonamesActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                isLoading: false
            }
        case GeonamesActionTypes.FETCH_FAIL:
            return {
                ...state,
                errorMsg: 'cambiar por string',
                isLoading: false
            }
        default:
            return state;
    }
}
