import {Component, OnInit} from '@angular/core';
import {GRIDSTER_CONFIGURATION} from './shared/gridster-config';
import {DashboardItem} from './shared/dashboard-item';
import { matBaseAnimations, BaseComponent } from 'src/app/base/base.component';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';
import { SpinnerService } from 'src/app/shared/spinner.service';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation]
})
export class HomeComponent extends BaseComponent implements OnInit {

    // PROPIEDADES
    public gridConf = GRIDSTER_CONFIGURATION;
    public breakdown: DashboardItem;
    public log: DashboardItem;
    public numericDashboard = new Array<DashboardItem>();

    // CONSTRUCTOR
    constructor(public SideNav: SidenavService, public spinner: SpinnerService, private userService: UserMenuService) {
        super(SideNav);


    }

    ngOnInit() {
        this.breakdown = new DashboardItem(0, 0, 4, 1, 'breakdown', '');
        this.log = new DashboardItem(4, 0, 3, 2, 'log', '');

        // this.numericDashboard.push(new DashboardItem(4, 1, 1, 1, 'numeric', 'template_count'));
        this.userService.user$.subscribe(u => {
            if (u.rol == 'admin') {
                this.numericDashboard.push(new DashboardItem(5, 1, 1, 1, 'numeric', 'users_count'));
            }
        });

        this.numericDashboard.push(new DashboardItem(6, 1, 1, 1, 'numeric', 'doc_count'));
        this.numericDashboard.push(new DashboardItem(4, 1, 1, 1, 'numeric', 'batch_count'));
    }

}
