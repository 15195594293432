import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SubtenantEditState } from './state';

export const getSubtenantEditError = (state: SubtenantEditState) => state.errorMsg;
export const getIsLoading = (state: SubtenantEditState): boolean => state.isLoading;

export const selectSubtenantEditState: MemoizedSelector<
    object,
    SubtenantEditState
> = createFeatureSelector<SubtenantEditState>('subtenantEdit');

export const selectSubtenantEditError: MemoizedSelector<
    object,
    any
> = createSelector(selectSubtenantEditState, getSubtenantEditError);

export const selectSubtenantEditIsLoading: MemoizedSelector<
    object,
    boolean
> = createSelector(
    selectSubtenantEditState,
    getIsLoading
);
