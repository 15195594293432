import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'contactsNameExtractor'
})
export class ContactsNameExtractorPipe implements PipeTransform {
    transform(value: Array<any>): any {

        return value.map((current) => {
            return (current.name + ' ' + current.surname_1 + ' ' + current.surname_2 ).trim();
        }).join(', ');

    }
}
