import { Action } from '@ngrx/store';
import { Country } from 'src/app/models/country.model';

export enum GeonamesActionTypes
{
    FETCH = '[GEONAMES] FETCH',
    FETCH_SUCCESS = '[GEONAMES] FETCH_SUCCESS',
    FETCH_FAIL = '[GEONAMES] FETCH_FAIL',
}

export class Fetch implements Action
{
    readonly type = GeonamesActionTypes.FETCH;
}
export class FetchSuccess implements Action
{
    readonly type = GeonamesActionTypes.FETCH_SUCCESS;
    constructor(public payload: Country[]) { }
}
export class FetchFail implements Action
{
    readonly type = GeonamesActionTypes.FETCH_FAIL;
}

export type GeonamesActionsUnion = Fetch | FetchSuccess | FetchFail;
