<mat-sidenav-container
    class="mat-typography"
    (backdropClick)="opened = false"
    class="height100"
>
    <!-- Menú izquierdo -->
    <mat-sidenav
        mode="over"
        #sidenav
        [opened]="opened"
        class="bg mat-typography sidenav-grid"
    >
        <div>
            <div class="flex-row-center" style="height: 100%;">
                <img
                    style="width: 50%;"
                    src="assets/svg/my_rubric_logo_04.svg"
                />
            </div>
        </div>

        <!-- MOBILE HOLDER TOP -->
        <div *ngIf="(responsive.isMobile$ | async) && service && sendings">
            <div
                class="hello"
                [translate]="'Hi'"
                [translateParams]="{
                    value:
                        service.userData && service.userData.name
                            ? service.userData.name
                            : '...'
                }"
            ></div>
            <mat-divider></mat-divider>
            <div class="available-credit">
                <div>
                    <mat-icon>send</mat-icon>
                    {{ "Documents" | translate }}:
                    {{ service.tenantData?.docs_budget || 0 | number }}
                </div>
                <div>
                    <img
                        style="width: 24px; height: 24px; fill: white;"
                        src="assets/svg/whatsapp-brands.svg"
                    />
                    {{ "Whatsapp" | translate }}:
                    {{ service.tenantData?.whats_budget || 0 | number }}
                </div>
                <div>
                    <mat-icon>sms</mat-icon>
                    {{ "SMS" | translate }}:
                    {{ service.tenantData?.sms_budget || 0 | number }}
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>

        <mat-list class="menu">
            <ng-container *ngIf="responsive.isMobile$ | async">
                <div class="list-label">{{ "Navigation" | translate }}</div>
            </ng-container>
            <ng-container *ngFor="let option of options">
                <mat-list-item
                    (click)="opened = false; sidenav.toggle()"
                    [routerLink]="option.goTo"
                >
                    <div
                        class="flex-row-start-center"
                        [style.paddingLeft.px]="
                            option.padded ? option.padding * 24 : 0
                        "
                        [class]="
                            SetMenuLinkSelected(option.goTo)
                                ? 'selected menu-item'
                                : 'menu-item'
                        "
                        fxLayoutAlign="start center"
                    >
                        <mat-icon class="mar-r10" mat-list-icon>{{
                            option.icon
                        }}</mat-icon>
                        <div class="contrast-text pad0">
                            {{ option.title | translate }}
                        </div>
                    </div>
                </mat-list-item>
            </ng-container>
        </mat-list>

        <!-- MOBILE HOLDER BOTTOM -->
        <ng-container *ngIf="(responsive.isMobile$ | async) && service">
            <div>
                <ng-container
                    *ngIf="service.userData && service.userData.rol === 'admin'"
                >
                    <mat-divider></mat-divider>
                    <div class="list-label mar-t10">
                        {{ "Administration" | translate }}
                    </div>
                    <mat-list class="menu" style="padding-right: 12px;">
                        <mat-list-item
                            *ngIf="
                                service.userData &&
                                service.userData.rol === 'admin'
                            "
                            class="flex-row-start-center"
                            (click)="opened = false; sidenav.toggle()"
                            [class]="
                                SetMenuLinkSelected('manage', true)
                                    ? 'selected menu-item'
                                    : 'menu-item'
                            "
                            [routerLink]="['/tenant_management/manage']"
                        >
                            <mat-icon class="mar-r10" mat-list-icon
                                >settings</mat-icon
                            >
                            <div class="contrast-text pad0">
                                {{ "menu_tenant_managament" | translate }}
                            </div>
                        </mat-list-item>

                        <mat-list-item
                            *ngIf="
                                service.userData &&
                                service.userData.rol === 'admin'
                            "
                            class="flex-row-start-center"
                            (click)="opened = false; sidenav.toggle()"
                            [class]="
                                SetMenuLinkSelected('invoices', true)
                                    ? 'selected menu-item'
                                    : 'menu-item'
                            "
                            [routerLink]="['/tenant_management/invoices']"
                        >
                            <mat-icon class="mar-r10" mat-list-icon
                                >account_balance</mat-icon
                            >
                            <div class="contrast-text pad0">
                                {{ "menu_tenant_invoices" | translate }}
                            </div>
                        </mat-list-item>

                        <mat-list-item
                            *ngIf="
                                service.userData &&
                                service.userData.rol === 'admin'
                            "
                            class="flex-row-start-center"
                            (click)="opened = false; sidenav.toggle()"
                            [class]="
                                SetMenuLinkSelected('user_managament')
                                    ? 'selected menu-item'
                                    : 'menu-item'
                            "
                            [routerLink]="['/user_managament']"
                        >
                            <mat-icon class="mar-r10" mat-list-icon
                                >people</mat-icon
                            >
                            <div class="contrast-text pad0">
                                {{ "menu_users_managament" | translate }}
                            </div>
                        </mat-list-item>
                    </mat-list>
                </ng-container>
            </div>
            <div>
                <mat-list class="menu">
                    <mat-divider></mat-divider>
                    <mat-list-item
                        *ngIf="service.userData"
                        class="flex-row-start-center"
                        (click)="openAbout()"
                    >
                        <mat-icon class="mar-r10" mat-list-icon>info</mat-icon>
                        <div class="contrast-text pad0">
                            {{ "About" | translate }}
                        </div>
                    </mat-list-item>

                    <mat-list-item
                        *ngIf="service.userData"
                        class="flex-row-start-center"
                        (click)="logOut()"
                    >
                        <mat-icon class="mar-r10" mat-list-icon
                            >exit_to_app</mat-icon
                        >
                        <div class="contrast-text pad0">
                            {{ "Exit" | translate }}
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </ng-container>
    </mat-sidenav>

    <!-- Contenido -->
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
