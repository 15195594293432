import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, zip } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { RootStoreState, SubtenantEditStoreActions, SubtenantEditStoreSelectors } from 'src/app/root-store';
import { ERROR_CODES } from 'src/app/root-store/error-codes';
import { SubtenantEditState } from 'src/app/root-store/subtenant-edit-store/state';
import { SubtenantConfigurationComponent }
    from './components/subtenant-configuration/subtenant-configuration.component';
import { SubtenantFormComponent } from './components/subtenant-form/subtenant-form.component';
import { SubtenantInvoicesComponent } from './components/subtenant-invoices/subtenant-invoices.component';

@Component({
    selector: 'app-subtenant-edit',
    templateUrl: './subtenant-edit.component.html',
    styleUrls: ['./subtenant-edit.component.scss']
})
export class SubtenantEditComponent implements AfterViewInit, OnDestroy
{
    private form: SubtenantFormComponent;
    private config: SubtenantConfigurationComponent;
    private invoices: SubtenantInvoicesComponent;

    @ViewChild('form', { static: false }) set formComponent(content: SubtenantFormComponent)
    {
        if (content)
        { // initially setter gets called with undefined
            this.form = content;
        }
    }
    @ViewChild('config', { static: false }) set configComponent(content: SubtenantConfigurationComponent)
    {
        if (content)
        { // initially setter gets called with undefined
            this.config = content;
        }
    }
    @ViewChild('invoices', { static: false }) set invoicesComponent(content: SubtenantInvoicesComponent)
    {
        if (content)
        { // initially setter gets called with undefined
            this.invoices = content;
        }
    }

    private unsubscriber: Subject<void>;
    private updateListenerSubscription: Subscription;

    public error$: Observable<any>;
    public loading$: Observable<boolean>;
    public state$: Observable<SubtenantEditState>;

    constructor(
        private store$: Store<RootStoreState.State>,
        private route: ActivatedRoute,
        private router: Router,
        private toast: I18nToastService
    )
    {
        this.unsubscriber = new Subject<void>();

        this.error$ = this.store$.select(SubtenantEditStoreSelectors.selectSubtenantEditError);
        this.loading$ = this.store$.select(SubtenantEditStoreSelectors.selectSubtenantEditIsLoading);
        this.state$ = this.store$.select(SubtenantEditStoreSelectors.selectSubtenantEditState);

        this.route.params
            .pipe(
                take(1),
                map(
                    params =>
                    {
                        const uuid = params.uuid;
                        if (uuid && typeof uuid === 'string')
                            return uuid;
                        else
                            throw new Error('Param uuid not found or not a number')
                    }
                )
            )
            .subscribe(
                uuid =>
                {
                    this.store$.dispatch(
                        new SubtenantEditStoreActions.Fetch(uuid)
                    );
                },
                err => this.handleError(err, true)
            );

        this.error$
            .pipe(
                takeUntil(this.unsubscriber)
            )
            .subscribe(err => this.handleError(err, false))
    }

    ngAfterViewInit(): void
    { }

    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();

        if (this.updateListenerSubscription)
            this.updateListenerSubscription.unsubscribe();

        this.store$.dispatch(
            new SubtenantEditStoreActions.Clear()
        );
    }

    public store(): void
    {
        if (!this.updateListenerSubscription || this.updateListenerSubscription.closed)
            this.configureUpdateListener();

        // Lanzamos verificación para si los forms son válidos
        // y salte el proceso de update.
        this.form.submit();
        this.config.submit();
    }
    public handleTabChange(event: MatTabChangeEvent): void
    {
        if (event.index == 1)
            this.invoices.sizeToFit();
    }

    private handleError(err: string, navigate: boolean = false): void
    {
        if (err)
        {
            this.toast.open(err, 'accept', 5000, true);
            if (navigate || err == ERROR_CODES.FETCHING_SINGLE_CONTACT)
                this.router.navigate(['subtenant_management', 'list']);
        }
    }
    private configureUpdateListener(): void
    {
        this.updateListenerSubscription = zip(this.form.done.asObservable(), this.config.done.asObservable())
            .subscribe(res =>
            {
                this.store$.dispatch(new SubtenantEditStoreActions.Update({
                    tenant: res[0],
                    config: res[1]
                }))
                this.updateListenerSubscription.unsubscribe();
            });
    }
}
