import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { AutoTableColumnDefinition } from '../definitions/auto-table-column-definition';
import { LaravelPaginatedList } from '../definitions/laravel-paginated-list';
import { TableAdapterInterface } from '../definitions/table-adapter.interface';
import { PaginatorService } from './paginator.service';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseTableAdapterService implements TableAdapterInterface
{

    public data: Subject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    public currentPage: number;
    public elementCount: number;
    public pageSize: number;

    protected constructor(
        public responsive: ResponsiveService,
        public paginator: PaginatorService
    )
    {
        this.currentPage = 0;
        this.elementCount = 0;
        this.pageSize = 0;
    }

    abstract getStdCols(): Array<AutoTableColumnDefinition>;

    abstract getNonStdCols(): Array<AutoTableColumnDefinition>;

    abstract getLaravelPaginatedList(loader?: boolean): Observable<LaravelPaginatedList<any>>;

    abstract warmUp(params?: any): Observable<boolean>;

    public getAllColumnsName()
    {
        return this.getStdCols()
            .concat(this.getNonStdCols())
            .sort((a, b) =>
            {
                return Number(a.order) - Number(b.order);
            })
            .map(c =>
            {
                return c.column;
            });
    }

    getAsExcel(loader?: boolean)
    {

    }

    getData(loader?: boolean, filtering = false): void
    {

        this.getLaravelPaginatedList(loader)
            .pipe(
                map(r =>
                {
                    this.currentPage = Number(r.current_page) - 1;
                    this.pageSize = r.per_page;
                    this.elementCount = r.total;
                    return r.data;
                })
            )
            .subscribe(r =>
            {
                this.data.next(r);
                if (filtering)
                    this.paginator.resetPagination$.next(true);
            },
                err =>
                {
                    console.log(err);
                });
    }

    paginatorEventHandler(ev)
    {
        console.log(ev);
        this.currentPage = ev.pageIndex;
        this.getData();
    }
    downloadAttachment(val1, val2, val3): void
    {

    }
}
