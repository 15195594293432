import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ApiKeysTableAdapterService } from 'src/app/core/services/pages/tenant-management/api-keys-table-adapter.service';

@Component({
    selector: 'app-api-keys-table',
    templateUrl: './api-keys-table.component.html',
    styleUrls: ['./api-keys-table.component.css']
})
export class ApiKeysTableComponent implements OnInit {

    @ViewChild('table') table: MatTable<any>;

    @Input('tableAdapter') tableAdapter: ApiKeysTableAdapterService;

    constructor() {

    }

    ngOnInit() {
        this.tableAdapter
            .warmUp()
            .subscribe(
                r => {
                    this.table.renderRows();
                },
                e => {

                },
                () => {

                }
            );
    }
}
