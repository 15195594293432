import { Component, HostBinding, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-privacy-modal',
    templateUrl: './privacy-modal.component.html',
    styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent implements OnInit
{
    @HostBinding('class') classes = 'mat-typography';

    public master_domain = environment.master_domain

    constructor()
    {
    }

    ngOnInit()
    {
    }

}
