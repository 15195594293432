import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { TableAdapterInterface } from '../../definitions/table-adapter.interface';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'app-base-table',
    templateUrl: './base-table.component.html',
    styleUrls: ['./base-table.component.css']
})
export class BaseTableComponent implements OnInit {

    @ViewChild('table') table: MatTable<any>;

    @Input('tableAdapter') tableAdapter: TableAdapterInterface;

    constructor() {

    }

    ngOnInit() {
        this.tableAdapter
            .warmUp()
            .subscribe(
                r => {
                    this.table.renderRows();
                },
                e => {

                },
                () => {

                }
            );
    }
}
