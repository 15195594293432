import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { SIGNATURE_EDITOR_TIPS } from 'src/app/configs/dicctionaries/pdf-editable/signature-editor-tips.dictionary';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { Template } from 'src/app/core/models/api/template/template.model';
import { Dragable } from 'src/app/core/models/send-assistant/dragable.model';
import { DocumentTemplateManagementService } from 'src/app/core/services/content/document-template-management.service';
import { FormTemplateManagementService } from 'src/app/core/services/content/form-template-management.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
    selector: 'app-signature-editor',
    templateUrl: './signature-editor.component.html',
    styleUrls: ['./signature-editor.component.scss']
})
export class SignatureEditorComponent implements OnInit
{
    @HostBinding('class') classes = '';
    public signers = [{ name: 'Firmante 1', value: 0, color: 'blue' }];
    public dragables: Array<Dragable> = [];
    private unsubscriber: Subject<void>;

    // Ref.
    public TIPS = SIGNATURE_EDITOR_TIPS;

    constructor(
        public responsive: ResponsiveService,
        public pdfControls: PdfControlsService,
        protected templateEditor: TemplateEditorService,
        protected router: Router,
        protected toast: I18nToastService,
        protected manager: FormTemplateManagementService
    ) { }

    ngOnInit(): void
    {
    }

    public continueButBack(): void
    {
        const template = this.templateEditor.getTemplate();
        const serialize = this.pdfControls.serializeAndSave().pipe(take(1));
        forkJoin([this.updateTemplateInfo(template.tpl), serialize])
            .subscribe(
                obs =>
                {
                    this.templateEditor.reset();
                    this.router.navigate([
                        'editable_pdf',
                        'list'
                    ]);
                },
                error =>
                {
                    let msg = '';

                    if (error instanceof HttpErrorResponse)
                        msg = error.error.message;
                    else
                        msg = error;

                    this.toast.open(msg, 'Accept', 7000, false);
                }
            )
    }
    
    public continue(): void{
        const template = this.templateEditor.getTemplate();
        const serialize = this.pdfControls.serializeAndSave().pipe(take(1));
        forkJoin([this.updateTemplateInfo(template.tpl), serialize])
            .pipe(take(1))
            .subscribe(
                serialized =>
                {
                    const template = this.templateEditor.getTemplate();
                    this.router.navigate([
                        'editable_pdf',
                        'contacts',
                        template.tpl.id,
                        this.templateEditor.getPdfEditableId()]);
                },
                error =>
                {
                    let msg = '';
    
                    if (error instanceof HttpErrorResponse)
                        msg = error.error.message;
                    else
                        msg = error;
    
                    this.toast.open(msg, 'Accept', 7000, false);
                }
            )
      }

    protected updateTemplateInfo(template: TemplateData): Observable<any>
    {
       return this.manager.updateFormTemplate(template, this.templateEditor.getPdfEditableId());
    }
}
