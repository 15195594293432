<ng-container *ngIf="contact && contact.name">
    <div class="contact-header">
        <div class="flex-v-center-content fab-initials" [style.color]="Color" [style.background]="BaseColor">
            {{ contact.name.slice(0,2).toUpperCase() }}
        </div>
        <div class="flex-col pad-l10">
            <div class="capitalize" style="margin-top: 5px;padding-right: 20px;">
                {{contact.name}} {{contact.surname_1}} {{contact.surname_2}}
            </div>
            <div class="flex-row-start">
                <span class="hint rbt" style="text-transform: none;">{{ contact.customer_legal_identifier }}</span>
            </div>
        </div>
    </div>
    <ng-container *ngIf="this.service.getActiveCustomField() | async as customFieldInUse;">
        <div class="pad-l10 flex-row-start-center" style="font-size: 12px;" *ngIf="customFieldInUse && contact[customFieldInUse]">
            <mat-icon class="small mar-r10">gesture</mat-icon><span [matTooltip]="'Auth signer' | translate">{{ contact[customFieldInUse] }}</span>
        </div>
    </ng-container>
    <div class="pad-l10 flex-row-start-center" style="font-size: 12px;" *ngIf="contact.company">
        <mat-icon class="small mar-r10">domain</mat-icon> {{ contact.company }}
    </div>
    <!--  <div *ngIf="contact.personal_email" fxLayout="row" class="pad-l10" fxLayoutAlign="start center">
            <mat-icon class="small mar-r10">send</mat-icon>
            <a style="font-size: 14px;" class="rbt" [href]="'mailto:' + contact.personal_email">{{
                contact.personal_email }}</a>
        </div> -->

    <div *ngIf="contact.corporate_email" class="pad-l10 flex-row-start-center">
        <mat-icon class="small mar-r10">email</mat-icon>
        <a style="font-size: 12px;" class="rbt" [href]="'mailto:' + contact.corporate_email">{{
                contact.corporate_email }}</a>
    </div>
    <div *ngIf="contact.mobile_phone" class="pad-l10 flex-row-start-center">
        <mat-icon class="small mar-r10">phone</mat-icon>
        <a style="font-size: 14px;" class="rbt" [href]="'tel:' + contact.mobile_phone">{{
                contact.mobile_phone }}</a>
        <mat-icon *ngIf="contact.country" class="small mar-l10 mar-r10">public</mat-icon>
        {{ contact.country ? contact.country : '' }}
    </div>
    <div class="actions">
        <button color="primary" class="mar-r10" *ngIf="contact.user_id !== null"
            [matTooltip]="'this contact must be edited from user management' | translate" mat-icon-button>
            <mat-icon class="small">block</mat-icon>
        </button>
        <button color="primary" class="mar-r10" *ngIf="contact.user_id === null" (click)="EditContact()"
            mat-icon-button>
            <mat-icon class="small">edit</mat-icon>
        </button>
        <!-- <button *ngIf="contact.status == 1" matTooltip="{{ 'Disable' | translate }}" mat-icon-button (click)="DeleteContact()"
                color="warn">
            <mat-icon>delete</mat-icon>
        </button> -->
        <mat-slide-toggle [disabled]="contact.user_id !== null" [checked]="contact.status == 1" (change)="DeleteContact()"></mat-slide-toggle>
        <!-- <button *ngIf="contact.status == 2" matTooltip="{{ 'Enable' | translate }}" mat-icon-button (click)="DeleteContact()"
                color="accent">
            <mat-icon>delete</mat-icon>
        </button> -->
    </div>
</ng-container>
<ng-container *ngIf="!contact">
    Cargando...
</ng-container>
