import {Injectable} from '@angular/core';
import {WebServiceHelperService} from 'src/app/shared/web-service-helper.service';
import {TileNumeric, TileNumericEntry} from './tile-numeric';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import * as _ from 'lodash';
import {Log} from './log';

@Injectable({
    providedIn: 'root'
})
export class KpiService {

    constructor(
        public wsh: WebServiceHelperService
    ) {
    }

    public getBreakdownByStatesKpiData(): Observable<TileNumeric> {
        return this.wsh.get<{ status: any, aggregatedCount: any }>(environment.backEndDomain + 'api/kpis/status_count')
            .pipe(
                map(response => {
                    const states = response.status;
                    const values = response.aggregatedCount;
                    const result = this.configureBreakdownTile();

                    // Montamos el obj
                    result.entries = Object.keys(states).map(stateKey => {
                        // creamos instancia de entry y la configuramos.
                        const entry = new TileNumericEntry();

                        entry.description = states[stateKey];
                        entry.value = values[stateKey] || 0;
                        entry.link = ['/document', stateKey];
                        return entry;

                    })

                    return result;
                })
            );
    }

    public getTemplatesCountKpiData(): Observable<TileNumeric> {
        return this.wsh.get<number>(environment.backEndDomain + 'api/kpis/templates_count')
            .pipe(
                map(results => {
                    const result = this.configureTemplateCountTile();
                    const entry = new TileNumericEntry();
                    entry.description = 'Created_female';
                    entry.value = results;

                    result.entries.push(entry);

                    return result;
                })
            );
    }

    public getBatchCountKpiData(): Observable<TileNumeric> {
        return this.wsh.get<number>(environment.backEndDomain + 'api/kpis/batch_count')
            .pipe(
                map(results => {
                    const result = this.configureBatchCountTile();
                    const entry = new TileNumericEntry();
                    entry.description = 'Created';
                    entry.value = results;
                    entry.link = ['/document'];
                    result.entries.push(entry);

                    return result;
                })
            );
    }

    public getUsersCountKpiData(): Observable<TileNumeric> {
        return this.wsh.get<number>(environment.backEndDomain + 'api/kpis/users_count')
            .pipe(
                map(results => {
                    const result = this.configureUsersCountTile();
                    const entry = new TileNumericEntry();
                    entry.description = 'Created';
                    entry.value = results;
                    entry.link = ['/user_managament'];
                    result.entries.push(entry);

                    return result;
                })
            );
    }

    public getDocCountKpiData(): Observable<TileNumeric> {
        return this.wsh.get<number>(environment.backEndDomain + 'api/kpis/doc_count')
            .pipe(
                map(results => {
                    const result = this.configureDocCountTile();
                    const entry = new TileNumericEntry();
                    entry.description = 'Sent';
                    entry.value = results;
                    entry.link = ['/document'];

                    result.entries.push(entry);

                    return result;
                })
            )
    }

    public getLogKpiData(): Observable<Array<Log>> {
        return this.wsh.get<any>(environment.backEndDomain + 'api/kpis/history_log')
            .pipe(
                map(response => {
                    return response.map( rawLogEntry => {
                        const logEntry = new Log();
                        logEntry.action = `audit_trail_table_action_id_${rawLogEntry.at_action_id}`;
                        logEntry.date = rawLogEntry.at_created_at;
                        logEntry.email = rawLogEntry.c_corporate_email;
                        logEntry.template = rawLogEntry.dt_name;

                        return logEntry;
                    });
                })
            )
    }

    private configureBreakdownTile(): TileNumeric {
        const base = new TileNumeric();
        base.icon = 'send';
        base.iconColor = 'white';
        base.iconFabColor = '#1A237E';
        base.title = 'Sends by state';

        return base;
    }

    private configureTemplateCountTile(): TileNumeric {
        const base = new TileNumeric();
        base.icon = 'file_copy';
        base.iconColor = 'white';
        base.iconFabColor = '#FF9100';
        base.title = 'Templates'

        return base;
    }

    private configureUsersCountTile(): TileNumeric {
        const base = new TileNumeric();
        base.icon = 'account_circle';
        base.iconColor = 'white';
        base.iconFabColor = '#FF0000';
        base.title = 'Users'

        return base;
    }

    private configureDocCountTile(): TileNumeric {
        const base = new TileNumeric();
        base.icon = 'insert_drive_file';
        base.iconColor = 'white';
        base.iconFabColor = '#987ADF';
        base.title = 'Docs.'

        return base;
    }

    private configureBatchCountTile() {
        const base = new TileNumeric();
        base.icon = 'file_copy';
        base.iconColor = 'white';
        base.iconFabColor = '#81cc2c';
        base.title = 'Batches';

        return base;
    }
}
