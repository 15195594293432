export class Contact {
    public id: number = null;
    // tslint:disable-next-line:variable-name
    // public status_id: number = null;
    // public selected: boolean = null;
    public address: string = null;
    public locale: string = null;
    public created_at: Date = null;
    public updated_at: Date = null;
    public city: string = null;
    // tslint:disable-next-line:variable-name
    public corporate_email: string = null;
    public country: string = null;
    // tslint:disable-next-line:variable-name
    public custom_field1: string = null;
    // tslint:disable-next-line:variable-name
    public customer_legal_identifier: string = null;
    // tslint:disable-next-line:variable-name
    public mobile_phone: string = null;
    public name: string = null;
    // tslint:disable-next-line:variable-name
    public personal_email: string = null;
    // tslint:disable-next-line:variable-name
    public postal_code: string = null;
    public region: string = null;
    // tslint:disable-next-line:variable-name
    public surname_1: string = null;
    // tslint:disable-next-line:variable-name
    public surname_2: string = null;
    public company: string = null;

    // UI purpouses
    public repeated: boolean;
    public status: number;


    // public created_at: Date = new Date();
    // public updated_at: Date = new Date();
    // public status_id: number;
    public user_id: number;

    public custom_value_0: string = '';
    public custom_value_1: string = '';
    public custom_value_2: string = '';
    public custom_value_3: string = '';
    public custom_value_4: string = '';
    public custom_value_5: string = '';
    public custom_value_6: string = '';
    public custom_value_7: string = '';
    public custom_value_8: string = '';
    public custom_value_9: string = '';

    // Dev purpose
    public flags?: string[];
    public email_callbacks?: string[];

}
