<div class="actions">
    <div class="title">{{ "menu_subtenant_managament" | translate }}</div>
    <button mat-raised-button color="primary" (click)="newSubtenant()">
        {{ "new subtenant" | translate }}
    </button>
</div>
<div class="grid">
    <ng-container *ngIf="!(loading$ | async); else loading">
        <ng-container *ngIf="state$ | async as SubtenantList; else loading">
            <ag-grid-angular
                *ngIf="SubtenantList.subTenantList?.data"
                class="ag-theme-material"
                [gridOptions]="gridOptions"
                [rowData]="SubtenantList.subTenantList.data"
                (gridReady)="gridReady($event)"
            >
            </ag-grid-angular>
        </ng-container>
    </ng-container>
    <ng-template #loading>
        <mat-icon>hourglass_top</mat-icon>
        <span class="ml-12">{{ "Loading..." | translate }}</span>
    </ng-template>
</div>

<!-- <div class="loader" *ngIf="loading$ | async">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div> -->
