import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { DocumentTemplateManagementService } from 'src/app/core/services/content/document-template-management.service';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';

@Component({
    selector: 'app-template-search',
    templateUrl: './template-search.component.html',
    styleUrls: ['./template-search.component.scss']
})
export class TemplateSearchComponent implements OnInit, OnDestroy
{
    @Input() showPreview: boolean = false;
    @Output() preview = new EventEmitter<TemplateData>();
    @Output() selected = new EventEmitter<TemplateData>();
    tplSubject: Subject<Array<TemplateData>> = new Subject<Array<TemplateData>>();
    public selectedTpl: TemplateData;
    public searchText: string;
    private tplsCache: Array<TemplateData>;

    constructor(
        private tplService: DocumentTemplateManagementService
    )
    {
    }

    ngOnInit()
    {
        this.loadTemplates();
    }

    private loadTemplates()
    {
        this.tplService.listTemplates()
            .subscribe(
                r =>
                {
                    this.tplsCache = r;
                    this.tplSubject.next(r);
                }
            );
    }

    selectTemplate(tpl: TemplateData)
    {
        this.selectedTpl = tpl;
        this.selected.emit(tpl);
    }

    textChanged($event: Event)
    {

        const tpls = this.tplsCache.filter(t => t.name.indexOf(this.searchText) != -1)
        this.tplSubject.next(tpls);

    }

    ngOnDestroy(): void
    {
        this.tplSubject.unsubscribe();
    }


}
