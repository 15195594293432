import { ERROR_CODES } from '../error-codes';
import { SubtenantInvoicesActionsUnion, SubtenantInvoicesActionTypes } from './actions';
import { subtenantInitialState, SubtenantInvoicesState } from './state';

export function subtenantReducer(state: SubtenantInvoicesState = subtenantInitialState,
    action: SubtenantInvoicesActionsUnion): SubtenantInvoicesState
{
    switch (action.type)
    {
        case SubtenantInvoicesActionTypes.FETCH:
            return {
                ...state,
                isLoading: true
            }
        case SubtenantInvoicesActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
                isLoading: false
            }
        case SubtenantInvoicesActionTypes.FETCH_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMsg: ERROR_CODES.FETCHING_SUBTENANT_INVOICES
            }
        case SubtenantInvoicesActionTypes.CLEAR:
            return {
                invoices: null,
                isLoading: false,
                errorMsg: null
            }
        default:
            return state;
    }
}
