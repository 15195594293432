<div class="title flex-space-between pad-l10 pad-r10">
    {{ 'Pdf editable module' | translate }}
    <!-- Boton de los filtros con estado -->
    <div class="flex-center-content">
        <!-- DESKTOP -->
        <button style="color: black;" *ngIf="!(responsive.isMobile$ | async)" mat-button (click)="filters.toggle()">
            {{ filters.expanded ? ('Hide filters' | translate) : ('Show filters' | translate) }}
        </button>

        <!-- MOBILE -->
        <button *ngIf="(responsive.isMobile$ | async)" mat-icon-button (click)="filters.toggle()">
            <mat-icon>filter_alt</mat-icon>
        </button>

        <mat-icon style="cursor: pointer;" (click)="filters.toggle()"
            [@iconExapansionAnimation]="filters.expanded ? 'EXPANDED' : 'COLLAPSED'">keyboard_arrow_up
        </mat-icon>
    </div>
</div>

<div class="filters">
    <mat-expansion-panel #filters>
        <div class="not-too-big" [formGroup]="filtersGroup">
            <mat-form-field  class="filter_input">
                <mat-label>{{'Template' | translate}}</mat-label>
                <input matInput placeholder="Template" formControlName="name">
            </mat-form-field>
            <mat-form-field  class="filter_input date">
                <mat-label>{{ 'From' | translate }}</mat-label>
                <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a date" formControlName="dateIni">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field  class="filter_input date">
                <mat-label>{{ 'To' | translate }}</mat-label>
                <input matInput [matDatepicker]="pickerTo" placeholder="Choose a date" formControlName="dateEnd">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>

            <mat-form-field  class="filter_input status">
                <mat-label>{{'Status' | translate}}</mat-label>
                <mat-select disableRipple formControlName="status" >
                    <mat-option *ngFor="let k of [{k:1, v: 'active'}, {k:2, v: 'inactive'}]" [value]="k.k">
                        {{ k.v | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-stroked-button class="btn white" (click)="filter()">
                {{ 'filter' | translate }}
            </button>
        </div>
    </mat-expansion-panel>
</div>

<div class="grid">
    <ng-container *ngFor="let tpl of (this.templates$ | async)">
        <app-pdf-editable-card (deleted)="filter()" [template]="tpl"></app-pdf-editable-card>
    </ng-container>
</div>

<button [routerLink]="['/editable_pdf/file']" mat-fab color="primary" class="floating-button">
    <mat-icon>add</mat-icon>
</button>