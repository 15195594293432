
<app-responsive-topbar></app-responsive-topbar>
<div class="bg-content mat-elevation-z6 pad10 document-tray-content">
    <div class="flex-space-between pad-l10 pad-r10">
        <div class="title flex-center-content">{{ 'menu_editable' | translate}}</div>

        <!-- Boton de los filtros con estado -->
        <div class="flex-center-content">
            <!-- DESKTOP -->
            <button *ngIf="!(responsive.isMobile$ | async)" mat-button (click)="filters.toggle()">
                {{ filters.expanded ? ('Hide filters' | translate) : ('Show filters' | translate) }}
            </button>

            <!-- MOBILE -->
            <button *ngIf="(responsive.isMobile$ | async)" mat-icon-button (click)="filters.toggle()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <mat-icon style="cursor: pointer;" (click)="filters.toggle()"
                [@iconExapansionAnimation]="filters.expanded ? 'EXPANDED' : 'COLLAPSED'">keyboard_arrow_up
            </mat-icon>
        </div>
    </div>
    <div class="filters">
        <mat-expansion-panel #filters>
            <div class="not-too-big">
                <mat-form-field class="filter_input">
                    <mat-icon matSuffix (click)="editableTAS.tplFilter = ''">clear</mat-icon>
                    <mat-label>{{ 'Document' | translate }}</mat-label>
                    <input [(ngModel)]="editableTAS.tplFilter" (keydown.enter)="editableTAS.getData()" matInput>
                </mat-form-field>

                <mat-form-field  class="filter_input">
                    <mat-icon matSuffix (click)="editableTAS.templateIdFilter = -1">clear</mat-icon>
                    <mat-label>{{'Template' | translate}}</mat-label>
                    <mat-select disableRipple [(ngModel)]="editableTAS.templateIdFilter" >
                        <mat-option *ngFor="let k of editableTAS.tplsKeys" [value]="k.id">
                            {{k.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field  class="filter_input">
                    <mat-icon matSuffix (click)="editableTAS.batchFilter = ''">clear</mat-icon>
                    <mat-label>{{ 'Batch' | translate }}</mat-label>
                    <input [(ngModel)]="editableTAS.batchFilter" (keydown.enter)="editableTAS.getData()" matInput>
                </mat-form-field> -->

                <mat-form-field  class="filter_input status">
                    <mat-label>{{'Status' | translate}}</mat-label>
                    <mat-select disableRipple [(ngModel)]="editableTAS.statusFilter" multiple>
                        <mat-option *ngFor="let k of editableTAS.statusKeys" [value]="k">
                            {{k | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field  class="filter_input date">
                    <mat-label>{{ 'From' | translate }}</mat-label>
                    <input matInput [matDatepicker]="pickerFrom" placeholder="Choose a date"
                        [(ngModel)]="editableTAS.dateFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>

                <mat-form-field  class="filter_input date">
                    <mat-label>{{ 'To' | translate }}</mat-label>
                    <input matInput [matDatepicker]="pickerTo" placeholder="Choose a date"
                        [(ngModel)]="editableTAS.dateTo">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>

                <button mat-stroked-button class="btn white" (click)="editableTAS.getData()">
                    {{ 'filter' | translate }}
                </button>
            </div>
        </mat-expansion-panel>
    </div>


    <app-editable-pdf-table
        [tableAdapter]="editableTAS" class="full-width">
    </app-editable-pdf-table>
</div>
