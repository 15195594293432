import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MobileStepperService
{
    private steps: BehaviorSubject<number>;
    public steps$: Observable<number>;

    constructor()
    {
        this.steps = new BehaviorSubject(4);
        this.steps$ = this.steps.asObservable();
    }

    public setSteps(steps: number): void
    {
        this.steps.next(steps);
    }
}
