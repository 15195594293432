import { ERROR_CODES } from '../error-codes';
import { SubtenantActionsUnion, SubtenantListActionTypes } from './actions';
import { subtenantInitialState, SubtenantState } from './state';

export function subtenantReducer(state: SubtenantState = subtenantInitialState,
    action: SubtenantActionsUnion): SubtenantState
{
    switch (action.type)
    {
        case SubtenantListActionTypes.FETCH:
        case SubtenantListActionTypes.UNLINK:
            return {
                ...state,
                isLoading: true
            }
        case SubtenantListActionTypes.UNLINK_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case SubtenantListActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                subTenantList: action.payload,
                isLoading: false
            }
        case SubtenantListActionTypes.UNLINK_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMsg: ERROR_CODES.UNLINKING_SUBTENANT
            }
        case SubtenantListActionTypes.FETCH_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMsg: ERROR_CODES.FETCHING_SUBTENANT_LIST
            }
        default:
            return state;
    }
}
