import { Component, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import { TemplateOutputDragged } from 'src/app/core/models/api/template/template-output.model';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { iDialogBase } from 'src/app/cross-platform-dialog/shared/iDialogBase';
import {BehaviorSubject, Subject} from 'rxjs';
import {CustomTextConf} from '../../../../core/models/contacts/custom-text-conf.model';
import {ConstactsService} from '../../../../core/services/pages/contacts/constacts.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {CustomFieldsConfiguration} from '../../../../core/models/contacts/custom-fields-configuration';

@Component({
    selector: 'app-item-configuration',
    templateUrl: './item-configuration.component.html',
    styleUrls: ['./item-configuration.component.scss']
})
export class ItemConfigurationComponent implements OnInit, OnDestroy, iDialogBase
{
    public outputForm: FormGroup;
    public file: FormControl;
    public field: FormControl;
    public repeat: FormControl;

    public customTextConf$: BehaviorSubject <{
        customText: Array<CustomTextConf>,
        useAsName: CustomTextConf
    }> = new BehaviorSubject <{
        customText: Array<CustomTextConf>,
        useAsName: CustomTextConf
    }>({
        customText: [],
        useAsName: new CustomTextConf()
    });

    data: DialogData<TemplateOutputDragged>;
    binding: EventEmitter<boolean>;

    constructor(
        private toast: MatSnackBar,
        public pdfControls: PdfControlsService,
        private contactService: ConstactsService
    )
    {
        this.binding = new EventEmitter();
    }

    setData(data: DialogData<TemplateOutputDragged>): void
    {
        this.data = data;

        this.file = new FormControl('', Validators.required);
        this.field = new FormControl('', Validators.required);
        this.repeat = new FormControl(false);

        const output = this.data.data;
        if (output.meta.filename)
            this.file.setValue(output.meta.filename);
        if (output.meta.textname)
            this.field.setValue(output.meta.textname);
        if (output.meta.repeat_all_pages)
            this.repeat.setValue(output.meta.repeat_all_pages);

        this.outputForm = new FormGroup({});

        switch (output.meta.output_type)
        {
            case 'aditional_doc':
                this.outputForm.addControl('file', this.file);
                this.outputForm.addControl('check_nif', new FormControl(output.meta.check_nif));
                this.outputForm.addControl('optional_validation', new FormControl(output.meta.optional_validation));

                const done = new Subject<void>();
                this.customTextConf$
                .pipe(takeUntil(done))
                .subscribe(
                    x =>
                    {
                        x.customText.forEach(
                            (ctc, y) =>
                            {
                                if (this.outputForm.controls['check_' + ctc.data_key])
                                    this.outputForm.controls['check_' + ctc.data_key].setValue(output.meta['check_' + ctc.data_key]);
                                else
                                    this.outputForm.addControl('check_' + ctc.data_key, new FormControl(output.meta['check_' + ctc.data_key]));
                            }
                        );

                        this.outputForm.updateValueAndValidity();

                        if (x.customText.length > 0)
                        {
                            done.next();
                            done.complete();
                        }
                    }
                );

                break;
            case 'arbitrary_text_on_send':
                this.outputForm.addControl('field', this.field);
                break;
            case 'firma':
                this.outputForm.addControl('repeat', this.repeat);
                this.outputForm.addControl('repeat_first_page', new FormControl(output.meta.repeat_first_page));
                this.outputForm.addControl('repeat_second_page', new FormControl(output.meta.repeat_second_page));
                this.outputForm.addControl('repeat_second_last_page', new FormControl(output.meta.repeat_second_last_page));
                this.outputForm.addControl('repeat_last_page', new FormControl(output.meta.repeat_last_page));
                break;
        }
    }
    action(args?: any)
    {
        if (this.outputForm.valid)
        {
            const formVal = this.outputForm.value;
            const output = this.data.data;
            switch (output.meta.output_type)
            {
                case 'aditional_doc':
                    output.meta.filename = formVal.file;
                    output.meta.check_nif = formVal.check_nif;

                    this.customTextConf$.getValue().customText.forEach((ctc, y) => {
                        output.meta['check_' + ctc.data_key] = formVal['check_' + ctc.data_key];
                    });

                    output.meta.optional_validation = formVal.optional_validation;

                    break;
                case 'arbitrary_text_on_send':
                    output.meta.textname = formVal.field;
                    break;
                case 'firma':
                    output.meta.repeat_all_pages = formVal.repeat;

                    output.meta.repeat_first_page = formVal.repeat_first_page;
                    output.meta.repeat_second_page = formVal.repeat_second_page;
                    output.meta.repeat_second_last_page = formVal.repeat_second_last_page;
                    output.meta.repeat_last_page = formVal.repeat_last_page;
                    break;
            }

            this.binding.emit(true);
        }
        else
        {
            //this.toa
        }
    }

    ngOnInit(): void
    {

        if(this.customTextConf$.getValue().customText.length === 0){
            this.contactService.getCustomTextStatus()
                .pipe(
                    map( (x: CustomFieldsConfiguration) => {
                        x.customText = x.customText.filter((ctc: CustomTextConf) => ctc.flag_value !== 0);
                        return x;
                    }),
                    tap((x: CustomFieldsConfiguration) => {
                        const output = this.data.data;
                        x.customText.forEach((ctc: CustomTextConf) => {
                            this.outputForm.addControl('check_' + ctc.data_key , new FormControl(output.meta['check_' + ctc.data_key]));
                        })
                    })
                )
                .subscribe(this.customTextConf$);
        }

    }

    ngOnDestroy(): void
    {

    }

    public remove(): void
    {
        const isDeleted = this.pdfControls.deleteOutput(this.data.data);
        if (isDeleted)
            this.binding.emit(true);
    }
    public CopyStylesFrom(toClone: TemplateOutputDragged): void
    {
        const currentOutput = this.data.data;
        const currentBorderColor = currentOutput.meta.style['border-color'];
        currentOutput.meta.style = cloneDeep(toClone.meta.style);
        currentOutput.meta.style['border-color'] = currentBorderColor;
    }
}
