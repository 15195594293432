import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagePreviewComponent } from './message-preview.component';
import { TemplateMessagePipe } from './pipes/template-message.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material/material.module';
import { WhatsappPreviewComponent } from './components/whatsapp-preview/whatsapp-preview.component';
import { SmsPreviewComponent } from './components/sms-preview/sms-preview.component';

@NgModule({
    declarations: [MessagePreviewComponent, TemplateMessagePipe, WhatsappPreviewComponent, SmsPreviewComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule
    ],
    exports: [MessagePreviewComponent, WhatsappPreviewComponent, SmsPreviewComponent]
})
export class MessagePreviewModule { }
