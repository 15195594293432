<div class="bar top">
    <mat-icon>arrow_back</mat-icon>
    <div>{{ contacts[0][0].mobile_phone }}</div>
    <mat-icon>search</mat-icon>
    <mat-icon>more_horiz</mat-icon>
</div>
<div>
    <div class="message-left mat-elevation-z2">
        <p>
            {{
                "sms hello preview"
                    | translate: { origin: (user.user$ | async).name }
            }}
            https://bit.ly/XXXX
        </p>
    </div>
</div>
<!-- <div class="bar footer">
    <mat-icon>mood</mat-icon>
    <mat-icon>attach_file</mat-icon>
    <div class="enter-text">
        {{ "Write the text of your message" | translate }}
    </div>
    <mat-icon>mic</mat-icon>
</div> -->
