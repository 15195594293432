<div class="title">
    {{ "Credit transfer" | translate }}
</div>
<div class="grid" [formGroup]="transferForm">
    <mat-form-field class="low-density">
        <mat-select
            formControlName="product"
            [placeholder]="'Product' | translate"
        >
            <mat-option [value]="PRODUCT_TYPE.DOCS">{{
                "document" | translate
            }}</mat-option>
            <mat-option [value]="PRODUCT_TYPE.WHATSAPP">{{
                "whatsapp" | translate
            }}</mat-option>
            <mat-option [value]="PRODUCT_TYPE.SMS">{{
                "SMS" | translate
            }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="low-density">
        <mat-select
            formControlName="movementType"
            [placeholder]="'Movement type' | translate"
        >
            <mat-option [value]="1">{{ "deposite" | translate }}</mat-option>
            <mat-option [value]="-1">{{ "withdraw" | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="low-density">
        <mat-label>{{ "quantity" | translate }}</mat-label>
        <input
            formControlName="quantity"
            type="number"
            matInput
            [placeholder]="'quantity' | translate"
        />
    </mat-form-field>
</div>
<div class="buttons">
    <button mat-raised-button color="primary" (click)="store()">
        {{ "Accept" | translate }}
    </button>
</div>
