import { BaseComponent } from 'src/app/base/base.component';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { Location } from '@angular/common';
import { Tip } from 'src/app/core/models/tip';
import { catchError, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiKeyFormComponent } from 'src/app/pages/user-management/api-key-form/api-key-form.component';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { DialogData, DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { GeoNamesService } from 'src/app/shared/geo-names.service';
import { TenantService } from 'src/app/core/services/pages/tenant-management/tenant.service';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { ApiKeysTableAdapterService } from 'src/app/core/services/pages/tenant-management/api-keys-table-adapter.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';
import { CustomTextConf } from '../../../../core/models/contacts/custom-text-conf.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { TemplateTableAdapterService } from 'src/app/core/services/pages/tenant-management/template-table-adapter.service';
import { CUSTOM_MAIL_FOOTER } from 'src/app/core/mocks/pages/tenant-management/custom-mail-footer.mock';
import { TenantMailFooterService } from 'src/app/core/services/pages/tenant-management/tenant-mail-footer.service';
import { TenantMailFooter } from 'src/app/core/models/tenant-management/tenant-mail-footer.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LegalVerificationService } from 'src/app/core/services/pages/legal-verification/legal-verification.service';
import { TwoFactorAuthService } from 'src/app/core/services/pages/two-factor-auth/two-factor-auth.service';

@Component({
    selector: 'app-tenant-update',
    templateUrl: './tenant-update.component.html',
    styleUrls: ['./tenant-update.component.scss']
})

export class TenantUpdateComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild('heightCalc') heightCalc: ElementRef;
    @ViewChild('firstStep') firstStep: MatExpansionPanel;
    public validForm = true;
    public tenantData: Tenant;
    public invalidError: any;
    public currentPage: number;
    public countries: Array<any>;
    public customSeal: string;
    public currentSeal: string;
    public customLogo: string;
    public currentLogo: string;
    public currentUser: string = '';
    public mock = CUSTOM_MAIL_FOOTER;
    public footerData: Array<TenantMailFooter>;
    public imageTip: Tip = {
        title: 'Custom company seal',
        body: 'tenant_seal_tip'
    };
    public logoTip: Tip = {
        title: 'Company logo',
        body: 'tenant_logo_tip'
    };
    public cvsTip: Tip = {
        title: 'CVS',
        body: 'tenant_cvs_tip'
    };
    public fieldsTip: Tip = {
        title: 'Aditional fields',
        body: 'tenant_fields_tip'
    };

    public link: string;
    public initialMobileExpansionHeight = 300;

    private unsubscriber: Subject<void>;
    customTextConf$: ReplaySubject<{
        customText: Array<CustomTextConf>,
        useAsName: CustomTextConf
    }> = new ReplaySubject<{
        customText: Array<CustomTextConf>,
        useAsName: CustomTextConf
    }>(1);
    customTextformGroup: FormGroup;
    useAsNameDict: Array<number>;
    cvsAllow: string = 'allowed';
    cvsKeys: string[] = ['allowed', 'forbidden', 'forced'];
    legalValidation: boolean = false;

    constructor(
        public SideNav: SidenavService,
        public tenantService: TenantService,
        public geoNamesService: GeoNamesService,
        public apiKeyService: ApiKeysTableAdapterService,
        public templateService: TemplateTableAdapterService,
        public responsive: ResponsiveService,
        private location: Location,
        public user: UserMenuService,
        private toast: MatSnackBar,
        private translator: TranslateService,
        private confirmation: ConfirmationBoxService,
        private dialog: CrossPlatformDialogService,
        private matDialog: MatDialog,
        private contactService: ConstactsService,
        public mailFooterService: TenantMailFooterService,
        public legalVerificationService: LegalVerificationService,
        public tfAuthService: TwoFactorAuthService
    )
    {
        super(SideNav);
        this.currentPage = 1;
        this.tenantData = new Tenant();
        this.unsubscriber = new Subject<void>();
        this.link = `https://${ window.location.host }/docs`;
        this.useAsNameDict = [];
    }

    ngOnInit()
    {
        this.geoNamesService
            .loadCountries()
            .pipe(
                mergeMap(r =>
                {
                    this.countries = r as any;
                    return this.tenantService.getTenantData();
                })
            )
            .subscribe(r =>
            {
                this.tenantData = r;
            });

        zip(this.apiKeyService.warmUp(), this.templateService.warmUp())
            .subscribe(() =>
            {

            });


        this.user.user$
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(user =>
            {
                this.currentUser = `${ user.name } ${ user.surname }`.trim();
            });

        this.tenantService.getTenantSeal()
            .subscribe(x =>
            {
                const reader = new FileReader();
                reader.onload = (e: any) =>
                {
                    console.log(e);
                    this.currentSeal = e.target.result;
                };

                reader.readAsDataURL(x);
            });

        this.tenantService.getTenantLogo()
            .subscribe(x =>
            {
                const reader = new FileReader();
                reader.onload = (e: any) =>
                {
                    this.currentLogo = e.target.result;
                };

                reader.readAsDataURL(x);
            });

        this.contactService.getCustomTextStatus()
            .pipe(
                tap((configs) =>
                {
                    this.customTextformGroup = this.buildCustomTextFormGroup(configs);
                })
            )
            .subscribe(this.customTextConf$);


        this.tenantService.getTenantCvs()
            .subscribe(d =>
            {
                console.log(d);
                this.cvsAllow = d.value;
            })
        
        this.legalVerificationService.getTenantLegalVerification()
            .subscribe(result => {
                if(result.key === 'legal_validation') {
                    this.legalValidation = result.value === 'true';
                }
            });
    }

    public handleLegalValidationChange(event: MatSlideToggleChange):void{
        this.legalValidation = event.checked;
        this.tenantService.setTenantLegalValidation(this.legalValidation)
            .pipe(
                take(1)
            )
            .subscribe()

    }

    public handleOTPAuthChange(event: MatSlideToggleChange):void{
        this.tenantService.setOTPAuth(event.checked)
            .pipe(
                take(1)
            )
            .subscribe()

    }

    public setUseAsNameValue(fieldIndex: number): void
    {
        const useAsName = this.customTextformGroup.controls.useAsName as FormGroup;
        const currentSelectedAsName = useAsName.controls.data_value.value;
        if (currentSelectedAsName == fieldIndex)
        {
            useAsName.controls.data_value.setValue(null);
            useAsName.controls.flag_value.setValue(0);
        }
        else
        {
            useAsName.controls.data_value.setValue(fieldIndex);
            useAsName.controls.flag_value.setValue(1);
        }

        console.log(this.customTextformGroup.controls)
    }
    
    private buildCustomTextFormGroup(configs: {
        customText: Array<CustomTextConf>,
        useAsName: CustomTextConf
    })
    {
        this.useAsNameDict.length = 0;


        const useAsName = Object
            .keys(configs.useAsName)
            .reduce((auxfg: FormGroup, k: string) =>
            {
                auxfg.addControl(k, new FormControl(configs.useAsName[k]))
                return auxfg;
            }, new FormGroup({}));

        if (!configs.useAsName.data_value)
        {
            useAsName.controls.data_value.disable();
        }

        const textFormGroup = configs.customText
            .reduce((fc, config, idx) =>
            {
                const currentFc = Object
                    .keys(config)
                    .reduce((auxfg: FormGroup, k: string) =>
                    {
                        auxfg.addControl(k, new FormControl(config[k]))
                        return auxfg;
                    }, new FormGroup({}));

                if (!currentFc.controls.flag_value.value)
                {
                    currentFc.controls.data_value.disable();
                } else
                {
                    this.useAsNameDict.push(idx);
                }

                currentFc.controls.flag_value.valueChanges
                    .pipe(takeUntil(this.unsubscriber))
                    .subscribe(x =>
                    {
                        if (x)
                        {
                            currentFc.controls.data_value.enable();
                            this.useAsNameDict.push(idx);
                        } else
                        {
                            currentFc.controls.data_value.disable();

                            const auxIdx = this.useAsNameDict.indexOf(idx);
                            this.useAsNameDict.splice(auxIdx, 1);
                            if (useAsName.controls.data_value.value === idx)
                            {
                                useAsName.controls.data_value.setValue(null);
                            }
                        }
                    });


                fc.addControl(config.getIdx().toString(10), currentFc);

                return fc;
            }, new FormGroup({}));

        const masterFormGroup = new FormGroup({
            customText: textFormGroup,
            useAsName
        });

        return masterFormGroup;
    }
    ngOnDestroy()
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
    ngAfterViewInit()
    {
        if (this.responsive.IsMobile())
        {
            const rect = window.screen.availHeight;
            this.initialMobileExpansionHeight = rect - 48 - 250;
            this.firstStep.toggle();
        }
    }

    private setErrors(errors, control)
    {

        Object.keys(errors).forEach(errPath =>
        {
            let currentControl = control;
            errPath.split('.').forEach(errPathChunk =>
            {
                currentControl = currentControl.controls[errPathChunk];
            })

            const ngErrs = errors[errPath].reduce((carry, current) =>
            {
                carry[current] = true;
                return carry;
            }, {})

            currentControl.setErrors(ngErrs);
            currentControl.touched = true;

        })

        console.log(errors)
    }
    public setTenant()
    {

        const customTextConf = this.customTextformGroup.getRawValue() as {
            customText: any,
            useAsName: any
        };

        customTextConf.customText = Object.values(customTextConf.customText)
            .map(c => Object.assign(new CustomTextConf(), c)) as Array<CustomTextConf>;

        customTextConf.useAsName = Object.assign(new CustomTextConf(), customTextConf.useAsName) as CustomTextConf;

        const setTenatnData = this.tenantService.setTenantData(this.tenantData);
        const setCustomTexts = this.contactService.setCustomConfTexts(customTextConf)
            .pipe(catchError(err =>
            {
                this.setErrors(err.error.errors, this.customTextformGroup);
                return of(false);

            }));
        const setFooter = this.mailFooterService.setMailFooter(this.footerData);


        this.cvsSave();

        zip(setTenatnData, setCustomTexts, setFooter)
            .pipe(
                mergeMap(r =>
                {
                    const obs = r[1] === false ? of(false) : this.contactService.getCustomTextStatus();
                    return zip(this.tenantService.getTenantData(), obs);
                }),
                tap((data) =>
                {
                    this.tenantData = data[0];

                    if (false !== data[1])
                    {
                        const realData = data[1] as { customText: CustomTextConf[]; useAsName: CustomTextConf; };

                        this.customTextformGroup.setValue(realData);

                        this.useAsNameDict.length = 0;
                        realData.customText
                            .filter(x => x.flag_value)
                            .forEach(k =>
                            {
                                this.useAsNameDict.push(k.getIdx())
                            });
                    }

                    this.contactService.refreshCustomFieldConf();
                }),
                map(x => false === x[1] ? this.customTextformGroup.getRawValue() : x[1])
            )
            .subscribe(this.customTextConf$);
    }
    public handleSealChange(event: any, type: 'logo' | 'seal'): void
    {
        if (event.target.files.length > 0)
        {
            const file = event.target.files[0];
            const fileSize = file.size / 1024 / 1024;

            // Check file size:
            if (fileSize < 10)
            {
                this.isImage(file)
                    .subscribe(isImage =>
                    {
                        if (isImage)
                        {
                            const reader = new FileReader();
                            reader.onload = (e: any) =>
                            {
                                const src = e.target.result;
                                this.tenantService.checkImageBounds(src)
                                    .subscribe(correctSize =>
                                    {
                                        if (correctSize)
                                        {
                                            if (type == 'seal')
                                            {
                                                this.customSeal = src;
                                            } else
                                            {
                                                this.customLogo = src;
                                            }
                                        } else
                                        {
                                            this.toast.open(
                                                this.translator.instant(
                                                    'The image can not be greater than 300 x 110 px'),
                                                this.translator.instant('Accept'),
                                                { duration: 7000 }
                                            );
                                        }
                                    });
                            };

                            reader.readAsDataURL(event.target.files[0]);
                        } else
                        {
                            this.toast.open(
                                this.translator.instant('The file must be an image'),
                                this.translator.instant('Accept'),
                                { duration: 7000 }
                            );
                        }
                    });
            } else
            {
                this.toast.open(
                    this.translator.instant('The file can not be greater than 10mb'),
                    this.translator.instant('Accept'),
                    { duration: 7000 }
                );
            }
        }
    }
    public confirmSealSave(file: File): void
    {
        this.confirmation.open({
            title: this.translator.instant('You are going to upload a new seal'),
            body: this.translator.instant('This action will override an existing seal. Are you sure?'),
            btn_cancel: this.translator.instant('cancel'),
            btn_ok: this.translator.instant('save')
        })
            .pipe(mergeMap(x => this.tenantService.setTenantSeal(file)))
            .subscribe(
                x =>
                {
                    this.currentSeal = this.customSeal;
                    this.customSeal = null;
                },
                e =>
                {
                    console.log(e);
                }
            );
    }
    public confirmLogoSave(file: File): void
    {
        this.confirmation.open({
            title: this.translator.instant('You are going to upload a new logo'),
            body: this.translator.instant('This action will override an existing logo. Are you sure?'),
            btn_cancel: this.translator.instant('cancel'),
            btn_ok: this.translator.instant('save')
        })
            .subscribe(x =>
            {
                if (x)
                {
                    this.tenantService.setTenantLogo(file)
                        .subscribe(() =>
                        {
                            this.currentLogo = this.customLogo;
                            this.customLogo = null;
                        });
                }
            });
    }
    public goBack(): void
    {
        this.location.back();
    }
    public deleteTenantLogo(): void
    {
        this.confirmation.open({
            title: this.translator.instant('You are going to remove the logo'),
            body: this.translator.instant('Your current logo will be deleted, you can upload a new one anytime. Are you sure?'),
            btn_ok: this.translator.instant('Delete'),
            btn_cancel: this.translator.instant('cancel')
        })
            .subscribe(x =>
            {
                if (x)
                {
                    this.tenantService.deleteTenantLogo()
                        .subscribe(
                            () =>
                            {
                                this.currentLogo = null;
                                this.customLogo = null;
                            });
                }
            });
    }
    public deleteTenantSeal(): void
    {
        this.confirmation.open({
            title: this.translator.instant('You are going to remove the seal'),
            body: this.translator.instant('Your current seal will be deleted, you can upload a new one anytime. Are you sure?'),
            btn_ok: this.translator.instant('Delete'),
            btn_cancel: this.translator.instant('cancel')
        })
            .subscribe(x =>
            {
                if (x)
                {
                    this.tenantService.deleteTenantSeal()
                        .subscribe(
                            () =>
                            {
                                this.currentSeal = null;
                                this.customSeal = null;
                            }
                        );
                }
            });
    }
    public createKey()
    {

        const da: DialogData<ApiKeyFormComponent> = {
            title: 'Create key',
            positiveButton: 'create',
            data: null,
            locales: [],
            countries: [],
            isEdit: false,
            dynamycComponent: ApiKeyFormComponent,
            hideCloseButton: false
        };

        const ds: DialogStructure<ApiKeyFormComponent> = {
            data: da,
            width: '718px',
            // @ts-ignore
            height: '600px',
            disableClose: false
        };

        this.dialog.open(ds).subscribe(r =>
        {
            this.apiKeyService.getData();
        });
    }
    public openHelp(): void
    {
        this.matDialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: [this.cvsTip, this.logoTip, this.imageTip, this.fieldsTip]
        });
    }
    private isImage(file: File): Observable<boolean>
    {
        const isImage = new Subject<boolean>();
        const reader = new FileReader();
        reader.onloadend = (e: any) =>
        {
            let header = '';
            const arr = (new Uint8Array(e.target.result as any)).subarray(0, 4);
            for (let i = 0; i < arr.length; i++)
            {
                header += arr[i].toString(16);
            }

            // Determine if is an image
            isImage.next(this.checkFileHeadersToDetermineIfImage(header));
            isImage.complete();
        };

        reader.readAsArrayBuffer(file);
        return isImage.asObservable();
    }
    private checkFileHeadersToDetermineIfImage(header: string): boolean
    {
        switch (header)
        {
            case '89504e47':
                // type = "image/png";
                return true;
            case '47494638':
                // type = "image/gif";
                return true;
            case 'ffd8ffe0':
            case 'ffd8ffe1':
            case 'ffd8ffe2':
            case 'ffd8ffe3':
            case 'ffd8ffe8':
                // type = "image/jpeg";
                return true;
            default:
                return false;
        }
    }
    public cvsSave()
    {
        this.tenantService.setTenantCvs({ value: this.cvsAllow })
            .subscribe(d =>
            {
                this.cvsAllow = d.value;
            })
    }
}
