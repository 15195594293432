
<div class="flex-row-start">
    <div class="flex-col flex-v-center-content fab-initials" [style.color]="Color"
        [style.background]="BaseColor">
        {{ User.name[0].toUpperCase() }}
    </div>
    <div class="flex-col pad-l10">
        <div class="flex-row-start pad-l10 capitalize" style="margin-top: 5px;padding-right: 70px;">
            {{User.name}} {{User.surname}}
        </div>
        <div fxLayout="row" fxFlex="20px" fxLayoutAlign="start start" class="pad-l10">
            <span class="hint rbt" style="text-transform: none;">{{'created_date' |translate}}: {{ User.created_at | prepareDate | date:'short'}}</span>
        </div>
        <div fxLayout="row" fxFlex="20px" fxLayoutAlign="start start" class="pad-l10">
            <span class="hint rbt" style="text-transform: none;"><i>{{ User.rol | translate}}</i></span>
        </div>
    </div>
</div>
<div fxLayout="row" class="pad-l10 mar-t10" fxLayoutAlign="start center">
    <mat-icon class="small mar-r10">send</mat-icon> <a style="font-size: 14px;" class="rbt" [href]="'mailto:' + User.email">{{
        User.email }}</a>
</div>
<div class="actions">
    <mat-menu #userOptMenu="matMenu">
        <button color="primary" class="mar-r10" (click)="Edit()" mat-menu-item>
            <mat-icon color="primary" class="small mar-r5">edit</mat-icon>
            {{ 'edit' | translate }}
        </button>
        <button color="primary" class="mar-r10" (click)="PasswordReset()" mat-menu-item>
            <mat-icon color="primary" class="small mar-r5">mail</mat-icon>
            {{ 'send mail reset password' | translate }}
        </button>
        <button *ngIf="(userMenuService.user$ | async)?.id !== User.id" color="warn" mat-menu-item (click)="Delete()">
            <mat-icon color="warn" class="small">delete</mat-icon>
            {{ 'Delete' | translate }}
        </button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="userOptMenu">
        <mat-icon>more_vert</mat-icon>
    </button>


</div>
