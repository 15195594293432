import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ColorService } from 'src/app/core/services/color.service';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';

@Component({
    selector: 'app-template-simple',
    templateUrl: './template-simple.component.html',
    styleUrls: ['./template-simple.component.scss']
})
export class TemplateSimpleComponent implements OnInit
{

    @Input() template: TemplateData;
    @Input() showPreview = false;
    @Output() preview = new EventEmitter<TemplateData>();
    constructor(
        public color: ColorService
    ) { }

    ngOnInit()
    {
    }

    public toDate(date: string): string
    {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
    }

}
