import { Component, EventEmitter, OnInit } from '@angular/core';
import { iDialogBase } from 'src/app/cross-platform-dialog/shared/iDialogBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-api-key-form',
    templateUrl: './api-key-form.component.html',
    styleUrls: ['./api-key-form.component.scss']
})
export class ApiKeyFormComponent implements OnInit, iDialogBase
{
    binding: EventEmitter<boolean> = new EventEmitter<boolean>();
    data: any;
    Object: any = Object;
    Array: any = Array;
    public formGroup: FormGroup;

    constructor(
        private wsh: WebServiceHelperService,
        private formBuilder: FormBuilder
    )
    {
        this.formGroup = this.formBuilder.group({
            id: null,
            name: ['', [Validators.required]],
            redirect: null,
            key_type: ['auth_code']
        });

        this.formGroup.controls.key_type.valueChanges
            .subscribe(r => this.keyTypeChnage(r));

        const errs = {
            '*': {
                pattern: 'valor no valido',
                required: 'campo requerido'
            }
        };

        this.formGroup.statusChanges
            .pipe(filter(x => x === 'INVALID'))
            .subscribe(x =>
            {
                const fg = this.formGroup;

                Object.keys(this.formGroup.controls).forEach(inputName =>
                {
                    const errors = this.formGroup.controls[inputName].errors || {};

                    if (Array.isArray(errors) && errors.length > 0)
                    {
                        return;
                    }

                    const newErrs = [];
                    Object.keys(errors).forEach(validatorName =>
                    {
                        if (undefined !== (errs[inputName] || {})[validatorName])
                        {
                            newErrs.push(errs[inputName][validatorName]);
                        } else if (errs['*'][validatorName])
                        {
                            newErrs.push(errs['*'][validatorName]);
                        }
                    });
                    if (newErrs.length > 0)
                    {
                        this.formGroup.controls[inputName].setErrors(newErrs);
                    }

                });
            });
    }

    action(args?: any)
    {
        Object.keys(this.formGroup.controls).forEach(k =>
        {
            this.formGroup.controls[k].markAsTouched();
        });
        let url = environment.backEndDomain;
        url += 'api/customer/apiKeys';
        this.wsh.post<any>(url, this.formGroup.value)
            .subscribe(
                r =>
                {
                    Object.keys(this.formGroup.controls).forEach(k =>
                    {
                        this.formGroup.controls[k].setErrors({});
                    });
                    this.binding.emit(true);
                },
                e =>
                {
                    Object.keys(e.error.errors).forEach(k =>
                    {
                        this.formGroup.controls[k].setErrors(e.error.errors[k]);
                    });
                });
    }

    ngOnInit(): void
    {
    }

    setData(param: any): void
    {
        const data = param.data;
        if (data)
        {
            this.formGroup.controls.id.patchValue(data.id);
            this.formGroup.controls.name.patchValue(data.name);
            this.formGroup.controls.redirect.patchValue(data.redirect);
            this.formGroup.controls.key_type.patchValue(data.password_client ? 'passwd' : 'auth_code');
        }
    }

    keyTypeChnage(e: string)
    {
        switch (e)
        {
            case 'auth_code':
                this.formGroup.controls.redirect.enable();
                break;
            case 'passwd':
                this.formGroup.controls.redirect.disable();

                break;
        }
    }
}
