import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignaturePad } from 'angular2-signaturepad';
import { SignatureFromPicker } from 'src/app/core/models/signature-picker/signature-from-picker.model';
import { Signature } from 'src/app/core/models/signature-picker/signature.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';

@Component({
    selector: 'app-singature-picker',
    templateUrl: './singature-picker.component.html',
    styleUrls: ['./singature-picker.component.css']
})
export class SingaturePickerComponent implements OnInit
{
    @ViewChild('pad', { static: true }) public pad: SignaturePad;
    public signaturePadOptions: any = {
        minWidth: 0,
        canvasWidth: 324,
        canvasHeight: 200
    };
    public signatureForm: FormGroup;

    constructor(
        private toast: I18nToastService,
        private dialogRef: MatDialogRef<SingaturePickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Signature
    )
    {
        this.signatureForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            status_id: new FormControl(1),
            id: new FormControl()
        });
    }

    ngOnInit(): void
    {
        if (this.data)
        {
            const data = {
                name: this.data.name,
                status_id: this.data.status_id,
                id: this.data.id
            };

            this.signatureForm.setValue(data);
        }
    }

    public store(data: any): void
    {
        if (this.signatureForm.valid)
        {
            const formVal: SignatureFromPicker = this.signatureForm.value;
            const points = this.pad.toData();
            const img = this.pad.toDataURL();

            const totalPoints = points.reduce((prev, curr) => {
                return prev += curr.length;
            }, 0);

            if (totalPoints > 25)
            {
                formVal.payload = points;
                formVal.image = img;

                this.dialogRef.close(formVal);
            }
            else
            {
                this.toast.open(
                    'The signature is too short',
                    'Accept',
                    7000,
                    true
                );
            }
        }
        else
            this.toast.open(
                'Make sure you filled the name field',
                'Accept',
                7000,
                true
            );
    }
    public cancel(): void
    {
        this.dialogRef.close();
    }
}
