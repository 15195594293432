import { Component, OnInit } from '@angular/core';
import { DocumentDefinitionComponent } from '../../document-definition.component';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { DocumentTemplateFormManagementService } from 'src/app/core/services/content/document-template-form-management.service';
import { PdfBlobOpenerService } from 'src/app/shared/pdf-blob-opener.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import { DocumentTemplateManagementService } from 'src/app/core/services/content/document-template-management.service';
import { DOC_FORM_DEF_TIPS, TPL_DEF_TIPS, TPL_DEF_TIPS_MBL } from 'src/app/configs/dicctionaries/send-assistan/doc-definition-tips.dictionary';
import { of, zip } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-document-template-form-definition',
  templateUrl: './document-template-form-definition.component.html',
  styleUrls: ['./document-template-form-definition.component.scss']
})
export class DocumentTemplateFormDefinitionComponent extends DocumentDefinitionComponent {

  public TIPS = DOC_FORM_DEF_TIPS;

    constructor(
        protected router: Router,
        protected tplService: DocumentTemplateManagementService,
        protected blobOpener: PdfBlobOpenerService,
        protected toast: I18nToastService,
        protected translator: TranslateService,
        public responsive: ResponsiveService,
        protected dialog: MatDialog,
        protected mobileStepper: MobileStepperService,
        protected activatedRoute: ActivatedRoute,
        protected docFrmService: DocumentTemplateFormManagementService
    ){
        super(
        router,
        tplService,
        blobOpener,
        toast,
        translator,
        responsive,
        dialog,
        mobileStepper,
        activatedRoute
        );
    }

    ngOnInit()
    {
        zip(this.activatedRoute.data, this.activatedRoute.params)
        .pipe(
            take(1),
            map((x: Array<any>) => {
                return x.reduce((carry, current) => {
                    return Object.assign(carry, current);
                }, {});
            }),
            mergeMap(x => {
                if(x.id){
                    return zip(of(x), this.tplService.getById(x.id))
                }
                return of([x]);
            })
            )
        .subscribe(
            (data: Array<any>) =>
            {
                this.tpl.is_form = 1;
                this.tpl.is_tpl = data[0].isTemplate || false;
                if (this.tpl.is_tpl)
                {
                    this.classes = 'template-creation';
                    this.TIPS = TPL_DEF_TIPS;

                    if (this.responsive.IsMobile())
                        this.TIPS = TPL_DEF_TIPS_MBL;
                }

                if(data[1]){
                    this.tpl = data[1];
                    this.tpl.is_form = 1;
                    if(this.tpl.doc_count > 0){
                        const txt = this.translator.instant("Esta plantilla se ya se ha utilizado para firma documentos, algunas propiedades no son editables.");
                        this.toast.open(txt, 'Accept', 5000)
                    }
                }

            }
        )
    }

    next(){
        this.cleanErrors();
        if (this.choice)
        {
            if (this.choice == 'existing' && !this.tpl.id)
            {
                this.toast.open('You have to select a document before continue', 'Accept', 5000);
                return;
            }
            if (this.tpl.is_tpl && this.tpl.description == '')
            {
                this.toast.open('The description field is mandatory', 'Accept', 5000);
                return;
            }
            const o = this.choice == 'existing' ? this.cloneFrm() : this.uploadNewFrm();
            if (o)
                o.subscribe(
                    r => {
                        if (this.tpl.is_form == 1){
                            // r.is_form = 1;
                            
                            this.router.navigate(['document_template_form_management', 'configure', r.id]);
                        }else if (this.tpl.is_tpl){
                            this.router.navigate(['template_management', 'configure', r.id]);
                        }else{
                            this.router.navigate(['send_assistant', 'template', r.id]);
                        }
                    },
                    e => this.handleErrors(e)
                );
        }
        else
            this.toast.open('You have to choose a send method before continue', 'Accept');
    }

  protected uploadNewFrm()
  {
      const auxFileInput = this.fileInput.nativeElement;

      if (auxFileInput.files.length === 0 && this.tpl.id === null)
      {
          // if (!this.newAccordeonPanel._isExpanded()) {
          //     this.newAccordeonPanel._toggle();
          // }
          this.choice = 'new';
          this.radioNew.checked = true;

          if (!this.tpl.is_tpl)
              this.radioExisting.checked = false;

          this.inputFileErr = this.translator.instant('Please, choose a file to upload');
          return;
      }

      if(!(this.tpl.id)){
          this.tpl.id = undefined;
      }
      this.tpl.is_form = 1;
      return this.docFrmService.createForm(this.tpl, auxFileInput.files[0]);
  }
    
  cloneFrm(){
      const newTpl = { formId: this.tpl.id, newName: this.tpl.name };

      return this.docFrmService.cloneForm(newTpl);
  }

}
