import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { TenantUpdateComponent } from './components/tenant-update/tenant-update.component';
import { TenantManagementComponent } from './tenant-management.component';

const routes: Routes = [
    {
        path: '',
        component: TenantManagementComponent,
        children: [
            {
                path: 'manage',
                component: TenantUpdateComponent
            },
            {
                path: 'invoices',
                component: InvoicesComponent,
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TenantManagementRoutingModule { }
