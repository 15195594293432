import { Component, OnInit } from '@angular/core';
import { PdfEditableCardComponent } from '../../../../components/pdf-editable-card/pdf-editable-card.component';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pdf-editable-unattended-card',
  templateUrl: './pdf-editable-unattended-card.component.html',
  styleUrls: ['./pdf-editable-unattended-card.component.scss']
})
export class PdfEditableUnattendedCardComponent extends PdfEditableCardComponent {
    private signatureLink: BehaviorSubject<string>;
    public signatureLink$: Observable<string>;
    public elementType = NgxQrcodeElementTypes.URL;
    public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

    ngOnInit(): void
    {
        this.signatureLink = new BehaviorSubject<string>(null);
        this.signatureLink$ = this.signatureLink.asObservable();

        this.signatureLink.next(`${environment.backEndDomain}template_form_unattended?token=${this.template.token}`);
        console.log(this.signatureLink$)
    }

    public edit(): void{
        if (this.template)
            this.router.navigate(['editable_pdf', 'file_unattended'], {
                queryParams: { id: this.template.document_template_id, editablePdfId: this.template.id }
            });
    }
    public delete(): void{
            this.service.deleteTpl(this.template.id)
            .subscribe(
                x =>
                {
                    let msg = this.trans.instant('form x has been disabled', {contact_name: this.template.name});
                    if (this.template.status == 'OFF')
                        msg = this.trans.instant('form x has been enabled', {contact_name: this.template.name});

                    this.toast.open(msg, this.trans.instant('Accept'), 5000, false);
                    this.deleted.emit();
                },
                e =>
                {
                    console.log(e);
                    this.msgBox.instant({
                        data: {
                            title: this.trans.instant('error'),
                            body: this.trans.instant('Can not delete'),
                            btn_ok: this.trans.instant('ok')
                        }
                    })
                }
            );
    }

    public shareDocument($event, token): void{
        this.router.navigate([`editable_pdf/share/${token}`]);
    }

    public downloadQRCode($event): void{
        const image = document.querySelector('ngx-qrcode').querySelector('img');
        const link = document.createElement('a');
        link.href = image.src;
        link.download = 'qr_code.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
