<div
    class="pad-l10 pad-r10"
    style="display: flex; align-items: center; grid-column: 1 / -1;"
>
    <div class="title">{{ "billing" | translate }}</div>
</div>

<!-- MOBILE -->
<ng-container *ngIf="responsive.isMobile$ | async; else desk">
    <mat-tab-group style="overflow: hidden;">
        <mat-tab [label]="'Buy' | translate">
            <div class="carrousels">
                <ng-container *ngTemplateOutlet="carrousels"></ng-container>
            </div>
        </mat-tab>
        <mat-tab [label]="'Invoices' | translate">
            <ng-container *ngTemplateOutlet="invoicesTable"></ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-container>

<!-- DESK -->
<ng-template #desk>
    <ng-container *ngTemplateOutlet="carrousels"></ng-container>
    <div class="pad-l10 pad-r10 title" style="margin-top: 15px;" fxLayout="row">
        {{ "Invoices" | translate }}
    </div>
    <ng-container *ngTemplateOutlet="invoicesTable"></ng-container>
</ng-template>

<ng-template #invoicesTable>
    <app-invoices-table
        [tableAdapter]="invoicesTA"
        style="overflow-y: auto; padding: 0 10px; grid-column: 1 / -1;"
    >
    </app-invoices-table>
</ng-template>

<ng-template #carrousels>
    <app-buy-packages-carrousel
        [packs]="packs"
        [countryId]="tenant?.country_id"
        [title]="'Choose a pack' | translate"
        [docType]="'Documents' | translate"
        (buyAction)="startBuyProcess($event)"
    >
    </app-buy-packages-carrousel>

    <app-buy-packages-carrousel
        class="bg-green"
        [packs]="whatsappPacks"
        [countryId]="tenant?.country_id"
        [title]="'Choose a whatsapp pack' | translate"
        [docType]="'WhatsApp messages' | translate"
        (buyAction)="startBuyProcess($event)"
    >
    </app-buy-packages-carrousel>

    <app-buy-packages-carrousel
        class="bg-red"
        [packs]="smsPacks"
        [countryId]="tenant?.country_id"
        [title]="'Choose a sms pack' | translate"
        [docType]="'Sms messages' | translate"
        (buyAction)="startBuyProcess($event)"
    >
    </app-buy-packages-carrousel>
</ng-template>
