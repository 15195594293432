<div class="mat-typography">
    <div >
        {{'This webapp is optimize to work on Google Chrome' | translate}}.
    </div>

    <div>
        {{ 'Please copy the link and open it on chrome' | translate }}.
    </div>

    <br/>
    <br/>
    <div >
        <mat-form-field style="width: 100%;">
            <input #daInput type="text" matInput placeholder="{{'Link' | translate}}" [(ngModel)]="link">
        </mat-form-field>
    </div>
</div>
