<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title flex-row-between" style="align-items: center;">
        {{ 'Account' | translate }}
        <button (click)="openHelp()" color="primary" mat-icon-button>
            <mat-icon>help</mat-icon>
        </button>
    </div>
    <ng-container *ngTemplateOutlet="contentMobile"></ng-container>
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-container>

<ng-template #desktop>
    <div class="help-col">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <app-tip [idx]="0" [tip]="cvsTip"></app-tip>
        <app-tip [idx]="1" [tip]="logoTip"></app-tip>
        <app-tip [idx]="2" [tip]="imageTip"></app-tip>
        <app-tip [idx]="3" [tip]="fieldsTip"></app-tip>
    </div>
    <div class="right-column">
        <ng-container *ngTemplateOutlet="contentDesk"></ng-container>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
</ng-template>

<ng-template #buttons>
    <div class="buttons" style="margin-top: 12px;">
        <button mat-stroked-button color="primary" class="grow-none"
                (click)="goBack()">{{ 'close' | translate }}</button>
        <button [disabled]="!validForm" mat-raised-button color="primary" class="grow-none"
                (click)="setTenant()">{{ 'save' | translate }}</button>
    </div>
</ng-template>

<ng-template #contentDesk>
    <mat-tab-group>
        <mat-tab [label]="'account_management' | translate">
            <ng-container *ngTemplateOutlet="accountManage"></ng-container>
        </mat-tab>
        <mat-tab [label]="'Customization' | translate">
            <ng-container *ngTemplateOutlet="customization"></ng-container>
        </mat-tab>
        <mat-tab [label]="'Aditional fields' | translate">
            <ng-container *ngTemplateOutlet="fields"></ng-container>
        </mat-tab>
        <mat-tab label="API">
            <ng-container *ngTemplateOutlet="api"></ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template #contentMobile>
    <div #heightCalc>
        <mat-accordion>
            <mat-expansion-panel #firstStep>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'account_management' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [style.maxHeight.px]="initialMobileExpansionHeight" style="overflow-y:auto">
                    <ng-container *ngTemplateOutlet="accountManage"></ng-container>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'Customization' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [style.maxHeight.px]="initialMobileExpansionHeight" style="overflow-y:auto">
                    <ng-container *ngTemplateOutlet="customization"></ng-container>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'Aditional fields' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [style.maxHeight.px]="initialMobileExpansionHeight" style="overflow-y:auto">
                    <ng-container *ngTemplateOutlet="fields"></ng-container>
                </div>
            </mat-expansion-panel>

            <!-- <mat-expansion-panel>
                <mat-expansion-panel-header>
                <mat-panel-title>
                {{ 'api keys management'| translate }}
                </mat-panel-title>
                </mat-expansion-panel-header>
                <div style="max-height: 300px; overflow-y:auto">
                <ng-container *ngTemplateOutlet="api"></ng-container>
                </div>
                </mat-expansion-panel> -->
        </mat-accordion>
    </div>
</ng-template>

<ng-template #accountManage>
    <div class="configuration-row mar-t10">
        <mat-form-field>
            <mat-label>{{ 'company' | translate }}</mat-label>
            <input matInput placeholder="{{'company' | translate }}" [(ngModel)]="tenantData.company">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'fiscal_id' | translate }}</mat-label>
            <input matInput placeholder="{{'fiscal_id' | translate }}" [(ngModel)]="tenantData.fiscal_id">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'name' | translate }}</mat-label>
            <input matInput placeholder="{{'name' | translate }}" [(ngModel)]="tenantData.name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'surnames' | translate }}</mat-label>
            <input matInput placeholder="{{'surnames' | translate }}" [(ngModel)]="tenantData.surname">
        </mat-form-field>
    </div>
    <div class="configuration-row">
        <mat-form-field>
            <mat-label>{{ 'phone_no' | translate }}</mat-label>
            <input matInput placeholder="{{'phone_no' | translate }}" [(ngModel)]="tenantData.phone_no">
        </mat-form-field>
        <mat-form-field class="email-field">
            <mat-label>{{ 'email' | translate }}</mat-label>
            <input matInput placeholder="{{'email' | translate }}" [(ngModel)]="tenantData.email">
        </mat-form-field>
    </div>
    <div class="configuration-row">
        <mat-form-field class="email-field">
            <mat-label>{{ 'address' | translate }}</mat-label>
            <input matInput placeholder="{{'address' | translate }}" [(ngModel)]="tenantData.address">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'city' | translate }}</mat-label>
            <input matInput placeholder="{{'city' | translate }}" [(ngModel)]="tenantData.city">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'postal_code' | translate }}</mat-label>
            <input matInput placeholder="{{'postal_code' | translate }}" [(ngModel)]="tenantData.postal_code">
        </mat-form-field>
    </div>
    <div class="configuration-row">
        <mat-form-field>
            <mat-label>{{ 'region' | translate }}</mat-label>
            <input matInput placeholder="{{'region' | translate }}" [(ngModel)]="tenantData.region">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'country' | translate }}</mat-label>
            <mat-select [(value)]="tenantData.country_id">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let c of countries" [value]="c.id">{{c.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>
<ng-template #customization>
    <div class="cvs-grid">
        <div class="cvs-grid-item subtitle">
            1. {{ 'CVS configuration' | translate }}:
        </div>
        <div class="csv-grid-preview">
            <div class="doc mat-elevation-z3">
                <div class="cvs-elements">
                    <div class="content">
                        <div class="qr">
                            <img style="height: 42px;" src="assets/png/qrcvs.png">
                        </div>
                        <div class="cvs-content">
                            <div>7868asdhgasd7a8stdhasd8990gjauis</div>
                            <div>https://www.anyrubric.com</div>
                        </div>
                    </div>
                </div>
                <div class="dummy-text">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus unde quae quaerat accusamus asperiores! Quod eaque tenetur error, quam animi recusandae, in eos ut nisi vitae expedita eius voluptate. Nostrum. Quod eaque tenetur error, quam animi recusandae, in eos ut nisi vitae expedita eius voluptate.
                </div>
            </div>
        </div>
        <div class="cvs-grid-item">
            <mat-form-field  class="filter_input">
                <mat-label>{{'CVS' | translate}}:</mat-label>
                <mat-select [(ngModel)]="cvsAllow" >
                    <mat-option *ngFor="let v of cvsKeys" [value]="v">
                        {{ v | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div style="font-size: 10px;line-height: 12px;">
                {{ 'cvs_explanation_1' | translate }}
                {{ 'cvs_explanation_2' | translate }}
            </div>
        </div>
    </div>
    <div class="customization-grid" >
        <div class="col-order-1 subtitle">
            2. {{ 'legal_validation_title' | translate }}:
        </div>
        <div class="col-order-2 subtitle">
            3. {{ 'otp_auth_config_title' | translate }}:
        </div>
        <div class="col-order-3">
            <mat-slide-toggle (change)="handleLegalValidationChange($event)" [checked]="legalValidation" color="primary">{{(legalValidation ? 'active' : 'inactive') | translate}}</mat-slide-toggle>
            <div style="font-size: 10px;line-height: 12px;" [innerHTML]="('legal_validation_description' | translate)">
            </div>
        </div>
        <div class="col-order-4">
            <mat-slide-toggle (change)="handleOTPAuthChange($event)" [checked]="tfAuthService.tfAuthStatus$ | async" color="primary">{{((tfAuthService.tfAuthStatus$ | async) ? 'active' : 'inactive') | translate}}</mat-slide-toggle>
            <div style="font-size: 10px;line-height: 12px;" [innerHTML]="('otp_auth_description' | translate)">
            </div>
        </div>

    </div>
    <div class="customization-grid" >
        <div class="col-order-1 subtitle">
            4. {{ 'Custom logo preview' | translate }}:
        </div>
        <div class="col-order-2 subtitle">
            5. {{ 'Custom seal preview' | translate }}:
        </div>
        <!-- Previsualizaciones -->
        <div class="image-preview logo col-order-3">
            <img *ngIf="currentLogo && !customLogo" [src]="currentLogo"> <img
            *ngIf="(!currentLogo && customLogo) || currentLogo && customLogo" [src]="customLogo"> <img
            *ngIf="!customLogo && !currentLogo" src="assets/svg/my_rubric_logo_03.svg">
        </div>
        <div class="image-preview col-order-4">
            <div class="text">
                {{ 'Signed using MyRubric by x at y' | translate: {'user': currentUser} }}
            </div>
            <div class="signature">
                <img src="assets/png/signature.png">
            </div>
            <div class="custom-seal" *ngIf="customSeal" style="border: 1px solid black;">
                <img [src]="customSeal">
            </div>
            <div class="custom-seal" *ngIf="!customSeal && currentSeal" style="border: 1px solid black;">
                <img [src]="currentSeal">
            </div>
            <div class="seal">
                <img src="assets/png/default-tenant-seal.png">
            </div>

        </div>
        <div class="two-buttons col-order-5">
            <input (change)="handleSealChange($event, 'logo')" type="file" style="display: none;" #logofile>
            <button *ngIf="!customLogo" style="width:100%;" mat-stroked-button color="primary"
                    (click)="logofile.click()">{{ 'Upload tenant logo' | translate }}</button>
            <button *ngIf="customLogo" style="width:58%;" mat-stroked-button color="primary"
                    (click)="logofile.click()">{{ 'Choose another logo' | translate }}</button>
            <button *ngIf="customLogo" style="width:40%;" mat-raised-button color="primary"
                    (click)="confirmLogoSave(logofile.files[0])">{{ 'save' | translate }}</button>
        </div>
        <div class="two-buttons col-order-6">
            <input (change)="handleSealChange($event, 'seal')" type="file" style="display: none;" #sealfile>
            <button *ngIf="!customSeal" style="width:100%;" mat-stroked-button color="primary"
                    (click)="sealfile.click()">{{ 'Upload tenant seal' | translate }}</button>
            <button *ngIf="customSeal" style="width:58%;" mat-stroked-button color="primary"
                    (click)="sealfile.click()">{{ 'Choose another seal' | translate }}</button>
            <button *ngIf="customSeal" style="width:40%;" mat-raised-button color="primary"
                    (click)="confirmSealSave(sealfile.files[0])">{{ 'save' | translate }}</button>
        </div>
        <div class="col-order-7">
            <button *ngIf="currentLogo" style="width:100%;" mat-stroked-button color="warn"
                    (click)="deleteTenantLogo()">{{ 'Delete tenant logo' | translate }}</button>
        </div>
        <div class="col-order-8">
            <button *ngIf="currentSeal" style="width:100%;" mat-stroked-button color="warn"
                    (click)="deleteTenantSeal()">{{ 'Delete tenant seal' | translate }}</button>
        </div>
        <div class="mail-footer subtitle">
            6. {{ 'Mail footer customization' | translate }}
        </div>
        <div class="mail-footer">
            <app-customizable-mail-footer *ngIf="mailFooterService.footer$ | async as footer"
                [data]="footer"
                (valid)="validForm = $event"
                (done)="footerData = $event"
            >
            </app-customizable-mail-footer>
        </div>
    </div>
</ng-template>
<ng-template #fields>
    <div
        *ngIf="this.customTextConf$ | async as customTextConf"
        [formGroup]="customTextformGroup"
        class="fields-grid" style="margin-top: 10px;">
        <div class="bordered right">{{ 'State' | translate }}</div>
        <div class="bordered right">{{ 'field' | translate }}</div>
        <div class="bordered">{{ 'Auth signer' | translate }}</div>
        <ng-container formGroupName="customText" *ngIf="customTextformGroup.controls['customText']['controls'] as frmCtrls">
            <ng-container *ngFor="let conf of customTextConf.customText; let confQt = index" formGroupName="{{confQt}}">
                <div class="bordered right" style="display:flex; align-items: center; justify-content: center;">
                    <mat-checkbox type="text bordered right" matInput formControlName="flag_value"></mat-checkbox>
                </div>

                <div class="bordered right" style="padding: 10px">
                    <mat-form-field style="height: 40px;">
                        <mat-label translate>{{ ('Aditional field' | translate) + ' ' + (confQt + 1) }} </mat-label>
                        <input type="text" [placeholder]="'' | translate" matInput formControlName="data_value">
                        <ng-container
                            *ngIf="frmCtrls[confQt].controls['data_value'] as frmCtrl">
                            <ng-container *ngIf="frmCtrl.dirty || frmCtrl.touched">
                                <mat-error *ngFor="let err of frmCtrl.errors | keyvalue">
                                    {{ err.key | translate }}
                                </mat-error>
                            </ng-container>
                        </ng-container>
                    </mat-form-field>
                </div>
                <div class="bordered" style="display:flex; align-items: center;">
                    <mat-slide-toggle
                        [checked]="customTextformGroup.controls['useAsName']['controls']['data_value'].value == confQt"
                        [disabled]="frmCtrls[confQt].controls['flag_value'].value == 0"
                        (change)="setUseAsNameValue(confQt)"></mat-slide-toggle>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<ng-template #api>
    <div class="doc-link mar-t10 mar-b10" style="display: flex; align-items: center; justify-content: space-between;">
        <i>Documentación de la API: <a [href]="link">{{ link }}</a></i>
        <div>
            <button mat-raised-button color="primary" class="grow-none"
                    (click)="createKey()">{{ 'create api key' | translate }}</button>
        </div>
    </div>
    <div class="configuration-row">
        <app-api-keys-table [tableAdapter]="apiKeyService"></app-api-keys-table>
    </div>
    <div class="subtitle mar-t10"
         style="margin-top: 12px;margin-bottom: 10px; display: flex; justify-content: space-between;">
        {{ 'Templates'| translate }}
    </div>
    <div class="configuration-row">
        <app-template-table [tableAdapter]="templateService"></app-template-table>
    </div>
</ng-template>
