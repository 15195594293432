<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
    <div class="buttons">
        <button mat-button (click)="cancel()" color="primary">{{ 'Cancel' | translate }}</button>
        <button mat-raised-button (click)="save()" color="primary">{{ 'Save' | translate }}</button>
    </div>
</ng-container>

<ng-template #helpCol>
    <div class="help-col mat-typography">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <app-tip [idx]="0" [tip]="createTip"></app-tip>
        <!-- <app-tip [idx]="0" [tip]="legalTip"></app-tip>
        <app-tip [idx]="1" [tip]="mailTip"></app-tip>
        <app-tip [idx]="2" [tip]="localeTip"></app-tip> -->
    </div>
</ng-template>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="helpCol"></ng-container>
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
    <div></div>
    <div class="buttons">
        <button mat-button (click)="cancel()" color="primary">{{ 'Cancel' | translate }}</button>
        <button mat-raised-button (click)="save()" color="primary">{{ 'Save' | translate }}</button>
    </div>
</ng-template>
<ng-template #contentCol>
    <div class="content" [formGroup]="newGroup">
        <div class="title">
            <button (click)="openHelp()" mat-icon-button color="primary" *ngIf="responsive.isMobile$ | async">
                <mat-icon>help</mat-icon>
            </button>
            {{ 'New group' | translate }}
        </div>
        <div class="subtitle">
            {{ 'Indicate a name for the group' | translate }}
        </div>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'Choose name' | translate }}</mat-label>
            <input matInput formControlName="name" type="text">
            <mat-error>{{ 'You should indicate a name' | translate }}</mat-error>
        </mat-form-field>
        <div class="subtitle">
            {{ 'Choose the group type' | translate }}
        </div>
        <mat-radio-group formControlName="type">
            <div class="box-radio choose-opt">
                <mat-radio-button value="contacts">
                    {{ 'Contacts' | translate }}
                </mat-radio-button>
                <div class="icon-holder">
                    <mat-icon color="primary">supervisor_account</mat-icon>
                </div>
            </div>
            <div class="box-radio choose-opt">
                <mat-radio-button value="group">
                    {{ 'Groups' | translate }}
                </mat-radio-button>
                <div class="icon-holder">
                    <mat-icon color="primary">group_work</mat-icon>
                </div>
            </div>
        </mat-radio-group>
    </div>
</ng-template>