<div class="mat-typography bg" fxLayout="column" fxFlex>
    <div fxLayout="row" fxFlex="50px" fxLayoutAlign="start center">
        <button mat-icon-button (click)="OpenSideNav()">
            <mat-icon>menu</mat-icon>
        </button>
        <img style="height: 35px; margin-left: 15px;" src="assets/svg/my_rubric_logo_04.svg">
    </div>
    <div fxLayout="row" class="filters">
        <mat-expansion-panel #filters>
            <span style="font-size: 30px;">FILTROS AKI</span>
        </mat-expansion-panel>
    </div>
    <div fxLayout="row" fxFlex class="bg-content mat-elevation-z6 pad10">
        <div fxLayout="column" fxFlex>
            <div class="pad-l10 pad-r10" fxLayout="row" fxFlex="45px" fxLayoutAlign="space-between center">
                <div class="title">Inicio</div>

                <!-- Boton de los filtros con estado -->
                <div fxLayoutAlign="center center">
                    <button mat-icon-button (click)="filters.toggle()">
                        <mat-icon>tune</mat-icon>
                    </button>
                    <mat-icon style="cursor: pointer;" (click)="filters.toggle()" [@iconExapansionAnimation]="filters.expanded ? 'EXPANDED' : 'COLLAPSED'">keyboard_arrow_up</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
