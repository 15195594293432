import {ConfirmationBoxComponent, ConfirmationBoxConf} from './confirmation-box/confirmation-box.component';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationBoxService {
    constructor(public dialog: MatDialog) {
    }

    open(
        conf:
            | ConfirmationBoxConf
            | {
            title: string;
            body: string;
            btn_ok: string;
            btn_cancel: string;
        }
    ): Observable<boolean> {
        return new Observable(o => {
            this.dialog
                .open(ConfirmationBoxComponent, {data: conf})
                .afterClosed()
                .pipe(take(1))
                .subscribe(r => {
                    if (undefined === r) {
                        o.next(false);
                    } else {
                        o.next(r);
                    }
                    o.complete();
                });
        });
    }
}
