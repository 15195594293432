import { GridsterItem } from "angular-gridster2"

export class DashboardItem implements GridsterItem
{
    [propName: string]: any;
    x: number;
    y: number;
    rows: number;
    cols: number;
    initCallback?;
    dragEnabled?: boolean;
    resizeEnabled?: boolean;
    compactEnabled?: boolean;
    maxItemRows?: number;
    minItemRows?: number;
    maxItemCols?: number;
    minItemCols?: number;
    minItemArea?: number;
    maxItemArea?: number;
    public subType: string;
    public type: 'breakdown' | 'numeric' | 'log';

    constructor(x: number, y: number, cols: number, rows: number, type: 'breakdown' | 'numeric' | 'log', subType: string)
    {
        this.x = x;
        this.y = y;
        this.cols = cols;
        this.rows = rows;
        this.subType = subType;
        this.type = type;
    }
}