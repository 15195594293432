import { Pipe, PipeTransform } from '@angular/core';
import { TemplateOutputDragged } from 'src/app/core/models/api/template/template-output.model';

@Pipe({
    name: 'pageIndexFilter',
    pure: false
})
export class PageIndexFilterPipe implements PipeTransform
{

    transform(value: TemplateOutputDragged[], pageIndex: number): unknown
    {
        return value.filter(x => x.meta.pageIndex == pageIndex);
    }

}
