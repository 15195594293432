import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SubtenantEditAction from './actions';
import { Store } from '@ngrx/store';
import { RootStoreState, SubtenantInvoicesStoreActions } from '..';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SubtenantService } from 'src/app/core/services/pages/subtenant.service';
import { of } from 'rxjs';
import { SubtenantListStoreActions } from 'src/app/root-store/subtenant-list-store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SubtenantEditStoreSelectors } from '.';

@Injectable()
export class SubtenantEditEffects
{
    constructor(
        private actions$: Actions,
        private store$: Store<RootStoreState.State>,
        private service: SubtenantService,
        private dialogRef: MatDialog,
        private router: Router,
    ) { }

    fetch$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.Fetch>(SubtenantEditAction.SubtenantEditActionTypes.FETCH),
                mergeMap(action => this.service.fetchSubtenant(action.payload)
                    .pipe(
                        map(data => new SubtenantEditAction.FetchSuccess(data)),
                        catchError(() => of(new SubtenantEditAction.FetchFail()))
                    )
                )
            )
    );

    postSubtenant$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.Create>(SubtenantEditAction.SubtenantEditActionTypes.CREATE),
                mergeMap(action => this.service.postSubtenant(action.payload)
                    .pipe(
                        map(data => new SubtenantEditAction.CreateSuccess()),
                        catchError((err) => of(new SubtenantEditAction.CreateFail(err.error.errors)))
                    )
                )
            )
    )

    creditTransfer$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.CreditTransfer>(
                    SubtenantEditAction.SubtenantEditActionTypes.CREDIT_TRANSFER),
                mergeMap(action => this.service.creditTransfer(action.payload)
                    .pipe(
                        map(data => new SubtenantEditAction.CreditTransferSuccess()),
                        catchError(() => of(new SubtenantEditAction.CreditTransferFail()))
                    )
                )
            )
    );

    // Cuando se detecta que se ha creado correctamente un subtenatn
    // o se ha transferido crédito correctamente,
    // se despacha el fetch del listado.
    createdOrTransfered$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.CreateSuccess | SubtenantEditAction.CreditTransferSuccess>(
                    SubtenantEditAction.SubtenantEditActionTypes.CREATE_SUCCESS,
                    SubtenantEditAction.SubtenantEditActionTypes.CREDIT_TRANSFER_SUCCESS),
                withLatestFrom(this.store$.select(SubtenantEditStoreSelectors.selectSubtenantEditState)),
                map(([action, state]) =>
                {
                    // Si el registro se crea correctamente, cerramos el dialogo.
                    this.dialogRef.closeAll();
                    const finalUrl = this.router.url;

                    // Dependiendo de donde estemos, hay que hacer un fetch
                    // de las invoices o de la lista de subtenants.
                    if (finalUrl.indexOf('/subtenant_management/edit') != -1)
                        return new SubtenantInvoicesStoreActions.Fetch(state.tenant.uuid);
                    else if (finalUrl.indexOf('/subtenant_management/list') != -1)
                        return new SubtenantListStoreActions.Fetch(1)
                    else
                        return new SubtenantEditAction.DoNothing();
                })
            )
    );

    // Update flow
    updateSubtenant$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.Update>(SubtenantEditAction.SubtenantEditActionTypes.UPDATE),
                mergeMap(action => this.service.updateSubtenant(action.payload.tenant)
                    .pipe(
                        map(data => new SubtenantEditAction.UpdateSuccess(action.payload.config)),
                        catchError((err) => of(new SubtenantEditAction.UpdateFail(err.error.errors)))
                    )
                )
            )
    )
    startUpdateConfig$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.UpdateSuccess>(SubtenantEditAction.SubtenantEditActionTypes.UPDATE_SUCCESS),
                map(action => new SubtenantEditAction.UpdateConfig(action.payload))
            )
    )
    updateConfig$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.UpdateConfig>(SubtenantEditAction.SubtenantEditActionTypes.UPDATE_CONFIG),
                mergeMap(action => this.service.postSubtenantConfig(action.payload)
                    .pipe(
                        map(data => new SubtenantEditAction.UpdateConfigSuccess(action.payload.uuid)),
                        catchError(err => of(new SubtenantEditAction.UpdateConfigFail(err.error.errors)))
                    )
                )
            )
    );
    updateFlowDone$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<SubtenantEditAction.UpdateConfigSuccess>(
                    SubtenantEditAction.SubtenantEditActionTypes.UPDATE_CONFIG_SUCCESS),
                map(action => new SubtenantEditAction.Fetch(action.payload))
            )
    )
}
