<ng-container *ngIf="!(responsive.isMobile$ | async)">
    <!-- Pantallas grandes -->
    <div
        class="available-credit"
        *ngIf="!(responsive.isMd$ | async); else mdAvailableCredit"
    >
        <div [matTooltip]="'Documents' | translate" class="separator">
            <mat-icon>send</mat-icon>
            {{ tenantData?.tenantData?.docs_budget || 0 | number }}
        </div>
        <div [matTooltip]="'whatsapp' | translate" class="separator">
            <img src="assets/svg/whatsapp-brands.svg" alt="" />
            {{ tenantData.tenantData?.whats_budget || 0 | number }}
        </div>
        <div [matTooltip]="'sms' | translate" style="padding-right: 0px;">
            <mat-icon>sms</mat-icon>
            {{ tenantData?.tenantData?.sms_budget || 0 | number }}
        </div>
    </div>

    <!-- Pantallas pequeñas -->
    <ng-template #mdAvailableCredit>
        <div
            class="available-credit"
            (mouseover)="menuTrigger.openMenu()"
            (mouseleave)="menuTrigger.closeMenu()"
        >
            <div
                class="separator"
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="credit"
            >
                <mat-icon>send</mat-icon>
                {{ tenantData?.tenantData?.docs_budget || 0 | number }}
            </div>
        </div>

        <mat-menu #credit [hasBackdrop]="false">
            <div mat-menu-item>
                <mat-icon>send</mat-icon>
                {{ "Documents" | translate }}:
                <b>{{ tenantData?.tenantData?.docs_budget || 0 | number }}</b>
            </div>
            <div mat-menu-item>
                <mat-icon>whatsapp</mat-icon>
                {{ "whatsapp" | translate }}:
                <b>{{ tenantData.tenantData?.whats_budget || 0 | number }}</b>
            </div>
            <div mat-menu-item>
                <mat-icon>sms</mat-icon>
                {{ "sms" | translate }}:
                <b>{{ tenantData?.tenantData?.sms_budget || 0 | number }}</b>
            </div>
        </mat-menu>
    </ng-template>
    <div class="separator-topbar" style="margin-right: 0px;"></div>
</ng-container>

<!-- DESKTOP -->
<button
    *ngIf="!(responsive.isMobile$ | async)"
    class="noborder acc"
    mat-stroked-button
    [matMenuTriggerFor]="appMenu"
>
    <mat-icon class="mar-r5">account_circle</mat-icon>
    <ng-container *ngIf="tenantData && tenantData.userData">
        {{ tenantData.userData.name }}
    </ng-container>
</button>

<!-- MOBILE -->
<!-- <button *ngIf="responsive.isMobile$ | async" class="noborder" mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon>account_circle</mat-icon>
</button> -->

<mat-menu #appMenu="matMenu">
    <div
        class="mat-typography"
        style="font-size: 14px;padding: 12px; text-transform: capitalize;"
        [translate]="'Hi'"
        [translateParams]="{
            value:
                tenantData.userData && tenantData.userData.name
                    ? tenantData.userData.name
                    : '...'
        }"
    ></div>
    <mat-divider></mat-divider>
    <button
        class="usermenu-button"
        [routerLink]="['/subtenant_management/list']"
        routerLinkActive="router-link-active"
        mat-menu-item
        *ngIf="service.userData && service.userData.is_partner == 1"
    >
        <mat-icon>supervisor_account</mat-icon>
        {{ "menu_subtenant_managament" | translate }}
    </button>
    <button
        class="usermenu-button"
        [routerLink]="['/tenant_management/manage']"
        routerLinkActive="router-link-active"
        mat-menu-item
        *ngIf="service.userData && service.userData.rol === 'admin'"
    >
        <mat-icon>settings</mat-icon>
        {{ "menu_tenant_managament" | translate }}
    </button>
    <button
        class="usermenu-button"
        [routerLink]="['/tenant_management/invoices']"
        routerLinkActive="router-link-active"
        mat-menu-item
        *ngIf="service.userData && service.userData.rol === 'admin'"
    >
        <mat-icon>account_balance</mat-icon>
        {{ "menu_tenant_invoices" | translate }}
    </button>
    <button
        class="usermenu-button"
        [routerLink]="['/user_managament']"
        routerLinkActive="router-link-active"
        mat-menu-item
        *ngIf="service.userData && service.userData.rol === 'admin'"
    >
        <mat-icon>people</mat-icon>
        {{ "menu_users_managament" | translate }}
    </button>
    <button class="usermenu-button" (click)="openAbout()" mat-menu-item>
        <mat-icon>info</mat-icon>
        {{ "About" | translate }}
    </button>
    <button
        *ngIf="(tfAuthService.tfAuthStatus$ | async) == true"
        class="usermenu-button"
        (click)="openTwoFactorAuth()"
        mat-menu-item
    >
        <mat-icon>admin_panel_settings</mat-icon>
        {{ "tf_title" | translate }}
    </button>
    <button
        class="usermenu-button"
        (click)="logOut()"
        mat-menu-item
        color="danger"
    >
        <mat-icon>exit_to_app</mat-icon>
        {{ "Exit" | translate }}
    </button>
</mat-menu>
