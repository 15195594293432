import { Component, OnInit } from '@angular/core';
import { TemplateEditorComponent } from '../../template-editor.component';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-document-form-editor',
  templateUrl: './document-form-editor.component.html',
  styleUrls: ['./document-form-editor.component.scss']
})
export class DocumentFormEditorComponent extends TemplateEditorComponent {

  constructor(
    public responsive: ResponsiveService,
    public pdfControls: PdfControlsService,
    protected toast: I18nToastService,
    protected dialog: MatDialog,
    protected location: Location,
    protected templateEditor: TemplateEditorService,
    protected router: Router,
    protected stepper: MobileStepperService,
    protected route: ActivatedRoute
  ) {
    super(responsive, pdfControls, toast, dialog, location, templateEditor, router, stepper, route);
  }
  public previous(): void{
      if (this.templateEditor.isPreviewMode())
          this.location.back();
      else
          this.router.navigate(['document_template_form_management', 'file']);
  }
  public continue(): void{
      this.pdfControls.serializeAndSave()
          .pipe(take(1))
          .subscribe(
              serialized =>
              {
                  const template = this.templateEditor.getTemplate();
                  this.router.navigate([
                      'document_template_form_management',
                      'share',
                      template.tpl.form_id]);
              },
              error =>
              {
                  let msg = '';

                  if (error instanceof HttpErrorResponse)
                      msg = error.error.message;
                  else
                      msg = error;

                  this.toast.open(msg, 'Accept', 7000, false);
              }
          )
  }
  editPrevious(){

      this.router.navigate(['/document_template_form_management', 'list']);
  }

  saveNReturn(){
    this.pdfControls.serializeAndSave()
      .pipe(take(1))
      .subscribe(
          serialized =>
          {
              const template = this.templateEditor.getTemplate();
              this.router.navigate(['/document_template_form_management', 'list']);
          },
          error =>
          {
              let msg = '';

              if (error instanceof HttpErrorResponse)
                  msg = error.error.message;
              else
                  msg = error;

              this.toast.open(msg, 'Accept', 7000, false);
          }
      )
  }

}
