import { ERROR_CODES } from '../error-codes';
import { SubtenantEditActionsUnion, SubtenantEditActionTypes } from './actions';
import { subtenantInitialState, SubtenantEditState } from './state';

export function subtenantEditReducer(state: SubtenantEditState = subtenantInitialState,
    action: SubtenantEditActionsUnion): SubtenantEditState
{
    switch (action.type)
    {
        case SubtenantEditActionTypes.FETCH:
            return {
                ...state,
                tenant: null,
                isLoading: true,
                errorMsg: null,
            }
        case SubtenantEditActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                tenant: action.payload,
                isLoading: false
            }
        case SubtenantEditActionTypes.FETCH_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMsg: ERROR_CODES.FETCHING_SINGLE_CONTACT
            }
        case SubtenantEditActionTypes.CREATE:
        case SubtenantEditActionTypes.CREDIT_TRANSFER:
        case SubtenantEditActionTypes.UPDATE:
        case SubtenantEditActionTypes.UPDATE_CONFIG:
            return {
                ...state,
                isLoading: true,
                errorMsg: null
            }
        case SubtenantEditActionTypes.CREATE_SUCCESS:
        case SubtenantEditActionTypes.CREDIT_TRANSFER_SUCCESS:
        case SubtenantEditActionTypes.UPDATE_SUCCESS:
        case SubtenantEditActionTypes.UPDATE_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case SubtenantEditActionTypes.CREATE_FAIL:
        case SubtenantEditActionTypes.UPDATE_FAIL:
        case SubtenantEditActionTypes.UPDATE_CONFIG_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload || ERROR_CODES.PROCESSING_SUBTENANT
            }
        case SubtenantEditActionTypes.CREDIT_TRANSFER_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMsg: ERROR_CODES.PROCESSING_CREDIT_TRANSFER
            }
        case SubtenantEditActionTypes.CLEAR:
            return {
                tenant: null,
                errorMsg: null,
                isLoading: false,
            }
        default:
            return state;
    }
}
