import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import { TemplateTableAdapterService } from 'src/app/core/services/pages/tenant-management/template-table-adapter.service';

@Component({
    selector: 'app-template-table',
    templateUrl: './template-table.component.html',
    styleUrls: ['./template-table.component.css']
})
export class TemplateTableComponent implements OnInit
{
    @ViewChild('table') table: MatTable<any>;

    @Input('tableAdapter') tableAdapter: TemplateTableAdapterService;

    constructor(private router: Router) { }

    ngOnInit() {

    }

    public preview(id: number): void
    {
        this.router.navigate([
            'send_assistant',
            'template',
            id
        ], {
            queryParams: {
                isPreview: true
            }
        });
    }

}
