<table mat-table #table [dataSource]="tableAdapter.data">
    <ng-container *ngFor="let colDef of tableAdapter.getStdCols()">
        <ng-container [matColumnDef]="colDef.column">
            <th mat-header-cell *matHeaderCellDef>{{colDef.columnTitle}}</th>
            <td mat-cell *matCellDef="let row">{{row[colDef.column]}}</td>
        </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableAdapter.getAllColumnsName()"></tr>
    <tr mat-row *matRowDef="let row; columns: tableAdapter.getAllColumnsName();"></tr>
</table>

<mat-paginator #paginator [pageIndex]="tableAdapter.currentPage" [length]="tableAdapter.elementCount"
    [pageSize]="tableAdapter.pageSize" (page)="tableAdapter.paginatorEventHandler($event)">
</mat-paginator>
