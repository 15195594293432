import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toFloat'
})
export class ToFloatPipe implements PipeTransform
{

    transform(value: string): number
    {
        return parseFloat(value);
    }

}
