<div *ngIf="!(responsive.isMobile$ | async); else mobile">
    <div class="pdfControls">
        <!-- Add PDF -->
        <ng-container *ngTemplateOutlet="pdfSelectAndSignature"></ng-container>
        <ng-container *ngTemplateOutlet="pageAndZoom"></ng-container>
    </div>
</div>

<ng-template #mobile>
    <div>
        <ng-container *ngTemplateOutlet="pdfSelectAndSignature"></ng-container>
    </div>
    <div>
        <ng-container *ngTemplateOutlet="pageAndZoom"></ng-container>
    </div>
</ng-template>


<ng-template #pdfSelectAndSignature>
    <!-- Add PDF -->
    <div style="display: flex;align-items: center;">
        <ng-container *ngIf="!tpl.tpl.id; else withDocument">
            <button (click)="file.click()" mat-raised-button color="primary">
                {{ 'Select a PDF' | translate }}
            </button>
        </ng-container>
        <input type="file" #file (change)="fileChange($event)" style="display: none">
        <ng-template #withDocument>
            <span *ngIf="!(responsive.isMobile$ | async); else mblSpan">{{ tpl.tpl.name }}</span>
            <ng-template #mblSpan>
                <span
                    [matTooltip]="tpl.tpl.name"
                    style="text-overflow: ellipsis;width: 80px;white-space: nowrap;overflow: hidden;">{{ tpl.tpl.name }}</span>
            </ng-template>
        </ng-template>
        <div *ngIf="!(responsive.isMobile$ | async)" class="separator"></div>
        <ng-container *ngIf="!(responsive.isMobile$ | async); else mblSignature">
            <button [matTooltip]="'Colocar firma' | translate" (click)="addSignature()" mat-icon-button [disabled]="!(pdfControls.pdf$ | async)">
                <mat-icon class="mat-r10">gesture</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="!(responsive.isMobile$ | async)">
            <button [matTooltip]="'Adjunto' | translate" (click)="addAttached()" mat-icon-button [disabled]="!(pdfControls.pdf$ | async)">
                <mat-icon>attach_file</mat-icon>
            </button>
        </ng-container>
        <ng-template #mblSignature>
            <button (click)="addSignature()" mat-icon-button *ngIf="pdfControls.pdf$ | async">
                <mat-icon class="mat-r10">gesture</mat-icon>
            </button>
            <button [matTooltip]="'Adjunto' | translate" (click)="addAttached()" mat-icon-button [disabled]="!(pdfControls.pdf$ | async)">
                <mat-icon>attach_file</mat-icon>
            </button>
        </ng-template>
        <div class="separator"></div>
        <mat-form-field class="filter_input" style="width: 120px; height: 20px;">
            <mat-label>{{ 'Tipo' | translate }}</mat-label>
            <mat-select [formControl]="isPublic" (selectionChange)="changeType()">
                <mat-option [value]="1">
                    {{ 'publica' | translate}}
                </mat-option>
                <mat-option [value]="0">
                    {{ 'privada' | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #pageAndZoom>
    <div style="display: flex; align-items: center;">
        <!-- Page controls -->
        <div class="mar-r10">
            <button (click)="pdfControls.lastPage()" [disabled]="!(pdfControls.pages$ | async) || (pdfControls.pages$ | async) == 1" mat-stroked-button>
                <ng-container *ngIf="!(responsive.isMobile$ | async); else mobileLeftBtn">{{ 'Previous page' | translate}}</ng-container>
                <ng-template #mobileLeftBtn>
                    <mat-icon>arrow_left</mat-icon>
                </ng-template>
            </button>
            <span class="mar-l10" *ngIf="pdfControls.currentPage$ | async as currentPage">
                {{ currentPage || 0 }} / {{ (pdfControls.pages$ | async) || 0 }}
            </span>
            <button (click)="pdfControls.nextPage()" [disabled]="!(pdfControls.pages$ | async) || (pdfControls.pages$ | async) == 1" mat-stroked-button class="mar-l10">
                <ng-container *ngIf="!(responsive.isMobile$ | async); else mobileRightBtn">{{ 'Next page' | translate}}</ng-container>
                <ng-template #mobileRightBtn>
                    <mat-icon>arrow_right</mat-icon>
                </ng-template>
            </button>
        </div>

        <div class="separator mar-l10 mar-r10"></div>

        <!-- Zoom -->
        <div class="mar-l10" style="display: flex;align-items: center;justify-content: flex-end;">
            <button [disabled]="!(pdfControls.pdf$ | async)" color="primary" mat-mini-fab (click)="pdfControls.zoomOut()">
                <mat-icon>zoom_out</mat-icon>
            </button>
            <div class="mar-l10 mar-r10">
                {{ (pdfControls.zoom$ | async) || 1 }}
            </div>
            <button [disabled]="!(pdfControls.pdf$ | async)" color="primary" mat-mini-fab (click)="pdfControls.zoomIn()">
                <mat-icon>zoom_in</mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<div class="state-overlay" *ngIf="(pdfElements.state$ | async) == PDF_STATE.ADDING">
    <ng-container *ngIf="responsive.isMobile$ | async">
        <mat-icon color="primary">
            touch_app
        </mat-icon>
        <div class="state-text">
            {{ 'Press over the pdf to add the control' | translate }}
        </div>
        <div class="vertical-separator"></div>
        <button mat-stroked-button color="primary" (click)="pdfElements.changeState(PDF_STATE.PENDING)">
            {{ 'Cancel' | translate }}
        </button>
    </ng-container>
    <ng-container *ngIf="!(responsive.isMobile$ | async)">
        <div class="overlay-desk">
            <mat-icon color="primary">
                my_location
            </mat-icon>
            <div class="state-text">
                {{ 'Click over the pdf to add the control' | translate }}
            </div>
            <div class="vertical-separator"></div>
            <button mat-raised-button color="primary" (click)="pdfElements.changeState(PDF_STATE.PENDING)">
                {{ 'Cancel' | translate }}
            </button>
        </div>
    </ng-container>
</div>