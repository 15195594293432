import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ResponsiveService } from '../../responsive.service';
import { AutoTableColumnDefinition } from '../../../../shared/auto-table/definitions/auto-table-column-definition';

@Injectable({
    providedIn: 'root'
})
export abstract class ExcelUploadReportTableAdapter
{

    private sort = {
        id: -1,
        'DNI/NIF': 0,
        nombre: 1,
        apellido_1: 2,
        apellido_2: 3,
        email: 4,
        telefono_movil: 5,
        direccion_postal: 6,
        codigo_postal: 7,
        ciudad: 8,
        region: 9,
        pais: 10,
        campo_personalizado: 11,
        empresa: 12,
        grupos: 13

    }


    public data: Subject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    private realCols: Array<AutoTableColumnDefinition>;

    protected constructor(
        public responsive: ResponsiveService
    )
    {
    }

    getStdCols(): Array<AutoTableColumnDefinition>
    {
        return this.realCols;
    }


    public getAllColumnsName()
    {
        return this.getStdCols()
            .sort((a, b) =>
            {
                return this.sort[a.column] - this.sort[b.column];
            })
            .map(c =>
            {
                return c.column;
            });
    }


    setData(errs: Array<any>)
    {
        this.realCols = errs.reduce((carry, current) =>
        {
            Object.keys(current).forEach(k =>
            {
                carry.push(k);
            })
            return carry.filter((v, i, a) => a.indexOf(v) === i);
        }, []).map(c =>
        {
            const conf = new AutoTableColumnDefinition();

            conf.column = c;
            conf.columnTitle = c;

            return conf;
        });

        this.data.next(errs);
    }
}
