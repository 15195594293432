import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { RootStoreState, SubtenantEditStoreActions } from 'src/app/root-store';

export const SUBTENANT_FORM_DIAG_WIDTH = '900px';
export const SUBTENANT_FORM_DIAG_HEIGHT = '300px';

@Component({
    selector: 'app-new-subtenant-dialog',
    templateUrl: './new-subtenant-dialog.component.html',
    styleUrls: ['./new-subtenant-dialog.component.scss']
})
export class NewSubtenantDialogComponent implements OnInit
{
    @HostBinding('class') classes = 'mat-typography';

    public error$: Observable<any>;
    public loading$: Observable<boolean>;
    public state$: Observable<any>;

    constructor(
        private store$: Store<RootStoreState.State>
    )
    { }

    ngOnInit(): void
    {
    }

    public store(tenant: Tenant): void
    {
        this.store$.dispatch(new SubtenantEditStoreActions.Create(tenant));
    }
}
