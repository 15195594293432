import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthRepositoryService {

  constructor(
    private wsh: WebServiceHelperService
  ){}

  public getTwoFactorAuthStatus():Observable<{status: number}>{
    const url = `${environment.backEndDomain}api/two_factor_auth/status`;
    return this.wsh.get(url);
  }
    
  public getTwoFactorAuthStatusInUser():Observable<{status: number}>{
    const url = `${environment.backEndDomain}api/two_factor_auth/authenticated`;
    return this.wsh.get(url);
  }

  public getQRCode():Observable<{qr_code: string, secret_code: string}>{
    const url = `${environment.backEndDomain}api/two_factor_auth/qr_code`;
    return this.wsh.get(url);
  }

  public linkTwoFactorAuth(otp_code: string, secret_code: string){
    const url = `${environment.backEndDomain}api/two_factor_auth/enable`;
    return this.wsh.post(url, {otp_code: otp_code, secret_code: secret_code});
  }

  public unlinkTwoFactorAuth(){
    const url = `${environment.backEndDomain}api/two_factor_auth/delete`;
    return this.wsh.delete(url, {});
  }

}
