import { Component, OnInit, Inject, HostBinding } from '@angular/core';

import { MsgBoxData } from './MsgBoxConfig';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit
{
    @HostBinding('class') classes = 'mat-typography';
    constructor(@Inject(MAT_DIALOG_DATA) public data: MsgBoxData) { }

    ngOnInit()
    {
        console.log(this.data);
    }
}
