import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { TileNumeric } from '../shared/tile-numeric';
import { KpiService } from '../shared/kpi.service';

@Component({
    selector: 'app-numeric-kpi',
    templateUrl: '../breakdown-numeric-kpi/breakdown-numeric-kpi.component.html',
    styleUrls: ['../breakdown-numeric-kpi/breakdown-numeric-kpi.component.css']
})
export class NumericKpiComponent implements OnInit
{
    // A través del input, eligiremos a qué fnc del servicio apuntamos para recoger los datos.
    @Input() type: string;
    public tileData$: Observable<TileNumeric>;

    constructor(
        private kpi: KpiService
    )
    { }

    ngOnInit()
    {
        switch (this.type)
        {
            case 'template_count':
                this.tileData$ = this.kpi.getTemplatesCountKpiData();
                break;
            case 'users_count':
                this.tileData$ = this.kpi.getUsersCountKpiData();
                break;
            case 'doc_count':
                this.tileData$ = this.kpi.getDocCountKpiData();
                break;
            case 'batch_count':
                this.tileData$ = this.kpi.getBatchCountKpiData();
                break;
            default:
                this.tileData$ = this.kpi.getTemplatesCountKpiData();
        }
    }

}
