<ng-container *ngIf="Contacts.currentData() as currentData; else noData">
    <div class="flex-col height100" style="flex: 1 1 0px">
        <cdk-virtual-scroll-viewport #scroll [itemSize]="(listType == 'default' ? 170 : 70) / itemsPerRow"
            class="flex-row-start pad-r10 pad-l10 grid" style="height: 100%; overflow-y: auto; flex-wrap: wrap;">
            <ng-container *ngIf="listType == 'default'">
                <app-contact style="height: 170px; margin-right: 10px;" [contact]="contact"
                    [BaseColor]="StringToColour(contact && contact.name ? contact.name : 'ggg')"
                    [Color]="InvertColor(StringToColour(contact && contact.name ? contact.name : 'ggg'), true)"
                    (Edit)="contact.name ? openModal($event, true) : undefined"
                             (Delete)="deleteContact($event)"
                             *cdkVirtualFor="let contact of Contacts"
                    [ngClass]="{'group-view': groupView}" class="round-container blue mar-t10"></app-contact>
            </ng-container>
            <ng-container *ngIf="listType == 'simple'">
                <app-contact-simple matRipple style="height: 70px;" [contact]="contact"
                    (click)="ContactSelected(contact)" *cdkVirtualFor="let contact of Contacts" class="mar-t10"
                    [ngClass]="{'selected': isSelected(contact)}"></app-contact-simple>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>

    <ng-container *ngIf="currentData.length == 0">
        <ng-container *ngTemplateOutlet="noData"></ng-container>
    </ng-container>
</ng-container>

<ng-template #noData>
    <div class="placeholder-select-group">
        <mat-icon>person_search</mat-icon>
        {{ 'In order to see contacts, you can apply another filters, select another group or even create new contacts to see them' | translate }}.
    </div>
</ng-template>
