import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'flagToIcon'
})
export class FlagToIconPipe implements PipeTransform
{
    transform(flags: string): string
    {
        const splitted = flags.split('|');
        if (flags.indexOf('FIRMA_LINK') != -1)
            return 'FIRMA_LINK';
        else if (flags == '')
            return 'MAIL';
        else if (splitted.length > 1)
            return splitted[1];
        else
            return splitted[0];
    }
}
