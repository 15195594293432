import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ArbitraryTextDefinitionService} from '../services/pages/arbitrary-text-definition.service';

@Injectable({
    providedIn: 'root'
})
export class PreFormTextGuard implements CanActivate {
    constructor(
        private router: Router,
        private service: ArbitraryTextDefinitionService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const extras = this.router.getCurrentNavigation().extras;
        // const extras = ARBITRARY_TEXT_ROUTE_MOCK;

        if (extras.state
            && extras.state.template
            && extras.state.signers
            && extras.state.from
            && extras.state.arbitraryText
            && extras.state.editablePdfId) {

            this.service.setData(
                extras.state.template,
                extras.state.signers,
                extras.state.arbitraryText,
                extras.state.wasGroup,
                extras.state.group,
                extras.state.cvs,
                extras.state.editablePdfId);

            return true;
        }

        this.router.navigate(['editable_pdf', 'file']);

        return false;
    }
}
