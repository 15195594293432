import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Contact } from 'src/app/core/models/contacts/contact';
import { ContactFilters } from 'src/app/core/models/contacts/contactFilters';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';

@Component({
    selector: 'app-contact-search',
    templateUrl: './contact-search.component.html',
    styleUrls: ['./contact-search.component.scss']
})
export class ContactSearchComponent implements OnInit, OnDestroy
{
    @Output() selected = new EventEmitter<Contact>();
    @Output() newContact = new EventEmitter<void>();
    @Output() editContact = new EventEmitter<Contact>();

    contacFilter: ContactFilters;
    contactSubject: Subject<Array<Contact>> = new Subject<Array<Contact>>();

    lastTimestamp = 0;
    public searchText: string;

    constructor(
        private contactsService: ConstactsService
    ) {
        this.contacFilter = new ContactFilters();
        this.contacFilter.status = 1;
    }

    ngOnInit() {
        this.loadContacts();
        this.contactsService.contacts$.subscribe(
            x => this.contactSubject.next(x)
        );
    }

    public loadContacts() {
        this.contactsService.getAllContacts(this.contacFilter);
    }

    selectContact(contact: Contact) {
        this.selected.emit(contact);
    }

    addContact()
    {
        this.newContact.emit();
    }

    editingContact(contact: Contact)
    {
        this.editContact.emit(contact);
    }

    textChanged($event: Event) {
        this.lastTimestamp =  $event.timeStamp;

        setTimeout(() => {
            if (this.lastTimestamp === $event.timeStamp){
                this.contacFilter.fullName = this.searchText;
                this.loadContacts();
            }
        }, 700);
    }

    ngOnDestroy(): void {
        this.contactSubject.unsubscribe();
    }

}
