<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title flex-row-between" style="display: flex;align-items: center;">
        {{ 'Set up the contact' | translate }}
        <button (click)="openHelp()" color="primary" mat-icon-button>
            <mat-icon>help</mat-icon>
        </button>
    </div>
    <ng-container *ngTemplateOutlet="mobileList"></ng-container>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<!-- Desktop -->
<ng-template #desktop>
    <div class="help-col">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <app-tip *ngFor="let tip of TIPS; let i = index" [idx]="i" [tip]="tip"></app-tip>
    </div>
    <div class="content">
        <div class="subtitle">
            {{ 'Choose the contact for the shipment' | translate }}
        </div>
        <div style="display: flex; justify-content: space-between;">
            <!-- Descomentar esta parte cuando tengamos el envio de whatasp de los forms -->
            <!-- <div class="subtitle"> {{ 'Send channel' | translate }}: </div>
            <mat-button-toggle-group #toggleD (change)="flagDeploy($event, selectedContacts[0])" [value]="selectedContacts[0] && selectedContacts[0][0] && selectedContacts[0][0].flags[0] || 'MAIL'" [disabled]="selectedContacts[0] | disabledChannel" name="channel" class="toggle-channel">
                <mat-button-toggle value="WHATSAPP"><img style="width: 20px; height: 20px;" src="assets/svg/whatsapp-icon.svg"></mat-button-toggle>
                <mat-button-toggle value="MAIL"><mat-icon>mail</mat-icon></mat-button-toggle>
            </mat-button-toggle-group> -->
        </div>
        <app-contact-search (newContact)="addContact()"
            (editContact)="editContact($event)" (selected)="selectContact($event)">
        </app-contact-search>
        <div class="contact-wrapper">
            <div class="contact-wrapper-scroller">
                <ng-container *ngFor="let i of range(1)">
                    <div class="signer-content-handler">
                        <div class="ball-24px mar-r10"
                            [style.backgroundColor]="mtlg.StringToColour(i + 'Firmante ' + (i + 20))">
                        </div>
                        {{ 'Signer' | translate }} {{i + 1}}:
                    </div>
                    <app-contact-simple [showDeleteButton]="true"
                        *ngIf="selectedContacts.length > 0 && selectedContacts[0] && selectedContacts[0][i]; else nocontact"
                        matRipple [contact]="selectedContacts[0][i]" (click)="unselectContact(0, i)"
                        style="padding-left: 20px;"></app-contact-simple>
                </ng-container>
            </div>
        </div>

        <!-- CONTACT PLACEHOLDER TEMPLATE-->
        <ng-template #nocontact>
            <div matRipple *ngIf="responsive.isMobile$ | async" class="contact-placeholder"
                (click)="openSelectContactDialog()">
                {{ 'Click here to choose a contact' | translate }}
            </div>
            <div matRipple *ngIf="!(responsive.isMobile$ | async)" class="contact-placeholder">
                {{ 'Select a contact from the list' | translate }}
            </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
    <div></div>
</ng-template>

<!-- LIST -->
<ng-template #mobileList>
    <div class="selected-contact-holder">
        <div class="contact-wrapper">
            <div class="contact-wrapper-scroller">
                <ng-container *ngFor="let i of range(1)">
                    <div class="signer-content-handler">
                        <div class="ball-24px mar-r10"
                            [style.backgroundColor]="mtlg.StringToColour(i + 'Firmante ' + (i + 20))">
                        </div>
                        {{ 'Signer' | translate }} {{i + 1}}:
                    </div>
                    <app-contact-simple [showDeleteButton]="true"
                        *ngIf="selectedContacts.length > 0 && selectedContacts[0] && selectedContacts[0][i]; else nocontact"
                        matRipple [contact]="selectedContacts[0][i]" (click)="unselectContact(0, i)"
                        style="padding-left: 20px;"></app-contact-simple>
                </ng-container>
            </div>
        </div>

        <!-- CONTACT PLACEHOLDER TEMPLATE-->
        <ng-template #nocontact>
            <div matRipple *ngIf="responsive.isMobile$ | async" class="contact-placeholder"
                (click)="openSelectContactDialog()">
                {{ 'Click here to choose a contact' | translate }}
            </div>
            <div matRipple *ngIf="!(responsive.isMobile$ | async)" class="contact-placeholder">
                {{ 'Select a contact from the list' | translate }}
            </div>
        </ng-template>

    </div>
</ng-template>

<!-- Footer -->
<ng-template #footer>
    <div class="footer">
        <button mat-stroked-button color="primary" (click)="previous()" class="mar-l8-important">{{'Back' |
            translate}}</button>
        <button mat-raised-button color="primary" (click)="next()">{{'Next' | translate}}</button>
    </div>
</ng-template>