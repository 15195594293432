import * as _ from 'lodash';

export class MaterialTableColumn
{
    public column: string;
    public columnTitle: string;
    public isSticky: boolean;
    public aligment: string;
    public paddingRight: string;
    constructor(){}
}

export class MaterialTablePaginator
{
    public per_page: number;
    public from: number;
    public to: number;
    public total: number;
    public next_page_url: string;
    public last_page_url: string;
    public prev_page_url: string;
}

export class MaterialTable
{
    public columns: Array<MaterialTableColumn>;
    public rows: Array<any>;
    public isHeaderSicky: boolean;
    public hasPaginator: boolean;
    public hasFooter: boolean;
    public isFooterSticky: boolean;
    public paginator: MaterialTablePaginator;

    constructor(columns: Array<MaterialTableColumn>, stickyHeader: boolean, hasPaginator = false, hasFooter = false, isFooterSticky = false)
    {
        this.columns = columns;
        this.isHeaderSicky = stickyHeader;
        this.hasPaginator = hasPaginator;
        this.hasFooter = hasFooter;
        this.isFooterSticky = isFooterSticky;
    }

    public getRawColumns(): Array<string>
    {
        return _.map(this.columns, "column");
    }
}
