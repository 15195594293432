import {Injectable} from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
    providedIn: 'root',
})

export class DatePickerAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }

    parse(value: any): Date | null {
        try{
            console.log(value);
            const parts = value.split("/");
            const d = (new Date(
                parseInt(parts[2], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[0], 10)));
            console.log(d);
            return d;

        }catch(e){
            console.error(e);
            return null;
        }
    }
}
