export class TemplateOutput
{
    public class: string;
    public dragged: Array<TemplateOutputDragged>;
    public id: string;
}

export class TemplateOutputDragged
{
    public class: string;
    public db_id: number;
    public id: string;
    public innerHTML: string;
    public location: {
        position: string,
        x: number,
        y: number
    };
    public meta: {
        optional_validation: boolean;
        check_nif: boolean;
        filename: string,
        textname: string,
        filename_aux: string,
        repeat_all_pages: boolean,
        repeat_first_page: boolean,
        repeat_second_page: boolean,
        repeat_second_last_page: boolean,
        repeat_last_page: boolean,
        contac_idx: number,
        output_type: string,
        style: any,
        text: string,
        options: Array<any>,
        pageIndex: number
    };

    public dragged: Array<TemplateOutputDragged>;

    constructor ()
    {}
}
