import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ExcelUploadReportTableAdapter} from '../../../../core/services/pages/contacts/excel-upload-report-table-adapter.service';

@Component({
    selector: 'app-excel-upload-report',
    templateUrl: './excel-upload-report.component.html',
    styleUrls: ['./excel-upload-report.component.scss']
})
export class ExcelUploadReportComponent implements OnInit
{


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            totalRows: string,
            inserts: string,
            updates: string,
            errors: any
        },
        public tableAdapter: ExcelUploadReportTableAdapter
    )
    {
    }

    ngOnInit(): void
    {
        const errs = this.toTable(this.data.errors);
        this.tableAdapter.setData(errs);
    }


    private toTable(errors: any)
    {
        const errs = {};

        Object.keys(errors).forEach(k =>
        {
            const chuks = k.split('.');

            if(undefined === errs[chuks[0]]){
                errs[chuks[0]] = [];
                errs[chuks[0]]['id'] = parseInt(chuks[0], 10) + 1;
            }

            errs[chuks[0]][chuks[1]] = errors[k];
        })

        return Object.values(errs);

    }
}
