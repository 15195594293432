import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, matBaseAnimations } from '../../../base/base.component';
import { SidenavService } from '../../../sidenav/shared/sidenav.service';
import { DocumentTableAdapterService } from '../../services/document-table-adapter.service';
import { ActivatedRoute } from '@angular/router';
import { SendingsService } from '../../../shared/sendings.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { FormControl, FormGroup } from '@angular/forms';



@Component({
    selector: 'app-document-tray',
    templateUrl: './document-tray.component.html',
    styleUrls: ['./document-tray.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation],
})
export class DocumentTrayComponent extends BaseComponent implements OnInit, OnDestroy
{
    @HostBinding('class') classes = 'bg mat-typography';
    @ViewChild('filters') filters;
    private interval;



    constructor(
        public SideNav: SidenavService,
        public documentTAS: DocumentTableAdapterService,
        private serdigsService: SendingsService,
        private route: ActivatedRoute,
        public responsive: ResponsiveService
    )
    {
        super(SideNav);



    }

    ngOnInit()
    {
        this.documentTAS
            .warmUp(this.route)
            .subscribe(
                r =>
                {


                },
                e =>
                {
                },
                () =>
                {
                    this.documentTAS.getData();
                    this.serdigsService.RecalculateSendings();
                }
            );

        this.interval = setInterval(() =>
        {
            const nospiner = true;
            this.documentTAS.getData(nospiner);
            this.serdigsService.RecalculateSendings(nospiner);
        },
            60000);
    }

    ngOnDestroy(): void
    {
        clearInterval(this.interval);
    }

}
