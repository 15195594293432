import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { localesDictionary } from 'src/app/configs/languages.dictionary';
import { TenantMailFooter } from 'src/app/core/models/tenant-management/tenant-mail-footer.model';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';
import { UserMenuService } from '../../content/user-menu.service';


@Injectable({
    providedIn: 'root'
})
export class TenantMailFooterService
{
    private footer: BehaviorSubject<Array<TenantMailFooter>>;
    public footer$: Observable<Array<TenantMailFooter>>;

    constructor(
        private http: WebServiceHelperService,
        private translator: TranslateService,
        private user: UserMenuService
    )
    {
        this.footer = new BehaviorSubject(null);
        this.footer$ = this.footer.asObservable();

        const locales = this.translator.getLangs();

        this.http.get<Array<TenantMailFooter>>(`${ environment.backEndDomain }api/mail_tpl`)
            .pipe(
                mergeMap(res => this.fetchNeededLocales(res)),
                map(res => this.buildDefaultFoooterStructure(res, locales)),
            )
            .subscribe(res => this.footer.next(res), err => console.error(err));
    }

    public setMailFooter(data: Array<TenantMailFooter>): Observable<any>
    {
        return this.http.post(`${ environment.backEndDomain }api/mail_tpl`, data);
    }

    /**
     * Revisamos todos los idiomas que no están configurados y vamos a buscarlos
     * con el translate service.
     */
    private fetchNeededLocales(
        data: Array<TenantMailFooter>
    ): Observable<Array<TenantMailFooter>>
    {
        const locales = this.translator.getLangs();
        const newLocales = data.map(x => x.lang);
        const langToRequest = locales.filter(locale => newLocales.indexOf(locale) == -1)
        const requests = langToRequest.map(lang => this.translator.use(lang));
        if (requests.length > 0)
            return zip(...requests)
                .pipe(map(() => data))

        return of(data);
    }
    private buildDefaultFoooterStructure(
        data: Array<TenantMailFooter>,
        locales: string[]
    ): Array<TenantMailFooter>
    {
        locales.forEach(locale =>
        {
            const found = data.findIndex(x => x.lang == locale);
            if (found == -1)
                data.push({ lang: locale, tpl: this.buildTplForLocale(locale) })
        });

        this.translator.use(this.user.userData.locale).subscribe();

        return data;
    }
    private buildTplForLocale(locale: string): string
    {
        const translations = this.translator.translations[locale];
        const thanksText = translations['Thanks a lot'];
        const ifHelpText = translations[
            'if you need more help, you can contact us using this email'
        ];
        const greetings = translations['greetings'];
        const theTeamText = translations['MyRubric team'];

        return `${ thanksText }\n\n${ ifHelpText }: support@myrubric.com\n\n${ greetings }\n${ theTeamText }`;
    }
}