<div class="sign-container mat-typography">
    <div class="title" style="padding: 5px; margin-bottom: 12px;">
        {{ 'Trace your signature' | translate }}
    </div>
    <div [formGroup]="signatureForm" style="display: flex; justify-content: space-between; align-items: center;">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input type="text" matInput formControlName="name">
        </mat-form-field>
        <mat-checkbox formControlName="status_id">
            {{ 'Active' | translate }}
        </mat-checkbox>
    </div>
    <div class="whiteback">
        <signature-pad #pad [options]="signaturePadOptions"></signature-pad>
    </div>
    <div id="buttonbar" class="button-row">
        <button matTooltip="{{ 'cancel' | translate }}" mat-fab color="warn" (click)="cancel()">
            <mat-icon>clear</mat-icon>
        </button>
        <button matTooltip="{{ 'Clear' | translate }}" mat-fab color="primary" (click)="pad.clear()">
            <mat-icon>refresh</mat-icon>
        </button>
        <button matTooltip="{{ 'save' | translate }}" mat-fab color="primary" style="background-color: limegreen;" (click)="store({data: pad.toData(), img: pad.toDataURL()})">
            <mat-icon>done</mat-icon>
        </button>
    </div>
</div>
