import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { MaterialTableComponent } from './material-table.component';
import { MaterialModule } from '../material/material.module';

@NgModule({
    declarations: [MaterialTableComponent],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule
    ],
    exports: [MaterialTableComponent]
})
export class MaterialTableModule { }
