import { Component, OnInit, Inject } from '@angular/core';


@Component({
  selector: 'app-budget-shortage-alert',
  templateUrl: './budget-shortage-alert.component.html',
  styleUrls: ['./budget-shortage-alert.component.css']
})
export class BudgetShortageAlertComponent implements OnInit {

    ngOnInit() {
    }
}
