<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <div class="title flex-row-between">
        {{ "Share the link" | translate }}
    </div>
    <div>
        <div class="subtitle flex-row-between">
            {{ "Copy the link and share it with the contact" | translate }}
        </div>
        <app-copy-addon-field
            [link]="service.signatureLink$ | async"
        ></app-copy-addon-field>
        <label style="margin-bottom: 20px;" class="small flex-row-between">
            {{ "You can share the link with the contact" | translate }}
        </label>
        <div class="flex-column send-row">
            <a
                target="_blank"
                [href]="
                    'https://wa.me/' +
                    (signer?.mobile_phone | spaceExtractor) +
                    '?text=' +
                    (service.signatureLink$ | async)
                "
                mat-raised-button
                color="primary"
            >
                <img
                    style="width: 20px; height: 20px; margin-right: 10px;"
                    src="assets/svg/whatsapp-icon.svg"
                />
                {{ "Send message via whatsapp web" | translate }}
            </a>
            <a
                style="margin-top: 12px;"
                mat-raised-button
                color="primary"
                [href]="
                    'mailto:' +
                    signer?.corporate_email +
                    '?subject=' +
                    ('Sign the document' | translate) +
                    '&body=' +
                    ('Sign the document using this link' | translate) +
                    (service.signatureLink$ | async)
                "
            >
                <mat-icon style="margin-right: 10px;">mail</mat-icon>
                {{ "Send message via mail" | translate }}
            </a>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>

<ng-template #desktop>
    <div class="title flex-row-between">
        {{ "Share the link" | translate }}
    </div>
    <div>
        <div class="subtitle flex-row-between">
            {{ "Copy the link and share it with the contact" | translate }}
        </div>
        <app-copy-addon-field
            [link]="service.signatureLink$ | async"
        ></app-copy-addon-field>
        <label style="margin-bottom: 20px;" class="small flex-row-between">
            {{ "You can share the link with the contact" | translate }}
        </label>
        <div class="flex-row send-row">
            <a
                target="_blank"
                [href]="
                    'https://wa.me/' +
                    (signer?.mobile_phone | spaceExtractor) +
                    '?text=' +
                    (service.signatureLink$ | async)
                "
                mat-raised-button
                color="primary"
            >
                <img
                    style="width: 20px; height: 20px; margin-right: 10px;"
                    src="assets/svg/whatsapp-icon.svg"
                />
                {{ "Send message via whatsapp web" | translate }}
            </a>
            <a
                style="margin-left: 12px;"
                mat-raised-button
                color="primary"
                [href]="
                    'mailto:' +
                    signer?.corporate_email +
                    '?subject=' +
                    ('Sign the document' | translate) +
                    '&body=' +
                    ('Sign the document using this link' | translate) +
                    (service.signatureLink$ | async)
                "
            >
                <mat-icon style="margin-right: 10px;">mail</mat-icon>
                {{ "Send message via mail" | translate }}
            </a>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #footer>
    <div style="display:flex; justify-content: flex-end;" class="button-row">
        <button mat-raised-button color="accent" (click)="next()">
            {{ "Complete" | translate }}
        </button>
    </div>
</ng-template>
