import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { Contact } from 'src/app/core/models/contacts/contact';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ArbitraryTextDefinitionService } from 'src/app/core/services/pages/arbitrary-text-definition.service';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { TextDefinitionService } from 'src/app/core/services/pages/text-definition.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { MessageBoxService } from 'src/app/shared/message-box.service';
import { MsgBoxConfig, MsgBoxData } from 'src/app/shared/message-box/MsgBoxConfig';

@Component({
    selector: 'app-message-definition',
    templateUrl: './message-definition.component.html',
    styleUrls: ['./message-definition.component.scss']
})
export class MessageDefinitionComponent implements OnInit, OnDestroy
{
    public tpl: TemplateData;
    public contacts: Array<Array<Contact>>;

    private unsubscriber = new Subject<void>();

    constructor(
        public responsive: ResponsiveService,
        public service: ArbitraryTextDefinitionService,
        public deploy: TextDefinitionService,
        private router: Router,
        private toast: I18nToastService,
        private translator: TranslateService,
        private msgbox: MessageBoxService
    ) { }

    ngOnInit(): void
    {
        this.service.data$
        .pipe(
            takeUntil(this.unsubscriber)
        )
        .subscribe(
            struct =>
            {
                if (struct)
                {
                    this.tpl = struct.tpl;
                    this.contacts = struct.contacts
                    this.tpl.mail_text = "";
                }
                else
                {
                    this.router.navigate(
                        [
                            'editable_pdf',
                            'file'
                        ]
                    );

                    this.toast.open(
                        'The file was not found',
                        'Accept',
                        5000,
                        true
                    )
                }
            }
        );
    }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
    public next(): void
    {
        this.service.setFormData(null);
        const deployObj = this.deploy.getFormDeploy();
        deployObj.mail_text = this.tpl.mail_text;

        this.deploy.deployForm(deployObj)
        .pipe(
            mergeMap(
                r =>
                {
                    let title = 'Document sent succesfully!';
                    let body = 'You can follow its status from the document tray.';
                    const msgad = '';
                    const c = {
                        data: {
                            title: this.translator.instant(title),
                            body: this.translator.instant(body).concat(msgad),
                            btn_ok: this.translator.instant('Continue')
                        } as MsgBoxData,
                        height: '285px',
                        width: '500px',
                        disableClose: true
                    } as MsgBoxConfig;

                    return this.msgbox.open(c);
                }
            )
        )
        .subscribe(
            x =>
            {
                if (x)
                {
                    this.router.navigate(['editable']);
                }
            }
        );
    }
    public previous(): void
    {
        const struct = this.service.getData();
        this.router.navigate(
            [
                'editable_pdf',
                'form'
            ],
            {
                state: {
                    template: struct.tpl,
                    editablePdfId: struct.editablePdfId,
                    signers: struct.contacts,
                    arbitraryText: struct.arbitraryText,
                    from: 'message'
                }
            }
        )
    }
}
