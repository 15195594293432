<div class="flex-col round-flex-col blue">
    <div class="flex-row template-name subtitle">
        {{ template.name  }}
    </div>
    <div class="flex-row template-description">
        {{ template.description }}
    </div>
    <div class="flex-row flex-space-between">
        <div class="flex-col" style="font-size: 12px;">
            <div class="flex-row">
                <div class="flex-col">
                    <div class="flex-row label-gray bold"> {{ 'Creator' | translate}}:</div>
                    <div class="flex-row label-gray bold"> {{ 'Date' | translate}}:</div>
                    <div class="flex-row label-gray bold"> {{ 'Signers' | translate}}:</div>
                    <div class="flex-row label-gray bold"> {{ 'Tipo' | translate}}:</div>
                </div>
                <div class="flex-col mar-l5">
                    <div class="flex-row"> {{ template.creator_name }} {{ template.creator_surname }}</div>
                    <div class="flex-row">{{template.created_at | prepareDate | date:'short' }}</div>
                    <div class="flex-row">{{ template.contact_count }}</div>
                    <div class="flex-row">{{ template.is_public ? 'publica' : 'privada' }}</div>
                </div>
            </div>
            <div class="flex-row" style="align-items: center;">
                <button matTooltip="Ver enviados" mat-icon-button
                        [routerLink]="['/document/', { status: docService.statusCacheKey | async ,template_id: [template.id] }]"
                        color="primary">
                    <mat-icon>all_inbox</mat-icon>
                </button>
                <button  matTooltip="{{ 'edit' | translate }}" mat-icon-button [routerLink]="['/template_management/edit/', template.id]"
                        color="primary">
                    <mat-icon>edit</mat-icon>
                </button>
                <button  matTooltip="{{ 'clone' | translate }}" mat-icon-button (click)="clone()" color="primary">
                    <mat-icon>copy_all</mat-icon>
                </button>
                <!-- <button *ngIf="template.status == 1" matTooltip="{{ 'Disable' | translate }}" mat-icon-button (click)="delete()"
                        color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="template.status == 2" matTooltip="{{ 'Enable' | translate }}" mat-icon-button (click)="delete()"
                        color="accent">
                    <mat-icon>delete</mat-icon>
                </button> -->
                <mat-slide-toggle style="margin-left: 5px;" [checked]="template.status == 1" (change)="delete()"></mat-slide-toggle>
            </div>
        </div>
        <div class="flex-reverse-col">

            <button mat-raised-button color="accent" class="tempalte-card-send-button"
                    [routerLink]="['/send_assistant', 'contacts', template.id, { FLAG_ONLY_EDIT: true }]">
                <mat-icon style="margin-left: -5px;">send</mat-icon>
            </button>
        </div>
    </div>
</div>
