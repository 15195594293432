import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { TextDefinitionService } from 'src/app/core/services/pages/text-definition.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { PaginatorService } from 'src/app/shared/auto-table/services/paginator.service';
import { DocumentTemplateManagementService } from '../../core/services/content/document-template-management.service';
import { DocumentService } from '../../core/services/content/document.service';
import { AutoTableColumnDefinition } from '../../shared/auto-table/definitions/auto-table-column-definition';
import { LaravelPaginatedList } from '../../shared/auto-table/definitions/laravel-paginated-list';
import { TableAdapterInterface } from '../../shared/auto-table/definitions/table-adapter.interface';
import { BaseTableAdapterService } from '../../shared/auto-table/services/base-table-adapter.service';
import { ConfirmationBoxService } from '../../shared/confirmation-box.service';
import { ConfirmationBoxConf } from '../../shared/confirmation-box/confirmation-box.component';
import { MessageBoxService } from '../../shared/message-box.service';
import { MsgBoxConfig } from '../../shared/message-box/MsgBoxConfig';
import { PdfBlobOpenerService } from '../../shared/pdf-blob-opener.service';
import { DocumentExtrasModalComponent } from '../components/document-extras-modal/document-extras-modal.component';

@Injectable({
    providedIn: 'root'
})
export class DocumentTableAdapterService extends BaseTableAdapterService implements TableAdapterInterface
{


    public formGroup: FormGroup;


    public statusKeys: string[];

    public tplFilter: string = '';
    public batchFilter: string = '';
    public signerFilter: string = '';
    public statusFilter: string[] = [];
    public deliverOption: string[] = [];
    public templateIdFilter: number;
    public dateFrom: Date;
    public dateTo: Date;
    public tplsKeys: Array<any>;


    public deliverOptions: Array<{ id: string; name: string }> = [
        {
            id: 'MAIL',
            name: 'email'
        },
        {
            id: 'LINK',
            name: 'link'
        },
        {
            id: 'WHATSAPP',
            name: 'whatsApp'
        },
        {
            id: 'SMS',
            name: 'sms'
        }
    ];


    constructor(
        private docService: DocumentService,
        private docTemplate: DocumentTemplateManagementService,
        public dialog: MatDialog,
        public confirmationDialog: ConfirmationBoxService,
        private messageBoxService: MessageBoxService,
        private translator: TranslateService,
        public responsive: ResponsiveService,
        public linkService: TextDefinitionService,
        public blobOpener: PdfBlobOpenerService,
        public paginator: PaginatorService
    )
    {
        super(responsive, paginator);


        this.formGroup = new FormGroup({
            tplFilter: new FormControl(''),
            batchFilter: new FormControl(''),
            signerFilter: new FormControl(''),
            statusFilter: new FormControl([]),
            deliverOption: new FormControl([]),
            templateIdFilter: new FormControl(),
            dateFrom: new FormControl(),
            dateTo: new FormControl(),
            tplsKeys: new FormControl()
        });

    }

    backDate(numOfDays)
    {
        const today = new Date();
        return new Date(today.setDate(today.getDate() - numOfDays));
    }

    getStdCols(): Array<AutoTableColumnDefinition>
    {
        const cols = [
            {
                order: 1,
                column: 'doc_name',
                columnTitle: 'Document name',
                isSticky: false
            },
            // {
            //     order: 2,
            //     column: 'batch_name',
            //     columnTitle: 'Lote',
            //     isSticky: false
            // },
            //   {
            //       order: 3,
            //       column: 'customer_legal_identifier',
            //       columnTitle: 'Nif',
            //       isSticky: false
            //   },
            {
                order: 3,
                column: 'full_name',
                columnTitle: 'Signers name',
                isSticky: false
            }
        ];

        return cols;
    }

    getNonStdCols(): Array<AutoTableColumnDefinition>
    {
        if (!this.responsive.IsMobile())
        {
            return [

                {
                    order: 4,
                    column: 'doc_status',
                    columnTitle: this.translator.instant('Status'),
                    isSticky: false
                },
                {
                    order: 5,
                    column: 'min_date',
                    columnTitle: this.translator.instant('created_date'),
                    isSticky: false
                },
                // {
                //     order: 6,
                //     column: 'max_date',
                //     columnTitle: 'Última acción',
                //     isSticky: false
                // },
                {
                    order: 6,
                    column: 'flags',
                    columnTitle: this.translator.instant('Shipment'),
                    isSticky: false
                },
                {
                    order: 7,
                    column: 'retry',
                    columnTitle: this.translator.instant('Retry'),
                    isSticky: false
                },
                {
                    order: 8,
                    column: 'audit_trail',
                    columnTitle: this.translator.instant('Audit trail'),
                    isSticky: false
                },
                {
                    order: 9,
                    column: 'more_info',
                    columnTitle: this.translator.instant('More info'),
                    isSticky: false
                },
                {
                    order: 10,
                    column: 'download',
                    columnTitle: this.translator.instant('Download'),
                    isSticky: false
                },
                {
                    order: 11,
                    column: 'delete',
                    columnTitle: this.translator.instant('Delete'),
                    isSticky: false
                }
                // {
                //     order: 12,
                //     column: 'more',
                //     columnTitle: 'Mas',
                //     isSticky: false
                // }
            ];
        } else
        {
            return [
                {
                    order: 4,
                    column: 'actions',
                    columnTitle: this.translator.instant('Actions'),
                    isSticky: false
                }
            ]
        }
    }

    warmUp(route: ActivatedRoute): Observable<boolean>
    {
        const dateFrom = new Date();
        dateFrom.setMonth(dateFrom.getMonth() - 3);
        dateFrom.setDate(dateFrom.getDate() + 1)
        dateFrom.setHours(0, 0, 0, 0);
        dateFrom.setFullYear(2000);
        this.formGroup.controls.dateFrom.setValue(dateFrom);

        const dateTo = new Date();
        dateTo.setHours(23, 59, 59, 59);
        this.formGroup.controls.dateTo.setValue(dateTo);

        this.setDefaulDeliverOptions()

        const paramsObs: Observable<ParamMap> = route.paramMap.pipe(take(1));
        const statusObs = this.docService.statusCache.pipe(
            map(status => Object.keys(status)),
            take(1)
        );
        const tplsObs = this.docTemplate.listFlaggedTemplates({ template: '', dateIni: dateFrom, dateEnd: dateTo })
            .pipe(
                map(arr =>
                {
                    arr.unshift({ id: -1, name: '' })
                    return arr;
                }),
                take(1)
            );

        return forkJoin([paramsObs, statusObs, tplsObs])
            .pipe(
                tap(([tapParams, tapStatus, tapTpls]) =>
                {
                    tapParams = tapParams as ParamMap;
                    tapStatus = tapStatus as Array<string>;

                    this.statusKeys = tapStatus;
                    this.tplsKeys = tapTpls;


                    let status = [];

                    if (tapParams.has('status'))
                    {
                        const statusIdArr = tapParams.get('status').split(',');
                        status = tapStatus.filter(k => statusIdArr.indexOf(k) > 0);
                    } else
                    {
                        status = tapStatus;
                    }

                    status = status.filter(k => Number(k) !== 0)

                    this.formGroup.controls.statusFilter.setValue(status);

                    if (tapParams.has('template_id'))
                    {
                        const templateIdFilter = parseInt(tapParams.get('template_id'), 10);
                        this.formGroup.controls.templateIdFilter.setValue(templateIdFilter);
                    } else
                    {
                        this.formGroup.controls.templateIdFilter.setValue(-1);
                    }


                }),
                mapTo(true)
            );

    }

    setDefaulDeliverOptions()
    {
        const deliverOption = this.deliverOptions.map(o => o.id);
        this.formGroup.controls.deliverOption.setValue(deliverOption);
    }

    getLaravelPaginatedList(loader?: boolean)
    {
        const value = this.formGroup.getRawValue();
        this.formGroup.reset(value);

        return this.docService.listAll(
            this.currentPage + 1,
            this.formGroup.controls.tplFilter.value,
            this.formGroup.controls.templateIdFilter.value,
            this.formGroup.controls.batchFilter.value,
            this.formGroup.controls.signerFilter.value,
            this.formGroup.controls.statusFilter.value,
            this.formGroup.controls.dateFrom.value,
            this.formGroup.controls.dateTo.value,
            this.formGroup.controls.deliverOption.value,
            loader
        ).pipe(
            map(r => this.parseSigners(r))
        );
    }

    getExcel(loader?)
    {
        const value = this.formGroup.getRawValue();
        this.formGroup.reset(value);

        return this.docService.getExcel(
            this.formGroup.controls.tplFilter.value,
            this.formGroup.controls.templateIdFilter.value,
            this.formGroup.controls.batchFilter.value,
            this.formGroup.controls.signerFilter.value,
            this.formGroup.controls.statusFilter.value,
            this.formGroup.controls.dateFrom.value,
            this.formGroup.controls.dateTo.value,
            this.formGroup.controls.deliverOption.value,
            loader
        )
            .subscribe(
                r =>
                {
                    this.blobOpener.openArbitrary(r, 'application/vnd.ms-excel')

                },
                e =>
                {

                    const decodedString = String.fromCharCode.apply(null, new Uint8Array(e.error));
                    const error = JSON.parse(decodedString);

                    this.setErrors(error.errors, this.formGroup)
                },
                () =>
                {
                    console.log('finisah')
                })
    }

    getZip(loader?)
    {
        const value = this.formGroup.getRawValue();
        this.formGroup.reset(value);

        return this.docService.getZip(
            this.formGroup.controls.tplFilter.value,
            this.formGroup.controls.templateIdFilter.value,
            this.formGroup.controls.batchFilter.value,
            this.formGroup.controls.signerFilter.value,
            this.formGroup.controls.statusFilter.value,
            this.formGroup.controls.dateFrom.value,
            this.formGroup.controls.dateTo.value,
            this.formGroup.controls.deliverOption.value,
            loader
        )
            .subscribe(
                r =>
                {
                    const conf: MsgBoxConfig = {
                        data: {
                            title: this.translator.instant('Preparando informe'),
                            body: this.translator.instant('Tu informe se esta preparando en segundo plano. Te enviaremos un email para que lo descargues cuando este listo.'),
                            btn_ok: this.translator.instant('Ok')
                        }
                    };

                    this.messageBoxService.instant(conf)

                },
                e =>
                {

                    const decodedString = String.fromCharCode.apply(null, new Uint8Array(e.error));
                    const error = JSON.parse(decodedString);

                    this.setErrors(error.errors, this.formGroup)
                },
                () =>
                {
                    console.log('finisah')
                })
    }

    getPdf(id: any)
    {
        this.docService.getPdf(id);
    }

    retrySend(id: any, row: any)
    {
        const flags = row.flags.split('|');
        const found = flags.indexOf('FIRMA_LINK');
        if (found != -1)
        {
            this.retrieveLinkForShareAgain(row);
            return;
        }

        this.docService.retrySend(id);
    }

    retrieveLinkForShareAgain(row: any): void
    {
        this.linkService.retreiveLinkForSingOnShop(row.doc_id, true);
    }

    getPdfAuditTrail(id: any)
    {
        this.docService.getPdfAuditTrail(id);
    }

    more(row)
    {

        const f = {
            doc_id: row.doc_id
        };


        this.docService
            .listByTpl(row.tpl_id, f)
            .pipe(
                mergeMap(r => this.dialog.open(DocumentExtrasModalComponent, { data: r }).afterClosed())
            )
            .subscribe(
                r => console.log(r)
            );
    }

    private setErrors(errors, formGroup: FormGroup)
    {

        const propertyMap = {
            fromDate: 'dateFrom',
            toDate: 'dateTo'
        };

        Object.keys(errors).forEach(errPath =>
        {


            const ngErrs = errors[errPath].reduce((carry, current) =>
            {
                carry[current] = true;
                return carry;
            }, {})

            errPath = propertyMap[errPath] || errPath;

            formGroup.controls[errPath].setErrors(ngErrs);
            formGroup.controls[errPath].markAsTouched();

        })

        console.log(errors)
    }

    delete(id: number)
    {
        const conf: ConfirmationBoxConf = {
            title: this.translator.instant('You are going to remove the document'),
            body: this.translator.instant('This actions is irreversible'),
            btn_ok: this.translator.instant('Accept'),
            btn_cancel: this.translator.instant('Cancel')
        };

        this.confirmationDialog.open(conf)
            .pipe(
                mergeMap(r => this.docService.delete(id))
            )
            .subscribe(
                r => this.getData(),
                e => console.error(e)
            );
    }

    public downloadAttachment(docId, inputId, mime)
    {
        this.docService.downloadAttachment(docId, inputId, mime);
    }

    translateStatusKey(docStatus: any)
    {
        return this.docService.translateStatusKey(docStatus);
    }

    private parseSigners(data: LaravelPaginatedList<any>): LaravelPaginatedList<any>
    {
        data.data.forEach(row =>
        {
            const signers = row.full_name ? row.full_name.split('|') : [];
            const signatureStatus = row.full_status.split(',');

            row.parsedSigners = [];

            signers.forEach((signer, i) =>
            {
                const name = signer.trim();
                const newSigner = {
                    name: name,
                    initials: `${ name[0] }${ name[1] }`,
                    status: signatureStatus[i] || '0'
                };

                row.parsedSigners.push(newSigner);
            });
        });

        return data;
    }


}
