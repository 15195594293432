import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DocumentTrayComponent} from './components/document-tray/document-tray.component';
import {ModuleRoutes} from './module-routes';
import {DocumentsTableComponent} from './components/documents-table/documents-table.component';
import {DocumentExtrasModalComponent} from './components/document-extras-modal/document-extras-modal.component';
import {DatePickerAdapter} from '../shared/date-picker.adapter';
import { DateAdapter } from '@angular/material/core';
import { ResponsiveTopbarModule } from '../content/responsive-topbar/responsive-topbar.module';
import { UserMenuModule } from '../content/user-menu/user-menu.module';
import { FlagToIconPipe } from './components/documents-table/pipes/flag-to-icon.pipe';


@NgModule({
    declarations: [
        DocumentTrayComponent,
        DocumentsTableComponent,
        DocumentExtrasModalComponent,
        FlagToIconPipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule.forChild(ModuleRoutes),
        UserMenuModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ResponsiveTopbarModule
    ],
    providers: [
        {provide: DateAdapter, useClass: DatePickerAdapter}
    ],
    exports: [],
    entryComponents: [
        DocumentExtrasModalComponent
    ]
})
export class DocumentTrayModule {
}
