import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { Subject } from 'rxjs';
import { PDF_STATE } from 'src/app/configs/enums/pdf-elements-state.enum';
import { ResizeEvent } from 'src/app/core/interfaces/Resize/resize-event.interface';
import { TemplateOutputDragged } from 'src/app/core/models/api/template/template-output.model';
import { ColorService } from 'src/app/core/services/color.service';
import { PdfElementsService } from 'src/app/core/services/content/pdf-elements.service';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { ItemConfigurationComponent } from '../item-configuration/item-configuration.component';

export const MIN_BOX_WIDTH = 50;
export const MIN_BOX_HEIGHT = 20;

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy
{
    @ViewChild('boundary', { static: false }) boundary: ElementRef;
    @ViewChild(PdfViewerComponent, { static: true }) pdfViewer: PdfViewerComponent;
    @Input() signatureOnly: boolean

    public initialSizes: DOMRect;
    private unsubscriber: Subject<void>;

    // Ref.
    public PDF_STATE = PDF_STATE;

    constructor(
        public pdfControls: PdfControlsService,
        public pdfElements: PdfElementsService,
        public color: ColorService,
        public toast: I18nToastService,
        public responsive: ResponsiveService,
        private crossPlatformDialog: CrossPlatformDialogService
    )
    {
        this.unsubscriber = new Subject<void>();
    }

    ngOnInit(): void
    { }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();

        // Al destruir el editor, hay que resetear el pdfControls.
        this.pdfControls.reset();
    }

    public updateLocation(event: CdkDragEnd, target: TemplateOutputDragged): void
    {
        const newPosition = event.source.getFreeDragPosition();
        target.location.x = newPosition.x;
        target.location.y = newPosition.y;
    }
    public onResizeEnd(event: ResizeEvent, resizable: HTMLDivElement, output: TemplateOutputDragged): void
    {
        const newStyle = {
            width: event.rectangle.width,
            height: event.rectangle.height
        };

        output.meta.style['width.px'] = newStyle.width;
        output.meta.style['height.px'] = newStyle.height;
    }
    public validateResize(event: ResizeEvent): boolean
    {
        const boundaryElement = this.boundary.nativeElement as HTMLDivElement;
        const boundaryRect = boundaryElement.getBoundingClientRect();

        if (event.rectangle.width < MIN_BOX_WIDTH || event.rectangle.height < MIN_BOX_HEIGHT)
        {
            return false;
        }

        const right = event.rectangle.originalLeft + event.rectangle.width;
        const bottom = event.rectangle.originalTop + event.rectangle.height;

        const boundaryRight = boundaryRect.right;
        const boundaryBottom = boundaryRect.bottom;

        if (boundaryRight < right || boundaryBottom < bottom)
            return false;

        return true;
    }
    public loadedCompleted(event: PDFDocumentProxy): void
    {
        this.pdfControls.setPages(event.numPages);
    }
    public pageRendered(): void
    {
        const rect = document.querySelector('.ng2-pdf-viewer-container .page').getBoundingClientRect();
        if (!this.initialSizes)
            this.initialSizes = Object.assign(rect, {});

        let element = this.pdfViewer['element'].nativeElement as Element;
        element = element.querySelector('.page');

        setTimeout(() =>
        {
            const isAlreadyChildOfPdfViewer = element.querySelector('.boundary');
            if (this.boundary && !isAlreadyChildOfPdfViewer)
            {
                // Internamente esta linea solo se ejecuta una vez
                this.pdfControls.setBoundary(this.boundary.nativeElement);

                element.appendChild(this.boundary.nativeElement);
            }
        });
    }
    public pageChanged(): void
    {
        try
        {
            this.pageRendered();
        }
        catch (e)
        {
            console.log('Not ready yet');
        }
    }
    public clickOverBoundary(evt: MouseEvent | any): void
    {
        const currentState = this.pdfElements.getCurrentState();
        if (currentState == PDF_STATE.ADDING)
        {
            const target = evt.target as HTMLDivElement;
            if (target instanceof HTMLDivElement)
            {
                const isBoundary = target.className.indexOf('boundary') != -1;
                if (isBoundary)
                {
                    const item = this.pdfControls.addOutput(evt.layerX, evt.layerY);
                    this.pdfElements.changeState(PDF_STATE.PENDING);

                    // Si añadimos un aditional doc, automáticamente tenemos
                    // que abrir la ventana de configuración.
                    if (item &&
                        (item.meta.output_type == 'aditional_doc' ||
                            item.meta.output_type == 'arbitrary_text_on_send'))
                        this.configureAndOpenProperties(item);
                }
                else
                    this.toast.open(
                        'You have to touch an empty space of the pdf',
                        'Accept',
                        5000
                    )
            }
            else
                this.toast.open(
                    'You have to touch an empty space of the pdf',
                    'Accept',
                    5000
                )
        }
    }
    public openPdfItemConfiguration(event: MouseEvent | any, item: TemplateOutputDragged): void
    {
        const isPreview = this.pdfControls.templateEditor.isPreviewMode();
        if (!isPreview)
        {
            const isHandler = event.composedPath().findIndex(
                x =>
                {
                    try
                    {
                        if (x.getAttribute('class') == 'not-clickable')
                            return x;
                    }
                    catch (err)
                    { }
                });

            if (isHandler == -1)
            {
                this.configureAndOpenProperties(item);
            }
        }
    }
    public trackById(index: number, item: TemplateOutputDragged): string
    {
        return item.id;
    }

    private configureAndOpenProperties(item: TemplateOutputDragged): void
    {
        // Este control se hace para el output de archivo adjunto.
        let disableClose = false;
        const outputType = item.meta.output_type;
        const fileName = item.meta.filename;
        const fieldName = item.meta.textname;
        if ((outputType == 'aditional_doc' && !fileName) || (outputType == 'arbitrary_text_on_send' && !fieldName))
            disableClose = true;

        const dialogConf = {
            // width: '300px',
            // height: '400px',
            disableClose,
            data: {
                countries: [],
                title: 'Field properties',
                dynamycComponent: ItemConfigurationComponent,
                data: item,
                positiveButton: 'save',
                isEdit: false,
                locales: [],
                hideCloseButton: disableClose
            }
        } as DialogStructure<TemplateOutputDragged>;
        this.crossPlatformDialog.open(dialogConf);
    }



    public onError(event)
    {
        console.error(event);
    }
}
