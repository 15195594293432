import { Country } from 'src/app/models/country.model';
import { CommonState } from 'src/app/models/default.model';

export interface GeonamesState extends CommonState
{
    countries: Country[]
}

export const geonamesInitialState: GeonamesState =
{
    countries: [],
    isLoading: true,
    errorMsg: null
}
