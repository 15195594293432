<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
</ng-container>

<ng-template #desktop>
    <ng-container *ngTemplateOutlet="helpCol"></ng-container>
    <ng-container *ngTemplateOutlet="contentCol"></ng-container>
</ng-template>

<ng-template #helpCol>
    <div class="help-col mat-typography">
        <div class="help-title">
            <mat-icon class="mar-r10 primary-color">help</mat-icon>
            <span class="subtitle">{{ 'Help' | translate }}</span>
        </div>
        <app-tip [idx]="0" [tip]="roleTip"></app-tip>
        <app-tip [idx]="1" [tip]="passwordTip"></app-tip>
    </div>
</ng-template>

<ng-template #contentCol>
    <mat-tab-group style="min-height: 100%;">
        <mat-tab label="Datos">
            <div class="content mat-typography" [formGroup]="formGroup">
                <div class="form">
                    <ng-container formGroupName="contact">
                        <ng-container *ngIf="responsive.isMobile$ | async; else deskTitle">
                            <div class="subtitle flex-row-between"
                                style="align-items:center; padding-top: 2px; padding-bottom: 12px;">
                                {{ data.title | translate }}
                                <button mat-icon-button color="primary" (click)="openHelp()">
                                    <mat-icon>help</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        <ng-template #deskTitle>
                            <div class="subtitle" style="padding-top: 2px; padding-bottom: 12px;">
                                {{ data.title | translate }}
                            </div>
                        </ng-template>
                        <mat-form-field class="low-density">
                            <mat-label>{{'Legal identity' | translate}}</mat-label>
                            <input required matInput placeholder="12345678A"
                                formControlName="customer_legal_identifier">
                            <ng-container
                                *ngIf="contactFormGroup.controls.customer_legal_identifier.invalid && Array.isArray(contactFormGroup.controls.customer_legal_identifier.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.customer_legal_identifier.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field class="low-density">
                            <mat-label>{{'name' | translate}}</mat-label>
                            <input required matInput placeholder="{{'Name' |translate}}" formControlName="name">

                            <ng-container
                                *ngIf="contactFormGroup.controls.name.invalid && Array.isArray(contactFormGroup.controls.name.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.name.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>


                        <mat-form-field class="low-density">
                            <mat-label>{{'surname1' | translate}}</mat-label>
                            <input required matInput placeholder="{{'surname_1' |translate}}"
                                formControlName="surname_1">
                            <ng-container
                                *ngIf="contactFormGroup.controls.surname_1.invalid && Array.isArray(contactFormGroup.controls.surname_1.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.surname_1.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field class="low-density">
                            <mat-label>{{'surname2' | translate}}</mat-label>
                            <input matInput placeholder="{{'surname_2' |translate}}" formControlName="surname_2">
                            <ng-container
                                *ngIf="contactFormGroup.controls.surname_2.invalid && Array.isArray(contactFormGroup.controls.surname_2.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.surname_2.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field class="low-density">

                            <mat-label>{{'email' | translate}}</mat-label>
                            <input required matInput placeholder="name@gmail.com" formControlName="corporate_email">
                            <ng-container
                                *ngIf="contactFormGroup.controls.corporate_email.invalid && Array.isArray(contactFormGroup.controls.corporate_email.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.corporate_email.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field class="low-density">
                            <mat-label>{{'company' | translate}}</mat-label>
                            <input matInput placeholder="ACME S.A." formControlName="company">
                            <ng-container
                                *ngIf="contactFormGroup.controls.company.invalid && Array.isArray(contactFormGroup.controls.company.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.company.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>



                        <mat-form-field class="low-density">
                            <mat-label>{{'mobile_phone' | translate}}</mat-label>
                            <!-- <input matInput placeholder="+3455500000" formControlName="mobile_phone">
                            <ng-container
                                *ngIf="contactFormGroup.controls.mobile_phone.invalid && Array.isArray(contactFormGroup.controls.mobile_phone.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.mobile_phone.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container> -->
                            <lac-mat-country-selector matPrefix [selectorFor]="input"></lac-mat-country-selector>
                            <lac-mat-tel-input #input formControlName="mobile_phone"></lac-mat-tel-input>
                            <mat-error *ngIf="contactFormGroup.controls['mobile_phone']?.errors?.invalidPhone">Invalid phone</mat-error>
                        </mat-form-field>

                        <mat-form-field class="low-density" *ngFor="let c of this.custoTextConf$ | async">
                            <mat-label>{{ c.data_value }}</mat-label>
                            <input required matInput placeholder="name@gmail.com"
                                formControlName="{{'custom_value_' + c.getIdx()}}">
                            <ng-container
                                *ngIf="contactFormGroup.controls['custom_value_' + c.getIdx()].invalid && Array.isArray(contactFormGroup.controls['custom_value_' + c.getIdx()].errors)">
                                <mat-error
                                    *ngFor="let e of contactFormGroup.controls['custom_value_' + c.getIdx()].errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field class="low-density">
                            <mat-select formControlName="locale" placeholder="{{ 'locale' | translate }}" required>
                                <mat-option *ngFor="let l of localesDictionary" [value]="l.code">{{l.lang | translate}}
                                </mat-option>
                            </mat-select>
                            <ng-container
                                *ngIf="contactFormGroup.controls.locale.invalid && Array.isArray(contactFormGroup.controls.locale.errors)">
                                <mat-error *ngFor="let e of contactFormGroup.controls.locale.errors">
                                    {{e}}
                                </mat-error>
                            </ng-container>
                        </mat-form-field>
                    </ng-container>

                    <mat-form-field class="low-density">
                        <mat-label>{{ 'Role' | translate }}</mat-label>
                        <mat-select formControlName="rol">
                            <mat-option *ngFor="let rol of data.roles$ | async" [value]="rol">
                                {{ 'rol_' + rol | translate }}
                            </mat-option>
                        </mat-select>
                        <ng-container
                            *ngIf="formGroup.controls['rol'].invalid && Array.isArray(formGroup.controls['rol'].errors)">
                            <mat-error *ngFor="let e of formGroup.controls['rol'].errors">
                                {{ e }}
                            </mat-error>
                        </ng-container>
                    </mat-form-field>

                </div>
                <div class="footer">
                    <button mat-stroked-button mat-dialog-close>
                        {{ 'Cancel' | translate }}
                    </button>
                    <button mat-raised-button color="primary" (click)="action()" *ngIf="data.isEdit">
                        {{ 'save' | translate }}
                    </button>
                    <button mat-raised-button color="primary" (click)="action()" *ngIf="!data.isEdit">
                        {{ 'create' | translate }}
                    </button>
                </div>
            </div>
        </mat-tab>
        <ng-container *ngIf="currentUser.user$ | async as currentLoggedUser">
            <mat-tab label="Firmas" *ngIf="data.data && data.data.id == currentLoggedUser.id">
                <div class="signature-container">
                    <app-user-signatures></app-user-signatures>
                </div>
                <div>
                    <button mat-button color="secondary" mat-dialog-close>
                        {{ 'cancel' | translate }}
                    </button>
                </div>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</ng-template>