import { Tip } from 'src/app/core/models/tip';

export const CONTACT_SELECTION_TIPS: Tip[] = [
    {
        title: 'Search a contact',
        body: 'tip_body_editable_5'
    },
    {
        title: 'Select a contact',
        body: 'tip_body_editable_6'
    },
    {
        title: 'Send the document',
        body: 'tip_body_editable_7'
    }
];
