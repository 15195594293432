import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { SUBTENANT_LIST_COL_DEF } from 'src/app/configs/constants/subtenant-list-column-definition.constants';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { RootStoreState } from 'src/app/root-store';
import { SubtenantListStoreSelectors, SubtenantListStoreActions } from 'src/app/root-store/subtenant-list-store';
import { SubtenantState } from 'src/app/root-store/subtenant-list-store/state';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';
import { BaseTableComponent } from 'src/app/shared/base-table/base-table.component';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { CreditTransferDialogComponent, SUBTENANT_CREDIT_DIAG_HEIGHT, SUBTENANT_CREDIT_DIAG_WIDTH } from '../credit-transfer-dialog/credit-transfer-dialog.component';
import { NewSubtenantDialogComponent, SUBTENANT_FORM_DIAG_HEIGHT, SUBTENANT_FORM_DIAG_WIDTH } from '../subtenant-edit/components/new-subtenant-dialog/new-subtenant-dialog.component';
import { SubtenantListActionsComponent } from './extras/subtenant-list-actions.component';
import { SubtenantListProductResumeComponent } from './extras/subtenant-list-product-resume.component';

@Component({
    selector: 'app-subtenant-list',
    templateUrl: './subtenant-list.component.html',
    styleUrls: ['./subtenant-list.component.scss']
})
export class SubtenantListComponent extends BaseTableComponent implements AfterViewInit, OnInit, OnDestroy
{
    public error$: Observable<any>;
    public loading$: Observable<boolean>;
    public state$: Observable<SubtenantState>;
    private unsubscriber: Subject<void>;

    constructor(
        private store$: Store<RootStoreState.State>,
        private dialog: MatDialog,
        public translator: TranslateService,
        private toast: I18nToastService,
        private confirmation: ConfirmationBoxService
    )
    {
        super(translator);
        this.hasToFit = false;

        this.error$ = this.store$.select(SubtenantListStoreSelectors.selectSubtenantError);
        this.loading$ = this.store$.select(SubtenantListStoreSelectors.selectSubtenantIsLoading);
        this.state$ = this.store$.select(SubtenantListStoreSelectors.selectSubtenantState);

        this.unsubscriber = new Subject<void>();
    }


    ngAfterViewInit(): void
    {
        this.translateColumnHeaders(SUBTENANT_LIST_COL_DEF);

        this.gridOptions = {
            ...this.gridOptions,
            context: this.context,
            frameworkComponents: {
                SubtenantListTableActions: SubtenantListActionsComponent,
                SubtenantListProductResume: SubtenantListProductResumeComponent
            }
        };
    }
    ngOnInit(): void
    {
        this.store$.dispatch(new SubtenantListStoreActions.Fetch(1));

        this.error$
            .pipe(
                takeUntil(this.unsubscriber)
            )
            .subscribe(err =>
            {
                if (err)
                    this.toast.open(err, 'Accept', 5000, true)
            });
    }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public newSubtenant(): void
    {
        this.dialog.open(NewSubtenantDialogComponent, {
            width: SUBTENANT_FORM_DIAG_WIDTH,
            height: SUBTENANT_FORM_DIAG_HEIGHT
        });
    }
    public transferCredit(uuid: string): void
    {
        this.dialog.open(CreditTransferDialogComponent, {
            width: SUBTENANT_CREDIT_DIAG_WIDTH,
            height: SUBTENANT_CREDIT_DIAG_HEIGHT,
            data: uuid
        });
    }
    public unlinkSubtenant(uuid: string): void
    {
        this.state$
            .pipe(
                take(1),
                map(state => state.subTenantList.data.find(x => x.uuid === uuid)),
                mergeMap(subtenant => this.confirmation.open({
                    title: this.translator.instant('Do you want to unlink the subtenant?'),
                    body: this.translator.instant('subtenant x delete', { subtenant: subtenant.company }),
                    btn_cancel: this.translator.instant('Cancel'),
                    btn_ok: this.translator.instant('Accept')
                }))
            )
            .subscribe(
                result =>
                {
                    if (result)
                        this.store$.dispatch(new SubtenantListStoreActions.Unlink(uuid));
                }
            )
    }
}
