<ng-container *ngIf="contact && contact.name">
    <!-- Número contactos -->
    <div (click)="select()" class="col-top-centered">
        <div class="flex-center-content fab-initials" [ngClass]="{'bordered': contact.user_id}"
            style="font-size: 18px; font-weight: bold;" [style.color]="'black'" [style.background-color]="'#E2E2E2'">
            {{ contact.name.slice(0,2).toUpperCase() }}
        </div>
        <mat-icon [matTooltip]="'This contact is also a user' | translate" color="primary" style="margin-top: 3px;" *ngIf="contact.user_id">
            account_circle
        </mat-icon>
    </div>
    <!-- Datos de la plantilla -->
    <div class="pad-l10" (click)="select()">
        <div>
            {{contact.name}} {{contact.surname_1}} {{contact.surname_2}}
        </div>
        <div class="rbt">
            <span class="hint rbt"
                style="text-transform: none;">{{ contact && contact.customer_legal_identifier ? contact.customer_legal_identifier : 'Cargando...' }}</span>
        </div>
        <ng-container *ngIf="this.service.getActiveCustomField() | async as customFieldInUse">
            <div class="rbt" *ngIf="customFieldInUse && contact[customFieldInUse]">
                <span class="hint rbt"
                    style="text-transform: none;">
                    <mat-icon class="signature">gesture</mat-icon><span [matTooltip]="'Auth signer' | translate">{{ contact[customFieldInUse] }}</span>
                </span>
            </div>
        </ng-container>
        <div class="rbt" *ngIf="contact && contact.corporate_email">
            <span class="hint rbt" style="text-transform: none; color: blue!important">{{ contact.corporate_email }}</span>
        </div>
        <div class="rbt" *ngIf="contact && contact.mobile_phone">
            <span class="hint rbt" style="text-transform: none;">{{ contact.mobile_phone }}</span>
        </div>
    </div>
    <button mat-icon-button color="primary" *ngIf="editable && !contact.user_id" class="float-edit" (click)="edit()">
        <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button color="primary" *ngIf="editable && contact.user_id" class="float-edit" [matTooltip]="'this contact must be edited from user management' | translate">
        <mat-icon>block</mat-icon>
    </button>

    <!-- Floating btn delete-->
    <button (click)="select()" class="float-delete" mat-icon-button *ngIf="showDeleteButton" color="warn">
        <mat-icon>delete</mat-icon>
    </button>
</ng-container>
<ng-container *ngIf="!contact">
    Cargando...
</ng-container>
