import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { CommonState } from 'src/app/models/default.model';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';

export interface SubtenantState extends CommonState
{
    subTenantList: LaravelPaginatedList<Tenant>, // TODO: Definir modelo datos
}

export const subtenantInitialState: SubtenantState =
{
    subTenantList: null,
    isLoading: true,
    errorMsg: null
}
