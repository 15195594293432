import { Action } from "@ngrx/store";
import { Tenant } from "src/app/core/models/tenant-management/tenant";
import { LaravelPaginatedList } from "src/app/shared/auto-table/definitions/laravel-paginated-list";

export enum SubtenantListActionTypes
{
    FETCH = '[SUBTENANT] FETCH',
    FETCH_SUCCESS = '[SUBTENANT] FETCH_SUCCESS',
    FETCH_FAIL = '[SUBTENANT] FETCH FAIL',
    UNLINK = '[SUBTENANT] UNLINK',
    UNLINK_SUCCESS = '[SUBTENANT] UNLINK_SUCCESS',
    UNLINK_FAIL = '[SUBTENANT] UNLINK_FAIL',

}

export class Fetch implements Action
{
    readonly type = SubtenantListActionTypes.FETCH;
    constructor(public payload: number) { }
}
export class FetchSuccess implements Action
{
    readonly type = SubtenantListActionTypes.FETCH_SUCCESS;
    constructor(public payload: LaravelPaginatedList<Tenant>) { }
}
export class FetchFail implements Action
{
    readonly type = SubtenantListActionTypes.FETCH_FAIL;
}
export class Unlink implements Action
{
    readonly type = SubtenantListActionTypes.UNLINK;
    constructor(public payload: string) { }
}
export class UnlinkSuccess implements Action
{
    readonly type = SubtenantListActionTypes.UNLINK_SUCCESS;
    constructor() { }
}
export class UnlinkFail implements Action
{
    readonly type = SubtenantListActionTypes.UNLINK_FAIL;
    constructor() { }
}

export type SubtenantActionsUnion = Fetch | FetchSuccess | FetchFail | Unlink | UnlinkSuccess | UnlinkFail;
