import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyAddonFieldComponent } from './copy-addon-field.component';
import { MaterialModule } from 'src/app/material/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [CopyAddonFieldComponent],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule
    ],
    exports: [CopyAddonFieldComponent]
})
export class CopyAddonFieldModule { }
