import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { RootStoreState, SubtenantInvoicesStoreActions, SubtenantInvoicesStoreSelectors } from 'src/app/root-store';
import { BaseTableComponent } from 'src/app/shared/base-table/base-table.component';
import { CreditTransferDialogComponent, SUBTENANT_CREDIT_DIAG_HEIGHT, SUBTENANT_CREDIT_DIAG_WIDTH } from '../../../credit-transfer-dialog/credit-transfer-dialog.component';

@Component({
    selector: 'app-subtenant-invoices',
    templateUrl: './subtenant-invoices.component.html',
    styleUrls: ['./subtenant-invoices.component.scss']
})
export class SubtenantInvoicesComponent extends BaseTableComponent implements AfterViewInit, OnDestroy
{
    @Input() uuid: string;
    public error$: Observable<any>;
    public loading$: Observable<boolean>;
    public state$: Observable<any>;
    private unsubscriber: Subject<void>;

    constructor(
        private store$: Store<RootStoreState.State>,
        private dialog: MatDialog,
        public translator: TranslateService,
        private toast: I18nToastService
    )
    {
        super(translator);
        this.error$ = this.store$.select(SubtenantInvoicesStoreSelectors.selectSubtenantInvoicesError);
        this.loading$ = this.store$.select(SubtenantInvoicesStoreSelectors.selectSubtenantInvoicesIsLoading);
        this.state$ = this.store$.select(SubtenantInvoicesStoreSelectors.selectSubtenantInvoicesState);

        this.unsubscriber = new Subject<void>();
    }

    ngAfterViewInit(): void
    {
        const cols = [
            {
                field: 'date', headerName: 'date', cellRenderer: (params) =>
                {
                    const date = new Date(params.value);
                    return date.toLocaleString();
                }
            },
            {
                field: 'concept', headerName: 'concept', cellRenderer: (params) =>
                {
                    return this.translator.instant(params.value);
                }
            },
            { field: 'amount', headerName: 'quantity' },
        ] as ColDef[];
        this.translateColumnHeaders(cols);

        this.gridOptions = {
            ...this.gridOptions,
            context: this.context,
        }

        this.store$.dispatch(
            new SubtenantInvoicesStoreActions.Fetch(this.uuid)
        );

        this.error$
            .pipe(
                takeUntil(this.unsubscriber)
            )
            .subscribe(err =>
            {
                if (err)
                    this.toast.open(err, 'Accept', 5000, true)
            });
    }
    ngOnDestroy(): void
    {
        this.store$.dispatch(
            new SubtenantInvoicesStoreActions.Clear()
        );
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public transferCredit(): void
    {
        this.dialog.open(CreditTransferDialogComponent, {
            width: SUBTENANT_CREDIT_DIAG_WIDTH,
            height: SUBTENANT_CREDIT_DIAG_HEIGHT,
            data: this.uuid
        });
    }
    public sizeToFit(): void
    {
        this.gridApi?.sizeColumnsToFit();
    }
}
