import { Tip } from 'src/app/core/models/tip';

export const CONTACT_QUERY_TIPS: Tip[] = [
    {
        title: 'Shippment type',
        body: 'tip_4_body'
    },
    {
        title: 'Search contacts',
        body: 'tip_5_body'
    },
    {
        title: 'Select contacts',
        body: 'tip_6_body'
    }
];
