<ng-container [formGroup]="tenantForm">
    <mat-form-field>
        <mat-label>{{ "company" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'company' | translate }}"
            formControlName="company"
        />
        <mat-error *ngIf="tenantForm.controls.company.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.company.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.company.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "fiscal_id" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'fiscal_id' | translate }}"
            formControlName="fiscal_id"
        />
        <mat-error *ngIf="tenantForm.controls.fiscal_id.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.fiscal_id.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.fiscal_id.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "Contact name" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'Contact name' | translate }}"
            formControlName="name"
        />
        <mat-error *ngIf="tenantForm.controls.name.errors">
            <ng-container
                *ngIf="tenantForm.controls.name.errors?.required; else regular"
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.name.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "Contact surnname" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'Contact surnname' | translate }}"
            formControlName="surname"
        />
        <mat-error *ngIf="tenantForm.controls.surname.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.surname.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.surname.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ "phone_no" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'phone_no' | translate }}"
            formControlName="phone_no"
        />
        <mat-error *ngIf="tenantForm.controls.phone_no.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.phone_no.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.phone_no.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="email-field">
        <mat-label>{{ "email" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'email' | translate }}"
            formControlName="email"
        />
        <mat-error *ngIf="tenantForm.controls.email.errors">
            <ng-container
                *ngIf="tenantForm.controls.email.errors?.required; else regular"
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.email.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="email-field">
        <mat-label>{{ "address" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'address' | translate }}"
            formControlName="address"
        />
        <mat-error *ngIf="tenantForm.controls.address.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.address.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.address.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "city" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'city' | translate }}"
            formControlName="city"
        />
        <mat-error *ngIf="tenantForm.controls.city.errors">
            <ng-container
                *ngIf="tenantForm.controls.city.errors?.required; else regular"
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.city.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "postal_code" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'postal_code' | translate }}"
            formControlName="postal_code"
        />
        <mat-error *ngIf="tenantForm.controls.postal_code.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.postal_code.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.postal_code.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ "region" | translate }}</mat-label>
        <input
            matInput
            placeholder="{{ 'region' | translate }}"
            formControlName="region"
        />
        <mat-error *ngIf="tenantForm.controls.region.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.region.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.region.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="isNewTenant">
        <mat-label>{{ "country" | translate }}</mat-label>
        <mat-select formControlName="country">
            <ng-container *ngIf="loading$ | async; else options">
                {{ "Loading..." | translate }}
            </ng-container>
            <ng-template #options>
                <ng-container *ngIf="state$ | async as state">
                    <mat-option
                        *ngFor="let c of state.countries"
                        [value]="c.id"
                    >
                        {{ c.name }}
                    </mat-option>
                </ng-container>
            </ng-template>
        </mat-select>
        <mat-error *ngIf="tenantForm.controls.country.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.country.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.country.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!isNewTenant">
        <mat-label>{{ "country" | translate }}</mat-label>
        <mat-select formControlName="country_id">
            <ng-container *ngIf="loading$ | async; else options">
                {{ "Loading..." | translate }}
            </ng-container>
            <ng-template #options>
                <ng-container *ngIf="state$ | async as state">
                    <mat-option
                        *ngFor="let c of state.countries"
                        [value]="c.id"
                    >
                        {{ c.name }}
                    </mat-option>
                </ng-container>
            </ng-template>
        </mat-select>
        <mat-error *ngIf="tenantForm.controls.country_id.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.country_id.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.country_id.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="isNewTenant">
        <mat-label>{{ "locale" | translate }}</mat-label>
        <mat-select formControlName="locale">
            <mat-option *ngFor="let c of locales" [value]="c.code">
                {{ c.lang | translate }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="tenantForm.controls.locale.errors">
            <ng-container
                *ngIf="
                    tenantForm.controls.locale.errors?.required;
                    else regular
                "
                >{{ "Mandatory field" | translate }}</ng-container
            >
            <ng-template #regular>
                {{ tenantForm.controls.locale.errors }}
            </ng-template>
        </mat-error>
    </mat-form-field>
</ng-container>
