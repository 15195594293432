import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, matBaseAnimations } from 'src/app/base/base.component';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { SendingsService } from 'src/app/shared/sendings.service';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { EditableTableAdapterService } from 'src/app/core/services/pages/editable-tray/editable-table-adapter.service';

@Component({
    selector: 'app-editable-tray',
    templateUrl: './editable-tray.component.html',
    styleUrls: ['./editable-tray.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation],
})
export class EditableTrayComponent extends BaseComponent implements OnInit, OnDestroy
{
    @HostBinding('class') classes = 'bg mat-typography';
    @ViewChild('filters') filters;
    private interval;

    constructor(
        public SideNav: SidenavService,
        public responsive: ResponsiveService,
        public editableTAS: EditableTableAdapterService,
        private route: ActivatedRoute,
        private serdigsService: SendingsService,
    )
    {
        super(SideNav);
    }

    ngOnInit(): void
    {
        this.editableTAS
            .warmUp(this.route)
            .subscribe(
                r => {
                    console.log(r);
                },
                e => {
                },
                () => {
                    this.editableTAS.getData();
                    this.serdigsService.RecalculateSendings();
                }
            );
    }

    ngOnDestroy(): void
    {
        clearInterval(this.interval);
    }

}
