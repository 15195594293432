export var GRIDSTER_CONFIGURATION = {
    gridType: 'verticalFixed',
        compactType: 'compactUp',
        initCallback: undefined,
        destroyCallback: undefined,
        itemInitCallback: undefined,
        itemRemovedCallback: undefined,
        margin: 15,
        outerMargin: true,
        mobileBreakpoint: 640,
        minCols: 7,
        maxCols: 7,
        minRows: 10,
        maxRows: 10,
        maxItemCols: 10,
        minItemCols: 1,
        maxItemRows: 4,
        minItemRows: 1,
        maxItemArea: 2500,
        minItemArea: 1,
        defaultItemCols: 1,
        defaultItemRows: 1,
        fixedColWidth: 125,
        fixedRowHeight: 200,
        keepFixedHeightInMobile: false,
        keepFixedWidthInMobile: false,
        scrollSensitivity: 10,
        scrollSpeed: 20,
        enableEmptyCellClick: false,
        enableEmptyCellContextMenu: false,
        enableEmptyCellDrop: false,
        enableEmptyCellDrag: false,
        emptyCellClickCallback: undefined,
        emptyCellContextMenuCallback: undefined,
        emptyCellDropCallback: undefined,
        emptyCellDragCallback: undefined,
        emptyCellDragMaxCols: 50,
        emptyCellDragMaxRows: 50,
        draggable: {
            delayStart: 0,
            enabled: true,
            ignoreContentClass: 'gridster-item-content',
            ignoreContent: false,
            dragHandleClass: 'drag-handler',
            stop: undefined
        },
        resizable: {
            delayStart: 0,
            enabled: false,
            stop: undefined,
            handles: {
                s: true,
                e: true,
                n: true,
                w: true,
                se: true,
                ne: true,
                sw: true,
                nw: true
            }
        },
        api: {
            resize: undefined,
            optionsChanged: undefined,
            getNextPossiblePosition: undefined,
        },
        swap: false,
        pushItems: true,
        disablePushOnDrag: false,
        disablePushOnResize: false,
        pushDirections: { north: true, east: true, south: true, west: true },
        pushResizeItems: false,
        displayGrid: 'onDrag&Resize',
        disableWindowResize: false,
        disableWarnings: false,
        scrollToNewItems: false
}