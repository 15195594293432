
import { Component, OnInit } from '@angular/core';
import { WebServiceHelperService } from '../shared/web-service-helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-log-in-callback',
    templateUrl: './log-in-callback.component.html',
    styleUrls: ['./log-in-callback.component.css']
})
export class LogInCallbackComponent implements OnInit {

    // PROPIEDADES


    // CONSTRUCTOR
    constructor(private WsHelper: WebServiceHelperService, private router: Router) { }

    ngOnInit() {
        this.WsHelper.handleCallback().then(res => {
            if (res.status === 200) {
                this.router.navigate(['/document']);
            }
        });
    }


    // MÉTODOS PÚBLICOS


    // MÉTODOS PRIVADOS
}
