<!-- EN MBL se muestran 2 barras -->
<div class="pdf-controls-row" *ngIf="responsive.isMobile$ | async">
    <ng-container *ngTemplateOutlet="pageControls"></ng-container>
</div>
<ng-container *ngIf="pdfControls.template$ | async as template">
    <div class="pdf-components-row" *ngIf="template && template.tpl.id">
        <!-- Controles Mobile -->
        <div
            *ngIf="!(pdfControls.isPreview$ | async)"
            class="flex-row-start-center"
            style="color: #666;"
        >
            <ng-container *ngFor="let dragable of template.repo">
                <ng-container *ngIf="dragable.meta.kind != 'text'">
                    <button
                        *ngIf="responsive.isMobile$ | async"
                        mat-icon-button
                        (click)="addElement(dragable)"
                    >
                        <mat-icon>{{ dragable.icon }}</mat-icon>
                    </button>
                    <button
                        *ngIf="!(responsive.isMobile$ | async)"
                        mat-stroked-button
                        (click)="addElement(dragable)"
                    >
                        <mat-icon>{{ dragable.icon }}</mat-icon>
                        <span class="mar-l10">{{ dragable.meta.text }}</span>
                    </button>
                    <div class="separator"></div>
                </ng-container>
                <ng-container *ngIf="dragable.meta.kind == 'text'">
                    <button
                        *ngIf="responsive.isMobile$ | async"
                        mat-icon-button
                        [matMenuTriggerFor]="textFields"
                    >
                        <mat-icon>
                            text_fields
                        </mat-icon>
                        <mat-icon class="icon-dropdown">
                            arrow_drop_down
                        </mat-icon>
                    </button>
                    <button
                        *ngIf="!(responsive.isMobile$ | async)"
                        mat-stroked-button
                        [matMenuTriggerFor]="textFields"
                    >
                        <mat-icon>
                            text_fields
                        </mat-icon>
                        <span class="mar-l10">{{
                            "Text fields" | translate
                        }}</span>
                    </button>
                    <div
                        *ngIf="signers.length > 1"
                        class="mar-l10 separator"
                    ></div>

                    <mat-menu #textFields="matMenu">
                        <button
                            mat-menu-item
                            *ngFor="let child of dragable.childs"
                            (click)="addElement(child)"
                        >
                            <mat-icon>{{ child.icon }}</mat-icon
                            >&nbsp;
                            {{ child.meta.text }}
                        </button>
                    </mat-menu>
                </ng-container>
            </ng-container>
        </div>

        <!-- Si hay más de un firmante, colocamos el select -->
        <ng-container *ngIf="!(pdfControls.isPreview$ | async)">
            <div
                *ngIf="signers.length > 1"
                class="signers-row"
                [formGroup]="signersForm"
            >
                <div
                    [style.backgroundColor]="
                        colorService.StringToColour(
                            signersForm.controls['signer'].value +
                                'Firmante ' +
                                (signersForm.controls['signer'].value + 20)
                        )
                    "
                    class="ball mar-r10"
                ></div>
                <mat-form-field style="width: 120px;height: 30px;">
                    <mat-label>
                        {{ "Fields for" | translate }}
                    </mat-label>
                    <mat-select formControlName="signer">
                        <mat-option
                            *ngFor="let signer of signers; let idx = index"
                            [value]="idx"
                        >
                            {{ "Signer" | translate }} {{ idx + 1 }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    class="separator"
                    *ngIf="!(responsive.isMobile$ | async)"
                ></div>
            </div>
        </ng-container>

        <!-- Desktop aparecen aquí los controles de pdf -->
        <ng-container *ngIf="!(responsive.isMobile$ | async)">
            <div class="flex-row-start-center">
                <ng-container *ngTemplateOutlet="pageControls"></ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>

<div
    class="state-overlay"
    *ngIf="(pdfElements.state$ | async) == PDF_STATE.ADDING"
>
    <ng-container *ngIf="responsive.isMobile$ | async">
        <mat-icon color="primary">
            touch_app
        </mat-icon>
        <div class="state-text">
            {{ "Press over the pdf to add the control" | translate }}
        </div>
        <div class="vertical-separator"></div>
        <button
            mat-stroked-button
            color="primary"
            (click)="pdfElements.changeState(PDF_STATE.PENDING)"
        >
            {{ "Cancel" | translate }}
        </button>
    </ng-container>
    <ng-container *ngIf="!(responsive.isMobile$ | async)">
        <div class="overlay-desk">
            <mat-icon color="primary">
                my_location
            </mat-icon>
            <div class="state-text">
                {{ "Click over the pdf to add the control" | translate }}
            </div>
            <div class="vertical-separator"></div>
            <button
                mat-raised-button
                color="primary"
                (click)="pdfElements.changeState(PDF_STATE.PENDING)"
            >
                {{ "Cancel" | translate }}
            </button>
        </div>
    </ng-container>
</div>

<ng-template #pageControls>
    <!-- Page controls -->
    <ng-container *ngIf="pdfControls.pages$ | async as totalPages">
        <div class="flex-row-start-center page-nav" *ngIf="totalPages > 1">
            <button
                class="navigation"
                mat-stroked-button
                (click)="pdfControls.lastPage()"
            >
                {{ "Back" | translate }}
            </button>
            <div class="mar-l10 mar-r10 fs-16">
                {{ pdfControls.currentPage$ | async }} / {{ totalPages }}
            </div>
            <button
                class="navigation"
                mat-stroked-button
                (click)="pdfControls.nextPage()"
            >
                {{ "Next" | translate }}
            </button>
        </div>
        <div class="separator" *ngIf="totalPages > 1"></div>
    </ng-container>
    <div class="flex-row-start-center page-zoom">
        <button color="primary" (click)="pdfControls.zoomOut()" mat-mini-fab>
            <mat-icon>zoom_out</mat-icon>
        </button>
        <div class="mar-l10 mar-r10 fs-16">
            {{ pdfControls.zoom$ | async | number: "1.1" }}
        </div>
        <button (click)="pdfControls.zoomIn()" mat-mini-fab color="primary">
            <mat-icon>zoom_in</mat-icon>
        </button>
    </div>
</ng-template>
