<div class="flex-col round-flex-col blue">
    <div class="flex-row template-name subtitle">
        {{ template.name  }}
    </div>
    <div class="flex-row template-description" style="height: 15px;margin-top:3px">
        {{template.created_at | prepareDate | date:'short' }}
    </div>
    <div class="flex-row template-description" style="height: 80px;">
        <div class="flex-col" style="flex: 1">
            <div class="flex-row info">
                <!-- <b>{{ 'Tipo' | translate }}:</b> {{ (template.public ? 'publica' : 'privada') | translate }} -->
                <div class="flex-col">
                    <!-- <div class="flex-row label-gray bold"> {{ 'Creator' | translate}}:</div>
                    <div class="flex-row label-gray bold"> {{ 'Date' | translate}}:</div>
                    <div class="flex-row label-gray bold"> {{ 'Signers' | translate}}:</div> -->
                    <div class="flex-row label-gray bold"> {{ 'Date' | translate}}:</div>
                    <div class="flex-row label-gray bold"> {{ 'Tipo' | translate}}:</div>
                </div>
                <div class="flex-col mar-l5">
                    <!-- <div class="flex-row"> {{ template.creator_name }} {{ template.creator_surname }}</div>
                    <div class="flex-row">{{ template.contact_count }}</div> -->
                    <div class="flex-row">{{template.created_at | prepareDate | date:'short' }}</div>
                    <div class="flex-row">{{ (template.public ? 'publica' : 'privada') | translate }}</div>
                </div>
            </div>
            <div class="flex-row" style="align-items: center;">
                <button matTooltip="Ver enviados" mat-icon-button
                        [routerLink]="['/document/', { status: docService.statusCacheKey | async ,template_id: [template.document_template_id] }]"
                        color="primary">
                    <mat-icon>all_inbox</mat-icon>
                </button>
                <button matTooltip="Ver pendientes de firma" mat-icon-button
                        [routerLink]="['/editable/', { status: status, template_id: [template.id] }]"
                        color="primary">
                    <mat-icon>hourglass_bottom</mat-icon>
                </button>
                <!-- <button  matTooltip="{{ 'clone' | translate }}" mat-icon-button (click)="clone()" color="primary">
                    <mat-icon>copy_all</mat-icon>
                </button> -->
                <button [matTooltip]="'edit' | translate" color="primary" mat-icon-button (click)="edit()">
                    <mat-icon>edit</mat-icon>
                </button>
                <mat-slide-toggle style="margin-left: 5px;" [checked]="template.status == 'ON'" (change)="delete()"></mat-slide-toggle>
            </div>
        </div>
        <div class="flex-row button-area" style="flex: 1">
            <div class="flex-row" style="align-items: end; justify-content: flex-end;padding-bottom: 5px;padding-right: 5px;">
                <button [matTooltip]="'qr code title' | translate" (click)="downloadQRCode($event)" mat-mini-fab class="mat-elevation-z2" color="accent">
                    <mat-icon>qr_code_scanner</mat-icon>
                </button>
            </div>
            <div class="flex-row" style="align-items: end; justify-content: flex-end;padding-bottom: 5px;padding-right: 5px;">
                <button [matTooltip]="'Send' | translate" (click)="shareDocument($event, template.token)" mat-mini-fab class="mat-elevation-z2" color="accent">
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<ngx-qrcode
    [elementType]="elementType"
    [errorCorrectionLevel]="correctionLevel"
    [value]="signatureLink$ | async"
    cssClass="bshadow"
    style="display: none;"
    >
</ngx-qrcode>