import { TemplateData } from './template-data.model';
import { TemplateRepo } from './template-repo.model';
import { TemplateOutput } from './template-output.model';

export class Template
{
    public outputs: Array<TemplateOutput>;
    public repo: Array<TemplateRepo>;
    public tpl: TemplateData;

    constructor ()
    {
        this.outputs = [];
        this.repo = [];
        this.tpl = new TemplateData();
    }
}
