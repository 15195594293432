import { Component, OnInit } from '@angular/core';
import { TextDefinitionService } from 'src/app/core/services/pages/text-definition.service';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import { Contact } from 'src/app/core/models/contacts/contact';
import { TenantService } from 'src/app/core/services/pages/tenant-management/tenant.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-text-definition',
    templateUrl: './text-definition.component.html',
    styleUrls: ['./text-definition.component.scss']
})
export class TextDefinitionComponent implements OnInit
{
    public tpl: TemplateData;
    public contacts: Array<Array<Contact>>;
    public arbitraryText: { [key: string]: string };
    public docNameErr = '';
    public inputFileErr = '';
    private onlyEdit: boolean;
    public personalSend = {
        isPersonal: false,
        flag: null
    };
    public availableFlags = [];

    constructor(
        private service: TextDefinitionService,
        private confirmation: ConfirmationBoxService,
        private router: Router,
        private translator: TranslateService,
        public responsive: ResponsiveService,
        public mobileStepper: MobileStepperService,
        public tenantService: TenantService,
        private route: ActivatedRoute
    )
    {
        this.tpl = service.getCurrentTemplate();
        this.contacts = service.getCurrentSigners();
        this.arbitraryText = service.getCurrentArbitraryText();
    }

    ngOnInit()
    {
        this.onlyEdit = (this.route.snapshot.params.FLAG_ONLY_EDIT === 'true' ?? false);

        // Esto seguramente habrá que cambiarlo
        if (this.contacts.length > 0 && this.contacts[0].length > 0)
        {
            if (this.contacts[0] && this.contacts[0][0].flags && this.contacts[0][0].flags.length > 0)
            {
                const flags = this.contacts[0][0].flags;
                const personalFlagFound = flags.findIndex(x => x == 'FIRMA_EN_TIENDA' || x == 'AUTOFIRMA' || x == 'FIRMA_LINK');
                if (personalFlagFound != -1)
                {
                    this.personalSend.isPersonal = true;
                    this.personalSend.flag = flags[personalFlagFound];

                    this.next();
                }

                // Recogemos las flags disponibles.
                this.contacts.forEach(
                    deploy =>
                    {
                        if (deploy[0].flags)
                        {
                            const found = this.availableFlags.indexOf(deploy[0].flags[0]) != -1;
                            if (!found)
                                this.availableFlags.push(deploy[0].flags[0]);
                        }
                    }
                )
            }
        }
    }

    public previous(): void
    {
        this.confirmation.open({
            body: this.translator.instant('You will lose the text definition'),
            title: this.translator.instant('Are you sure you want to go back?'),
            btn_ok: this.translator.instant('Accept'),
            btn_cancel: this.translator.instant('Cancel')
        })
            .subscribe(x =>
            {
                if (x)
                {
                    const commands: Array<any> = [
                        'send_assistant',
                        'arbitrarytext',
                        this.tpl.id
                    ];

                    if (this.onlyEdit)
                    {
                        commands.push({ FLAG_ONLY_EDIT: true });
                    }


                    this.router.navigate(commands, {
                        state: {
                            template: this.tpl,
                            signers: this.contacts,
                            arbitraryText: this.arbitraryText,
                            from: 'text',
                            wasGroup: this.service.wasGroup,
                            group: this.service.group,
                            cvs: this.service.cvs
                        }
                    });
                }
            });
    }

    public next(): void
    {

        zip(this.service.send(this.tpl), this.service.arbitraryText$)
            .subscribe(
                r =>
                {
                    this.service.deploy(this.tpl, this.contacts, r[1] as any);
                }
            );
    }

}
