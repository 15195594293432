import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipComponent } from './tip.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [TipComponent],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [TipComponent]
})
export class TipModule { }
