import { OAuthService } from 'angular-oauth2-oidc';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { WebServiceHelperService } from '../shared/web-service-helper.service';
import { SidenavService } from './shared/sidenav.service';
import { NavigationEnd, Router } from '@angular/router';
import { ResponsiveService } from '../core/services/responsive.service';
import { SendingsService } from '../shared/sendings.service';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyModalComponent } from '../privacy/privacy-modal/privacy-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserMenuService } from '../core/services/content/user-menu.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit
{
    public opened = false;
    public options: Array<any> = [
        { title: 'menu_document', role: 'any', icon: 'inbox', goTo: 'document' },
        // anterior icono draft
        { title: 'menu_editable', icon: 'subdirectory_arrow_right', goTo: 'editable', padded: true, padding: 1 },
        { title: 'menu_contacts', role: 'any', icon: 'assignment_ind', goTo: 'contacts' },
        { title: 'menu_document_template_managament', icon: 'file_copy', goTo: 'template_management/list' },
        { title: 'menu_editable_template_managament', icon: 'drive_file_rename_outline', goTo: 'editable_pdf/list' },
        { title: 'template_form_management', icon: 'supervised_user_circle', goTo: 'document_template_form_management/list' },
        { title: 'menu_editable_template_unattended_managament', icon: 'list_alt', goTo: 'editable_pdf/list_unattended' }
    ];

    @ViewChild('sidenav', { static: true }) sidenav;

    public currentRoute: string;

    // Inyección dinámica de servicios.
    public service: UserMenuService;
    public sendings: SendingsService;

    private unsubscriber: Subject<void>;

    constructor(
        public WsHelper: WebServiceHelperService,
        private oAuthService: OAuthService,
        private SideNav: SidenavService,
        public router: Router,
        public responsive: ResponsiveService,
        public dialog: MatDialog,
        private injector: Injector
    )
    {
        this.unsubscriber = new Subject<void>();
    }

    ngOnInit()
    {
        this.SideNav.sidenav = this.sidenav;
        this.currentRoute = this.router.url;

        // Load de servicios en funcion de la url, esto es para evitar REDIRECT
        // en la pantalla de firmar.
        this.router.events
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(
                x =>
                {
                    if (x instanceof NavigationEnd)
                    {
                        const blackList = [
                            // '/',
                            '/logincallback',
                            '/login',
                            '/sign/doc',
                            '/sign/form',
                            '/sign/signed',
                            '/sign/at_shop',
                            'secure_download'
                        ]


                        const found = blackList
                            .reduce(
                                (carry, current) =>
                                {
                                    return carry || x.url.indexOf(current) !== -1;
                                }, false);


                        if (!found)
                        {
                            this.service = this.injector.get(UserMenuService);
                            this.sendings = this.injector.get(SendingsService);

                            this.currentRoute = this.router.url;

                            this.destroy();
                        }
                    }
                }
            );
    }

    private destroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public SetMenuLinkSelected(element: string, child = false): boolean
    {
        return window.location.pathname.split('/')[child ? 3 : 2] === element;
    }

    public logOut(): void
    {
        this.WsHelper.logOut();
    }

    public openAbout(): void
    {
        console.log(this.currentRoute);
        this.dialog.open(PrivacyModalComponent, { width: '500px', height: '590px' });
    }
}
