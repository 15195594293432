import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'src/app/core/models/contacts/contact';

@Pipe({
    name: 'disabledChannelGeneral',
    pure: false
})
export class DisabledChannelGeneralGeneralPipe implements PipeTransform
{
    transform(deploys: Contact[][]): boolean
    {
        if (deploys && deploys.length > 0)
        {
            let hasToBeDisabled = false;
            deploys.forEach(
                deploy =>
                {
                    const contactFound = deploy.findIndex(contact => !contact)
                    if (contactFound != -1)
                    {
                        hasToBeDisabled = true;
                        return;
                    }

                }
            );

            return hasToBeDisabled;
        }

        return true;
    }
}
