import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { subtenantReducer } from './reducer';
import { SubtenantEffects } from './effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('subtenant', subtenantReducer),
        EffectsModule.forFeature([SubtenantEffects])
    ]
})
export class SubtenantListStoreModule { }
