import {
    Component,
    ViewChild,
    ViewContainerRef,
    ComponentRef,
    ComponentFactoryResolver,
    Inject,
    HostBinding,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogData } from '../shared/DialogStructure';

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent<TComponent> implements AfterViewInit, OnDestroy
{
    @HostBinding('class') classes = 'mat-typography';
    @ViewChild('target', { read: ViewContainerRef }) vcRef: ViewContainerRef;
    public componentRef: ComponentRef<any>;
    private unsubscriber: Subject<void>;

    constructor
    (
        private resolver: ComponentFactoryResolver,
        @Inject(MAT_BOTTOM_SHEET_DATA) public injectedData: DialogData<any>,
        private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent<TComponent>>
    )
    {
        this.unsubscriber = new Subject<void>();
    }

    ngAfterViewInit()
    {
        const factory = this.resolver.resolveComponentFactory(this.injectedData.dynamycComponent);
        this.componentRef = this.vcRef.createComponent(factory);

        this.componentRef.instance.setData(this.injectedData);

        // Subscribe al binding.
        this.componentRef.instance.binding
        .pipe(
            takeUntil(this.unsubscriber)
        )
        .subscribe(result =>
        {
            if (result)
            {
                this.close(this.injectedData);
            }
            else
            {
                console.log('Nome sierro habido un fallo equisde');
            }
        });
    }
    ngOnDestroy(): void
    {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public action(args?: any): void
    {
        if (this.componentRef.instance !== undefined)
        {
            this.componentRef.instance.action(args);
        }
    }

    public close(result: any): void
    {
        this.bottomSheetRef.dismiss(result);
    }

}
