import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { TFAuthDialogComponent } from 'src/app/content/user-menu/tf-auth-dialog/tf-auth-dialog.component';
import { PrivacyModalComponent } from 'src/app/privacy/privacy-modal/privacy-modal.component';
import { SendingsService } from 'src/app/shared/sendings.service';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { TwoFactorAuthService } from 'src/app/core/services/pages/two-factor-auth/two-factor-auth.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, AfterViewInit
{
    public tenantData: UserMenuService;
    public routePath = null;

    constructor(
        public wsh: WebServiceHelperService,
        public service: UserMenuService,
        public sendings: SendingsService,
        public dialog: MatDialog,
        public responsive: ResponsiveService,
        public tfAuthService: TwoFactorAuthService
    )
    {}

    ngOnInit()
    {
        this.tenantData = this.service;
    }

    ngAfterViewInit()
    {
    }

    public logOut(): void
    {
        this.wsh.logOut();
    }

    public openAbout(): void
    {
        this.dialog.open(PrivacyModalComponent, { width: '500px', height: '590px' });
    }

    public openTwoFactorAuth(): void{
        this.dialog.open(
            TFAuthDialogComponent,
            {
                width: "530px",
                height: "fit-content",
            }
        )
    }

}
