import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';
import { UserModel } from 'src/app/shared/Models/UserModel';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService
{
    public users: Observable<Array<UserModel>>;

    constructor(private wsh: WebServiceHelperService)
    {
    }

    public getAllUsers(): void
    {
        this.users = this.configureUsersObservable();
    }

    public post(user: UserModel): Observable<boolean>
    {
        const url = environment.backEndDomain + 'api/user_managment/post';
        return this.wsh.post(url, user);
    }

    public delete(id): void
    {
        const url = environment.backEndDomain + 'api/user_managment/del';
        this.wsh.delete(url, { id: id }).subscribe(r =>
        {
            this.getAllUsers();
        });
    }

    public getRoles(): Observable<Array<string>>
    {
        return this.wsh.get(
            environment.backEndDomain + 'api/user_managment/role'
        );
    }

    private configureUsersObservable(): Observable<Array<UserModel>>
    {
        return this.wsh.get<Array<any>>(
            environment.backEndDomain + 'api/user_managment/list'
        )
            .pipe(
                map(users =>
                {
                    return users.map(u =>
                    {
                        const uModel = new UserModel();

                        uModel.id = u.user_id;
                        uModel.name = u.user_name;
                        uModel.email = u.user_email;
                        uModel.created_at = u.user_created_at;
                        uModel.updated_at = u.user_updated_at;
                        uModel.surname = u.user_surname;
                        uModel.country = u.user_country;
                        uModel.region = u.user_region;
                        uModel.city = u.user_city;
                        uModel.address = u.user_address;
                        uModel.locale = u.user_locale;
                        uModel.rol = u.user_rol;

                        uModel.contact.address = u.contact_address;
                        uModel.contact.city = u.contact_city;
                        uModel.contact.company = u.contact_company;
                        uModel.contact.corporate_email = u.contact_corporate_email;
                        uModel.contact.country = u.contact_country;
                        uModel.contact.created_at = u.contact_created_at;
                        uModel.contact.custom_field1 = u.contact_custom_field1;
                        uModel.contact.customer_legal_identifier = u.contact_customer_legal_identifier;
                        uModel.contact.id = u.contact_id;
                        uModel.contact.locale = u.contact_locale;
                        uModel.contact.mobile_phone = u.contact_mobile_phone;
                        uModel.contact.name = u.contact_name;
                        uModel.contact.personal_email = u.contact_personal_email;
                        uModel.contact.postal_code = u.contact_postal_code;
                        uModel.contact.region = u.contact_region;
                        // uModel.contact.status_id = u.contact_status_id;
                        uModel.contact.surname_1 = u.contact_surname_1;
                        uModel.contact.surname_2 = u.contact_surname_2;
                        uModel.contact.updated_at = u.contact_updated_at;
                        uModel.contact.custom_value_0 = u.contact_custom_value_0;
                        uModel.contact.custom_value_1 = u.contact_custom_value_1;
                        uModel.contact.custom_value_2 = u.contact_custom_value_2;
                        uModel.contact.custom_value_3 = u.contact_custom_value_3;
                        uModel.contact.custom_value_4 = u.contact_custom_value_4;

                        return uModel;
                    }) as Array<UserModel>;
                }),
                take(1),
                catchError(err =>
                {
                    return of(new Array<UserModel>());
                })
            );
    }

    sendPaddwordReset(id: string)
    {
        const url = environment.backEndDomain + 'api/user_managment/passwordReset';
        return this.wsh.post(url, { id: id });
    }
}
