import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { TEMPLATE_TIPS } from 'src/app/configs/dicctionaries/send-assistan/assistain-template-tips.dictionary';
import { Observable, Subject } from 'rxjs';
import { PdfControlsService } from 'src/app/core/services/pdf-controls.service';
import { Dragable } from 'src/app/core/models/send-assistant/dragable.model';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { Location } from '@angular/common';
import { TemplateEditorService } from 'src/app/core/services/pages/template-editor.service';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { MobileStepperService } from 'src/app/core/services/pages/mobile-stepper.service';
import { Template } from 'src/app/core/models/api/template/template.model';

@Component({
    selector: 'app-template-editor',
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit, OnDestroy
{
    @HostBinding('class') classes = '';
    public signers = [{ name: 'Firmante 1', value: 0, color: 'blue' }];
    public dragables: Array<Dragable> = [];
    public selectedSigner = 0;

    // Ref.
    public TIPS = TEMPLATE_TIPS;

    protected unsubscriber: Subject<void>;
    public onlyEdit: boolean = false;

    constructor(
        public responsive: ResponsiveService,
        public pdfControls: PdfControlsService,
        protected toast: I18nToastService,
        protected dialog: MatDialog,
        protected location: Location,
        protected templateEditor: TemplateEditorService,
        protected router: Router,
        protected stepper: MobileStepperService,
        protected route: ActivatedRoute
    )
    {
        this.unsubscriber = new Subject<void>();
    }

    ngOnInit()
    {
        (this.templateEditor.template$ as Observable<Template>)
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(
                tpl =>
                {
                    if (tpl && tpl.tpl.id)
                    {
                        if (tpl.tpl.text_name && tpl.tpl.text_name.length > 0)
                            this.stepper.setSteps(5);
                        else
                            this.stepper.setSteps(4);
                    }

                    if(tpl.tpl.doc_count > 0){
                        this.templateEditor.setIsPreview(true);
                    }else{
                        this.templateEditor.setIsPreview(false);
                    }
                }
            )
        this.route.data
            .pipe(take(1))
            .subscribe(
                (data: Data) =>
                {
                    this.onlyEdit = data.isEditOnly || false;
                    if (this.onlyEdit)
                        this.classes = 'template-creation'
                }
            );

        // this.onlyEdit = (this.route.snapshot.params.FLAG_ONLY_EDIT === 'true' ?? false);

    }

    ngOnDestroy()
    {
        console.log('SE MUERE!');
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public continue(): void
    {
        this.pdfControls.serializeAndSave()
            .pipe(take(1))
            .subscribe(
                serialized =>
                {
                    const template = this.templateEditor.getTemplate();
                    this.router.navigate([
                        'send_assistant',
                        'contacts',
                        template.tpl.id]);
                },
                error =>
                {
                    let msg = '';

                    if (error instanceof HttpErrorResponse)
                        msg = error.error.message;
                    else
                        msg = error;

                    this.toast.open(msg, 'Accept', 7000, false);
                }
            )
    }

    public previous(): void
    {
        if (this.templateEditor.isPreviewMode())
            this.location.back();
        else
            this.router.navigate(['send_assistant', 'file']);
    }

    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: TEMPLATE_TIPS
        });
    }

    saveNReturn()
    {
        this.pdfControls.serializeAndSave()
            .pipe(take(1))
            .subscribe(
                serialized =>
                {
                    const template = this.templateEditor.getTemplate();
                    this.router.navigate(['/template_management', 'list']);
                },
                error =>
                {
                    let msg = '';

                    if (error instanceof HttpErrorResponse)
                        msg = error.error.message;
                    else
                        msg = error;

                    this.toast.open(msg, 'Accept', 7000, false);
                }
            )
    }

    editPrevious()
    {

        this.router.navigate(['/template_management', 'list']);
    }
}
