 <!-- Número contactos -->
 <div class="indicator">
    <div class="fab-initials" fxLayoutAlign="center center" style="font-size: 18px; font-weight: bold;"
        [style.color]="color.InvertColor(color.StringToColour(template && template.name ? template.name : 'julandron'), true)"
         [style.background-color]="color.StringToColour(template && template.name ? template.name : 'julandron')">
        {{ template && template.contact_count ? template.contact_count : '...' }}
        <mat-icon style="font-size: 14px; height: 12px; width: 12px;">person</mat-icon>
    </div>
</div>
<!-- Datos de la plantilla -->
<div class="pad-l10 data">
    <div class="template-name"
            [title]="template && template.name ? template.name : 'Cargando...'">
        {{ template && template.name ? template.name : 'Cargando...' }}
    </div>

    <div class="rbt" style="font-size: 12px; color: #333; line-height: 0.7em;">
        {{ template && template.created_at ? toDate(template.created_at) : 'Cargando...' }}
    </div>
</div>
<button *ngIf="showPreview" class="preview-btn" mat-raised-button color="primary" (click)="preview.emit(template)">
    {{ 'See' | translate }}
</button>