import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SubtenantState } from './state';

export const getSubtenantError = (state: SubtenantState) => state.errorMsg;
export const getIsLoading = (state: SubtenantState): boolean => state.isLoading;

export const selectSubtenantState: MemoizedSelector<
    object,
    SubtenantState
> = createFeatureSelector<SubtenantState>('subtenant');

export const selectSubtenantError: MemoizedSelector<
    object,
    any
> = createSelector(selectSubtenantState, getSubtenantError);

export const selectSubtenantIsLoading: MemoizedSelector<
    object,
    boolean
> = createSelector(
    selectSubtenantState,
    getIsLoading
);
