import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit
{
    @HostBinding() class = '';
    @Input() node: ContactNode;
    @Input() showBreadcrumbs = false;
    @Input() showNavArrow = true;
    @Output() selected = new EventEmitter<ContactNode>();

    constructor() { }

    ngOnInit(): void
    {
        if (!this.showNavArrow)
            this.class = 'no-arrow';
    }

}
