import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';
import { Signature } from '../../models/signature-picker/signature.model';

@Injectable({
    providedIn: 'root'
})
export class UserSignaturesRepositoryService
{

    constructor(private WsHelper: WebServiceHelperService) { }

    public getUserSignatures(): Observable<Signature>
    {
        const url = environment.backEndDomain + 'api/user_managment/userAutoSign';
        return this.WsHelper.get<Signature>(url);
    }
    public createUserSignature(payload: FormData): Observable<Signature>
    {
        const url = environment.backEndDomain + 'api/user_managment/userAutoSign';
        return this.WsHelper.post<Signature>(url, payload);
    }
    public editUserSignature(payload: FormData): Observable<Signature>
    {
        const url = environment.backEndDomain + 'api/user_managment/update_userAutoSign';
        return this.WsHelper.post<Signature>(url, payload);
    }
}
