import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactsManagementComponent } from './contacts-management.component';

const routes: Routes = [
    {
        path: '',
        component: ContactsManagementComponent,
        data: { groupsView: false }
    },
    {
        path: 'groups',
        component: ContactsManagementComponent,
        data: { groupsView: true }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContactsRoutingModule
{
}
