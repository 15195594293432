<ng-container *ngIf="responsive.isMobile$ | async; else desktop">
    <ng-container *ngIf="!onlyEdit">
        <app-mobile-stepper *ngIf="!(pdfControls.isPreview$ | async)" [currentStep]="2"></app-mobile-stepper>
    </ng-container>
    <div class="title flex-row-between">
        <ng-container *ngIf="!onlyEdit">2. </ng-container>{{ 'Prepare the template' | translate }}
        <button (click)="openHelp()" color="primary" mat-icon-button>
            <mat-icon>help</mat-icon>
        </button>
    </div>
    <div *ngIf="(pdfControls.isPreview$ | async) && !onlyEdit"></div>
    <app-editor></app-editor>
    <div class="footer flex-row-between pad-b10" *ngIf="onlyEdit == false">
        <button color="primary" mat-button (click)="previous()">
            {{ 'Back' | translate }}
        </button>

        <button *ngIf="!(pdfControls.isPreview$ | async)" color="primary" mat-raised-button (click)="continue()">
            {{ 'Next' | translate }}
        </button>
    </div>
    <div class="footer flex-row-between pad-b10" *ngIf="onlyEdit == true">
        <button color="primary" mat-button (click)="editPrevious()">
            {{ 'Back' | translate }}
        </button>

        <button *ngIf="!(pdfControls.isPreview$ | async)" color="primary" mat-raised-button (click)="saveNReturn()">
            {{ 'Save' | translate }}
        </button>
    </div>
</ng-container>

<!-- Desktop -->
<ng-template #desktop>
    <div class="help-col">
        <ng-container *ngIf="!(pdfControls.isPreview$ | async)">
            <div class="help-title">
                <mat-icon class="mar-r10 primary-color">help</mat-icon>
                <span class="subtitle">{{ 'Help' | translate }}</span>
            </div>
            <div class="scroller">
                <app-tip *ngFor="let tip of TIPS; let i = index" [idx]="i" [tip]="tip"></app-tip>
            </div>
        </ng-container>
    </div>
    <app-editor></app-editor>
    <div class="empty-col"></div>
    <div class="footer flex-row-between pad-b10" *ngIf="onlyEdit == false">
        <button color="primary" mat-button (click)="previous()">
            {{ 'Back' | translate }}
        </button>

        <button *ngIf="!(pdfControls.isPreview$ | async)" color="primary" mat-raised-button (click)="continue()">
            {{ 'Next' | translate }}
        </button>
    </div>
    <div class="footer flex-row-between pad-b10" *ngIf="onlyEdit == true">
        <button color="primary" mat-button (click)="editPrevious()">
            {{ 'Back' | translate }}
        </button>

        <button *ngIf="!(pdfControls.isPreview$ | async)" color="primary" mat-raised-button (click)="saveNReturn()">
            {{ 'Save' | translate }}
        </button>
    </div>
</ng-template>
