import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TwoFactorAuthService } from 'src/app/core/services/pages/two-factor-auth/two-factor-auth.service';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';

@Component({
  selector: 'app-tf-auth-dialog',
  templateUrl: './tf-auth-dialog.component.html',
  styleUrls: ['./tf-auth-dialog.component.scss']
})
export class TFAuthDialogComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  public isLoading: boolean = false;
  private unsubscribe = new Subject<void>();
  private status: BehaviorSubject<boolean>;
  public status$: Observable<boolean>;
  public qrCode: string = '';
  public secret: string = '';

  constructor(
    private dialogRef: MatDialogRef<TFAuthDialogComponent>,
    private twoFactorAuthService: TwoFactorAuthService,
    private toast: I18nToastService,
    public trans: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData<{}>,
  ) {
    this.status = new BehaviorSubject(false);
    this.status$ = this.status.asObservable();
  }

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      otp_code: new FormControl(null, [Validators.required])
    });
    this.isLoading = true;
    this.twoFactorAuthService.getTwoFactorAuthStatusInUser()
    .pipe(
      takeUntil(this.unsubscribe)
    )
    .subscribe(
      res => {
        if( res.status == 0 ){
          this.twoFactorAuthService.getQRCode()
          .pipe(
            takeUntil(this.unsubscribe)
          )
          .subscribe(
            res => {
              this.qrCode = res.qr_code;
              this.secret = res.secret_code;
              this.status.next(false);
            }
          );
        }else{
          this.status.next(true);
        }
        this.isLoading = false;
      }
    )

  }

  ngOnDestroy(): void{
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.status.complete();
  }

  private setErrors(errors, control):void{

    Object.keys(errors).forEach(errPath =>
    {
        let currentControl = control;
        errPath.split('.').forEach(errPathChunk =>
            {
                currentControl = currentControl.controls[errPathChunk];
            })

        const ngErrs = errors[errPath].reduce((carry, current) =>
        {
            carry[current] = true;
            return carry;
        }, {})

        currentControl.setErrors(ngErrs);
        currentControl.touched = true;

    })

    console.log(errors)
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public link(): void {
    this.twoFactorAuthService.linkTwoFactorAuth(this.formGroup.value.otp_code, this.secret)
    .pipe(
      takeUntil(this.unsubscribe)
    )
    .subscribe(
      res => {
        if( res.status == 1 ){
          this.status.next(true);
          this.dialogRef.close();
          this.toast.open(this.trans.instant('tf_activation_success'), 'Accept', 5000, true)

        }else{
          this.setErrors(res.errors, this.formGroup);
        }
      }
    );
  }

  public unlink(): void {
    this.isLoading = true;
    this.twoFactorAuthService.unlinkTwoFactorAuth()
    .pipe(
      takeUntil(this.unsubscribe)
    )
    .subscribe(
      res => {
        this.isLoading = false;
        this.dialogRef.close();
        this.toast.open(this.trans.instant('tf_deactivation_success'), 'Accept', 5000, true)
      }
    );
  }

  public closeDialong(): void {
    this.dialogRef.close();
  }

}
