import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { GeonamesState } from "./state";

export const getGeonamesError = (state: GeonamesState) => state.errorMsg;
export const getIsLoading = (state: GeonamesState): boolean => state.isLoading;

export const selectGeonamesState: MemoizedSelector<
    object,
    GeonamesState
> = createFeatureSelector<GeonamesState>('geonames');

export const selectGeonamesError: MemoizedSelector<
    object,
    any
> = createSelector(selectGeonamesState, getGeonamesError);

export const selectGeonamesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
    selectGeonamesState,
  getIsLoading
);
