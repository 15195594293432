<app-responsive-topbar></app-responsive-topbar>
<div class="bg-content document-tray-content mat-elevation-z6 pad10">

    <div class="pad-l10 pad-r10 flex-row-between">
        <div class="title flex-center-content">
            <button style="margin-left: -10px;" *ngIf="(responsive.isMobile$ | async) && groupsView" mat-icon-button
                (click)="treeview.toggle()">
                <mat-icon>
                    {{ treeview.opened ? 'close' : 'subdirectory_arrow_right' }}</mat-icon>
            </button>
            {{ (!groupsView ? 'menu_contacts' : 'Groups') | translate}}
            <button mat-icon-button [matMenuTriggerFor]="viewChange">
                <mat-icon>expand_more</mat-icon>
            </button>

            <mat-menu #viewChange>
                <div class="mat-typography" style="padding: 0 30px 10px 10px;">
                    {{ 'View change' | translate}}:
                </div>
                <mat-divider class="mar-b10"></mat-divider>
                <button mat-menu-item [disabled]="!groupsView" routerLink="/contacts">
                    <span>{{ 'Contacts view' | translate }}</span>
                </button>
                <button mat-menu-item [disabled]="groupsView" routerLink="/contacts/groups">
                    <span>{{ 'Groups view' | translate }}</span>
                </button>
            </mat-menu>
        </div>

        <!-- Boton de los filtros con estado -->
        <div class="flex-center-content">

            <button *ngIf="!(responsive.isMobile$ | async)" mat-button (click)="filters.toggle()">
                {{ filters.expanded ? ('Hide filters' | translate) : ('Show filters' | translate) }}
            </button>

            <!-- MOBILE -->
            <button *ngIf="(responsive.isMobile$ | async)" mat-icon-button (click)="filters.toggle()">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <mat-icon style="cursor: pointer;" (click)="filters.toggle()"
                [@iconExapansionAnimation]="filters.expanded ? 'EXPANDED' : 'COLLAPSED'">keyboard_arrow_up
            </mat-icon>

            <button mat-icon-button class="mar-l10" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button (click)="fileInput.click()" mat-menu-item class="rbt">
                    <mat-icon class="mar-r10">cloud_upload</mat-icon>
                    {{ 'Cargar excel de contactos' | translate }}
                </button>
                <button (click)="downloadTemplate()" mat-menu-item class="rbt">
                    <mat-icon class="mar-r10">cloud_download</mat-icon>
                    {{'Descargar plantilla' | translate}}
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="filters">
        <mat-expansion-panel #filters>
            <div class="not-too-big" style="justify-content: space-between;">
                <div>
                    <mat-form-field class="filter_input">
                        <mat-icon matPrefix class="mar-r10">person</mat-icon>
                        <mat-icon matSuffix (click)="filterObj.name = null; ApplyFilters();">clear</mat-icon>
                        <mat-label>{{ 'name' | translate }}</mat-label>
                        <input [(ngModel)]="filterObj.name" matInput placeholder="{{ 'name' | translate }}">
                    </mat-form-field>
                    <mat-form-field class="filter_input">
                        <mat-icon matPrefix class="mar-r10">mail</mat-icon>
                        <mat-icon matSuffix (click)="filterObj.email = null; ApplyFilters();">clear</mat-icon>
                        <mat-label>{{ 'mail' | translate }}</mat-label>
                        <input [(ngModel)]="filterObj.email" matInput placeholder="{{ 'mail' | translate }}">
                    </mat-form-field>
                    <mat-form-field class="filter_input">
                        <mat-icon matPrefix class="mar-r10">featured_play_list</mat-icon>
                        <mat-icon matSuffix (click)="filterObj.legalid = null; ApplyFilters();">clear</mat-icon>
                        <mat-label>{{ 'gdpr_new_legalId' | translate }}</mat-label>
                        <input [(ngModel)]="filterObj.legalid" matInput
                            placeholder="{{ 'gdpr_new_legalId' | translate }}">
                    </mat-form-field>
                    <mat-form-field *ngIf="groupsView" class="filter_input">
                        <mat-icon matPrefix class="mar-r10">group_work</mat-icon>
                        <mat-label>{{ 'Group' | translate }}</mat-label>
                        <input disabled matInput [value]="currentGroup ? currentGroup.node.name : null">
                    </mat-form-field>
                    <mat-form-field class="filter_input">
                        <mat-label>{{'Status' | translate}}</mat-label>
                        <mat-select [(ngModel)]="filterObj.status">
                            <mat-option *ngFor="let k of [{k:1, v: 'active'}, {k:2, v: 'inactive'}]" [value]="k.k">
                                {{ k.v | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button mat-stroked-button class="btn white" style="width: inherit; margin-right: 1em;" (click)="getExcel()">
                    {{ "Download excel report" | translate }}
                </button>

                <button mat-stroked-button class="btn white" style="margin-right: 80px;" (click)="ApplyFilters()">
                    {{ 'filter' | translate }}
                </button>


            </div>
        </mat-expansion-panel>
    </div>

    <mat-drawer-container [hasBackdrop]="(responsive.isMobile$ | async) ? true : false">
        <mat-drawer #treeview [mode]="(responsive.isMobile$ | async) ? 'over' : 'side'" [opened]="groupsView"
            class="pad-l10 pad-t10 groups" style="width: 250px; background-color: #fafafa;">
            <div>
                <div class="subtitle flex-row-between mar-b10" style="align-items: center;">
                    {{ 'Groups' | translate }}
                </div>
                <ng-container *ngIf="contactsService.groupsHierarchy$ | async as groupsHierarchy">
                    <cdk-tree *ngIf="groupsHierarchy.rawData().length > 0; else noData" [dataSource]="groupsHierarchy"
                        [treeControl]="treeControl">
                        <!-- This is the tree node template for leaf nodes -->
                        <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="tree-node">
                            <div #elementNode class="pointer" (click)="loadContacts(elementNode, node)"
                                (mouseover)="btnEdit._elementRef.nativeElement.style.display = 'block'"
                                (mouseleave)="btnEdit._elementRef.nativeElement.style.display = 'none'"
                                style="display: flex; align-items: center; justify-content: space-between;">
                                <!-- use a disabled button to provide padding for tree leaf -->
                                <div style="display: flex; align-items: center; width: calc(100% - 40px);">
                                    <button mat-icon-button disabled></button>
                                    <div [matTooltip]="node.name" class="node-name">{{node.name}}</div>
                                </div>
                                <button #btnEdit (click)="editGroup(node)" style="display: none;" mat-icon-button
                                    color="primary">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                        </cdk-nested-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="tree-node">
                            <div class="pointer" #elementNode (click)="loadContacts(elementNode, node)"
                                style="display: flex; flex-direction: row;">
                                <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" cdkTreeNodeToggle>
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <div (mouseover)="btnEdit._elementRef.nativeElement.style.display = 'block'"
                                    (mouseleave)="btnEdit._elementRef.nativeElement.style.display = 'none'"
                                    class="label-plus-edit" style="width: calc(100% - 40px); ">
                                    <div [matTooltip]="node.name" class="node-name">{{node.name}}</div>
                                    <button #btnEdit (click)="editGroup(node)" style="display: none;" mat-icon-button
                                        color="primary">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container cdkTreeNodeOutlet></ng-container>
                            </div>
                        </cdk-nested-tree-node>
                    </cdk-tree>
                </ng-container>

                <ng-template #noData>
                    {{ 'You can create a new group by pressing the + button at the botom right corner' | translate }}
                </ng-template>
            </div>
        </mat-drawer>
        <mat-drawer-content [ngClass]="{'pad-l10': !(responsive.isMobile$ | async) && groupsView}"
            style="display: flex;">
            <ng-container *ngIf="!groupsView; else groupViewTpl">
                <!-- Grid con los usuarios-->
                <app-contact-virtual-scroll #scroller class="grid flex-row-start"
                    style="overflow-y: auto; flex-wrap: wrap; flex: 1 1 0px" *ngIf="AuxContacts"
                    [totalItems]="AuxContacts.total" [pageSize]="AuxContacts.per_page" [cachedData]="AuxContacts.data"
                    [trackedPage]="0" (editedContact)="openModal($event, true, 'contact')"
                    (contactDeleted)="contactDeleted($event)">

                </app-contact-virtual-scroll>
            </ng-container>
            <ng-template #groupViewTpl>
                <app-contact-virtual-scroll #scroller class="grid flex-row-start"
                    style="overflow-y: auto; flex-wrap: wrap; flex: 1 1 0px" *ngIf="AuxContacts"
                    [totalItems]="AuxContacts.total" [groupView]="true" [pageSize]="AuxContacts.per_page"
                    [cachedData]="AuxContacts.data" [trackedPage]="0"
                    (editedContact)="openModal($event, true, 'contact')"
                    (contactDeleted)="contactDeleted($event)"></app-contact-virtual-scroll>
            </ng-template>
        </mat-drawer-content>
    </mat-drawer-container>

</div>
<input id="singleFile" type="file" (change)="fileChangeHandler($event)" #fileInput style="display:none;"/>
<button [matMenuTriggerFor]="options" mat-fab color="primary" class="floating-button">
    <mat-icon>add</mat-icon>
</button>
<mat-menu #options="matMenu">
    <button mat-menu-item (click)="openModal(null, false, 'contact')">
        <mat-icon>person</mat-icon>
        <span>{{ 'new contact' | translate }}</span>
    </button>
    <button mat-menu-item (click)="openModal(null, false, 'group')">
        <mat-icon>group_work</mat-icon>
        <span>{{ 'Add group' | translate }}</span>
    </button>
</mat-menu>
