import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyComponent } from './privacy.component';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { SignPrivacyModalComponent } from './sign-privacy-modal/sign-privacy-modal.component';

@NgModule({
    declarations: [PrivacyComponent, PrivacyModalComponent, SignPrivacyModalComponent],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
    ],
    exports: [PrivacyComponent, PrivacyModalComponent],
    entryComponents: [PrivacyModalComponent, SignPrivacyModalComponent]
})
export class PrivacyModule { }
