import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'src/app/core/models/contacts/contact';

@Pipe({
    name: 'selectedSendChannelGeneral',
    pure: false
})
export class SelectedSendChannelGeneralPipe implements PipeTransform
{
    transform(deploys: Contact[][]): string[]
    {
        const availableFlags: string[] = [];
        deploys.forEach(
            deploy =>
            {
                if (deploy[0])
                {
                    const deployFlag = deploy[0].flags[0];
                    const alreadyIn = availableFlags.indexOf(deployFlag) != -1;
                    if (!alreadyIn)
                        availableFlags.push(deployFlag);
                }
            }
        )

        return availableFlags;
    }
}
