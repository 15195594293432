<div class="content mat-typography" [formGroup]="formGroup">

    <div class="col">

        <mat-radio-group aria-label="Select an option" formControlName="key_type" class="row">
            <div class="col">
            <mat-label class="row bttm-margin">{{ 'Key type' | translate }}</mat-label>
            <mat-radio-button value="auth_code" class="row bttm-margin">{{'Authorization Code grant' | translate}}</mat-radio-button>
            <mat-radio-button value="passwd" class="row bttm-margin">{{'Password grant' | translate}}</mat-radio-button>
            </div>
        </mat-radio-group>

        <mat-form-field class="low-density" class="row">
            <mat-label>{{ 'name' | translate }}</mat-label>
            <input required matInput placeholder="{{ 'name' | translate }}" formControlName="name">
            <ng-container
                *ngIf="formGroup.controls['name'].touched && Array.isArray(formGroup.controls['name'].errors)">
                <mat-error *ngFor="let e of formGroup.controls['name'].errors">
                    {{ e | translate }}
                </mat-error>
            </ng-container>
        </mat-form-field>
        <mat-form-field class="low-density" class="row">
            <mat-label>{{ 'redirect' | translate }}</mat-label>
            <input required matInput placeholder="{{ 'redirect' | translate }}" formControlName="redirect">
            <ng-container
                *ngIf="formGroup.controls['redirect'].touched && Array.isArray(formGroup.controls['redirect'].errors)">
                <mat-error *ngFor="let e of formGroup.controls['redirect'].errors">
                    {{ e | translate }}
                </mat-error>
            </ng-container>
        </mat-form-field>
    </div>
</div>
