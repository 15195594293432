import { ArrayDataSource } from '@angular/cdk/collections';
import { Pipe, PipeTransform } from '@angular/core';
import { GroupArrayDataSource } from 'src/app/core/classes/group-array-datasource.class';
import { ContactNode } from 'src/app/core/models/contacts/contact-node.model';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';

@Pipe({
    name: 'groupHierarchy'
})
export class GroupHierarchyPipe implements PipeTransform
{
    private index = {};

    constructor(
        private service: ConstactsService
    )
    { }

    transform(currentNode: ContactNode, fullPath: boolean = false): Array<string>
    {
        const structure = this.service.getCurrentHierarchy().rawData();
        this.buildIndex({id: -1, name: 'Root'}, structure);
        const breadcrumbs = this.getPath({id: currentNode.id, name: currentNode.name});

        if (!fullPath)
            breadcrumbs.pop();
        breadcrumbs.shift();

        return breadcrumbs;
    }

    private buildIndex(root, children: Array<ContactNode>)
    {
        children.forEach(
            child =>
            {
                this.index[child.id] = root;
                this.buildIndex({id: child.id, name: child.name}, child.children);
            }
        );
    }

    private getPath(leaf): Array<string>
    {
        return this.index[leaf.id] ? this.getPath(this.index[leaf.id]).concat([leaf.name]) : [leaf.name];
    }
}
