<table mat-table #table [dataSource]="tableAdapter.data" style="width: 100%">

    <ng-container [matColumnDef]="'concept'">
        <th mat-header-cell style="vertical-align: middle;" *matHeaderCellDef>
            {{ 'Concept'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="tableAdapter.responsive.isMobile$ | async; else desktop"></ng-container>
            <div style="display: flex; flex-direction: column">
                <div><b>{{ row.concept }}</b></div>
                <div>{{ row.nav_id }}</div>
                <div>{{ row.created_at| prepareDate | date:'short' }}</div>
            </div>
            <ng-template #desktop>
                {{ row.concept }}
            </ng-template>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'nav_id'">
        <th mat-header-cell style="vertical-align: middle;" *matHeaderCellDef>
            {{ 'Document No' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            {{ row.nav_id }}
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'download'">
        <th mat-header-cell style="vertical-align: middle;" *matHeaderCellDef>
            {{ 'Download'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.nav_id !== null">
                <button mat-icon-button (click)="tableAdapter.downloadAttachment(row, null, null)">
                    <i class="material-icons">
                        cloud_download
                    </i>
                </button>
            </ng-container>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'created_at'">
        <th mat-header-cell style="vertical-align: middle;" *matHeaderCellDef>
            {{ 'Date' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            {{row.created_at| prepareDate | date:'short' }}
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'created_at2'">
        <th mat-header-cell style="vertical-align: middle;" *matHeaderCellDef>
            {{ 'Date'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            {{row.created_at| prepareDate | date:'short'}}
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'amount'">
        <th mat-header-cell style="vertical-align: middle;" *matHeaderCellDef>
            {{ 'Amount'  | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
            {{row.amount | currency:'EUR'}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableAdapter.getAllColumnsName(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableAdapter.getAllColumnsName();"></tr>


</table>

<mat-paginator class="mat-paginator-sticky" #paginator [pageIndex]="tableAdapter.currentPage" [length]="tableAdapter.elementCount"
               [pageSize]="tableAdapter.pageSize" (page)="tableAdapter.paginatorEventHandler($event)">
</mat-paginator>
