import { Action } from '@ngrx/store';
import { CreditTransferConfig } from 'src/app/core/models/subtenants-management/credit-transfer-config.model';
import { Tenant } from 'src/app/core/models/tenant-management/tenant';
import { TenantConfig } from 'src/app/core/models/tenant-management/tenant-config';

export enum SubtenantEditActionTypes
{
    FETCH = '[SUBTENANT_EDIT] FETCH',
    FETCH_SUCCESS = '[SUBTENANT_EDIT] FETCH_SUCCESS',
    FETCH_FAIL = '[SUBTENANT_EDIT] FETCH_FAIL',
    CREATE = '[SUBTENANT_EDIT] CREATE',
    CREATE_SUCCESS = '[SUBTENANT_EDIT] CREATE_SUCCESS',
    CREATE_FAIL = '[SUBTENANT_EDIT] CREATE_FAIL',
    CLEAR = '[SUBTENANT_EDIT] CLEAR',
    CREDIT_TRANSFER = '[SUBTENANT_EDIT] CREDIT_TRANSFER',
    CREDIT_TRANSFER_SUCCESS = '[SUBTENANT_EDIT] CREDIT_TRANSFER_SUCCESS',
    CREDIT_TRANSFER_FAIL = '[SUBTENANT_EDIT] CREDIT_TRANSFER_FAIL',
    UPDATE = '[SUBTENANT_EDIT] UPDATE',
    UPDATE_SUCCESS = '[SUBTENANT_EDIT] UPDATE_SUCCESS',
    UPDATE_FAIL = '[SUBTENANT_EDIT] UPDATE_FAIL',
    UPDATE_CONFIG = '[SUBTENANT_EDIT] UPDATE_CONFIG',
    UPDATE_CONFIG_SUCCESS = '[SUBTENANT_EDIT] UPDATE_CONFIG_SUCCESS',
    UPDATE_CONFIG_FAIL = '[SUBTENANT_EDIT] UPDATE_CONFIG_FAIL',
    DO_NOTHING = '[SUBTENANT_EDIT] DO_NOTHING'
}

export class Fetch implements Action
{
    readonly type = SubtenantEditActionTypes.FETCH;
    constructor(public payload: string) { }
}
export class FetchSuccess implements Action
{
    readonly type = SubtenantEditActionTypes.FETCH_SUCCESS;
    constructor(public payload: Tenant) { }
}
export class FetchFail implements Action
{
    readonly type = SubtenantEditActionTypes.FETCH_FAIL;
    constructor() { }
}
export class Create implements Action
{
    readonly type = SubtenantEditActionTypes.CREATE;
    constructor(public payload: Tenant) { }
}
export class CreateSuccess implements Action
{
    readonly type = SubtenantEditActionTypes.CREATE_SUCCESS
}
export class CreateFail implements Action
{
    readonly type = SubtenantEditActionTypes.CREATE_FAIL
    constructor(public payload: any) { }
}
export class Clear implements Action
{
    readonly type = SubtenantEditActionTypes.CLEAR;
}
export class CreditTransfer implements Action
{
    readonly type = SubtenantEditActionTypes.CREDIT_TRANSFER;
    constructor(public payload: CreditTransferConfig) { }
}
export class CreditTransferSuccess implements Action
{
    readonly type = SubtenantEditActionTypes.CREDIT_TRANSFER_SUCCESS;
}
export class CreditTransferFail implements Action
{
    readonly type = SubtenantEditActionTypes.CREDIT_TRANSFER_FAIL;
}
export class Update implements Action
{
    readonly type = SubtenantEditActionTypes.UPDATE;
    constructor(public payload: { tenant: Tenant, config: TenantConfig }) { }
}
export class UpdateSuccess implements Action
{
    readonly type = SubtenantEditActionTypes.UPDATE_SUCCESS
    constructor(public payload: TenantConfig) { }
}
export class UpdateFail implements Action
{
    readonly type = SubtenantEditActionTypes.UPDATE_FAIL
    constructor(public payload: { key: string }) { }
}
export class UpdateConfig implements Action
{
    readonly type = SubtenantEditActionTypes.UPDATE_CONFIG;
    constructor(public payload: TenantConfig) { }
}
export class UpdateConfigSuccess implements Action
{
    readonly type = SubtenantEditActionTypes.UPDATE_CONFIG_SUCCESS
    constructor(public payload: string) { }
}
export class UpdateConfigFail implements Action
{
    readonly type = SubtenantEditActionTypes.UPDATE_CONFIG_FAIL
    constructor(public payload: { key: string }) { }
}
export class DoNothing implements Action
{
    readonly type = SubtenantEditActionTypes.DO_NOTHING;
}
export type SubtenantEditActionsUnion = Fetch | FetchSuccess | FetchFail |
    Clear | Create | CreateSuccess | CreateFail | CreditTransfer | CreditTransferSuccess | CreditTransferFail | Update |
    UpdateSuccess | UpdateFail | UpdateConfig | UpdateConfigSuccess |
    UpdateConfigFail | DoNothing;
