import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-file-selector-dialog',
    templateUrl: './file-selector-dialog.component.html',
    styleUrls: ['./file-selector-dialog.component.scss']
})
export class FileSelectorDialogComponent implements OnInit
{
    @HostBinding('class') classes = 'mat-typography';
    public name: FormControl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: string)
    {
        this.name = new FormControl();
    }

    ngOnInit(): void
    {
        if (this.data)
            this.name.setValue(this.data);
    }

}
