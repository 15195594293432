import { Component, HostBinding, OnInit } from '@angular/core';
import { UserManagementService } from './shared/user-management.service';
import { UserFormDataComponent } from './user-form-data/user-form-data.component';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { BaseComponent, matBaseAnimations } from 'src/app/base/base.component';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { CrossPlatformDialogService } from 'src/app/cross-platform-dialog/shared/cross-platform-dialog.service';
import { DialogStructure } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { UserModel } from 'src/app/shared/Models/UserModel';
import { SidenavService } from 'src/app/sidenav/shared/sidenav.service';
import { UserMenuService } from 'src/app/core/services/content/user-menu.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation],
    providers: [MatBottomSheet]
})

export class UserManagementComponent extends BaseComponent implements OnInit
{
    @HostBinding('class') classes = 'bg mat-typography';

    public countries: Array<any>;
    public locales: Array<any>;
    public filterObj = { name: null, email: null };
    public roles$: ReplaySubject<Array<string>> = new ReplaySubject<Array<string>>(1);

    constructor(
        public userService: UserManagementService,
        public dialog: MatDialog,
        public confirmationBoxService: ConfirmationBoxService,
        private NavSidebar: SidenavService,
        private crossPlatformDialog: CrossPlatformDialogService,
        private translator: TranslateService,
        private toast: MatSnackBar,
        private userMenuService: UserMenuService,
        public responsive: ResponsiveService
    )
    {
        super(NavSidebar);
    }

    ngOnInit()
    {
        this.userService.getRoles().subscribe(this.roles$);
        this.userService.getAllUsers();

        // TODO: Esto que hacemos?
        this.countries = [];
        this.locales = ['es', 'ca', 'en'];
    }

    public openModal(user: UserModel, isEdit: boolean): void
    {
        const structure =
            {
                data: {
                    title: isEdit ? 'edit user' : 'new user',
                    positiveButton: isEdit ? 'save' : 'create',
                    countries: this.countries,
                    locales: this.locales,
                    roles$: this.roles$,
                    data: isEdit ? user : new UserModel(),
                    isEdit,
                    dynamycComponent: UserFormDataComponent,
                    hideCloseButton: false
                },
                width: '718px',
                height: '650px',
                disableClose: false
            } as DialogStructure<UserModel>;

        this.dialog.open(UserFormDataComponent, structure)
            .afterClosed()
            .subscribe(x =>
            {
                if (x)
                {
                    if (isEdit)
                        this.toast.open(
                            this.translator.instant('The user has been edited sucessful'),
                            this.translator.instant('Accept'),
                            { duration: 5000 }
                        );
                    else
                        this.toast.open(
                            this.translator.instant('The user has been created sucessful'),
                            this.translator.instant('Accept'),
                            { duration: 5000 }
                        );
                    this.userService.getAllUsers();
                }
            });
    }

    public delete(user: UserModel): void
    {

        this.userMenuService.user$
            .pipe(take(1))
            .subscribe(tU =>
            {
                let txt = '';
                txt += this.translator.instant('Los documentos generados por el usuario');
                txt += ' ';
                txt += user.name;
                txt += ' ';
                txt += this.translator.instant('se asignarán al usuario');
                txt += ' ';
                txt += tU.name;

                this.confirmationBoxService
                    .open({
                        title: this.translator.instant('Delete user'),
                        body: txt,
                        btn_ok: this.translator.instant('Aceptar'),
                        btn_cancel: this.translator.instant('Cancelar')
                    })
                    .subscribe(s =>
                    {
                        this.userService.delete(user.id);
                    });
            });


    }

    passwordReset(user: UserModel)
    {
        this.userService.sendPaddwordReset(user.id).subscribe(r => { console.log(r); });
    }
}
