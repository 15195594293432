import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, take } from 'rxjs/operators';
import { CONTACT_SELECTION_TIPS } from 'src/app/configs/dicctionaries/pdf-editable/contact-selection-tips.dictionary';
import { ContactFormComponent } from 'src/app/content/contacts/components/contact-form/contact-form.component';
import { ContactPickerBaseComponent } from 'src/app/content/contacts/components/contact-picker-base/contact-picker-base.component';
import { ContactSearchComponent } from 'src/app/content/contacts/components/contact-search/contact-search.component';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { Contact } from 'src/app/core/models/contacts/contact';
import { CustomTextConf } from 'src/app/core/models/contacts/custom-text-conf.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { ContactDefinitionService } from 'src/app/core/services/pages/contact-definition.service';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { ConfirmationBoxService } from 'src/app/shared/confirmation-box.service';
import { MakeThingsLookGoodService } from 'src/app/shared/MakeThingsLookGood.service';
import { MessageBoxService } from 'src/app/shared/message-box.service';

@Component({
    selector: 'app-contact-selection',
    templateUrl: './contact-selection.component.html',
    styleUrls: ['./contact-selection.component.scss']
})
export class ContactSelectionComponent extends ContactPickerBaseComponent implements OnInit
{
    // Ref.
    public TIPS = CONTACT_SELECTION_TIPS;
    public selectedContacts: Array<Array<Contact>> = [];
    private referer = null;
    private id: number;
    private pdfId: number;
    constructor(
        public responsive: ResponsiveService,
        public mtlg: MakeThingsLookGoodService,
        public toast: I18nToastService,
        public bottomSheet: MatBottomSheet,
        public contactService: ConstactsService,
        public dialog: MatDialog,
        private confirmation: ConfirmationBoxService,
        public translator: TranslateService,
        private service: ContactDefinitionService,
        private route: ActivatedRoute,
        private router: Router,
        public msgBox: MessageBoxService,
        public changeDetector: ChangeDetectorRef
    )
    {
        super(
            contactService,
            dialog,
            toast,
            bottomSheet,
            msgBox,
            translator,
            changeDetector
        );
    }

    ngOnInit(): void
    {
        this.sendType = 'simple';

        /**
         *  Como el guard nos valida la entrada de la ruta, pillamos los extras
         *  directamente y los asignamos al template.
         */
        this.template = this.service.getCurrentTemplate();
        const signers = this.service.getCurrentSigners();
        if (signers)
        {
            this.selectedContacts = signers;
        }

        // Revisamos si hay referer en la ruta.
        this.route.params
            .subscribe(
                params =>
                {
                    if (params.id)
                        this.id = params.id;
                    if (params.editablePdfId)
                        this.pdfId = params.editablePdfId;
                }
            )
        this.route.queryParams
            .subscribe(
                params =>
                {
                    if (params.referer)
                        this.referer = params.referer;
                }
            )
    }

    public unselectContact(i: number, contactIndex: number): void
    {
        const found = this.selectedContacts[i][contactIndex];
        if (!found.repeated)
            this.selectedContacts[i][contactIndex] = null;
    }
    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: this.TIPS
        });
    }
    public previous()
    {
        this.confirmation.open({
            body: this.translator.instant('You will lose the progress'),
            title: this.translator.instant('Are you sure you want to go back?'),
            btn_ok: this.translator.instant('Accept'),
            btn_cancel: this.translator.instant('Cancel')
        })
            .pipe(take(1))
            .subscribe(x =>
            {
                if (x)
                {
                    if (this.referer)
                    {
                        this.router.navigate([this.referer]);
                    }
                    else
                    {
                        this.router.navigate([
                            'editable_pdf',
                            'file'
                        ],
                            {
                                queryParams: { id: this.template.id, editablePdfId: this.pdfId }
                            });
                    }
                }
            });
    }
    public next()
    {
        try
        {
            if (this.selectedContacts.length != 1)
            {
                this.toast.open(
                    'Wrong number of contacts. Please add the necessary ones',
                    'Accept',
                    7000
                );
                throw new Error('worng numebr of contacts in ->');
            }
            this.selectedContacts.forEach((x, i) =>
            {
                const selectedLength = x.filter(y => y).length;
                if (selectedLength != this.template.contact_count)
                {
                    // this.stepper.selectedIndex = i;
                    this.toast.open(
                        'Wrong number of contacts. Please add the necessary ones',
                        'Accept',
                        7000
                    );
                    throw new Error('worng numebr of contacts in -> ' + i);
                }
            });

            const template = this.service.getCurrentTemplate();

            this.router.navigate(
                [
                    'editable_pdf',
                    'form'
                ],
                {
                    state: {
                        template,
                        editablePdfId: this.service.getPdfEditableId(),
                        signers: this.selectedContacts,
                        arbitraryText: this.service.arbitraryText,
                        from: 'contacts'
                    }
                });

        } catch (e)
        {
            console.warn(e);
        }
    }
}
