import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spaceExtractor'
})
export class SpaceExtractorPipe implements PipeTransform
{

    transform(value: string): string
    {
        return value ? value.replace(/ /g, '') : null;
    }
}
