import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { PDF_EDITABLE_STATUS } from 'src/app/configs/dicctionaries/pdf-editable/pdf-editable-status.dictionary';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { PaginatorService } from 'src/app/shared/auto-table/services/paginator.service';
import { DocumentExtrasModalComponent } from '../../../../document-tray/components/document-extras-modal/document-extras-modal.component';
import { AutoTableColumnDefinition } from '../../../../shared/auto-table/definitions/auto-table-column-definition';
import { LaravelPaginatedList } from '../../../../shared/auto-table/definitions/laravel-paginated-list';
import { TableAdapterInterface } from '../../../../shared/auto-table/definitions/table-adapter.interface';
import { BaseTableAdapterService } from '../../../../shared/auto-table/services/base-table-adapter.service';
import { ConfirmationBoxService } from '../../../../shared/confirmation-box.service';
import { ConfirmationBoxConf } from '../../../../shared/confirmation-box/confirmation-box.component';
import { DocumentService } from '../../content/document.service';
import { FormTemplateManagementService } from '../../content/form-template-management.service';

@Injectable({
    providedIn: 'root'
})
export class EditableTableAdapterService extends BaseTableAdapterService implements TableAdapterInterface
{

    public statusKeys: string[];

    public tplFilter: string = '';
    public batchFilter: string = '';
    public signerFilter: string = '';
    public statusFilter: string[] = [];
    public templateIdFilter: number;


    public range: Range;
    public dateFrom: Date;
    public dateTo: Date;
    public tplsKeys: Array<any>;


    constructor(
        private docService: DocumentService,
        private docTemplate: FormTemplateManagementService,
        public dialog: MatDialog,
        public confirmationDialog: ConfirmationBoxService,
        private translator: TranslateService,
        public responsive: ResponsiveService,
        public paginator: PaginatorService
    )
    {
        super(responsive, paginator);
    }

    backDate(numOfDays)
    {
        const today = new Date();
        return new Date(today.setDate(today.getDate() - numOfDays));
    }

    getStdCols(): Array<AutoTableColumnDefinition>
    {
        const cols = [
            {
                order: 1,
                column: 'title',
                columnTitle: 'Document name',
                isSticky: false
            },
            // {
            //     order: 2,
            //     column: 'batch_name',
            //     columnTitle: 'Lote',
            //     isSticky: false
            // },
            //   {
            //       order: 3,
            //       column: 'customer_legal_identifier',
            //       columnTitle: 'Nif',
            //       isSticky: false
            //   },
            {
                order: 3,
                column: 'full_name',
                columnTitle: 'Signers name',
                isSticky: false
            }
        ];

        return cols;
    }

    getNonStdCols(): Array<AutoTableColumnDefinition>
    {
        if (!this.responsive.IsMobile())
        {
            return [

                {
                    order: 4,
                    column: 'status',
                    columnTitle: this.translator.instant('Status'),
                    isSticky: false
                },
                {
                    order: 5,
                    column: 'created_at',
                    columnTitle: this.translator.instant('created_date'),
                    isSticky: false
                },
                // {
                //     order: 6,
                //     column: 'max_date',
                //     columnTitle: 'Última acción',
                //     isSticky: false
                // },
                {
                    order: 7,
                    column: 'retry',
                    columnTitle: this.translator.instant('Retry'),
                    isSticky: false
                },
                // {
                //     order: 8,
                //     column: 'audit_trail',
                //     columnTitle: this.translator.instant('Audit trail'),
                //     isSticky: false
                // },
                // {
                //     order: 9,
                //     column: 'more_info',
                //     columnTitle: this.translator.instant('More info'),
                //     isSticky: false
                // },
                {
                    order: 10,
                    column: 'download',
                    columnTitle: this.translator.instant('Download'),
                    isSticky: false
                },
                {
                    order: 11,
                    column: 'delete',
                    columnTitle: this.translator.instant('Delete'),
                    isSticky: false
                }
                // {
                //     order: 12,
                //     column: 'more',
                //     columnTitle: 'Mas',
                //     isSticky: false
                // }
            ];
        } else
        {
            return [
                {
                    order: 4,
                    column: 'actions',
                    columnTitle: this.translator.instant('Actions'),
                    isSticky: false
                }
            ]
        }
    }

    warmUp(route: ActivatedRoute): Observable<boolean>
    {


        this.dateFrom = this.backDate(3650);
        this.dateFrom.setHours(0, 0, 0, 0);
        this.dateTo = new Date();
        this.dateTo.setHours(23, 59, 59, 59);

        const paramsObs: Observable<ParamMap> = route.paramMap.pipe(take(1));
        const availableStatus = of(PDF_EDITABLE_STATUS).pipe(take(1), map(status => status.map(x => x.value)));

        const formsObs = this.docTemplate.listForms({ template: '', dateIni: this.dateFrom, dateEnd: this.dateTo })
            .pipe(
                take(1),
                map(arr =>
                {
                    arr.unshift({ id: -1, name: '' })
                    return arr;
                }
                )
            );

        return forkJoin([availableStatus, paramsObs, formsObs])
            .pipe(
                map(([tapStatus, tapParams, tapForms]) =>
                {
                    tapParams = tapParams as ParamMap;
                    this.statusKeys = tapStatus;
                    this.tplsKeys = tapForms;

                    if (tapParams.has('status'))
                    {
                        const statusIdArr = tapParams.get('status').split(',');
                        this.statusFilter = tapStatus.filter(k => statusIdArr.indexOf(k) > 0);
                    } else
                    {
                        this.statusFilter = tapStatus;
                    }

                    if (tapParams.get('template_id'))
                    {
                        this.templateIdFilter = parseInt(tapParams.get('template_id'));
                    }

                    this.statusFilter = this.statusFilter.filter(k => Number(k) !== 0)

                    return true;
                })
            );

    }

    getLaravelPaginatedList(loader?: boolean)
    {

        console.log(this.templateIdFilter)

        return this.docService.listAllForms(
            this.currentPage + 1,
            this.tplFilter,
            this.templateIdFilter,
            this.batchFilter,
            this.signerFilter,
            this.statusFilter,
            this.dateFrom,
            this.dateTo,
            loader
        ).pipe(
            map(r => this.parseSigners(r))
        );
    }

    getPdf(id: any)
    {
        this.docService.getForm(id);
    }

    retrySend(id: any)
    {
        this.docService.retrySendForm(id);
    }

    getPdfAuditTrail(id: any)
    {
        this.docService.getPdfAuditTrail(id);
    }

    more(row)
    {

        const f = {
            doc_id: row.doc_id
        };


        this.docService
            .listByTpl(row.tpl_id, f)
            .pipe(
                mergeMap(r => this.dialog.open(DocumentExtrasModalComponent, { data: r }).afterClosed())
            )
            .subscribe(
                r => console.log(r)
            );
    }

    delete(id: number)
    {
        const conf: ConfirmationBoxConf = {
            title: this.translator.instant('You are going to remove the document'),
            body: this.translator.instant('This actions is irreversible'),
            btn_ok: this.translator.instant('Accept'),
            btn_cancel: this.translator.instant('Cancel')
        };

        this.confirmationDialog.open(conf)
            .pipe(
                mergeMap(r => this.docService.deleteForm(id))
            )
            .subscribe(
                r => this.getData(),
                e => console.error(e)
            );
    }

    public downloadAttachment(docId, inputId, mime)
    {
        this.docService.downloadAttachment(docId, inputId, mime);
    }

    translateStatusKey(docStatus: any)
    {
        return this.docService.translateStatusKey(docStatus);
    }

    private parseSigners(data: LaravelPaginatedList<any>): LaravelPaginatedList<any>
    {
        data.data.forEach(row =>
        {
            const signers = row.full_name ? row.full_name.split('|') : [];
            const signatureStatus = row.full_status.split(',');

            row.parsedSigners = [];

            signers.forEach((signer, i) =>
            {
                const name = signer.trim();
                const newSigner = {
                    name: name,
                    initials: `${ name[0] }${ name[1] }`,
                    status: signatureStatus[i] || '0'
                };

                row.parsedSigners.push(newSigner);
            });
        });

        return data;
    }


}
