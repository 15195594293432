<div class="search-box">
    <mat-form-field appearance="standard" class="w100" style="margin-top: -20px">
        <input matInput placeholder="{{'Search' | translate}}" [(ngModel)]="searchText" (input)="textChanged($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
</div>
<div class="template-container">
    <div class="template-scroller">
        <app-template-simple [showPreview]="showPreview" *ngFor="let tpl of tplSubject | async" matRipple [template]="tpl"
            (click)="selectTemplate(tpl)" (preview)="preview.emit(tpl)" class="mar-t10 min-height-content"
            [ngClass]="{'selected': selectedTpl && (tpl.id == selectedTpl.id)}">
        </app-template-simple>
    </div>
</div>