import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { iDialogBase } from 'src/app/cross-platform-dialog/shared/iDialogBase';
import { GeoNamesService } from 'src/app/shared/geo-names.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Tip } from 'src/app/core/models/tip';
import { environment } from 'src/environments/environment';
import { localesDictionary } from 'src/app/configs/languages.dictionary';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { TipModalComponent } from 'src/app/content/tip-modal/tip-modal.component';
import { Contact } from 'src/app/core/models/contacts/contact';
import { CustomTextConf } from 'src/app/core/models/contacts/custom-text-conf.model';
import { ConstactsService } from 'src/app/core/services/pages/contacts/constacts.service';
import { DialogData } from 'src/app/cross-platform-dialog/shared/DialogStructure';
import { ConfirmationBoxService } from '../../../../shared/confirmation-box.service';
import { of } from 'rxjs';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit, iDialogBase
{

    binding: EventEmitter<boolean>;
    public locales: Array<any>;
    public countries: Array<any>;
    public region: Array<any>;
    public towns: Array<any>;
    public formGroup: FormGroup;
    Array: any = Array;
    private sub_region;
    public legalTip: Tip = {
        title: 'Legal identity',
        body: 'legal_identity_tip'
    };
    public mailTip: Tip = {
        title: 'mail',
        body: 'mail_tip'
    };
    public localeTip: Tip = {
        title: 'locale',
        body: 'locale_tip'
    };
    public asteriscTip: Tip = {
        title: 'Campos obligatorios',
        body: 'Los campos obligatorios están marcados con un *'
    };

    public localesDictionary = localesDictionary;

    constructor(
        private geoNames: GeoNamesService,
        private contactService: ConstactsService,
        private toast: MatSnackBar,
        private translator: TranslateService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ContactFormComponent>,
        private dialog: MatDialog,
        public responsive: ResponsiveService,
        private confirmationBoxService: ConfirmationBoxService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData<{ contact: Contact, config: Array<CustomTextConf> }>
    )
    {

        // tslint:disable-next-line:max-line-length
        const emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const mailValidator = Validators.pattern(emailregex);
        this.binding = new EventEmitter();

        const cnf = new Contact();

        // @ts-ignore
        cnf.id = '';
        // @ts-ignore
        cnf.customer_legal_identifier = [null, [Validators.required]];
        // @ts-ignore
        cnf.name = [null, [Validators.required]];
        // @ts-ignore
        cnf.surname_1 = [null, [Validators.required]];
        // @ts-ignore
        cnf.surname_2 = '';
        cnf.company = '';
        cnf.personal_email = '';
        // @ts-ignore
        cnf.corporate_email = [null, [Validators.required, mailValidator]];
        cnf.mobile_phone = '';
        cnf.address = '';
        cnf.postal_code = '';
        cnf.city = '';
        cnf.region = '';
        cnf.country = '';
        cnf.custom_field1 = '';
        cnf.company = '';
        // @ts-ignore
        cnf.user_id = null;
        cnf.locale = environment.defaultLang;
        cnf.status = 1;

        this.formGroup = formBuilder.group(cnf);

    }

    private setErrors(errors, control)
    {

        Object.keys(errors).forEach(errPath =>
        {
            let currentControl = control;
            errPath.split('.').forEach(errPathChunk =>
            {
                currentControl = currentControl.controls[errPathChunk];
            })

            const ngErrs = errors[errPath].reduce((carry, current) =>
            {
                carry[current] = true;
                return carry;
            }, {})

            currentControl.setErrors(ngErrs);
            currentControl.touched = true;

        })

        console.log(errors)
    }

    public setData(data: DialogData<{ contact: Contact, config: Array<CustomTextConf> }>)
    {

        data.data.config.forEach(x =>
        {
            console.log(x.getIdx());
        });

        this.formGroup.setValue(Object.assign({}, data.data.contact));
        this.countries = data.countries;
        this.locales = data.locales;

    }

    public action(): void
    {
        this.EditOrCreate(this.formGroup.value);
    }

    ngOnInit()
    {

        if (this.data.data.contact.id)
        {
            this.setData(this.data);
        }
    }

    /*
        public onCountryChange(event: MatSelectChange) {
            return new Observable(o => {
                this.data.user.country = event.value;
                this.geoNames.loadRegions(this.data.user.country).subscribe(r => {
                    this.region = r;
                    o.complete();
                });
            });
        }

        public onRegionChange(event: MatSelectChange): Observable<any> {
            return new Observable(o => {

                const aux = this.region.filter(x => {
                    return x.data.admin2_code === event.value;
                });

                this.data.user.region = aux[0].data.admin2_code;
                this.sub_region = aux[0].data.admin1_code;

                this.geoNames.loadTowns(
                    this.data.user.country,
                    this.sub_region,
                    this.data.user.region
                )
                    .subscribe(
                        r => {
                            this.towns = r;
                        },
                        e => {
                        },
                        () => {
                            o.complete();
                        }
                    );
            });
        }

        public initSelectsIfTheresData() {
            if (this.data.user.country && this.data.user.country.length > 0) {
                const e = new MatSelectChange(null, this.data.user.country);
                this.onCountryChange(e).subscribe(
                    () => {
                    },
                    () => {
                    },
                    () => {
                        if (this.data.user.region.length > 0) {
                            const e2 = new MatSelectChange(
                                null,
                                this.data.user.region
                            );
                            this.onRegionChange(e2).subscribe(
                                () => {
                                },
                                () => {
                                },
                                () => {
                                }
                            );
                        }
                    }
                );
            }
        }
    */
    public openHelp(): void
    {
        this.dialog.open(TipModalComponent, {
            width: '100%',
            height: '65%',
            data: [this.legalTip, this.mailTip, this.localeTip]
        });
    }

    public EditOrCreate(contactModel: any): void
    {
        if (contactModel.mobile_phone)
        {
            contactModel.mobile_phone = contactModel.mobile_phone.trim();
            contactModel.mobile_phone = contactModel.mobile_phone.replace(/ /g, "");
        }
        this.contactService.postContact(contactModel)
            .pipe(
                catchError(err =>
                {
                    const customTextBoom = Object.keys(err.error.errors).reduce((carry, current) =>
                    {

                        if (carry)
                        {
                            return true;
                        }

                        return current.match(/custom_value_/)?.length > 0
                    }, false)

                    const bodyKey = 'En caso afirmativo lo informaremos con los datos del contacto que has proporcionado.\nSi quieres indicar un firmante autorizado alternativo, haz clic en  Cancelar y rellena el campo "firmante autorizado".'
                    const cnf = {
                        title: this.translator.instant('¿El firmante autorizado es el mismo contacto?'),
                        body: this.translator.instant(bodyKey),
                        btn_ok: this.translator.instant('Accept'),
                        btn_cancel: this.translator.instant('Cancel')
                    };

                    if (customTextBoom)
                        return this.confirmationBoxService.open(cnf)
                            .pipe(
                                mergeMap(r =>
                                {

                                    if (r === false)
                                    {
                                        throw err;
                                    }

                                    const key = Object.keys(err.error.errors).reduce((carry, current) =>
                                    {

                                        if (carry.length > 0)
                                        {
                                            return carry;
                                        }

                                        return current.match(/custom_value_.*/);
                                    }, []).pop();

                                    contactModel[key] = [
                                        contactModel.name,
                                        contactModel.surname_1,
                                        contactModel.surname_2
                                    ]
                                        .join(' ')
                                        .trim();

                                    contactModel.mobile_phone = contactModel.mobile_phone.trim();
                                    contactModel.mobile_phone = contactModel.mobile_phone.replace(/ /g, "");

                                    return this.contactService.postContact(contactModel);
                                })
                            )
                    else
                    {
                        throw err;
                    }
                })
            )
            .subscribe(r =>
            {
                delete r.status_id;
                this.formGroup.patchValue(Object.assign({}, r));
                this.dialogRef.close(r);
            },
                e =>
                {
                    this.setErrors(e.error.errors, this.formGroup);

                });
    }
}
