import { Injectable } from '@angular/core';
import { Template } from '../../models/api/template/template.model';
import { Observable, BehaviorSubject, throwError, Subject, from, of, ReplaySubject } from 'rxjs';
import { TemplateEditorRepositoryService } from '../../repositories/pages/template-editor-repository.service';
import { TemplateOutputDragged } from '../../models/api/template/template-output.model';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap } from 'rxjs/operators';
import { PdfEditable } from '../../models/pdf-editable/pdf-editable.model';
import { FormTemplateInput } from '../../models/pdf-editable/form-template-input.model';
import { isBoolean } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class TemplateEditorService
{
    public editablePdfId$: Observable<number>;
    private editablePdfId: BehaviorSubject<number>;
    public template$: Observable<Template | PdfEditable>;
    private template: BehaviorSubject<Template | PdfEditable>;
    private pdf: Subject<Uint8Array>;
    public pdf$: Observable<Uint8Array>;

    private isPreview: BehaviorSubject<boolean>;
    public isPreview$: Observable<boolean>;


    constructor(
        private repository: TemplateEditorRepositoryService
    )
    {
        const emptyTemplate = new Template();

        this.template = new BehaviorSubject(emptyTemplate);
        this.template$ = this.template.asObservable();

        this.pdf = new ReplaySubject(1);
        this.pdf$ = this.pdf.asObservable();

        this.isPreview = new BehaviorSubject(false);
        this.isPreview$ = this.isPreview.asObservable();

        this.editablePdfId = new BehaviorSubject(null);
        this.editablePdfId$ = this.editablePdfId.asObservable();
    }
    public load(id: number, pdfEditableId?: number): Observable<Template>
    {
        if (pdfEditableId)
            this.editablePdfId.next(pdfEditableId);

        const sub = this.repository.getTplInput(id);
        sub
        .pipe(
            mergeMap(
                pdf =>
                {
                    this.template.next(pdf);
                    return this.repository.getTplPreviewAsArrayBuffer(id);
                }
            )
        )
        .subscribe(
            pdfBuffer =>
            {
                const uintArray = new Uint8Array(pdfBuffer);
                this.pdf.next(uintArray);
            },
            err => this.template.next(undefined)
        );

        return sub;
    }
    public loadForm(id: number): Observable<Template | PdfEditable>
    {
        const sub = this.repository.getFormInput(id);
        sub
        .pipe(
            mergeMap(
                pdf =>
                {
                    this.template.next(pdf);
                    return this.repository.getFormPreviewAsArrayBuffer(id);
                }
            )
        )
        .subscribe(
            pdfBuffer =>
            {
                const uintArray = new Uint8Array(pdfBuffer);
                this.pdf.next(uintArray);
            },
            err => this.template.next(undefined)
        );

        return sub;
    }
    public setPdfEditableId(val: number): void
    {
        this.editablePdfId.next(val);
    }
    public setIsPreview(state: boolean): void
    {
        this.isPreview.next(state);
    }
    public isPreviewMode(): boolean
    {
        return this.isPreview.value;
    }
    public getTemplate(): Template
    {
        return this.template.value as Template;
    }
    public getForm(): PdfEditable
    {
        return this.template.value as PdfEditable;
    }
    public getPdfEditableId(): number
    {
        return this.editablePdfId.value;
    }
    public reset(): void
    {
        this.pdf.next(null);
    }
    public resetTemplate(): void
    {
        this.template.next(null);
    }
    public buildFormInputMapping(formInputs: any, editablePdf?: PdfEditable): Array<{form_template_input_id: number, data: any}>
    {
        const formKeys = Object.keys(formInputs);
        const form = editablePdf || this.getForm();
        const fields_text = form.form_template_input
        .map(
            input =>
            {
                const found = formKeys.find(x => x == input.name);
                if (found)
                {
                    let formInput = formInputs[found];
                    if (input.type == 'Button')
                    {
                        // En el caso que el resultado del parse int sea un número,
                        // querrá decir que estamos trabajando con un checkbox, por tanto,
                        // tenemos que parsear el value a on u off
                        if (typeof(formInput) === 'boolean')
                        {
                            formInput = formInput ? 'On' : 'Off';
                        }
                    }
                    return {
                        form_template_input_id: input.id,
                        data: formInput
                    }
                }
            }
        );

        return fields_text || [];
    }
}
