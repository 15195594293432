import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TemplateData } from 'src/app/core/models/api/template/template-data.model';
import { I18nToastService } from 'src/app/core/services/i18n-toast.service';
import { TextDefinitionService } from 'src/app/core/services/pages/text-definition.service';
import { TEXT_ROUTE_EXTRAS } from 'src/app/core/mocks/pages/text-definition/route.mock';
import { Contact } from 'src/app/core/models/contacts/contact';

@Injectable({
    providedIn: 'root'
})
export class MessageDefinitionGuard implements CanActivate
{
    constructor(
        private router: Router,
        private service: TextDefinitionService,
        private toast: I18nToastService
    )
    { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const extras = this.router.getCurrentNavigation().extras;
        // const extras = TEXT_ROUTE_EXTRAS;
        if (extras.state
            && extras.state.deploy)
        {

            this.service.setFormDeploy(extras.state.deploy);
            return true;
        }
        else
        {
            console.error('The template data was not correct');
            this.toast.open(
                'The template data was not correct',
                'Accept',
                5000
            );

            this.router.navigate(['editable_pdf', 'file']);

            return false;
        }
    }
}
