import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { TemplateData } from '../../models/api/template/template-data.model';
import { Contact } from '../../models/contacts/contact';
import { ContactNode } from '../../models/contacts/contact-node.model';
import { AribtraryStructure } from '../../models/send-assistant/arbitrary-structure.model';

@Injectable({
    providedIn: 'root'
})
export class ArbitraryTextDefinitionService
{
    private data: BehaviorSubject<AribtraryStructure>;
    public data$: Observable<AribtraryStructure>;
    public formData$: Observable<any>;
    private formData: BehaviorSubject<any>;

    constructor()
    {
        this.data = new BehaviorSubject<AribtraryStructure>(null);
        this.data$ = this.data.asObservable();

        this.formData = new BehaviorSubject<any>(null);
        this.formData$ = this.formData.asObservable();
    }

    public getData(): AribtraryStructure
    {
        return this.data.value;
    }
    public setData(
        template: TemplateData,
        contacts: Contact[][],
        arbitraryText: { [key: string]: string; },
        wasGroup: boolean = false,
        group: ContactNode,
        cvs: boolean,
        editablePdfId?: number): void
    {
        const struct = new AribtraryStructure();

        struct.contacts = contacts;
        struct.tpl = template;
        struct.tpl_id = template.id;
        struct.arbitraryText = arbitraryText;
        struct.wasGroup = wasGroup;
        struct.group = group
        struct.cvs = cvs;

        if (editablePdfId)
            struct.editablePdfId = editablePdfId;

        this.data.next(struct);
    }
    public setFormData(val: any): void
    {
        this.formData.next(val);
    }
    public getFormData(): any
    {
        return this.formData.value;
    }
}
