import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-box',
    templateUrl: './confirmation-box.component.html',
    styleUrls: ['./confirmation-box.component.css']
})
export class ConfirmationBoxComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationBoxConf) {}

    ngOnInit() {}
}

export interface ConfirmationBoxConf {
    title: string;
    body: string;
    btn_ok: string;
    btn_cancel: string;
}
