import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateSearchComponent } from './template-search.component';
import { MaterialModule } from 'src/app/material/material.module';
import { TemplateSimpleComponent } from './components/template-simple/template-simple.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [TemplateSearchComponent, TemplateSimpleComponent],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule
    ],
    exports: [TemplateSearchComponent]
})
export class TemplateSearchModule { }
