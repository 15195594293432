import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditorComponent} from './components/editor/editor.component';
import {ControlRibbonComponent} from './components/control-ribbon/control-ribbon.component';
import {MaterialModule} from 'src/app/material/material.module';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResizableModule} from '../resizable/resizable.module';
import {PageIndexFilterPipe} from './pipes/page-index-filter.pipe';
import {TipModalModule} from '../tip-modal/tip-modal.module';
import {ItemConfigurationComponent} from './components/item-configuration/item-configuration.component';
import {OutputFilterPipe} from './pipes/output-filter.pipe';
import { EditableEditorComponent } from './components/editable-editor/editable-editor.component';
import { SignatureOnlyRibbonComponent } from './components/signature-only-ribbon/signature-only-ribbon.component';
import { FileSelectorDialogComponent } from './components/signature-only-ribbon/components/file-selector-dialog/file-selector-dialog.component';


@NgModule({
    declarations: [
        EditorComponent,
        ControlRibbonComponent,
        PageIndexFilterPipe,
        ItemConfigurationComponent,
        OutputFilterPipe,
        EditableEditorComponent,
        SignatureOnlyRibbonComponent,
        FileSelectorDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        PdfViewerModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ResizableModule,
        TipModalModule
    ],
    exports: [
        EditorComponent, ControlRibbonComponent, EditableEditorComponent, FileSelectorDialogComponent
    ]
})
export class TemplateEditorModule
{

    constructor()
    {
    }

}
