import { DocumentService } from '../core/services/content/document.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { MaterialTable, MaterialTableColumn, MaterialTablePaginator } from './shared/tableModel';
import { TablePayload, TableProvider } from './shared/iTableProvider';
import { MatPaginator } from '@angular/material/paginator';
import { TenantService } from '../core/services/pages/tenant-management/tenant.service';

@Component({
    selector: 'app-material-table',
    templateUrl: './material-table.component.html',
    styleUrls: ['./material-table.component.css']
})
export class MaterialTableComponent implements OnInit
{
    @Input() mountedTable: MaterialTable;
    @Input() tableId: string;
    @Input() tableProvider: TableProvider;
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('container') container: ElementRef;

    public initialWidth = null;
    public table: MaterialTable;
    public tzOffset: any;

    constructor(
        public docServ: DocumentService,
        public tenant: TenantService
    )
    {


        this.tzOffset = new Date()['getGmtTimezoneOffset']()
    }

    ngOnInit()
    {
        // Si pasan la tabla montada por referencia, la configuramos directamente.
        if (this.mountedTable && this.mountedTable.columns.length > 0)
        {
            this.table = _.cloneDeep(this.mountedTable);
        }
        // Si no, usamos el provider y la url para recoger los datos.
        else
        {
            this.filter({ params: {} });
        }

        this.initialWidth = this.container.nativeElement.offsetWidth;
    }

    public FilterData(payload: TablePayload): void
    {
        // Si aplicamos los filtros sobre la tabla, tenemos que poner el paginador en la
        // primera página siempre.
        this.paginator.pageIndex = 0;

        this.filter(payload);
    }

    public Navigate(event: any): void
    {
        console.log(event);
        this.tableProvider.setEndpointUrl(
            `api/document/type/${ this.tableProvider.templateId }?page=${ (event.pageIndex + 1) }`,
            this.tableProvider.templateId);
        this.filter(this.tableProvider.filters);
    }

    private getDataFromProvider(payload: TablePayload): Observable<any[]>
    {
        return this.tableProvider.getData(payload);
    }

    private generateTableFromServiceData(r: any): void
    {
        const columns: MaterialTableColumn[] = r.conf.columns;
        const rows = r.rows.data;
        const paginator: MaterialTablePaginator = {
            from: r.rows.from,
            to: r.rows.to,
            total: r.rows.total,
            last_page_url: r.rows.last_page_url,
            next_page_url: r.rows.next_page_url,
            prev_page_url: r.rows.prev_page_url,
            per_page: r.rows.per_page
        }

        this.table = new MaterialTable(columns, true, true);
        this.table.rows = rows;
        this.table.paginator = paginator;
    }

    private filter(payload: TablePayload): void
    {
        this.getDataFromProvider(payload).subscribe(r =>
        {
            this.generateTableFromServiceData(r);
        },
            error =>
            {
                console.log(error);
            });
    }
}
