import { Tile } from "./tile.interface";

export class TileNumeric implements Tile
{
    public icon: string;
    public iconColor: string;
    public iconFabColor: string;
    public title: string;

    public entries: Array<TileNumericEntry>;

    constructor()
    {
        this.icon = 'insert_drive_file';
        this.iconColor = "white";
        this.iconFabColor = '#987ADF';
        this.title = "Documentos";
        this.entries = new Array<TileNumericEntry>();
    }
}

export class TileNumericEntry
{
    public description: string;
    public value: number;
    public tend: number;
    public link: any[];


    constructor()
    {}
}
