import { Contact } from 'src/app/core/models/contacts/contact';

export class UserModel
{
    public id: string;
    public name: string;
    public email: string;
    public created_at: string;
    public updated_at: string;
    public surname: string;
    public country: string;
    public region: string;
    public city: string;
    public address: string;
    public locale: string;
    public rol: string;
    public is_partner: number;

    public contact: Contact;

    constructor()
    {
        this.country = '';
        this.region = '';
        this.city = '';
        this.address = '';
        this.name = '';
        this.surname = '';
        this.email = '';
        this.rol = '';
        this.is_partner = 0;

        this.contact = new Contact();
    }

}
