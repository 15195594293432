import { SubtenantMovement } from 'src/app/core/models/subtenants-management/subtenant-movement.model';
import { CommonState } from 'src/app/models/default.model';
import { LaravelPaginatedList } from 'src/app/shared/auto-table/definitions/laravel-paginated-list';

export interface SubtenantInvoicesState extends CommonState
{
    invoices: LaravelPaginatedList<SubtenantMovement>
}

export const subtenantInitialState: SubtenantInvoicesState =
{
    invoices: null,
    isLoading: true,
    errorMsg: null
}
