import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../sidenav/shared/sidenav.service';
import { AnimationTriggerMetadata, trigger, state, style, transition, animate } from '@angular/animations';

export const ANIMATION_TIMING = '300ms cubic-bezier(0.4,0.0,0.2,1)';

export const matBaseAnimations: { iconExapansionAnimation: AnimationTriggerMetadata } = {
    iconExapansionAnimation: trigger('iconExapansionAnimation', [
        state('COLLAPSED', style({ transform: 'rotate(180deg)' })),
        state('EXPANEDED', style({ transform: 'rotate(0deg)' })),
        transition('EXPANDED <=> COLLAPSED', [
            animate(ANIMATION_TIMING)
        ]),
    ])
}

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit
{

    // CONSTRUCTOR
    constructor(public SideNav: SidenavService) { }

    ngOnInit()
    {
    }

    // MÉTODOS PÚBLICOS
    public OpenSideNav(): void
    {
        this.SideNav.open();
    }

    public StringToColour(name): string
    {
        let hash = 0;
        for (let i = 0; i < name.length; i++)
        {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++)
        {
            const value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }

        return colour;
    }

    public InvertColor(hex: string, bw: boolean): string
    {
        if (hex.indexOf('#') === 0)
        {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3)
        {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6)
        {
            throw new Error('Invalid HEX color.');
        }
        let r: any = parseInt(hex.slice(0, 2), 16),
            g: any = parseInt(hex.slice(2, 4), 16),
            b: any = parseInt(hex.slice(4, 6), 16);
        if (bw)
        {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
    }

    private padZero(str: string, len?: number)
    {
        len = len || 2;
        const zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }
}
