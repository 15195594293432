import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ArbitraryTextDefinitionService} from '../services/pages/arbitrary-text-definition.service';

@Injectable({
    providedIn: 'root'
})
export class ArbitraryTextGuard implements CanActivate {
    constructor(
        private router: Router,
        private service: ArbitraryTextDefinitionService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const extras = this.router.getCurrentNavigation().extras;
        // const extras = ARBITRARY_TEXT_ROUTE_MOCK;

        if (extras.state
            && extras.state.template
            && extras.state.signers
            && extras.state.from
            && extras.state.arbitraryText) {
            if (!extras.state.template.text_count || extras.state.template.text_count === 0) {
                if (extras.state.from === 'contacts') {

                    const commands: Array<any> = ['send_assistant', 'text'];

                    if (route.params.FLAG_ONLY_EDIT === 'true' ?? false) {
                        commands.push({FLAG_ONLY_EDIT: true});
                    }

                    this.router.navigate(
                        commands,
                        {
                            state: {
                                template: extras.state.template,
                                signers: extras.state.signers,
                                arbitraryText: extras.state.arbitraryText,
                                wasGroup: extras.state.wasGroup,
                                group: extras.state.group,
                                cvs: extras.state.cvs
                            }
                        }
                    );
                }

                if (extras.state.from === 'text') {

                    const commands: Array<any> = ['send_assistant', 'contacts', extras.state.template.id];

                    if (route.params.FLAG_ONLY_EDIT === 'true' ?? false) {
                        commands.push({FLAG_ONLY_EDIT: true});
                    }

                    this.router.navigate(
                        commands,
                        {
                            state: {
                                template: extras.state.template,
                                signers: extras.state.signers,
                                arbitraryText: extras.state.arbitraryText,
                                wasGroup: extras.state.wasGroup,
                                group: extras.state.group,
                                cvs: extras.state.cvs
                            }
                        }
                    );
                }

                return false;
            }

            this.service.setData(
                extras.state.template,
                extras.state.signers,
                extras.state.arbitraryText,
                extras.state.wasGroup,
                extras.state.group,
                extras.state.cvs);

            return true;
        }

        this.router.navigate(['send_assistant', 'file']);

        return false;
    }
}
