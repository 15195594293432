import { Injectable } from '@angular/core';
import { Observable,  } from 'rxjs';
import { LegalVerificationService } from 'src/app/core/services/pages/legal-verification/legal-verification.service';
import { WebServiceHelperService } from 'src/app/shared/web-service-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LegalVerificationRepositoryService {

  constructor(
    private wsh: WebServiceHelperService,
  ){}

  public verifyLegalIdentifier(legal_identification: string, config:{}): Observable<any>{

    const url = `${environment.backEndDomain}api/contact_managment/verify${config['type']}Contact/${config['token']}?legal=${legal_identification}`;
    return this.wsh.get<Observable<any>>(url);

  }

  public getTenantLegalVerification(): Observable<{id: number, created_at: string, update_at: string, key: string, type: string, value: string}>{
    return this.wsh.get(environment.backEndDomain + 'api/legal_verification')

  }

}
