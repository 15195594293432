import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {BaseComponent, matBaseAnimations} from 'src/app/base/base.component';
import { DocumentTemplateFormManagementService } from 'src/app/core/services/content/document-template-form-management.service';
import {ResponsiveService} from 'src/app/core/services/responsive.service';
import {SidenavService} from 'src/app/sidenav/shared/sidenav.service';

@Component({
    selector: 'app-document-template-form-list',
    templateUrl: './document-template-form-list.component.html',
    styleUrls: ['./document-template-form-list.component.scss'],
    animations: [matBaseAnimations.iconExapansionAnimation]
})
export class DocumentTemplateFormListComponent extends BaseComponent implements OnInit, OnDestroy {
    public templates$: Subject<Array<any>> = new Subject<Array<any>>();
    public filtersGroup: FormGroup;

    private unsubscriber: Subject<void>;

    constructor(
        public SideNav: SidenavService,
        public docFrmMng: DocumentTemplateFormManagementService,
        public responsive: ResponsiveService) {
        super(SideNav);

        const now = new Date();
        const today = new Date();
        today.setDate(now.getDate() + 3600);
        const past = new Date();
        past.setDate(now.getDate() - 3600);

        this.filtersGroup = new FormGroup({
            dateIni: new FormControl(past),
            dateEnd: new FormControl(today),
            // status:  new FormControl(1)
        });

        this.unsubscriber = new Subject<void>();
    }

    ngOnInit(): void {
        this.filter();
    }

    ngOnDestroy(): void {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    public filter(): void {
        const filters = Object.assign({}, this.filtersGroup.value);
        console.log(filters);
        this.docFrmMng.listFlaggedDocForms(filters).subscribe(r => {
            this.templates$.next([])
            this.templates$.next(r)
        });
    }

    refreshList() {
        this.filter();
    }
}
